import styled from 'styled-components';

interface HeaderUserProps {
	pressed?: boolean;
}

export const MenuContainer = styled.div`
	border-left: 1px solid #f4f4f4;
	height: 80px;
	justify-content: center;
	align-items: center;
	display: flex;
	background: transparent;
	width: 88px;
`;

export const HeaderUserContainer = styled.div<HeaderUserProps>`
	height: 40px;

	.dropdown-toggle-categoria {
		padding: 0;
	}

	.header_user_flex {
		display: flex;
		align-items: center;
		cursor: pointer;
	}

	.user_circle {
		background: ${(props) => (props.pressed ? '#208337' : '#18aa36')} 0% 0%
			no-repeat padding-box;
		border: 1px solid ${(props) => (props.pressed ? '#208337' : '#18aa36')};
		opacity: 1;
		height: 40px;
		width: 40px;
		border-radius: 20px;
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		font: normal normal 500 14px/19px Roboto;
		letter-spacing: 0px;
		color: #ffffff;
		text-transform: uppercase;
		vertical-align: center;
	}

	.dropdown-menu {
		background: #f6f6f8 0% 0% no-repeat padding-box !important;
		box-shadow: 0px 6px 12px #0000001a;
		border: 1px solid #ebebeb;
		border-radius: 24px;

		width: 328px;
	}

	.dropdown_circle {
		background: #18aa36 0% 0% no-repeat padding-box;
		border: 1px solid #18aa36;
		opacity: 1;
		height: 40px;
		width: 40px;
		border-radius: 20px;
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		font: normal normal 500 14px/19px Roboto;
		letter-spacing: 0px;
		color: #ffffff;
		text-transform: uppercase;
		vertical-align: center;
	}

	:hover {
		.user_circle {
			background: ${(props) => (props.pressed ? '#208337' : '#208337')} 0% 0%
				no-repeat padding-box;
			border: 1px solid ${(props) => (props.pressed ? '#208337' : '#208337')};
		}
	}

	.row-center {
		display: flex;
		flex-direction: row;
		align-items: center;
	}

	.user-item-select {
		background: rgb(246, 246, 248) 0% 0% no-repeat padding-box;

		:hover {
			background: rgba(240, 240, 242, 2) 0% 0% no-repeat padding-box;
		}

		cursor: pointer;
		img {
			width: 20px;
			height: 20px;
		}
		p {
			margin: 0;
		}
		.select-label {
			text-align: left;
			font: normal normal 500 16px/21px Roboto;
			letter-spacing: 0px;
			color: #676767;
			margin-left: 18px;
		}
		padding-top: 26px;
		padding-bottom: 26px;

		border-top: 1px solid #ebebeb;
		border-bottom: 1px solid #ebebeb;
	}

	.user-info {
		margin-left: 8px;
		p {
			margin: 0;
		}
		.user-title {
			text-align: left;
			font: normal normal bold 16px/21px Roboto;
			letter-spacing: 0px;
			color: #676767;
		}
		.user-email {
			text-align: left;
			font: normal normal normal 16px/21px Roboto;
			letter-spacing: 0px;
			color: #676767;
		}
	}

	.pt-24 {
		padding-top: 24px;
	}
	.pb-24 {
		padding-bottom: 24px;
	}
	.pl-24 {
		padding-left: 24px;
	}
	.pr-24 {
		padding-right: 24px;
	}
`;
