/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Dashboard from "./dashboard";
import Login from "./login";
import Relatorios from "./relatorios";
import PrivateRoute from "./privateRoute";
import AppRoutes from "./appRoutes";
import { SnackbarProvider } from "notistack";
import RecoverPassword from "./RecoverPassword";
import ForgotPasswordPage from "./ForgotPassword";
//import Admin from './admin/admin';
//import Login from './login/login';

export default function Routes() {
  /**
     * 
				<Route path='/' exact component={Login} />
				<Route path='/admin' exact component={Admin} />
     */

  return (
    <SnackbarProvider
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      preventDuplicate
      autoHideDuration={3000}
      maxSnack={3}
    >
      <BrowserRouter>
        <Switch>
          <Route path="/login" exact component={Login} />
          <Route path="/recovery" exact component={RecoverPassword} />
          <Route path="/esqueceuasenha" exact component={ForgotPasswordPage} />
          
          <PrivateRoute path="/" component={AppRoutes} />
        </Switch>
      </BrowserRouter>
    </SnackbarProvider>
  );
}
