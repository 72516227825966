/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useRef, useState } from 'react';
import {
	Body,
	Container,
	Header,
	HeaderButton,
	HeaderSearchContainer,
	HeaderSearchInput,
	HeaderTitle,
	Card,
	CardItem,
	CardTitle,
	GreenLine,
	CardValue,
	CardPercentage,
	CardIconContainer,
	Row,
	CardLegend,
	CardRow,
	RowBetween,
	ToggleText,
	ListRank,
	ListRankTextLight,
	Grid,
	Skeleton,
	HeaderUserContainer,
	CardMeasureTitle,
	CardPercentageContainer,
	SearchSelector,
	SelectMenu,
	SelectItem,
	CalendarSettings,
	MerchantRank,
	MerchantLine,
	FooterDash,
	HeaderContainer,
} from './style';
import {
	UilCalendarAlt,
	UilSearch,
	UilChartDown,
	UilBoltAlt,
	UilTear,
	UilBusAlt,
	UilPhone,
	UilTrophy,
	UilAngleDown,
} from '@iconscout/react-unicons';

import NumberFormat from 'react-number-format';

import './styles.css';
import { Dropdown, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';

import moment from 'moment';
import { DateRange } from 'react-date-range';
import { pt } from 'date-fns/locale';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import CalendarDropDown from '../../components/calendar/Calendar';
import api from '../../services/api';
import { useEffect } from 'react';
import {
	DashCategory,
	DashTimeline,
	Measure,
	DashMerchant,
} from '../../models/model';

import TrendingUp from '../../assets/images/trending_up.svg';
import TrendingDown from '../../assets/images/trending_down.svg';
import AccountIcon from '../../assets/images/account.svg';
import PieChartGreen from '../../assets/images/pie_chart-green.svg';

import ArrowDownDark from '../../assets/images/arrow_dropdown-dark.svg';
import DropdownMenu from 'react-bootstrap/esm/DropdownMenu';
import Chart from 'react-google-charts';
import DefaultImg from '../../assets/images/default.png';

import useDraggableScroll from 'use-draggable-scroll';
import { useHistory } from 'react-router-dom';

import HeaderUserButton from '../../components/logOut';
import {
	CalendarContainer,
	CalendarioPersonalizado,
	CalendarModal,
} from '../../components/CalendarioContainer';

export default function Dashboard() {
	const history = useHistory();
	const [filtroDia, setFiltroDia] = useState('hoje');

	const [rangeHeader, setRangeHeader] = useState([
		{
			startDate: moment(moment().format('DD/MM/YYYY'), 'DD/MM/YYYY').toDate(),
			endDate: moment(moment().format('DD/MM/YYYY'), 'DD/MM/YYYY').toDate(),
			key: 'selection',
		},
	]);
	////console.log(range)

	const [user, setUser] = useState('');

	useEffect(() => {
		const login = localStorage.getItem('@bill-login');
		if (login) setUser(login);
	}, []);

	const headerData = [
		{ dia: 'hoje' },
		{ dia: 'ontem' },
		{ dia: 'este mês' },
		//{ dia: 'personalizado', personalizado: true },
	];

	const [rankInfo] = useState({
		nome: 'Manaus',
		data: [
			{ valor: 20000, unidade: 'Manaus' },
			{ valor: 12000, unidade: 'P.Figueredo' },
			{ valor: 15000, unidade: 'Boa Vista' },
			{ valor: 8000, unidade: 'Rondonia' },
			{ valor: 200, unidade: 'Rio branco' },
			{ valor: 15750, unidade: 'Amapa' },
			{ valor: 18540, unidade: 'Bonfim' },
			{ valor: 5, unidade: 'Rorainopolis' },
		],
	});

	const [searchActive, setSearchActive] = useState(false);

	function handleRange(dia: string) {
		const p = [
			{
				dia: 'hoje',
				f: function () {
					setRangeHeader([
						{
							startDate: moment(
								moment().format('DD/MM/YYYY'),
								'DD/MM/YYYY'
							).toDate(),
							endDate: moment(
								moment().format('DD/MM/YYYY'),
								'DD/MM/YYYY'
							).toDate(),
							key: 'selection',
						},
					]);
				},
			},
			{
				dia: 'ontem',
				f: function () {
					setRangeHeader([
						{
							startDate: moment(
								moment().subtract(1, 'days').format('DD/MM/YYYY'),
								'DD/MM/YYYY'
							).toDate(),
							endDate: moment(
								moment().subtract(1, 'days').format('DD/MM/YYYY'),
								'DD/MM/YYYY'
							).toDate(),
							key: 'selection',
						},
					]);
				},
			},
			{
				dia: 'este mês',
				f: function () {
					setRangeHeader([
						{
							startDate: moment().startOf('month').toDate(),
							endDate: moment(
								moment().format('DD/MM/YYYY'),
								'DD/MM/YYYY'
							).toDate(),
							key: 'selection',
						},
					]);
				},
			},
			{ dia: 'personalizado', f: function () {} },
		];

		const item = p.find((d) => d.dia === dia);
		if (item) {
			item.f();
		}
	}

	async function getTransactionMeasure() {
		const token = sessionStorage.getItem('@bill-token');

		const config = {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		};

		const startDate = moment(rangeHeader[0].startDate).format('YYYY-MM-DD');
		const endDate = moment(rangeHeader[0].endDate).format('YYYY-MM-DD');
		setLoadingMeasures(true);

		try {
			const response = await api.get(
				`/admin/api/transaction/measure?start=${startDate}&end=${endDate}`,
				config
			);
			//console.log(response.data);
			if (response.data) {
				setMeasures(response.data);
			}
			setLoadingMeasures(false);
		} catch (error: any) {
			console.error(error);
			setLoadingMeasures(false);
			if (error.response && error.response.status === 401) {
				history.push('/login');
			}
		}
	}

	async function getTransactionCategory() {
		const token = sessionStorage.getItem('@bill-token');

		const config = {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		};

		const startDate = moment(rangeHeader[0].startDate).format('YYYY-MM-DD');
		const endDate = moment(rangeHeader[0].endDate).format('YYYY-MM-DD');
		setLoadingCategories(true);
		try {
			const response = await api.get(
				`/admin/api/transaction/measure/category?start=${startDate}&end=${endDate}`,
				config
			);
			//console.log(response.data);
			if (response.data) {
				setCategories(response.data);
			}

			setLoadingCategories(false);
		} catch (error: any) {
			console.error(error);
			setLoadingCategories(false);
			if (error.response && error.response.status === 401) {
				history.push('/login');
			}
		}
	}

	async function getTransactionMerchant() {
		const token = sessionStorage.getItem('@bill-token');

		const config = {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		};

		const startDate = moment(rangeHeader[0].startDate).format('YYYY-MM-DD');
		const endDate = moment(rangeHeader[0].endDate).format('YYYY-MM-DD');
		setLoadingMerchants(true);
		try {
			const response = await api.get(
				`/admin/api/transaction/measure/merchant?start=${startDate}&end=${endDate}`,
				config
			);
			//console.log(response.data);
			if (response.data) {
				setMerchants(response.data);
			}

			setLoadingMerchants(false);
		} catch (error: any) {
			console.error(error);
			setLoadingMerchants(false);
			if (error.response && error.response.status === 401) {
				history.push('/login');
			}
		}
	}

	const [measures, setMeasures] = useState<Measure | undefined>();
	const [loadingMeasures, setLoadingMeasures] = useState(false);

	const [categories, setCategories] = useState<DashCategory[]>([]);
	const [loadingCategories, setLoadingCategories] = useState(false);

	const [merchants, setMerchants] = useState<DashMerchant[]>([]);
	const [loadingMerchants, setLoadingMerchants] = useState(false);

	function calculatePercentage(current: number, compare: number) {
		const newCurrent = current;
		const newCompare = compare;
		////console.log(newCurrent, newCompare);
		var measure = (newCurrent / newCompare - 1) * 100;
		//console.log(newCurrent, newCompare, measure);

		////console.log(measure);
		//let percentage = 0;
		console.log(newCurrent, compare);
		console.log(measure);
		return measure;
	}

	useEffect(() => {
		getTransactionMeasure();
		getTransactionCategory();
		//getTransactionMerchant();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [rangeHeader]);

	//console.log(measures && measures?.compare.total > measures?.current.total);

	const gridRef = useRef<any>(null);
	const { onMouseDown } = useDraggableScroll(gridRef);

	function ScrollTo() {
		if (gridRef && gridRef.current) {
			console.log(1314);
			gridRef.current.scrollBy(0, 1000);
		}
	}

	return (
		<Container>
			<Header className='mr-30 ml-15'>
				<HeaderContainer>
					<HeaderTitle>Dashboard</HeaderTitle>
					{headerData.map((data) => {
						return (
							<HeaderButton
								key={data.dia}
								onClick={(e) => {
									e.preventDefault();
									setFiltroDia(data.dia);
									handleRange(data.dia);
								}}
								active={data.dia === filtroDia}>
								<p>{data.dia}</p>
							</HeaderButton>
						);
					})}

					<CalendarioPersonalizado
						item={filtroDia}
						setItem={setFiltroDia}
						range={rangeHeader}
						setRange={setRangeHeader}
					/>
				</HeaderContainer>

				<HeaderUserButton />
			</Header>

			<Body className=''>
				<div
					className='mr-30'
					style={{ display: 'flex', flexDirection: 'row' }}>
					{loadingMeasures ? (
						<Card>
							<CardItem>
								<CardMeasureTitle>Transações</CardMeasureTitle>
								<GreenLine />
								<CardValue>
									<Skeleton style={{ height: 32, width: 32 }} />
								</CardValue>
								<CardPercentage>
									<Skeleton style={{ height: 16, width: 50 }} />
								</CardPercentage>
							</CardItem>
						</Card>
					) : (
						<Card>
							<CardItem>
								<CardMeasureTitle>Transações</CardMeasureTitle>
								<GreenLine />
								<CardValue>{measures?.current.total}</CardValue>
								<CardPercentageContainer>
									<CardPercentage
										negative={
											parseFloat(measures?.current.total || '0') -
												parseFloat(measures?.compare.total || '0') <
											0
										}>
										<img
											src={TrendingUp}
											alt='up'
											className='TrendingUp'
											//color='#4BAF50'
										/>
										<img
											src={TrendingDown}
											alt='down'
											className='TrendingDown'
											//color='#C90716'
											//size='12'
										/>
									</CardPercentage>
									<span>
										{Math.abs(
											parseFloat(measures?.current.total || '0') -
												parseFloat(measures?.compare.total || '0') || 0
										).toLocaleString('pt-BR', {
											maximumFractionDigits: 0,
										})}{' '}
										transações
									</span>
								</CardPercentageContainer>
							</CardItem>
						</Card>
					)}
					{loadingMeasures ? (
						<Card style={{ marginLeft: 12, flex: 3 }}>
							<CardItem>
								<CardMeasureTitle>R$ Transacionados</CardMeasureTitle>
								<GreenLine />
								<CardValue>
									<Skeleton style={{ height: 32, width: 32 }} />
								</CardValue>
								<CardPercentage>
									<Skeleton style={{ height: 16, width: 50 }} />
								</CardPercentage>
							</CardItem>
							<CardItem>
								<CardMeasureTitle>Débito</CardMeasureTitle>
								<GreenLine />
								<CardValue>
									<Skeleton style={{ height: 32, width: 32 }} />
								</CardValue>
								<CardPercentage>
									<Skeleton style={{ height: 16, width: 50 }} />
								</CardPercentage>
							</CardItem>
							<CardItem>
								<CardMeasureTitle>Crédito</CardMeasureTitle>
								<GreenLine />
								<CardValue>
									<Skeleton style={{ height: 32, width: 32 }} />
								</CardValue>
								<CardPercentage>
									<Skeleton style={{ height: 16, width: 50 }} />
								</CardPercentage>
							</CardItem>
						</Card>
					) : (
						<Card style={{ marginLeft: 12, flex: 3 }}>
							<CardItem>
								<CardMeasureTitle>R$ Transacionados</CardMeasureTitle>
								<GreenLine />

								<CardValue>
									<span>R$ </span>
									{measures &&
										parseFloat(measures?.current.amount || '0').toLocaleString(
											'pt-BR',
											{ minimumFractionDigits: 2 }
										)}
								</CardValue>
								<CardPercentageContainer>
									<CardPercentage
										negative={
											parseFloat(measures?.current.amount || '0') -
												parseFloat(measures?.compare.amount || '0') <
											0
										}>
										<img
											src={TrendingUp}
											alt='up'
											className='TrendingUp'
											color='#4BAF50'
											//size='12'
										/>
										<img
											src={TrendingDown}
											alt='down'
											className='TrendingDown'
											color='#C90716'
											//size='12'
											style={{}}
										/>
									</CardPercentage>
									<span>
										R${' '}
										{Math.abs(
											parseFloat(measures?.current.amount || '0') -
												parseFloat(measures?.compare.amount || '0')
										).toLocaleString('pt-BR', {
											maximumFractionDigits: 2,
											minimumFractionDigits: 2,
										})}
									</span>
								</CardPercentageContainer>
							</CardItem>
							<CardItem>
								<CardMeasureTitle>Débito</CardMeasureTitle>
								<GreenLine />

								<CardValue>
									<span>R$ </span>

									{measures &&
										measures?.current.resume.debit.toLocaleString('pt-BR', {
											minimumFractionDigits: 2,
										})}
								</CardValue>
								<CardPercentageContainer>
									<CardPercentage
										negative={
											(measures?.current.resume.debit || 0) -
												(measures?.compare.resume.debit || 0) <
											0
										}>
										<img
											src={TrendingUp}
											alt='up'
											className='TrendingUp'
											color='#4BAF50'
											//size='12'
										/>
										<img
											src={TrendingDown}
											alt='down'
											className='TrendingDown'
											color='#C90716'
											//size='12'
										/>
									</CardPercentage>

									<span>
										R${' '}
										{Math.abs(
											(measures?.current.resume.debit || 0) -
												(measures?.compare.resume.debit || 0)
										).toLocaleString('pt-BR', {
											maximumFractionDigits: 2,
											minimumFractionDigits: 2,
										})}
									</span>
								</CardPercentageContainer>
							</CardItem>
							<CardItem>
								<CardMeasureTitle>Crédito</CardMeasureTitle>
								<GreenLine />

								<CardValue>
									<span>R$ </span>
									{measures &&
										measures?.current.resume.credit.toLocaleString('pt-BR', {
											minimumFractionDigits: 2,
										})}
								</CardValue>
								<CardPercentageContainer>
									<CardPercentage
										negative={
											(measures?.current.resume.credit || 0) -
												(measures?.compare.resume.credit || 0) <
											0
										}>
										<img
											src={TrendingUp}
											alt='up'
											className='TrendingUp'
											color='#4BAF50'
											//size='12'
										/>
										<img
											src={TrendingDown}
											alt='down'
											className='TrendingDown'
											color='#C90716'
											//size='12'
										/>
									</CardPercentage>
									<span>
										R${' '}
										{Math.abs(
											(measures?.current.resume.credit || 0) -
												(measures?.compare.resume.credit || 0)
										).toLocaleString('pt-BR', {
											maximumFractionDigits: 2,
											minimumFractionDigits: 2,
										})}
									</span>
								</CardPercentageContainer>
							</CardItem>
						</Card>
					)}
				</div>

				{loadingCategories ? (
					<Grid className='mr-30' style={{ marginTop: 16 }} length={4}>
						<CardRow>
							<CardItem>
								<Row>
									{
										<CardIconContainer>
											<Skeleton style={{ height: '100%', width: '100%' }} />
										</CardIconContainer>
									}

									<CardTitle>
										<Skeleton style={{ height: '100%', width: '100%' }} />
									</CardTitle>
								</Row>

								<Row>
									<div style={{ marginRight: 24 }}>
										<CardValue>
											<Skeleton style={{ height: 32, width: 56 }} />
										</CardValue>
										<CardLegend>transações</CardLegend>
									</div>
									<div>
										<CardValue>
											<span>R$</span>
											<Skeleton style={{ height: 32, width: 56 }} />
										</CardValue>
										<CardLegend>transacionados</CardLegend>
									</div>
								</Row>
							</CardItem>
						</CardRow>
						<CardRow>
							<CardItem>
								<Row>
									{
										<CardIconContainer>
											<Skeleton style={{ height: '100%', width: '100%' }} />
										</CardIconContainer>
									}

									<CardTitle>
										<Skeleton style={{ height: '100%', width: '100%' }} />
									</CardTitle>
								</Row>

								<Row>
									<div style={{ marginRight: 24 }}>
										<CardValue>
											<Skeleton style={{ height: 32, width: 56 }} />
										</CardValue>
										<CardLegend>transações</CardLegend>
									</div>
									<div>
										<CardValue>
											<span>R$</span>
											<Skeleton style={{ height: 32, width: 56 }} />
										</CardValue>
										<CardLegend>transacionados</CardLegend>
									</div>
								</Row>
							</CardItem>
						</CardRow>
						<CardRow>
							<CardItem>
								<Row>
									{
										<CardIconContainer>
											<Skeleton style={{ height: '100%', width: '100%' }} />
										</CardIconContainer>
									}

									<CardTitle>
										<Skeleton style={{ height: '100%', width: '100%' }} />
									</CardTitle>
								</Row>

								<Row>
									<div style={{ marginRight: 24 }}>
										<CardValue>
											<Skeleton style={{ height: 32, width: 56 }} />
										</CardValue>
										<CardLegend>transações</CardLegend>
									</div>
									<div>
										<CardValue>
											<span>R$</span>
											<Skeleton style={{ height: 32, width: 56 }} />
										</CardValue>
										<CardLegend>transacionados</CardLegend>
									</div>
								</Row>
							</CardItem>
						</CardRow>
						<CardRow>
							<CardItem>
								<Row>
									{
										<CardIconContainer>
											<Skeleton style={{ height: '100%', width: '100%' }} />
										</CardIconContainer>
									}

									<CardTitle>
										<Skeleton style={{ height: '100%', width: '100%' }} />
									</CardTitle>
								</Row>

								<Row>
									<div style={{ marginRight: 24 }}>
										<CardValue>
											<Skeleton style={{ height: 32, width: 56 }} />
										</CardValue>
										<CardLegend>transações</CardLegend>
									</div>
									<div>
										<CardValue>
											<span>R$</span>
											<Skeleton style={{ height: 32, width: 56 }} />
										</CardValue>
										<CardLegend>transacionados</CardLegend>
									</div>
								</Row>
							</CardItem>
						</CardRow>
					</Grid>
				) : (
					<Grid
						onMouseDown={onMouseDown}
						ref={gridRef}
						style={{
							marginTop: 16,
							paddingLeft: 15,
							marginLeft: -15,
							
						}}
						length={categories.length}>
						{categories.map((category, index) => {
							return (
								<SubCard
									titulo={category.category}
									transacao={category.count}
									valor={category.amount}
									mr30={categories.length - 1 === index}
								/>
							);
						})}
					</Grid>
				)}

				<FooterDash
					/*onMouseDown={subCardMouse}
					ref={SubCardRef}*/
					className='mr-30'>
					<CardGrid />
					<GeneralCard />
				</FooterDash>
			</Body>
		</Container>
	);
}

function SubCard(props: any) {
	const { titulo, transacao, valor, mr30 } = props;

	function getIcon() {
		const data = [
			{ titulo: 'Energia', icon: <UilBoltAlt /> },
			{ titulo: 'Saneamento', icon: <UilTear /> },
			{ titulo: 'Telefonia', icon: <UilPhone /> },
			{ titulo: 'Transporte', icon: <UilBusAlt /> },
		];

		const dataFiltered = data.find((d) => d.titulo === titulo);

		if (dataFiltered) {
			return dataFiltered.icon;
		} else return <img alt='' src={DefaultImg} />;
	}

	return (
		<div>
			<CardRow className={mr30 ? 'mr-30' : ''}>
				<CardItem>
					<Row>
						{<CardIconContainer>{getIcon()}</CardIconContainer>}

						<CardTitle>{titulo}</CardTitle>
					</Row>

					<Row>
						<div style={{ marginRight: 24 }}>
							<CardValue>{transacao}</CardValue>
							<CardLegend>transações</CardLegend>
						</div>
						<div style={{ flex: 1 }}>
							<CardValue>
								<span>R$ </span>
								{parseFloat(valor).toLocaleString('pt-BR', {
									minimumFractionDigits: 2,
								})}
							</CardValue>
							<CardLegend>transacionados</CardLegend>
						</div>
					</Row>
				</CardItem>
			</CardRow>
		</div>
	);
}

function CardGrid() {
	const history = useHistory();
	const [range, setRange] = useState([
		{
			startDate: moment(moment().format('DD/MM/YYYY'), 'DD/MM/YYYY').toDate(),
			endDate: moment(moment().format('DD/MM/YYYY'), 'DD/MM/YYYY').toDate(),
			key: 'selection',
		},
	]);

	const weekdays = ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sab'];

	////console.log(moment(range[0].startDate).format('DD/MM/YYYY'));

	const [rankInfo] = useState({
		nome: 'Manaus',
		data: [
			{ valor: 20000, unidade: 'Manaus' },
			{ valor: 12000, unidade: 'P.Figueredo' },
			{ valor: 15000, unidade: 'Boa Vista' },
			{ valor: 8000, unidade: 'Rondonia' },
			{ valor: 200, unidade: 'Rio branco' },
			{ valor: 15750, unidade: 'Amapa' },
			{ valor: 18540, unidade: 'Bonfim' },
			{ valor: 5, unidade: 'Rorainopolis' },
		],
	});

	async function getTransactionTimeline() {
		const token = sessionStorage.getItem('@bill-token');

		const config = {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		};

		const startDate = moment(range[0].startDate).format('YYYY-MM-DD');
		const endDate = moment(range[0].endDate).format('YYYY-MM-DD');

		setLoadingTimeline(true);

		try {
			const response = await api.get(
				`/admin/api/transaction/measure/timeline?start=${startDate}&end=${endDate}`,
				config
			);
			//console.log(response.data);
			if (response.data) {
				setTimeline(response.data);
			}

			setLoadingTimeline(false);
		} catch (error: any) {
			console.error(error);
			setLoadingTimeline(false);
			if (error.response && error.response.status === 401) {
				history.push('/login');
			}
		}
	}
	const [timeline, setTimeline] = useState<DashTimeline[]>([]);
	const [loadingTimeline, setLoadingTimeline] = useState(false);

	useEffect(() => {
		getTransactionTimeline();
		setDateDropdown(false);
	}, [range]);

	const [selectedDate, setSelectedDate] = useState('7 dias');

	useEffect(() => {
		const p = [
			{
				dia: '7 dias',
				f: function () {
					setRange([
						{
							startDate: moment(
								moment().subtract(7, 'days').format('DD/MM/YYYY'),
								'DD/MM/YYYY'
							).toDate(),
							endDate: moment(
								moment().format('DD/MM/YYYY'),
								'DD/MM/YYYY'
							).toDate(),
							key: 'selection',
						},
					]);
				},
			},
			{
				dia: '15 dias',
				f: function () {
					setRange([
						{
							startDate: moment(
								moment().subtract(15, 'days').format('DD/MM/YYYY'),
								'DD/MM/YYYY'
							).toDate(),
							endDate: moment(
								moment().format('DD/MM/YYYY'),
								'DD/MM/YYYY'
							).toDate(),
							key: 'selection',
						},
					]);
				},
			},
			{
				dia: '30 dias',
				f: function () {
					setRange([
						{
							startDate: moment(
								moment().subtract(30, 'days').format('DD/MM/YYYY'),
								'DD/MM/YYYY'
							).toDate(),
							endDate: moment(
								moment().format('DD/MM/YYYY'),
								'DD/MM/YYYY'
							).toDate(),
							key: 'selection',
						},
					]);
				},
			},
			{ dia: 'personalizado', f: function () {} },
		];

		const item = p.find((d) => d.dia === selectedDate);
		if (item) {
			item.f();
		}

		// ////console.log(selectedDate);
	}, [selectedDate]);

	const selectsDates = ['7 dias', '15 dias', '30 dias', 'Personalizado'];

	const [dateDropdown, setDateDropdown] = useState(false);
	const [modalVisible, setModalVisible] = useState(false);

	const dropdownRef = useRef<any>(null);

	useOutsideAlert(dropdownRef, handleClickOutsideEmpresa);
	function handleClickOutsideEmpresa(e: any) {
		if (
			dropdownRef.current &&
			dropdownRef.current !== null &&
			!dropdownRef.current.contains(e.target)
		) {
			setDateDropdown(false);
		}
	}

	const [chartData, setChartData] = useState<any>([]);

	function dateToBR(date: string) {
		return date.split('-').reverse().join('/');
	}

	function insertChartData() {
		if (timeline.length > 0) {
			let x = timeline.map((tl) => {
				if (tl.status === 'PAID') {
					return 'Transações totais';
				} else if (tl.status === 'CHARGEBACK') {
					return 'Chargebacks';
				}

				return tl.status;
			});

			x = ['x', ...x];

			const yarray = timeline.map((tl) => tl.result);

			////console.log('yArray', yarray);

			const time = yarray.map((ya) => ya.map((a) => a.total));

			//console.log(time, 'time');

			let y = [] as any[];

			for (let i = 0; i < time[0].length; i++) {
				let temp = [dateToBR(yarray[0][i].date.substr(5))] as any[];

				time.forEach((t) => {
					temp = [...temp, parseFloat(t[i])];
				});

				y = [...y, temp];
				//y = [...y, [time.forEach((t) => t[i])]];
			}

			//console.log(y,'ytemp')

			let finalArray = [[...x]];

			y.forEach((fe) => {
				finalArray = [...finalArray, fe];
			});

			//console.log('final array',finalArray)

			setChartData(finalArray);
		}
	}

	useEffect(() => {
		insertChartData();
	}, [timeline]);

	return (
		<CardRow className='dash_footer_item' style={{ marginRight: 0 }}>
			<CardItem>
				<RowBetween>
					<Row>
						<CardIconContainer>
							<img
								src={AccountIcon}
								alt='account'
								style={{ width: 19, height: 19 }}
							/>
						</CardIconContainer>
						<CardTitle>Transações e Chargeback</CardTitle>
					</Row>
					<Dropdown ref={dropdownRef} drop='down' show={dateDropdown}>
						<Dropdown.Toggle className='dropdown-dash-toggle'>
							<SearchSelector
								onClick={(e) => {
									e.preventDefault();
									setDateDropdown(!dateDropdown);
								}}
								pressed={dateDropdown}
								//active={empresa.value !== ''}
							>
								{selectedDate}
								<img className='ExportArrowDown' alt='' src={ArrowDownDark} />
							</SearchSelector>
						</Dropdown.Toggle>

						<DropdownMenu
							style={{
								background: 'transparent',
								border: 0,
								marginTop: -10,
								display: 'flex',
							}}>
							{selectedDate === 'Personalizado' && (
								<Modal
									show={modalVisible}
									onHide={() => {
										setModalVisible(false);
									}}>
									<Modal.Body>
										<CalendarModal
											modalVisible={modalVisible}
											range={range}
											setRange={setRange}
											setModalVisible={setModalVisible}
										/>
									</Modal.Body>
								</Modal>
							)}
							<SelectMenu>
								{selectsDates.map((sd) => {
									return (
										<Dropdown.Item
											style={{ padding: 0 }}
											//className='dropdown-item-categoria'
											onClick={(a) => {
												a.preventDefault();
												a.stopPropagation();
												setSelectedDate(sd);
												//console.log('2');
												if (sd !== 'Personalizado') setDateDropdown(false);
												else {
													setModalVisible(true);
												}
											}}>
											<SelectItem active={sd === selectedDate}>{sd}</SelectItem>
										</Dropdown.Item>
									);
								})}
							</SelectMenu>
						</DropdownMenu>
					</Dropdown>
				</RowBetween>

				<ListRank>
					<Chart
						chartType='AreaChart'
						height={'100%'}
						width='100%'
						data={chartData}
						options={{
							series: {
								1: { curveType: 'function' },
							},
							vAxis: { minValue: 0 },

							legend: { position: 'bottom', maxLines: 3 },
							chartArea: { width: '80%', height: '70%' },
						}}
					/>
				</ListRank>
				{/*
				<ListRank>
					{rankInfo.data.map((item) => {
						let maxV = 0;

						for (let i = 0; i < rankInfo.data.length; i++) {
							if (rankInfo.data[i].valor > maxV) {
								maxV = rankInfo.data[i].valor;
							}
						}
						let w = '0%';
						//////console.log(maxV);

						if (maxV === item.valor) {
							w = '100%';
						} else {
							let n = (item.valor * 100) / maxV;
							w = `${n}%`;
						}

						//////console.log(w);

						return (
							<div
								key={item.unidade}
								style={{
									display: 'flex',
									flexDirection: 'row',
									alignItems: 'center',
									marginBlock: 8,
								}}>
								<ListRankTextLight
									className='ranking-text-light'
									style={{ flex: 1.5 }}>
									{item.unidade}
								</ListRankTextLight>
								<div style={{ flex: 5 }}>
									<div
										style={{
											width: w,
											background: '#09AA36',
											height: 8,
											borderRadius: 5,
										}}
									/>
								</div>
								{
									<NumberFormat
										className='ranking-text-medium'
										value={item.valor}
										thousandSeparator='.'
										decimalSeparator=','
										decimalScale={2}
										fixedDecimalScale={true}
										prefix='R$ '
										displayType='text'
										//isAllowed={false}
										style={{
											flex: 2,
										}}></NumberFormat>
								}
							</div>
						);
					})}
				</ListRank>
*/}
			</CardItem>
		</CardRow>
	);
}

function GeneralCard() {
	const history = useHistory();
	const [chartTitle, setCharttitle] = useState('Total Transacionado');
	const [merchants, setMerchants] = useState<DashMerchant[]>([]);
	const [loadingMerchants, setLoadingMerchants] = useState(false);
	const [modalVisible, setModalVisible] = useState(false);
	const gridRef = useRef<any>(null);
	const { onMouseDown } = useDraggableScroll(gridRef);

	const chartOptions = [
		'Total Transacionado',
		'Ticket Médio',
		'Qtd de Pagamentos',
	];

	const [range, setRange] = useState([
		{
			startDate: moment(
				moment().subtract(7, 'days').format('DD/MM/YYYY'),
				'DD/MM/YYYY'
			).toDate(),
			endDate: moment(moment().format('DD/MM/YYYY'), 'DD/MM/YYYY').toDate(),
			key: 'selection',
		},
	]);

	////console.log(moment(range[0].startDate).format('DD/MM/YYYY'));

	async function getTransactionMerchant() {
		const token = sessionStorage.getItem('@bill-token');

		const config = {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		};

		const startDate = moment(range[0].startDate).format('YYYY-MM-DD');
		const endDate = moment(range[0].endDate).format('YYYY-MM-DD');
		setLoadingMerchants(true);
		try {
			const response = await api.get(
				`/admin/api/transaction/measure/merchant?start=${startDate}&end=${endDate}`,
				config
			);
			//console.log(response.data);
			if (response.data) {
				setMerchants(response.data);
			}

			setLoadingMerchants(false);
		} catch (error: any) {
			console.error(error);
			setLoadingMerchants(false);
			if (error.response && error.response.status === 401) {
				history.push('/login');
			}
		}
	}

	const [timeline, setTimeline] = useState<DashTimeline[]>([]);
	const [loadingTimeline, setLoadingTimeline] = useState(false);

	useEffect(() => {
		//getTransactionTimeline();
		getTransactionMerchant();

		setDateDropdown(false);
	}, [range]);

	const [selectedDate, setSelectedDate] = useState('Hoje');

	useEffect(() => {
		const p = [
			{
				dia: 'Hoje',
				f: function () {
					setRange([
						{
							startDate: moment(
								moment().format('DD/MM/YYYY'),
								'DD/MM/YYYY'
							).toDate(),
							endDate: moment(
								moment().format('DD/MM/YYYY'),
								'DD/MM/YYYY'
							).toDate(),
							key: 'selection',
						},
					]);
				},
			},

			{
				dia: '7 dias',
				f: function () {
					setRange([
						{
							startDate: moment(
								moment().subtract(7, 'days').format('DD/MM/YYYY'),
								'DD/MM/YYYY'
							).toDate(),
							endDate: moment(
								moment().format('DD/MM/YYYY'),
								'DD/MM/YYYY'
							).toDate(),
							key: 'selection',
						},
					]);
				},
			},
			{
				dia: '15 dias',
				f: function () {
					setRange([
						{
							startDate: moment(
								moment().subtract(15, 'days').format('DD/MM/YYYY'),
								'DD/MM/YYYY'
							).toDate(),
							endDate: moment(
								moment().format('DD/MM/YYYY'),
								'DD/MM/YYYY'
							).toDate(),
							key: 'selection',
						},
					]);
				},
			},
			{
				dia: '30 dias',
				f: function () {
					setRange([
						{
							startDate: moment(
								moment().subtract(30, 'days').format('DD/MM/YYYY'),
								'DD/MM/YYYY'
							).toDate(),
							endDate: moment(
								moment().format('DD/MM/YYYY'),
								'DD/MM/YYYY'
							).toDate(),
							key: 'selection',
						},
					]);
				},
			},
			{ dia: 'personalizado', f: function () {} },
		];

		const item = p.find((d) => d.dia === selectedDate);
		if (item) {
			item.f();
		}

		// ////console.log(selectedDate);
	}, [selectedDate]);

	const selectsDates = [
		'Hoje',
		'7 dias',
		'15 dias',
		'30 dias',
		'Personalizado',
	];

	const [dateDropdown, setDateDropdown] = useState(false);

	const dropdownRef = useRef<any>(null);

	useOutsideAlert(dropdownRef, handleClickOutsideEmpresa);
	function handleClickOutsideEmpresa(e: any) {
		if (
			dropdownRef.current &&
			dropdownRef.current !== null &&
			!dropdownRef.current.contains(e.target)
		) {
			setDateDropdown(false);
		}
	}

	const [chartData, setChartData] = useState<any>([]);

	function dateToBR(date: string) {
		return date.split('-').reverse().join('/');
	}

	function insertChartData() {
		if (timeline.length > 0) {
			let x = timeline.map((tl) => {
				if (tl.status === 'PAID') {
					return 'Transações totais';
				} else if (tl.status === 'CHARGEBACK') {
					return 'Chargebacks';
				}

				return tl.status;
			});

			x = ['x', ...x];

			const yarray = timeline.map((tl) => tl.result);

			////console.log('yArray', yarray);

			const time = yarray.map((ya) => ya.map((a) => a.total));

			//console.log(time, 'time');

			let y = [] as any[];

			for (let i = 0; i < time[0].length; i++) {
				let temp = [dateToBR(yarray[0][i].date.substr(5))] as any[];

				time.forEach((t) => {
					temp = [...temp, parseFloat(t[i])];
				});

				y = [...y, temp];
				//y = [...y, [time.forEach((t) => t[i])]];
			}

			//console.log(y,'ytemp')

			let finalArray = [[...x]];

			y.forEach((fe) => {
				finalArray = [...finalArray, fe];
			});

			//console.log('final array',finalArray)

			setChartData(finalArray);
		}
	}

	useEffect(() => {
		insertChartData();
	}, [timeline]);

	function DayDifferece() {
		const r = range[0];

		const a = moment(r.startDate);
		const b = moment(r.endDate);

		const c = a.diff(b, 'days');

		return Math.abs(c);
	}

	function handleDate(d: Date) {
		const m = moment(d).format('DD/MM/YYYY');

		let format = '';

		const split = m.split('/');

		format = split[0].replace('0', '');

		const months = [
			{ value: '01', name: 'Janeiro' },
			{ value: '02', name: 'Fevereiro' },
			{ value: '03', name: 'Março' },
			{ value: '04', name: 'Abril' },
			{ value: '05', name: 'Maio' },
			{ value: '06', name: 'Junho' },
			{ value: '07', name: 'Julho' },
			{ value: '08', name: 'Agosto' },
			{ value: '09', name: 'Setembro' },
			{ value: '10', name: 'Outubro' },
			{ value: '11', name: 'Novembro' },
			{ value: '12', name: 'Dezembro' },
		];

		const month = months.find((mon) => mon.value === split[1]);
		if (month) {
			format = `${format} de ${month.name}`;
		} else {
			format = `${format} de ${split[1]}`;
		}

		format = `${format} de ${split[2]}`;

		return format;
	}

	function SumAll() {
		let sum = 0;

		if (chartTitle === 'Total Transacionado') {
			merchants.forEach((me) => {
				sum = parseFloat(me.amount) + sum;
			});
		} else if (chartTitle === 'Ticket Médio') {
			merchants.forEach((me) => {
				sum = parseFloat(me.avg) + sum;
			});
		} else if (chartTitle === 'Qtd de Pagamentos') {
			merchants.forEach((me) => {
				sum = parseFloat(me.count) + sum;
			});
		}

		if (chartTitle !== 'Qtd de Pagamentos') {
			return (
				<p className='secondGridRightValue'>
					<span className='secondGridRightSymbol'>R$ </span>
					{sum.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}
				</p>
			);
		}
		return <p className='secondGridRightValue'>{sum}</p>;
	}

	return (
		<CardRow className='dash_footer_item' style={{ marginRight: 0 }}>
			<CardItem style={{ maxWidth: '100%' }}>
				<RowBetween>
					<Row>
						<CardIconContainer>
							<img
								src={PieChartGreen}
								alt='chart'
								style={{ width: 19, height: 19 }}
							/>
						</CardIconContainer>
						<Dropdown>
							<Dropdown.Toggle className='dropdown-dash-toggle'>
								<CardTitle>
									{chartTitle}
									<img className='ExportArrowDown' alt='' src={ArrowDownDark} />
								</CardTitle>
							</Dropdown.Toggle>
							<DropdownMenu
								style={{
									background: 'transparent',
									border: 0,
									marginTop: -10,
									//display: 'flex',
								}}>
								<SelectMenu>
									{chartOptions.map((co) => {
										return (
											<Dropdown.Item
												style={{ padding: 0 }}
												//className='dropdown-item-categoria'
												onClick={(a) => {
													a.preventDefault();
													a.stopPropagation();
													setCharttitle(co);
													//console.log('2');
													//if (sd !== 'Personalizado') setDateDropdown(false);
												}}>
												<SelectItem
													style={{ width: '100%', paddingRight: 16 }}
													active={co === chartTitle}>
													{co}
												</SelectItem>
											</Dropdown.Item>
										);
									})}
								</SelectMenu>
							</DropdownMenu>
						</Dropdown>
					</Row>
					<div className='secondGridRightItems'>
						<SumAll />
						<Dropdown ref={dropdownRef} drop='down' show={dateDropdown}>
							<Dropdown.Toggle className='dropdown-dash-toggle'>
								<div
									className='secondGridDropdownToggle'
									onClick={(e) => {
										e.preventDefault();
										setDateDropdown(!dateDropdown);
									}}
									//pressed={dateDropdown}
									//active={empresa.value !== ''}
								>
									<img className='ExportArrowDown' alt='' src={ArrowDownDark} />
									{selectedDate === 'Hoje'
										? `hoje,${handleDate(range[0].startDate)}`
										: selectedDate === 'Personalizado'
										? `${
												DayDifferece() === 0
													? `${handleDate(range[0].startDate)}`
													: `Do dia ${moment(range[0].startDate).format(
															'DD/MM/YYYY'
													  )} ao ${moment(range[0].endDate).format(
															'DD/MM/YYYY'
													  )}`
										  }`
										: `Últimos ${selectedDate}`}
								</div>
							</Dropdown.Toggle>

							<DropdownMenu
								style={{
									background: 'transparent',
									border: 0,
									marginTop: -10,
									display: 'flex',
								}}>
								{selectedDate === 'Personalizado' && (
									<Modal
										show={modalVisible}
										onHide={() => {
											setModalVisible(false);
										}}>
										<Modal.Body>
											<CalendarModal
												modalVisible={modalVisible}
												range={range}
												setRange={setRange}
												setModalVisible={setModalVisible}
											/>
										</Modal.Body>
									</Modal>
								)}
								<SelectMenu>
									{selectsDates.map((sd) => {
										return (
											<Dropdown.Item
												style={{ padding: 0 }}
												//className='dropdown-item-categoria'
												onClick={(a) => {
													a.preventDefault();
													a.stopPropagation();
													setSelectedDate(sd);
													//console.log('2');
													if (sd !== 'Personalizado') setDateDropdown(false);
													else {
														setModalVisible(true);
													}
												}}>
												<SelectItem active={sd === selectedDate}>
													{sd}
												</SelectItem>
											</Dropdown.Item>
										);
									})}
								</SelectMenu>
							</DropdownMenu>
						</Dropdown>
					</div>
				</RowBetween>

				<MerchantRank onMouseDown={onMouseDown} ref={gridRef}>
					{merchants.map((m, index) => {
						let highest = 0;
						let floatValue = 0;

						if (chartTitle === 'Total Transacionado') {
							merchants.forEach((me) => {
								if (parseFloat(me.amount) > highest) {
									highest = parseFloat(me.amount);
								}
							});

							floatValue = parseFloat(m.amount);
						} else if (chartTitle === 'Ticket Médio') {
							merchants.forEach((me) => {
								if (parseFloat(me.avg) > highest) {
									highest = parseFloat(me.avg);
								}
							});

							floatValue = parseFloat(m.avg);
						} else if (chartTitle === 'Qtd de Pagamentos') {
							merchants.forEach((me) => {
								if (parseFloat(me.count) > highest) {
									highest = parseFloat(me.count);
								}
							});

							floatValue = parseFloat(m.count);
						}

						const percentage = (floatValue * 100) / highest;

						return (
							<div key={m.merchant} className='merchant_rank_container'>
								<MerchantLine heightPercentage={percentage}>
									<OverlayTrigger
										placement={
											index !== merchants.length - 1 ? 'right' : 'left'
										}
										overlay={
											<Tooltip id='tool-tip'>
												<span style={{ textTransform: 'capitalize' }}>
													{chartTitle !== 'Qtd de Pagamentos'
														? `R$ ${' '}
												${floatValue.toLocaleString('pt-BR', {
													minimumFractionDigits: 2,
												})}`
														: floatValue}
												</span>
											</Tooltip>
										}>
										<div className='merchant_rank_intern' />
									</OverlayTrigger>
								</MerchantLine>

								<p className='merchant_rank_label'>{m.merchant}</p>
							</div>
						);
					})}
				</MerchantRank>
			</CardItem>
		</CardRow>
	);
}

function useOutsideAlert(ref: any, handleClickOutside: any) {
	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			// Unbind the event listener on clean up
			document.removeEventListener('mousedown', handleClickOutside);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ref]);
}
