/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';

import styled from 'styled-components';

import { UilCamera } from '@iconscout/react-unicons';
import ReactInputMask from 'react-input-mask';
import { Correspondente } from '../../models/model';
import { colors } from '../../globalStyles/colors';
import DoneWhite from '../../assets/images/done-white.svg';
import InputMask from 'react-input-mask';
import ClearIcon from '../../assets/images/clearIcon.svg';
import CloseWhite from '../../assets/images/close-white.svg';

import Loading from '../../assets/images/loading-roll.svg';
import {
	InputContainer,
	InputMaskContainer,
	SelectContainer,
} from '../../components/Input';
import api from '../../services/api';

import * as yup from 'yup';
import { useHistory } from 'react-router';

const Modal = styled.div`
	//padding-top: 25px;
	//padding-left: 50px;
	//padding-right: 20000px;
	//padding-bottom: 25px;
	min-height: 100vh;
	background-color: #f5f5f5;
	width: 100vw;
	//margin-left: 50vw;
	height: 100%;
	flex: 1;
`;

const ModalHeader = styled.div`
	background: #f6f6f6 0% 0% no-repeat padding-box;
	box-shadow: 0px 16px 16px #00000029;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	padding-top: 21px;
	padding-bottom: 21px;

	padding-left: 30px;
	padding-right: 30px;
	height: 80px;
`;

const HeaderTitle = styled.h1`
	font: normal normal 500 24px/32px Roboto;
	letter-spacing: 0px;
	color: #222222;
`;

const HeaderButton = styled.button`
	background: #28a745 0% 0% no-repeat padding-box;
	border-radius: 19px;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 38px;
	padding-left: 16px;
	padding-right: 16px;

	text-align: center;
	font: normal normal 500 16px/21px Roboto;
	letter-spacing: 0px;
	border: 0;
	color: #ffffff;

	.loading-roll {
		width: 21px;
		height: 21px;
	}
`;

const HeaderButtonCancel = styled.button`
	background: transparent 0% 0% no-repeat padding-box;
	border-radius: 19px;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 38px;
	padding-left: 16px;
	padding-right: 16px;

	text-align: center;
	font: normal normal 500 16px/21px Roboto;
	letter-spacing: 0px;
	border: 0;
	color: #676767;
`;

const ModalContainer = styled.div`
	background: #ffffff 0% 0% no-repeat padding-box;
	box-shadow: -16px 0px 16px #00000029;
	opacity: 1;
	display: flex;
	flex-direction: row;
	//flex: 1;
	height: calc(100vh - 80px);
`;

const BodyMenu = styled.div`
	/*padding-left: 30px;
	padding-right: 30px;
	max-height: calc(100vh - 80px);*/
	display: flex;
	justify-content: space-between;
	flex: 1;
	flex-direction: column;

	padding-top: 30px;
	padding-bottom: 30px;

	border-right: 1px solid #f0f0f0;
`;

const MenuContainer = styled.div`
	display: flex;
	//justify-content: space-between;
	flex-direction: column;
	//align-items: center;
	flex: 1;
	border-radius: 1px solid #f0f0f0;
`;

const MenuFooter = styled.div`
	padding-left: 30px;
	padding-right: 30px;

	h1 {
		text-align: left;
		font: normal normal 500 24px/32px Roboto;
		letter-spacing: 0px;
		color: #222222;
	}

	p {
		text-align: right;
		font: normal normal normal 11px/15px Roboto;
		letter-spacing: 0px;
		color: #9f9f9f;
	}
`;

const BodyItem = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	text-align: right;

	letter-spacing: 0px;
	color: #222222;
	padding-left: 30px;
	padding-right: 50px;
	justify-content: flex-end;
	//background-color: #000;
	margin-bottom: 60px;
	p {
		font: normal normal 500 18px Roboto;
		margin: 0;
	}
	button {
		border: 0;
		width: 40px;
		height: 40px;
		border-radius: 20px;
		background: #18aa36 0% 0% no-repeat padding-box;
		display: flex;
		align-items: center;
		justify-content: center;

		text-align: center;
		font: normal normal normal 20px/20px Roboto;
		letter-spacing: 0px;
		color: #9f9f9f;
		margin-left: 15px;
		position: relative;
	}

	.icon_error {
		width: 20px;
		height: 20px;
		border-radius: 10px;
		background-color: #f21f4a;
		display: flex;
		align-items: center;
		justify-content: center;
		color: #ffffff;
		position: absolute;
		top: 0px;
		right: -5px;
	}
`;

interface FormBodyProps {
	showScroll?: boolean;
}

const FormBody = styled.form<FormBodyProps>`
	flex: 1;
	height: calc(100vh - 80px);
	overflow-y: scroll;
	padding-top: 30px;
	padding-bottom: 30px;
	padding-left: 30px;
	padding-right: 30px;

	::-webkit-scrollbar {
		background: transparent;

		// display:none;

		width: 12px;
	}

	&::-webkit-scrollbar-track {
		/*  margin-top:0;
      border-bottom-right-radius: 16px;
      background-image: url("https://i.stack.imgur.com/Pwbuz.png");
    background-repeat: repeat-y;
    background-size: contain;
      */
		// margin-top: 54px;
	}
	&::-webkit-scrollbar-thumb {
		background: ${(props) =>
			props.showScroll ? 'rgba(103, 103, 103, 0.5)' : 'transparent'};
		border-radius: 20px;
		border: 2.5px solid rgba(0, 0, 0, 0);
		border-right: 5px solid rgba(0, 0, 0, 0);

		background-clip: padding-box;
		max-height: 5px;
		transition: all 1s;
	}

	h1 {
		text-align: left;
		font: normal normal 500 16px/22px Roboto;
		letter-spacing: 0px;
		color: #222222;
	}

	.inputGroup {
		margin-top: 24px;
	}

	.inputTitle {
		font: normal normal 500 11px/15px Roboto;
		letter-spacing: 0px;
		color: #4b4b4b;
		text-transform: uppercase;
		margin: 0;
		margin-bottom: 8px;
	}

	.input_bonus_container {
		background: #ffffff 0% 0% no-repeat padding-box;
		border: 1px solid #9f9f9f;
		border-radius: 16px;
		height: 50px;
		display: flex;
		input,
		select {
			::placeholder {
				font: normal normal 300 14px/19px Roboto;
				letter-spacing: 0px;
				color: #9f9f9f;
			}
		}
		padding-left: 15px;

		input {
			flex: 1;
			background: transparent;
			height: 50px;
			border: 0;
			border-top-left-radius: 16px;
			border-bottom-left-radius: 16px;

			text-align: left;
			font: normal normal 500 14px/19px Roboto;
			letter-spacing: 0px;
			color: #222222;
			flex: 1;
			border: 0;
			max-width: calc(100% - 50px);
		}

		.input_percentage {
			background: #f0f0f0 0% 0% no-repeat padding-box;
			border: 1px solid #9f9f9f;
			border-radius: 0px 16px 16px 0px;
			display: flex;
			align-items: center;
			justify-content: center;

			font: normal normal 500 18px/24px Roboto;
			letter-spacing: 0px;
			color: #676767;
			width: 50px;
			height: 100%;
			border: 0;
		}
	}

	.inputContainer {
		background: #ffffff 0% 0% no-repeat padding-box;
		border: 1px solid #9f9f9f;
		border-radius: 16px;
		height: 50px;
		display: flex;
		flex-direction: row;
		padding: 15px;
		input,
		select {
			::placeholder {
				font: normal normal 300 14px/19px Roboto;
				letter-spacing: 0px;
				color: #9f9f9f;
			}
			text-align: left;
			font: normal normal 500 14px/19px Roboto;
			letter-spacing: 0px;
			color: #222222;
			flex: 1;
			border: 0;
			margin-top: -15px;
			margin-bottom: -15px;
		}
	}

	.clearIcon {
		width: 17px;
		height: 17px;
		cursor: pointer;
	}
	.logoImg {
		width: 120px;
		height: 120px;
	}
`;

const ImageButton = styled.div`
	border: 1px dashed var(--disabled);
	background: #f5f5f5 0% 0% no-repeat padding-box;
	border: 1px dashed #9f9f9f;
	border-radius: 10px;

	display: flex;
	flex-direction: column;
	align-items: center;

	justify-content: space-between;

	padding-top: 10px;
	padding-bottom: 10px;

	//width: 114px;
	max-height: 120px;
	svg {
		width: 32px;
		height: 27px;
		color: #9f9f9f;
	}
	img {
		width: 100%;
		height: 100%;
	}
`;

const Select = styled.select`
	/*text-align: left;
	font: normal normal 500 16px/21px Roboto;
	letter-spacing: 0px;
	color: #09aa36;

	margin: 0;
	max-width: 85%;
	width: 200px;
	height: 25px;

	background: transparent;
	border: 0;
	border-bottom: 1px solid #09aa36;
	padding-left: 5px;
	padding-right: 5px;*/
`;

interface ModalProps {
	setModalVisible(b: boolean): void;
	correspondente?: Correspondente;
	updateCorrespondentes(c: Correspondente): void;
}

export default function ModalNovoCorrespondente({
	setModalVisible,
	//	relatorio,
	correspondente,
	updateCorrespondentes,
}: //tamanho = 0,
ModalProps) {
	const [isError, setIsError] = useState<any>({});
	const [name, setName] = useState('');
	const [CNPJ, setCNPJ] = useState('');

	const [cod, setCod] = useState('');
	const [recipientBonus, setRecipientBonus] = useState('');
	const [status, setStatus] = useState('0');

	const [street, setStreet] = useState('');
	const [number, setNumber] = useState('');
	const [zipCode, setZipcode] = useState('');
	const [complement, setComplement] = useState('');
	const [state, setState] = useState('');
	const [city, setCity] = useState('');

	const [showScroll, setShowScroll] = useState(false);

	const [loading, setLoading] = useState(false);

	const statusMap = [
		{ label: 'Ativo', value: '1' },
		{ label: 'Inativo', value: '0' },
	];

	async function EditCorrespondente() {
		setIsError({});
		setLoading(true);
		const token = sessionStorage.getItem('@bill-token');

		const config = {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		};

		try {
			const data = {
				name: name,
				doc: CNPJ,
				cod: cod,
				recipientBonus: Math.abs(parseFloat(recipientBonus)),
				status: parseInt(status),
				address: {
					city: city,
					complement: complement,
					number: number,
					state: state,
					street: street,
					zipCode: zipCode,
				},
			};

			const schema = yup.object().shape({
				name: yup.string().required(),
				doc: yup.string().required().min(11),
				cod: yup.string().required(),
				recipientBonus: yup.number().required().min(0),
				status: yup.number().default(0),
				address: yup.object({
					city: yup.string().required(),
					complement: yup.string().required(),
					number: yup.string().required(),
					state: yup.string().required(),
					street: yup.string().required(),
					zipCode: yup.string().required().min(8),
				}),
			});

			await schema.validate(data, { abortEarly: false });

			const response = await api.post(
				`/admin/api/recipient${correspondente ? `/${correspondente.id}` : ''}`,
				data,
				config
			);
			if (response.data.recipient) {
				updateCorrespondentes(response.data.recipient);
				setModalVisible(false);
			}
			setLoading(false);
		} catch (error: any) {
			console.log(error);
			const validationError = {} as any;
			if (error instanceof yup.ValidationError) {
				error.inner.forEach((err) => {
					//console.log(err.path);
					if (err.path) validationError[err.path] = true;
				});
			}
			if (error.response && error.response.data && error.response.data.errors && error.response.data.errors.error) {
				if(error.response.data.errors.error.includes('recipientBonus')){
					validationError['recipientBonus'] = true;
				}
				if(error.response.data.errors.error.includes('zipCode')){
					validationError['address.zipCode'] = true;
				}

			}
			//console.log(validationError)
			setIsError(validationError);
			setLoading(false);
			if (error.response && error.response.status === 401) {
				history.push('/login');
			}
		}
	}
	const history = useHistory();

	function maskDocument(preValue: string): string {
		let value = preValue;
		value = value.replace(/\D/g, '');
		if (value.length <= 11) {
			value = value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4');
		} else {
			value = value.replace(
				/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
				'$1.$2.$3/$4-$5'
			);
		}
		return value;
	}

	useEffect(() => {
		if (correspondente) {
			setName(correspondente.name);
			if (correspondente.doc) setCNPJ(maskDocument(correspondente.doc));
			setCod(correspondente.cod);
			setRecipientBonus(correspondente.recipientBonus);
			setStatus(correspondente.status.toString());
			if (correspondente.address) {
				setStreet(correspondente.address.street);
				setNumber(correspondente.address.number);
				setZipcode(correspondente.address.zipCode);
				setComplement(correspondente.address.complement);
				setState(correspondente.address.state);
				setCity(correspondente.address.city);
			}
		}
	}, [correspondente]);
	const DadosRef = useRef<any>(null);
	const ConfigRef = useRef<any>(null);

	function checkAddress() {
		return (
			city.length > 0 &&
			complement.length > 0 &&
			number.length > 0 &&
			state.length > 0 &&
			street.length > 0 &&
			zipCode.length > 0
		);
	}

	function checkPasso1Error() {
		if (
			isError['name'] ||
			isError['doc'] ||
			isError['address.street'] ||
			isError['address.number'] ||
			isError['address.zipCode'] ||
			isError['address.complement'] ||
			isError['address.state'] ||
			isError['address.city']
		)
			return true;
		return false;
	}

	function checkPasso1(completo: boolean) {
		if (completo) {
			return (
				name.length > 0 &&
				CNPJ.length > 0 &&
				street.length > 0 &&
				number.length > 0 &&
				zipCode.length > 0 &&
				complement.length > 0 &&
				state.length > 0 &&
				city.length > 0
			);
		}

		return (
			name.length > 0 ||
			CNPJ.length > 0 ||
			street.length > 0 ||
			number.length > 0 ||
			zipCode.length > 0 ||
			complement.length > 0 ||
			state.length > 0 ||
			city.length > 0
		);
	}

	function checkPasso2Error() {
		if (isError['cod'] || isError['recipientBonus'] || isError['status'])
			return true;
		return false;
	}

	function checkPasso2(completo: boolean) {
		if (completo) {
			return cod.length > 0 && recipientBonus.length > 0;
		}

		return cod.length > 0 || recipientBonus.length > 0;
	}

	const [stepsDone, setStepsDone] = useState(0);

	useEffect(() => {
		let counter = 0;
		if (checkPasso1(true)) counter = counter + 1;
		if (checkPasso2(true)) counter = counter + 1;
		setStepsDone(counter);
	}, [
		name,
		CNPJ,
		cod,
		recipientBonus,
		street,
		number,
		zipCode,
		complement,
		state,
		city,
	]);

	function changeError(s: string) {
		const ie = { ...isError };

		if (ie[s] === true) {
			ie[s] = false;
			setIsError({ ...ie });
		}
	}

	return (
		<Modal style={{ maxWidth: 786 }}>
			<ModalHeader>
				<HeaderTitle>
					{correspondente ? correspondente.name : 'Novo Correspondente'}
				</HeaderTitle>

				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
					}}>
					<HeaderButtonCancel
						onClick={(e) => {
							e.preventDefault();
							setModalVisible(false);
						}}
						style={{ marginRight: 8 }}>
						Cancelar
					</HeaderButtonCancel>

					<HeaderButton
						onClick={(e) => {
							e.preventDefault();
							//setModalVisible(false);
							EditCorrespondente();
						}}
						disabled={loading}
						style={{ background: '#28A745', color: '#FFFFFF' }}>
						{!loading ? (
							'Salvar'
						) : (
							<img className='loading-roll' alt='loading' src={Loading} />
						)}
					</HeaderButton>
				</div>
			</ModalHeader>

			<ModalContainer>
				<BodyMenu>
					<MenuContainer>
						<BodyItem>
							<div>
								<p style={{ fontSize: 11 }}>PASSO 1</p>
								<p style={{ fontSize: 18 }}>Dados do Correspondente</p>
							</div>
							<button
								onClick={(e) => {
									e.preventDefault();
									if (DadosRef && DadosRef.current) {
										DadosRef.current.scrollIntoView({
											behavior: 'smooth',
											block: 'start',
										});
									}
								}}
								style={{
									background: checkPasso1Error()
										? '#F0F0F0' //'#F21F4A'
										: checkPasso1(false)
										? '#18AA36'
										: '#F0F0F0',
									color: checkPasso1Error()
										? '#9F9F9F'
										: checkPasso1(false)
										? 'white'
										: '#9F9F9F',
								}}>
								{checkPasso1Error() ? (
									'1'
								) : checkPasso1(true) ? (
									<img src={DoneWhite} alt='check' />
								) : (
									'1'
								)}
								{checkPasso1Error() && <div className='icon_error'> !</div>}
							</button>
						</BodyItem>
						<BodyItem>
							<div>
								<p style={{ fontSize: 11 }}>PASSO 2</p>
								<p style={{ fontSize: 18 }}>Configurações</p>
							</div>
							<button
								onClick={(e) => {
									e.preventDefault();
									if (ConfigRef && ConfigRef.current) {
										ConfigRef.current.scrollIntoView({
											behavior: 'smooth',
											block: 'start',
										});
									}
								}}
								style={{
									background: checkPasso2Error()
										? '#F0F0F0'
										: checkPasso2(false)
										? '#18AA36'
										: '#F0F0F0',
									color: checkPasso2Error()
										? '#9F9F9F'
										: checkPasso2(false)
										? 'white'
										: '#9F9F9F',
								}}>
								{checkPasso2Error() ? (
									'2'
								) : checkPasso2(true) ? (
									<img src={DoneWhite} alt='check' />
								) : (
									'2'
								)}
								{checkPasso2Error() && <div className='icon_error'> !</div>}
							</button>
						</BodyItem>
					</MenuContainer>
					<MenuFooter>
						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
								justifyContent: 'space-between',
							}}>
							<h1>{50 * stepsDone}%</h1>
							<p>{stepsDone} de 2 passos concluidos</p>
						</div>

						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
								justifyContent: 'space-between',
							}}>
							<div
								style={{
									flex: 1,
									background: stepsDone > 0 ? '#18AA36' : '#F0F0F0',
									height: 4,
									borderRadius: 4,
								}}
							/>
							<div
								style={{
									flex: 1,
									background: stepsDone > 1 ? '#18AA36' : '#F0F0F0',
									height: 4,
									borderRadius: 4,
									marginLeft: 5,
								}}
							/>
						</div>
					</MenuFooter>
				</BodyMenu>
				<FormBody
					onSubmit={(e) => {
						e.preventDefault();
						EditCorrespondente();
					}}
					showScroll={showScroll}>
					<div>
						<h1>Comece inserindo os dados básicos do correspondente</h1>

						<InputContainer
							changeError={changeError}
							name='name'
							fieldLabel={'NOME FANTASIA'}
							inputName={name}
							setInputField={setName}
							isError={isError.name}
							placeholder={'Insira o nome do correspondente'}
						/>

						<InputMaskContainer
							fieldLabel={'CNPJ'}
							changeError={changeError}
							name='doc'
							inputName={CNPJ}
							setInputField={setCNPJ}
							isError={isError.doc}
							placeholder={'00.000.000/0000-00'}
						/>

						<InputContainer
							changeError={changeError}
							name='address.street'
							fieldLabel={'Endereço'}
							inputName={street}
							setInputField={setStreet}
							isError={isError['address.street']}
							placeholder={'Insira o nome da rua'}
						/>
						<div style={{ display: 'flex', justifyContent: 'space-between' }}>
							<div style={{ maxWidth: '47%' }}>
								<InputContainer
									changeError={changeError}
									name='address.number'
									fieldLabel={'Número'}
									inputName={number}
									setInputField={setNumber}
									isError={isError['address.number']}
									placeholder={'Insira o número do local'}
								/>
							</div>

							<div style={{ maxWidth: '47%' }}>
								<InputContainer
									changeError={changeError}
									name='address.zipCode'
									fieldLabel={'CEP'}
									inputName={zipCode}
									setInputField={setZipcode}
									isError={isError['address.zipCode']}
									placeholder={'00.000-000'}
								/>
							</div>
						</div>

						<InputContainer
							changeError={changeError}
							name='address.complement'
							fieldLabel={'Complemento'}
							inputName={complement}
							setInputField={setComplement}
							isError={isError['address.complement']}
							placeholder={'Insira o complemento'}
						/>

						<div style={{ display: 'flex', justifyContent: 'space-between' }}>
							<div style={{ maxWidth: '47%' }}>
								<InputContainer
									changeError={changeError}
									name='address.state'
									fieldLabel={'Estado'}
									inputName={state}
									setInputField={setState}
									isError={isError['address.state']}
									placeholder={'Insira a sigla do estado'}
								/>
							</div>

							<div style={{ maxWidth: '47%' }}>
								<InputContainer
									changeError={changeError}
									name='address.city'
									fieldLabel={'Cidade'}
									inputName={city}
									setInputField={setCity}
									isError={isError['address.city']}
									placeholder={'Insira o nome da cidade'}
								/>
							</div>
						</div>
					</div>

					<div style={{ marginTop: 60 }}>
						<h1 ref={ConfigRef}>Configurações do Correspondente</h1>

						<InputContainer
							changeError={changeError}
							name='cod'
							fieldLabel={'Código'}
							inputName={cod}
							setInputField={setCod}
							isError={isError.cod}
							placeholder={'Insira o código'}
						/>

						<div style={{ display: 'flex', justifyContent: 'space-between' }}>
							<div style={{ maxWidth: '47%' }}>
								<InputContainer
									changeError={changeError}
									name='recipientBonus'
									fieldLabel={'Bônus do Recebedor'}
									inputName={recipientBonus}
									setInputField={setRecipientBonus}
									isError={isError.recipientBonus}
									type='number'
									placeholder={'Insira o bônus do recebedor'}
								/>
							</div>

							<div style={{ width: '47%' }}>
								<SelectContainer
									changeError={changeError}
									name='status'
									fieldLabel={'Situação'}
									inputName={status}
									setInputField={setStatus}
									options={statusMap}
									isError={isError.status}
								/>
							</div>
						</div>
					</div>
				</FormBody>
			</ModalContainer>

			{/*
			<ModalBody>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'space-between',
						height: '100%',
						//background:"#000",
					}}>
					<div>
						<ModalTitle>
							{correspondente ? 'Editar Correspondente' : `Nova Correspondente`}
						</ModalTitle>

						{correspondente && <ModalName>{correspondente.name}</ModalName>}

						<GridDisplay style={{ flex: 1 }}>
							<div>
								<Text>Nome Fantasia</Text>
								<Input
									value={name}
									onChange={(e) => {
										setName(e.target.value);
									}}
								/>
							</div>
							<div style={{}}>
								<Text>CNPJ</Text>
								<ReactInputMask
									value={CNPJ}
									onChange={(e) => {
										setCNPJ(e.target.value);
									}}
									className='modal-categoria-mask-input'
									mask='99.999.999/9999-99'
								/>
							</div>
							<div>
								<Text>Código</Text>
								<Input
									value={codigo}
									onChange={(e) => {
										setCod(e.target.value);
									}}
								/>
							</div>
							<div />
						</GridDisplay>
					</div>
					<ButtonContainer>
						<ButtonCancel
							onClick={(e) => {
								e.preventDefault();
								setModalVisible(false);
							}}>
							Cancelar
						</ButtonCancel>
						<Button>Salvar</Button>
					</ButtonContainer>
				</div>
			</ModalBody>
	*/}
		</Modal>
	);
}
