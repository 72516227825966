/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
//import { Container } from '../dashboard/style';
import {
	Container,
	Sidebar,
	SidebarButton,
	SidebarHeader,
	SidebarIconContainer,
	SidebarItem,
	SidebarItemInfo,
	//SidebarItemTitle,
	SidebarTitle,
	Main,
	Header,
	HeaderButton,
	HeaderSearchContainer,
	HeaderSearchInput,
	Body,
	Table,
	TableContainer,
	TBody,
	Th,
	THead,
	ActionButton,
	//Gradient,
	Skeleton,
	BodyTR,
	HeaderTitle,
	HeaderContainer,
	SidebarItemContainer,
	SidebarSubItem,
} from './style';
import {
	UilDraggabledots,
	//UilBoltAlt,
	UilEllipsisV,
	//UilTear,
	//UilBusAlt,
	//UilPhone,
	/*UilSearch,
	UilPen,
	UilTrashAlt,
	UilUsdCircle,
	UilFileContract,
	UilMoneyWithdraw,*/
} from '@iconscout/react-unicons';
import { Dropdown, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import ModalScreen from './Modal';
//import { TableContainer } from '../relatorios/style';

import './categorias.css';
import ModalEmpresa from './ModalEmpresa';
import ModalConfig from './ModalConfiguracaoFin';
import ModalContrato from './ModalContratos';
//import ModalNovoContrato from './ModalNovoContrato';
import ModalMetodo from './ModalMetodoPagamento';
//import ModalNovoMetodo from './ModalNovoMetodo';
import api from '../../services/api';
import { useEffect } from 'react';
import {
	Categoria,
	Contrato,
	Empresa,
	PaymentMethod,
	Produto,
} from '../../models/model';
import { useRef } from 'react';
import { useHistory } from 'react-router-dom';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import sort from '../../utils/order';
import EditGray from '../../assets/images/edit_filled.svg';
import CloseWhite from '../../assets/images/close-white.svg';
import ExpandLess from '../../assets/images/expand_less.svg';
import AddBox from '../../assets/images/add_box.svg';
import ExpandMore from '../../assets/images/expand_more.svg';
import MoreHoz from '../../assets/images/more_horiz.svg';
import RemoveGray from '../../assets/images/delete_outline-gray.svg';
import RemoveDark from '../../assets/images/delete_filled-dark.svg';
import SettingsDark from '../../assets/images/settings_filled-dark.svg';
import SearchGray from '../../assets/images/search-gray.svg';
import NavigateNext from '../../assets/images/navigate_next-green.svg';
import HeaderUserButton from '../../components/logOut';
import DeleteModal from '../../components/deleteModal';
import { useCallback } from 'react';
import debounce from 'lodash.debounce';
import { DebouncerInput } from '../../components/Input';
import ModalProduto from './ModalProduto';
import ModalSub from './ModalSubCategoria';
import DefaultImg from '../../assets/images/default.png';

interface CategorieSort {
	id: number;
	sort: number;
}

export default function Categorias() {
	const [categorias, setCategorias] = useState<Categoria[]>([]);
	const [empresas, setEmpresas] = useState<Empresa[]>([]);
	const [produtos, setProdutos] = useState<Produto[]>([]);
	const [categoriaSelecionada, setCategoriaSelecionada] = useState<
		Categoria | undefined
	>();

	const [openCategoria, setOpenCategoria] = useState<Categoria | undefined>();

	//0 = empresa 1 = produto
	const [selectedItem, setSelectedItem] = useState(0);

	const history = useHistory();

	function selectOpenCategoria(c: Categoria | undefined) {
		if (c === undefined) {
			setOpenCategoria(undefined);
		} else if (openCategoria === undefined) {
			setOpenCategoria(c);
		} else if (openCategoria.id === c.id) {
			setOpenCategoria(undefined);
		} else {
			setOpenCategoria(c);
		}
	}

	function selecionarCategoria(c: Categoria | undefined) {
		if (c === undefined) {
			setCategoriaSelecionada(undefined);
		} else if (categoriaSelecionada === undefined) {
			setCategoriaSelecionada(c);
		} else if (categoriaSelecionada.id === c.id) {
			setCategoriaSelecionada(undefined);
		} else {
			setCategoriaSelecionada(c);
		}
	}

	const [loadingEmpresa, setLoadingEmpresa] = useState(false);

	function DeletarEmpresas(empresaId: number) {
		//setEmpresas([...Empresas]);
		const lista = [...empresas];
		const findIndex = lista.findIndex((l) => l.id === empresaId);
		//console.log(findIndex)
		console.log(lista);
		if (findIndex !== -1) {
			lista.splice(findIndex, 1);
		}

		setEmpresas([...lista]);
	}

	async function getProdutos() {
		setLoadingEmpresa(true);
		const token = sessionStorage.getItem('@bill-token');

		const config = {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		};

		try {
			const search = categoriaSelecionada
				? `&category=${categoriaSelecionada.id}`
				: '';
			const response = await api.get(
				`/admin/api/product?s=${empresaSearch}${search}`,
				config
			);
			if (response.data && response.data.products) {
				setProdutos(response.data.products);
			} else {
				setProdutos([]);
			}
			setLoadingEmpresa(false);
		} catch (error: any) {
			console.error(error);
			setLoadingEmpresa(false);
			if (error.response && error.response.status === 401) {
				history.push('/login');
			}
		}
	}

	async function getEmpresas() {
		setLoadingEmpresa(true);
		const token = sessionStorage.getItem('@bill-token');

		const config = {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		};
		try {
			const category = categoriaSelecionada
				? `&category=${categoriaSelecionada.id}`
				: '';
			const response = await api.get(
				`/admin/api/merchant?s=${empresaSearch}${category}`,
				config
			);
			//console.log(response.data);
			if (response.data.merchants) setEmpresas(response.data.merchants);

			setLoadingEmpresa(false);
		} catch (error: any) {
			console.error(error);
			setLoadingEmpresa(false);
			if (error.response && error.response.status === 401) {
				history.push('/login');
			}
		}
	}

	const [loadingCategorias, setLoadingCategorias] = useState(false);

	async function getCategorias() {
		setLoadingCategorias(true);
		const token = sessionStorage.getItem('@bill-token');

		const config = {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		};
		try {
			//merchant-category?parent=${id}
			const response = await api.get('/admin/api/merchant-category', config);
			//console.log(response.data);
			if (response.data && response.data.categories)
				setCategorias(sort(response.data.categories, 'sort', true));

			setLoadingCategorias(false);
		} catch (error: any) {
			console.error(error);
			setLoadingCategorias(false);
			if (error.response && error.response.status === 401) {
				history.push('/login');
			}
		}
	}

	const [searchInput, setSearchInput] = useState('');

	const [empresaSearch, setEmpresaSearch] = useState('');

	const [modalVisible, setModalVisible] = useState(false);

	const [modalNovaEmpresa, setModalNovaEmpresa] = useState(false);

	function onDragEnd(result: any) {
		console.log(result);
		if (!result.destination) {
			return;
		}

		if (result.destination.index === result.source.index) {
			return;
		}

		const quotes = reorder(
			categorias,
			result.source.index,
			result.destination.index
		);

		console.log(categorias, quotes);

		updateSort(quotes as Categoria[]);

		setCategorias(quotes as Categoria[]);
	}

	async function updateSort(posSort: Categoria[]) {
		const token = sessionStorage.getItem('@bill-token');

		const config = {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		};

		const data = {
			categories: [] as CategorieSort[],
		};

		posSort.forEach((ps) => {
			const index = posSort.findIndex((p) => p.id === ps.id);
			if (ps.sort !== index + 1) {
				data.categories.push({
					id: ps.id,
					sort: index + 1,
				});
			}
		});

		if (data.categories.length > 0) {
			try {
				const response = await api.post(
					'/admin/api/merchant-category/sort',
					data,
					config
				);

				console.log(response.data);
			} catch (error: any) {
				console.error(error);
				if (error.response && error.response.status === 401) {
					history.push('/login');
				}
			}
		}
	}

	const reorder = (list: any, startIndex: any, endIndex: any) => {
		const result = Array.from(list);
		const [removed] = result.splice(startIndex, 1);
		result.splice(endIndex, 0, removed);

		return result;
	};

	function UpdateProdutos(ProdutoUpdate: Produto) {
		const lista = [...produtos];
		const findIndex = lista.findIndex(
			(l) => l.id === (ProdutoUpdate.id as number)
		);

		if (findIndex !== -1) {
			lista[findIndex] = ProdutoUpdate;
		} else {
			lista.push(ProdutoUpdate);
		}
		setProdutos([...lista]);
	}

	function UpdateEmpresas(EmpresaUpdate: Empresa) {
		//setEmpresas([...Empresas]);
		const lista = [...empresas];
		const findIndex = lista.findIndex(
			(l) => l.id === (EmpresaUpdate.id as number)
		);
		//console.log(findIndex)
		//console.log(lista);
		if (findIndex !== -1) {
			lista[findIndex] = EmpresaUpdate;
		} else {
			lista.push(EmpresaUpdate);
		}

		setEmpresas([...lista]);
	}

	function UpdateCategoria(CategoriaUpdate: Categoria) {
		//setEmpresas([...Empresas]);
		const lista = [...categorias];
		const findIndex = lista.findIndex(
			(l) => l.id === (CategoriaUpdate.id as number)
		);
		//console.log(findIndex)
		console.log(lista);
		if (findIndex !== -1) {
			lista[findIndex] = CategoriaUpdate;
		} else {
			lista.push(CategoriaUpdate);
		}

		setCategorias([...lista]);
	}

	function RemoverCategoria(CategoriaRemove: Categoria) {
		//setEmpresas([...Empresas]);
		const lista = [...categorias];
		const findIndex = lista.findIndex(
			(l) => l.id === (CategoriaRemove.id as number)
		);
		//console.log(findIndex)
		console.log(lista);
		if (findIndex !== -1) {
			lista.splice(findIndex, 1);
			//lista[findIndex] = CategoriaRemove;
		}

		setCategorias([...lista]);
	}

	useEffect(() => {
		getCategorias();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (categoriaSelecionada) {
			if (categoriaSelecionada.type === 'M') {
				getEmpresas();
				setSelectedItem(0);
			} else if (categoriaSelecionada.type === 'P') {
				getProdutos();
				setSelectedItem(1);
			} else if (categoriaSelecionada.type === 'C') {
			}
		} else {
			getEmpresas();
			getProdutos();
			// setSelectedItem(0);
		}
	}, [empresaSearch, categoriaSelecionada]);

	useEffect(() => {
		const login = localStorage.getItem('@bill-login');
		if (login) {
			const l = JSON.parse(login);
			if (!(l && l.type === 999)) {
				history.replace('/');
			}
		} else {
			history.replace('/');
		}
	}, []);

	return (
		<Container>
			<Sidebar>
				<SidebarHeader>
					{
						<Modal show={modalVisible} onHide={() => setModalVisible(false)}>
							<Modal.Body>
								<ModalScreen
									setModalVisible={setModalVisible}
									tamanho={categorias.length}
									//UpdateEmpresas={UpdateEmpresas}
									UpdateCategoria={UpdateCategoria}
								/>
							</Modal.Body>
						</Modal>
					}
					<SidebarTitle>Categorias</SidebarTitle>
					<SidebarButton
						onClick={(e) => {
							e.preventDefault();
							setModalVisible(true);
						}}>
						Nova Categoria
					</SidebarButton>
				</SidebarHeader>

				{loadingCategorias ? (
					<SidebarItem selected={false} active={false}>
						<div style={{ display: 'flex' }}>
							{}

							<SidebarIconContainer selected={false}></SidebarIconContainer>
							<SidebarItemInfo>
								<Skeleton style={{ width: 80, height: 38 }} />
							</SidebarItemInfo>
						</div>
					</SidebarItem>
				) : (
					<DragDropContext onDragEnd={onDragEnd}>
						<Droppable droppableId='list'>
							{(provided) => (
								<div ref={provided.innerRef} {...provided.droppableProps}>
									<CategoriaList
										categorias={categorias}
										categoriaSelecionada={categoriaSelecionada}
										selecionarCategoria={selecionarCategoria}
										UpdateCategoria={UpdateCategoria}
										RemoverCategoria={RemoverCategoria}
										selectOpenCategoria={selectOpenCategoria}
										openCategoria={openCategoria}
									/>
									{provided.placeholder}
								</div>
							)}
						</Droppable>
					</DragDropContext>
				)}
				{/*categoriasData.map((categoria) => {
					return <SideItem item={categoria} />;
				})*/}
			</Sidebar>
			<Main>
				<Header>
					<div className='headerItems'>
						{categoriaSelecionada ? (
							<HeaderTitle active={true}>
								{categoriaSelecionada
									? `${categoriaSelecionada.name}`
									: 'Todas as empresas'}
								{categoriaSelecionada && (
									<img
										style={{ height: 25, width: 25 }}
										alt='>'
										src={NavigateNext}
									/>
								)}
								{categoriaSelecionada && categoriaSelecionada.type === 'M'
									? 'Empresas'
									: categoriaSelecionada &&
									  categoriaSelecionada.type === 'P' &&
									  'Produtos'}
							</HeaderTitle>
						) : (
							<div className='headerItems'>
								<HeaderTitle
									onClick={(e) => {
										e.preventDefault();
										e.stopPropagation();
										setSelectedItem(0);
									}}
									selectable={true}
									active={selectedItem === 0}
									borderShown={selectedItem === 0}>
									Empresas
								</HeaderTitle>
								<HeaderTitle
									onClick={(e) => {
										e.preventDefault();
										e.stopPropagation();
										setSelectedItem(1);
									}}
									selectable={true}
									active={selectedItem === 1}
									borderShown={selectedItem === 1}>
									Produtos
								</HeaderTitle>
							</div>
						)}
					</div>

					<Modal
						show={modalNovaEmpresa}
						onHide={() => {
							setModalNovaEmpresa(false);
						}}>
						<Modal.Body>
							<ModalEmpresa
								//categorias={categorias}
								setModalVisible={setModalNovaEmpresa}
								UpdateEmpresas={UpdateEmpresas}
							/>
						</Modal.Body>
					</Modal>
					<HeaderContainer>
						<HeaderButton
							style={{ marginRight: 10 }}
							onClick={(e) => {
								e.preventDefault();
								setModalNovaEmpresa(true);
							}}>
							<p>Nova Empresa</p>
						</HeaderButton>
						<HeaderUserButton />
					</HeaderContainer>
					{/*
					<HeaderSearchContainer active={searchActive}>
						<HeaderSearchInput placeholder='Cliente, ATK, UC...' />
						<button
							type='button'
							onClick={(e) => {
								e.preventDefault();
								console.log(searchActive);
								setSearchActive(!searchActive);
							}}>
							<UilSearch />
						</button>
					</HeaderSearchContainer>
				*/}
				</Header>
				<Body>
					<div className='body_header'>
						<DebouncerInput
							placeholder='Buscar por Empresa, CNPJ…'
							setSearchItem={setEmpresaSearch}
						/>
					</div>
					<TableContainer>
						<Table>
							<THead>
								{selectedItem === 0 ? (
									<tr>
										<Th></Th>
										<Th>EMPRESA</Th>
										<Th>CNPJ</Th>
										<Th>código</Th>
										<Th>
											<span style={{ paddingRight: 8 }}></span>
										</Th>
									</tr>
								) : (
									<tr>
										<Th></Th>
										<Th>PRODUTO</Th>
										<Th>EMPRESA</Th>
										<Th>CATEGORIA</Th>
										<Th>STATUS</Th>
										<Th>
											<span style={{ paddingRight: 8 }}></span>
										</Th>
									</tr>
								)}
							</THead>
							<TBody>
								{loadingEmpresa ? (
									<BodyTR>
										<td>
											<Skeleton style={{ width: 40, height: 40 }}></Skeleton>{' '}
										</td>
										<td>
											<Skeleton
												style={{ width: '100%', height: 19 }}></Skeleton>
										</td>
										<td>
											<Skeleton
												style={{ width: '100%', height: 19 }}></Skeleton>
										</td>
										<td>
											<Skeleton
												style={{ width: '100%', height: 19 }}></Skeleton>
										</td>
										<td>
											<Skeleton
												style={{
													width: '50%',
													height: 19,
													float: 'right',
												}}></Skeleton>
										</td>
									</BodyTR>
								) : selectedItem === 0 ? (
									empresas.map((empresa) => (
										<EmpresaTR
											UpdateEmpresas={UpdateEmpresas}
											empresa={empresa}
											categorias={categorias}
											DeletarEmpresas={DeletarEmpresas}
										/>
									))
								) : (
									produtos.map((produto) => {
										return (
											<ProdutoTR
												produto={produto}
												UpdateProdutos={UpdateProdutos}
											/>
										);
									})
								)}
							</TBody>
						</Table>
					</TableContainer>
				</Body>
			</Main>
		</Container>
	);
}

const CategoriaList = React.memo(function CategoriaList({
	categorias,
	categoriaSelecionada,
	selecionarCategoria,
	UpdateCategoria,
	RemoverCategoria,
	selectOpenCategoria,
	openCategoria,
}: any) {
	return categorias.map((categoria: any, index: number) => (
		<SideItem
			RemoverCategoria={RemoverCategoria}
			key={categoria.id}
			categoria={categoria}
			selected={
				categoriaSelecionada && categoriaSelecionada.id === categoria.id
					? true
					: false
			}
			open={openCategoria && openCategoria.id === categoria.id ? true : false}
			selecionarCategoria={selecionarCategoria}
			index={index}
			UpdateCategoria={UpdateCategoria}
			selectOpenCategoria={selectOpenCategoria}
			categoriaSelecionada={categoriaSelecionada}
		/>
	));
});

function SideItem({
	categoria,
	selected,
	selecionarCategoria,
	index,
	UpdateCategoria,
	RemoverCategoria,
	selectOpenCategoria,
	open,
	categoriaSelecionada,
}: {
	categoria: Categoria;
	selected: boolean;
	selecionarCategoria(c: Categoria | undefined): void;
	index: number;
	UpdateCategoria(c: Categoria): void;
	RemoverCategoria(c: Categoria): void;
	selectOpenCategoria?(c: Categoria | undefined): void;
	open?: boolean;
	categoriaSelecionada: Categoria | undefined;
}) {
	const history = useHistory();

	const [modalVisible, setModalVisible] = useState(false);
	const [modalSubVisible, setModalSubVisible] = useState(false);
	const [categorias, setCategorias] = useState<Categoria[]>([]);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [showDropdown, setShowDropdown] = useState(false);
	const [newHeight, setNewHeight] = useState(60);
	const dropdownRef = useRef<any>(null);

	function handleClickOutside(e: any) {
		if (
			dropdownRef.current &&
			dropdownRef.current !== null &&
			!dropdownRef.current.contains(e.target)
		) {
			setShowDropdown(false);
		}
	}

	useOutsideAlert(dropdownRef, handleClickOutside);

	async function RemoveThisCategory() {
		const token = sessionStorage.getItem('@bill-token');

		const config = {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		};

		try {
			const response = await api.delete(
				`admin/api/merchant-category/${categoria.id}`,
				config
			);

			if (response.data.success) {
				RemoverCategoria(categoria);
			}
		} catch (error: any) {
			console.log(error);
			if (error.response && error.response.status === 401) {
				history.push('/login');
			}
		}
	}

	function updateSubCategoria(CategoriaUpdate: Categoria) {
		const lista = [...categorias];
		const findIndex = lista.findIndex(
			(l) => l.id === (CategoriaUpdate.id as number)
		);
		//console.log(findIndex)
		console.log(CategoriaUpdate);
		if (findIndex !== -1) {
			lista[findIndex] = CategoriaUpdate;
		} else {
			lista.push(CategoriaUpdate);
		}

		setCategorias([...lista]);
	}

	function RemoverSubCategoria(CategoriaRemove: Categoria) {
		//setEmpresas([...Empresas]);
		const lista = [...categorias];
		const findIndex = lista.findIndex(
			(l) => l.id === (CategoriaRemove.id as number)
		);
		//console.log(findIndex)
		console.log(lista);
		if (findIndex !== -1) {
			lista.splice(findIndex, 1);
			//lista[findIndex] = CategoriaRemove;
		}

		setCategorias([...lista]);
	}

	async function getCategoryChilds() {
		const token = sessionStorage.getItem('@bill-token');

		const config = {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		};

		try {
			const response = await api.get(
				`/admin/api/merchant-category?parent=${categoria.id}`,
				config
			);
			if (response.data && response.data.categories) {
				setCategorias(response.data.categories);
			}
		} catch (error) {
			console.log(error);
		}
	}

	const [deleteModal, setDeleteModal] = useState(false);

	useEffect(() => {
		if (categoria.type === 'C') {
			getCategoryChilds();
		}
	}, [categoria]);

	useEffect(() => {
		if (categoria.type === 'C' && open) {
			let h = 60;
			if (categorias && categorias.length > 0) {
				h = h + 15;
				categorias.forEach(() => {
					h = h + 50;
				});
			}
			setNewHeight(h);
		} else {
			setNewHeight(60);
		}
	}, [open, categorias]);

	// false = visible true = hidden
	const [overflow, setOverflow] = useState(false);

	useEffect(() => {
		if (categoria.type === 'C') {
			if (open) setOverflow(true);

			if (newHeight > 60) {
				setTimeout(() => {
					setOverflow(false);
				}, 1000);
			}
		}
	}, [newHeight]);

	return (
		<Draggable draggableId={categoria.id.toString()} index={index}>
			{(provided) => (
				<SidebarItemContainer
					overflow={overflow}
					newHeight={newHeight}
					selected={selected || open ? true : false}
					active={categoria.status === 1 ? true : false}>
					<SidebarItem
						ref={provided.innerRef}
						{...provided.draggableProps}
						onClick={(e) => {
							e.preventDefault();
							if (categoria.type !== 'C') {
								selecionarCategoria(categoria);
								if (selectOpenCategoria) {
									selectOpenCategoria(undefined);
								}
							} else {
								if (selectOpenCategoria) {
									selectOpenCategoria(categoria);
									selecionarCategoria(undefined);
								}
							}
						}}
						newHeight={newHeight}
						selected={selected || open ? true : false}
						active={categoria.status === 1 ? true : false}>
						<div style={{ display: 'flex', alignItems: 'center' }}>
							{
								<div
									onClick={(e) => {
										e.stopPropagation();
									}}>
									<Modal
										show={modalVisible}
										onHide={() => setModalVisible(false)}>
										<Modal.Body>
											<ModalScreen
												categoria={categoria}
												setModalVisible={setModalVisible}
												UpdateCategoria={UpdateCategoria}
											/>
										</Modal.Body>
									</Modal>

									<Modal
										show={deleteModal}
										onHide={() => setDeleteModal(false)}>
										<Modal.Body>
											<DeleteModal
												activeFunction={RemoveThisCategory}
												labelStart='Tem certeza que deseja deletar a categoria '
												setModalVisible={setDeleteModal}
												focusItem={categoria.name}
												labelEnd={'? Essa ação não poderá ser desfeita.'}
												title={'Deletar Categoria?'}
											/>
										</Modal.Body>
									</Modal>
									<Modal
										show={modalSubVisible}
										onHide={() => setModalSubVisible(false)}>
										<Modal.Body>
											<ModalSub
												setModalVisible={setModalSubVisible}
												UpdateCategoria={updateSubCategoria}
												parent={{ id: categoria.id, label: categoria.name }}
											/>
										</Modal.Body>
									</Modal>
								</div>
							}
							{/*<div
							{...provided.dragHandleProps}
							style={{
								alignItems: 'center',
								justifyContent: 'center',
								display: 'flex',
							}}>
							<UilDraggabledots className='sideItem_draggableDot' />
						</div>*/}
							{/*<SidebarIconContainer selected={selected}>
							<img
								style={{ width: 48, height: 48 }}
								src={`${process.env.REACT_APP_IMG_HOST}/upload/categories/${categoria.img}`}
								alt={categoria.name}
							/>
						</SidebarIconContainer>*/}
							<span className='sideItem_status'></span>
							<p className='sideItem_name'>{categoria.name}</p>
						</div>

						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								marginRight: 16,
							}}>
							<Dropdown ref={dropdownRef} show={showDropdown}>
								<Dropdown.Toggle
									onClick={(e) => {
										e.preventDefault();
										e.stopPropagation();
										setShowDropdown(!showDropdown);
									}}
									className='dropdown-toggle-categoria'>
									<div>
										{showDropdown ? (
											<button
												type='button'
												onClick={(e) => {
													//e.preventDefault();
													//e.stopPropagation();
													//setShowDropdown(!showDropdown);
													//setModalVisible(true);
												}}
												style={{ background: '#18AA36', opacity: 1 }}
												className='sideItem_icon_container'>
												<img src={CloseWhite} alt='e' />
											</button>
										) : (
											<button
												type='button'
												onClick={(e) => {
													//e.preventDefault();
													//e.stopPropagation();
													//setShowDropdown(!showDropdown);
													//setModalVisible(true);
												}}
												style={{ background: '#FFFFFF' }}
												className='sideItem_icon_container'>
												<img src={MoreHoz} alt='e' />
											</button>
										)}
									</div>
								</Dropdown.Toggle>
								<Dropdown.Menu className='dropdown-menu-container'>
									{categoria.type === 'C' && (
										<Dropdown.Item
											onClick={(e) => {
												e.preventDefault();
												e.stopPropagation();
												setShowDropdown(false);
												setModalSubVisible(true);
												// setModalVisible(true);
											}}
											className='dropdown-item-categoria'>
											<div className='flex_row_center'>
												<img
													className='dropdown-item-icon'
													src={AddBox}
													alt='n'
												/>
												<span>Nova subcategoria</span>
											</div>
										</Dropdown.Item>
									)}
									{/*<Dropdown.Item
										onClick={(e) => {
											e.preventDefault();
											e.stopPropagation();
											setShowDropdown(false);
											//setModalVisible(true);
										}}
										className='dropdown-item-categoria'>
										<div className='flex_row_center'>
											<img
												className='dropdown-item-icon'
												src={EditGray}
												alt='n'
											/>
											<span>Editar</span>
										</div>
									</Dropdown.Item>*/}
									<Dropdown.Item
										onClick={(e) => {
											e.preventDefault();
											e.stopPropagation();
											setShowDropdown(false);

											setModalVisible(true);
										}}
										className='dropdown-item-categoria'>
										<div className='flex_row_center'>
											<img
												className='dropdown-item-icon'
												src={SettingsDark}
												alt='n'
											/>
											<span>Detalhes</span>
										</div>
									</Dropdown.Item>
									<Dropdown.Item
										onClick={(e) => {
											e.preventDefault();
											e.stopPropagation();
											setShowDropdown(false);
											//setModalVisible(true);
											setDeleteModal(true);
										}}
										className='dropdown-item-categoria'>
										<div className='flex_row_center'>
											<img
												className='dropdown-item-icon'
												src={RemoveDark}
												alt='n'
											/>
											<span>Excluir categoria</span>
										</div>
									</Dropdown.Item>
								</Dropdown.Menu>
							</Dropdown>

							{categoria.type === 'C' && (
								<div>
									<button
										type='button'
										onClick={(e) => {
											//e.preventDefault();
											//e.stopPropagation();
											//setShowDropdown(!showDropdown);
											//setModalVisible(true);
										}}
										style={{ background: '#FFFFFF', marginLeft: 12 }}
										className='sideItem_icon_container_open'>
										<img src={open ? ExpandLess : ExpandMore} alt='e' />
									</button>
								</div>
							)}
						</div>
					</SidebarItem>

					{categorias.length > 0 && open && (
						<div className='subCategoriasContainer'>
							{categorias.map((c) => {
								return (
									<SubItem
										c={c}
										categoriaSelecionada={categoriaSelecionada}
										selecionarCategoria={selecionarCategoria}
										updateSubCategoria={updateSubCategoria}
										RemoverSubCategoria={RemoverSubCategoria}
										parent={{ id: categoria.id, label: categoria.name }}
									/>
								);
							})}
						</div>
					)}
				</SidebarItemContainer>
			)}
		</Draggable>
	);
}

function SubItem({
	c,
	categoriaSelecionada,
	selecionarCategoria,
	updateSubCategoria,
	RemoverSubCategoria,
	parent,
}: {
	c: Categoria;
	categoriaSelecionada: Categoria | undefined;
	selecionarCategoria(c: Categoria | undefined): void;
	updateSubCategoria(c: Categoria): void;
	RemoverSubCategoria(c: Categoria): void;
	parent: { id: number; label: string };
}) {
	const dropdownRef = useRef<any>(null);
	const [showDropdown, setShowDropdown] = useState(false);
	const [modalVisible, setModalVisible] = useState(false);
	const [deleteModal, setDeleteModal] = useState(false);

	async function RemoveThisCategory() {
		const token = sessionStorage.getItem('@bill-token');

		const config = {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		};

		try {
			const response = await api.delete(
				`admin/api/merchant-category/${c.id}`,
				config
			);

			if (response.data.success) {
				RemoverSubCategoria(c);
			}
		} catch (error: any) {
			console.log(error);
			if (error.response && error.response.status === 401) {
				//history.push('/login');
			}
		}
	}

	function handleClickOutside(e: any) {
		if (
			dropdownRef.current &&
			dropdownRef.current !== null &&
			!dropdownRef.current.contains(e.target)
		) {
			setShowDropdown(false);
		}
	}

	useOutsideAlert(dropdownRef, handleClickOutside);
	return (
		<SidebarSubItem
			style={{ cursor: 'pointer' }}
			onClick={(e) => {
				e.stopPropagation();
				e.preventDefault();
				selecionarCategoria(c);
			}}
			selected={
				categoriaSelecionada && categoriaSelecionada.id === c.id ? true : false
			}>
			<div style={{ display: 'flex', alignItems: 'center' }}>
				{
					<div
						onClick={(e) => {
							e.stopPropagation();
						}}>
						<Modal show={modalVisible} onHide={() => setModalVisible(false)}>
							<Modal.Body>
								<ModalSub
									categoria={c}
									setModalVisible={setModalVisible}
									UpdateCategoria={updateSubCategoria}
									parent={parent}
								/>
							</Modal.Body>
						</Modal>
						<Modal show={deleteModal} onHide={() => setDeleteModal(false)}>
							<Modal.Body>
								<DeleteModal
									activeFunction={RemoveThisCategory}
									labelStart='Tem certeza que deseja deletar a categoria '
									setModalVisible={setDeleteModal}
									focusItem={c.name}
									labelEnd={'? Essa ação não poderá ser desfeita.'}
									title={'Deletar Categoria?'}
								/>
							</Modal.Body>
						</Modal>
					</div>
				}
				{/*<div
{...provided.dragHandleProps}
style={{
alignItems: 'center',
justifyContent: 'center',
display: 'flex',
}}>
<UilDraggabledots className='sideItem_draggableDot' />
</div>*/}
				{/*<SidebarIconContainer selected={selected}>
<img
style={{ width: 48, height: 48 }}
src={`${process.env.REACT_APP_IMG_HOST}/upload/categories/${categoria.img}`}
alt={categoria.name}
/>
</SidebarIconContainer>*/}
				<span
					style={{
						background: c.status === 1 ? '#18AA36' : '#F21F4A',
					}}
					className='sideItem_status'></span>
				<p className='sideItem_name'>{c.name}</p>
			</div>

			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					marginRight: 16,
				}}>
				<Dropdown ref={dropdownRef} show={showDropdown}>
					<Dropdown.Toggle
						onClick={(e) => {
							e.preventDefault();
							e.stopPropagation();
							setShowDropdown(!showDropdown);
						}}
						className='dropdown-toggle-categoria'>
						<div>
							{showDropdown ? (
								<button
									type='button'
									onClick={(e) => {
										//e.preventDefault();
										//e.stopPropagation();
										//setShowDropdown(!showDropdown);
										//setModalVisible(true);
									}}
									style={{ background: '#18AA36', opacity: 1 }}
									className='sideItem_icon_container'>
									<img src={CloseWhite} alt='e' />
								</button>
							) : (
								<button
									type='button'
									onClick={(e) => {
										//e.preventDefault();
										//e.stopPropagation();
										//setShowDropdown(!showDropdown);
										//setModalVisible(true);
									}}
									style={{ background: '#FFFFFF' }}
									className='sideItem_icon_container'>
									<img src={MoreHoz} alt='e' />
								</button>
							)}
						</div>
					</Dropdown.Toggle>
					<Dropdown.Menu className='dropdown-menu-container'>
						{c.type === 'C' && (
							<Dropdown.Item
								onClick={(e) => {
									e.preventDefault();
									e.stopPropagation();
									setShowDropdown(false);
									// setModalVisible(true);
								}}
								className='dropdown-item-categoria'>
								<div className='flex_row_center'>
									<img className='dropdown-item-icon' src={AddBox} alt='n' />
									<span>Nova subcategoria</span>
								</div>
							</Dropdown.Item>
						)}
						{/*<Dropdown.Item
							onClick={(e) => {
								e.preventDefault();
								e.stopPropagation();
								setShowDropdown(false);
								//setModalVisible(true);
							}}
							className='dropdown-item-categoria'>
							<div className='flex_row_center'>
								<img className='dropdown-item-icon' src={EditGray} alt='n' />
								<span>Editar</span>
							</div>
						</Dropdown.Item>*/}
						<Dropdown.Item
							onClick={(e) => {
								e.preventDefault();
								e.stopPropagation();
								setShowDropdown(false);
								setModalVisible(true);
							}}
							className='dropdown-item-categoria'>
							<div className='flex_row_center'>
								<img
									className='dropdown-item-icon'
									src={SettingsDark}
									alt='n'
								/>
								<span>Detalhes</span>
							</div>
						</Dropdown.Item>
						<Dropdown.Item
							onClick={(e) => {
								e.preventDefault();
								e.stopPropagation();
								setShowDropdown(false);
								setDeleteModal(true);
								//setModalVisible(true);
							}}
							className='dropdown-item-categoria'>
							<div className='flex_row_center'>
								<img className='dropdown-item-icon' src={RemoveDark} alt='n' />
								<span>Excluir categoria</span>
							</div>
						</Dropdown.Item>
					</Dropdown.Menu>
				</Dropdown>
			</div>
		</SidebarSubItem>
	);
}

function ProdutoTR({
	produto,
	UpdateProdutos,
}: {
	produto: Produto;
	UpdateProdutos(p: Produto): void;
}) {
	const [modalVisible, setModalVisible] = useState(false);

	return (
		<BodyTR
			style={{ cursor: 'pointer' }}
			onClick={(e) => {
				e.preventDefault();
				e.stopPropagation();
				setModalVisible(true);
			}}>
			<Modal
				show={modalVisible}
				onHide={() => {
					setModalVisible(false);
				}}>
				<span
					onClick={(e) => {
						e.stopPropagation();
					}}>
					<Modal.Body>
						<ModalProduto
							UpdateProdutos={UpdateProdutos}
							produto={produto}
							setModalVisible={setModalVisible}
						/>
					</Modal.Body>
				</span>
			</Modal>

			<td>
				{produto.img ? (
					<img
						style={{ width: 25, height: 25 }}
						src={`${process.env.REACT_APP_IMG_HOST}/upload/products/${produto.img}`}
						alt={produto.name}></img>
				) : (
					<img
						style={{ width: 25, height: 25 }}
						src={DefaultImg}
						alt={produto.name}></img>
				)}
			</td>
			<td>{produto.name} </td>
			<td>{produto.merchant.name} </td>
			<td>{produto.category.name} </td>
			<td>{produto.status === 1 ? 'Ativo' : 'Inativo'} </td>
			<td>
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						marginRight: 16,
						float: 'right',
					}}>
					<ActionButton
						onClick={(e) => {
							e.preventDefault();
							e.stopPropagation();
							setModalVisible(true);
						}}>
						Detalhes
					</ActionButton>
				</div>
			</td>
		</BodyTR>
	);
}

function EmpresaTR({
	empresa,
	categorias,
	UpdateEmpresas,
	DeletarEmpresas,
}: {
	empresa: Empresa;
	categorias: Categoria[];
	UpdateEmpresas(e: Empresa): void;
	DeletarEmpresas(e: number): void;
}) {
	const [modalNovaEmpresa, setModalNovaEmpresa] = useState(false);
	const [modalConfig, setModalConfig] = useState(false);
	const [modalContrato, setModalContrato] = useState(false);
	const [modalNovoContrato, setModalNovoContrato] = useState(false);
	const [modalEditarContrato, setModalEditarContrato] = useState(false);

	const [modalMetodo, setModalMetodo] = useState(false);
	const [modalNovoMetodo, setModalNovoMetodo] = useState(false);

	const [modalEditarMetodo, setModalEditarMetodo] = useState(false);

	const [metodo, setMetodo] = useState<PaymentMethod>();

	const [contratos, setContratos] = useState<Contrato[]>([]);

	const [contratoEscolhido, setContratoEscolhido] = useState<
		Contrato | undefined
	>();

	const history = useHistory();

	async function removerEmpresa() {
		const token = sessionStorage.getItem('@bill-token');

		const config = {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		};

		try {
			const response = await api.delete(
				`
			admin/api/merchant/${empresa.id}`,
				config
			);

			if (response.data.success) {
				DeletarEmpresas(empresa.id);
			}
		} catch (error: any) {
			console.log(error);
			if (error.response && error.response.status === 401) {
				history.push('/login');
			}
		}
	}
	//console.log(contratos)

	const [deleteModal, setDeleteModal] = useState(false);

	return (
		<BodyTR
			style={{ cursor: 'pointer' }}
			onClick={(e) => {
				e.preventDefault();
				e.stopPropagation();

				setModalNovaEmpresa(true);
			}}>
			<Modal show={modalConfig} onHide={() => setModalConfig(false)}>
				<span
					onClick={(e) => {
						e.stopPropagation();
					}}>
					<Modal.Body>
						<ModalConfig
							name={empresa.name}
							setModalVisible={setModalConfig}
							informacaoFinanceira={empresa.financialSettings}
						/>
					</Modal.Body>
				</span>
			</Modal>

			<Modal show={modalContrato} onHide={() => setModalContrato(false)}>
				<span
					onClick={(e) => {
						e.stopPropagation();
					}}>
					<Modal.Body>
						<ModalContrato
							setModalVisibleNovo={setModalNovoContrato}
							setModalVisibleEdit={setModalEditarContrato}
							setModalVisible={setModalContrato}
							empresa={empresa}
							setContratos={setContratos}
							contratos={contratos}
							modalVisible={modalContrato}
							setContratoEscolhido={setContratoEscolhido}
						/>
					</Modal.Body>
				</span>
			</Modal>
			{/*<Modal
				show={modalNovoContrato}
				onHide={() => {
					setModalNovoContrato(false);
					setModalContrato(true);
				}}>
				<Modal.Body>
					<ModalNovoContrato
						setPreModalVisible={setModalContrato}
						setModalVisible={setModalNovoContrato}
						empresa={empresa}
						setContratoEscolhido={setContratoEscolhido}
					/>
				</Modal.Body>
			</Modal>*/}
			{/*<Modal
				show={modalEditarContrato}
				onHide={() => {
					setModalEditarContrato(false);
					setModalContrato(true);
				}}>
				<Modal.Body>
					<ModalNovoContrato
						setPreModalVisible={setModalContrato}
						setModalVisible={setModalEditarContrato}
						empresa={empresa}
						contratos={contratos}
						contratoEscolhido={contratoEscolhido}
						setContratoEscolhido={setContratoEscolhido}
					/>
				</Modal.Body>
			</Modal>*/}
			<Modal
				show={modalNovaEmpresa}
				onHide={() => {
					setModalNovaEmpresa(false);
				}}>
				<span
					onClick={(e) => {
						e.stopPropagation();
					}}>
					<Modal.Body>
						<ModalEmpresa
							//categorias={categorias}
							setModalVisible={setModalNovaEmpresa}
							empresa={empresa}
							UpdateEmpresas={UpdateEmpresas}
						/>
					</Modal.Body>
				</span>
			</Modal>

			<Modal show={modalMetodo} onHide={() => setModalMetodo(false)}>
				<span
					onClick={(e) => {
						e.stopPropagation();
					}}>
					<Modal.Body>
						<ModalMetodo
							setModalVisibleNovo={setModalNovoMetodo}
							setModalVisibleEdit={setModalEditarMetodo}
							setModalVisible={setModalMetodo}
							empresa={empresa}
							setMetodo={setMetodo}
						/>
					</Modal.Body>
				</span>
			</Modal>

			<Modal show={deleteModal} onHide={() => setDeleteModal(false)}>
				<span
					onClick={(e) => {
						e.stopPropagation();
					}}>
					<Modal.Body>
						<DeleteModal
							activeFunction={removerEmpresa}
							labelStart='Tem certeza que deseja deletar a empresa '
							setModalVisible={setDeleteModal}
							focusItem={empresa.name}
							labelEnd={'? Essa ação não poderá ser desfeita.'}
							title={'Deletar Empresa?'}
						/>
					</Modal.Body>
				</span>
			</Modal>

			{/*<Modal
				show={modalNovoMetodo}
				onHide={() => {
					setModalNovoMetodo(false);
					setModalMetodo(true);
				}}>
				<Modal.Body>
					<ModalNovoMetodo
						setPreModalVisible={setModalMetodo}
						setModalVisible={setModalNovoMetodo}
						empresa={empresa}
					/>
				</Modal.Body>
			</Modal>
			<Modal
				show={modalEditarMetodo}
				onHide={() => {
					setModalEditarMetodo(false);
					setModalMetodo(true);
				}}>
				<Modal.Body>
					<ModalNovoMetodo
						setPreModalVisible={setModalMetodo}
						setModalVisible={setModalEditarMetodo}
						empresa={empresa}
						metodo={metodo}
					/>
				</Modal.Body>
			</Modal>*/}

			<td>
				{empresa.img ? (
					<img
						style={{ width: 25, height: 25 }}
						src={`${process.env.REACT_APP_IMG_HOST}/upload/merchants/${empresa.img}`}
						alt={empresa.name}></img>
				) : (
					<img
						style={{ width: 25, height: 25 }}
						src={DefaultImg}
						alt={empresa.name}></img>
				)}
			</td>
			<td>{empresa.name} </td>
			<td>{empresa.doc} </td>
			<td>{empresa.cod} </td>
			<td>
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						marginRight: 16,
						float: 'right',
					}}>
					{/*<OverlayTrigger
						placement='bottom'
						overlay={
							<Tooltip id='tool-tip'>
								<span style={{ textTransform: 'capitalize' }}>
									{'Editar Empresa'}
								</span>
							</Tooltip>
						}>
						<button
							type='button'
							onClick={(e) => {
								e.preventDefault();
								e.stopPropagation();
								//setShowDropdown(!showDropdown);
								// setModalVisible(true);
							}}
							style={{ marginRight: 8 }}
							className='tableItem_icon_container'>
							<img className='tableItem_icon tableItem_icon_edit' src={EditGray} alt='e' />
						</button>
					</OverlayTrigger>*/}

					<OverlayTrigger
						placement='bottom'
						overlay={
							<Tooltip id='tool-tip'>
								<span style={{ textTransform: 'capitalize' }}>
									{'Remover empresa'}
								</span>
							</Tooltip>
						}>
						<button
							onClick={(e) => {
								e.preventDefault();
								e.stopPropagation();
								setDeleteModal(true);
								//removerEmpresa();
							}}
							className='tableItem_icon_container'>
							<img className='tableItem_icon' src={RemoveGray} alt='e' />
						</button>
					</OverlayTrigger>
					<ActionButton
						onClick={(e) => {
							e.preventDefault();
							setModalNovaEmpresa(true);
						}}>
						Detalhes
					</ActionButton>
				</div>
				{/*
				<div style={{ display: 'flex', alignItems: 'center', float: 'right' }}>
					<ActionButton
						onClick={(e) => {
							e.preventDefault();
							setModalNovaEmpresa(true);
						}}>
						Editar
					</ActionButton>
					<ActionButton>Remover</ActionButton>
					<Dropdown>
						<Dropdown.Toggle className='dropdown-toggle-elip'>
							<UilEllipsisV />
						</Dropdown.Toggle>
						<Dropdown.Menu className='dropdown-menu-container'>
							<Dropdown.Item
								onClick={(e) => {
									e.preventDefault();
									setModalConfig(true);
								}}
								className='dropdown-item-categoria'>
								<UilUsdCircle /> Configurações financeiras
							</Dropdown.Item>
							<Dropdown.Item
								onClick={(e) => {
									e.preventDefault();
									setModalContrato(true);
								}}
								className='dropdown-item-categoria'>
								<UilFileContract /> Contratos
							</Dropdown.Item>
							<Dropdown.Item
								onClick={(e) => {
									e.preventDefault();
									setModalMetodo(true);
								}}
								className='dropdown-item-categoria'>
								<UilMoneyWithdraw /> Métodos de Pagamento
							</Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown>
				</div>{' '}
			*/}
			</td>
		</BodyTR>
	);
}

function useOutsideAlert(ref: any, handleClickOutside: any) {
	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			// Unbind the event listener on clean up
			document.removeEventListener('mousedown', handleClickOutside);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ref]);
}

// eslint-disable-next-line no-lone-blocks
{
	/*

<OverlayTrigger
							placement='bottom'
							overlay={
								<Tooltip id='tool-tip'>
									<span style={{ textTransform: 'capitalize' }}>
										{'Editar categoria'}
									</span>
								</Tooltip>
							}>
							<button
								type='button'
								onClick={(e) => {
									e.preventDefault();
									e.stopPropagation();
									//setShowDropdown(!showDropdown);
									setModalVisible(true);
								}}
								style={{ marginRight: 8 }}
								className='sideItem_icon_container'>
								<img className='sideItem_editIcon' src={EditGray} alt='e' />
							</button>
						</OverlayTrigger>

						<OverlayTrigger
							placement='bottom'
							overlay={
								<Tooltip id='tool-tip'>
									<span style={{ textTransform: 'capitalize' }}>
										{'Remover categoria'}
									</span>
								</Tooltip>
							}>
							<button
								onClick={(e) => {
									e.preventDefault();
									e.stopPropagation();
									setDeleteModal(true);
								}}
								className='sideItem_icon_container'>
								<img src={RemoveGray} alt='e' />
							</button>
						</OverlayTrigger>

					<Dropdown ref={dropdownRef} show={showDropdown}>
						<Dropdown.Toggle
							onClick={(e) => {
								e.preventDefault();
								e.stopPropagation();
								setShowDropdown(!showDropdown);
							}}
							className='dropdown-toggle-categoria'>
							<UilEllipsisV />
						</Dropdown.Toggle>
						<Dropdown.Menu className='dropdown-menu-container'>
							<Dropdown.Item
								onClick={(e) => {
									e.preventDefault();
									e.stopPropagation();
									setShowDropdown(false);
									setModalVisible(true);
								}}
								className='dropdown-item-categoria'>
								<UilPen /> Editar
							</Dropdown.Item>
							<Dropdown.Item
								onClick={(e) => {
									e.preventDefault();
									e.stopPropagation();
									setShowDropdown(false);
									//setModalVisible(true);
								}}
								className='dropdown-item-categoria'>
								<UilTrashAlt /> Remover
							</Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown>
*/
}
