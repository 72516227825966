/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useEffect, useState } from 'react';

import styled from 'styled-components';
import { colors } from '../../globalStyles/colors';
import { Categoria, Empresa, PaymentMethod, Produto } from '../../models/model';
import ClearIcon from '../../assets/images/clearIcon.svg';
import api from '../../services/api';
import Loading from '../../assets/images/loading-roll.svg';
import * as yup from 'yup';
import { InputContainer, SelectContainer } from '../../components/Input';
import { useHistory } from 'react-router';
import Dropzone, { WithDropZone } from '../../components/Dropzone';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import CloseWhite from '../../assets/images/close-white.svg';

const Modal = styled.div`
	//padding-top: 25px;
	//padding-left: 50px;
	//padding-right: 20000px;
	//padding-bottom: 25px;
	min-height: 100vh;
	background-color: #f5f5f5;
	width: 100vw;
	//margin-left: 50vw;
	height: 100%;
	flex: 1;
	.mb-8 {
		margin-bottom: 8px;
	}

	.mb-32 {
		margin-bottom: 32px;
	}
	.mb-40 {
		margin-bottom: 40px;
	}
`;

const ModalHeader = styled.div`
	background: #f6f6f6 0% 0% no-repeat padding-box;
	box-shadow: 0px 16px 16px #00000029;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	padding-top: 21px;
	padding-bottom: 21px;

	padding-left: 30px;
	padding-right: 30px;
	height: 80px;
`;

const HeaderTitle = styled.h1`
	font: normal normal 500 24px/32px Roboto;
	letter-spacing: 0px;
	color: #222222;
	display: flex;
	align-items: center;
`;
interface HeaderSwitchProps {
	active?: boolean;
}
const HeaderSwitch = styled.div<HeaderSwitchProps>`
	background: ${(props) => (props.active ? '#18AA36' : '#f21f4a')} 0% 0%
		no-repeat padding-box;
	border-radius: 19px;
	width: 58px;
	height: 32px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: ${(props) => (props.active ? 'flex-end' : 'flex-start')};
	padding-left: 4px;
	padding-right: 4px;

	cursor: pointer;

	transition: all 0.3s;

	margin-left: 16px;

	position: relative;
	.header_switch_button {
		background: #ffffff 0% 0% no-repeat padding-box;
		width: 24px;
		height: 24px;
		border-radius: 12px;
		//position: absolute;
		transition: all 0.3s;
		//${(props) => (props.active ? 'right: 4px' : 'left: 4px')}
	}
`;

const HeaderButton = styled.button`
	border-radius: 19px;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 38px;
	padding-left: 16px;
	padding-right: 16px;

	text-align: center;
	font: normal normal 500 16px/21px Roboto;
	letter-spacing: 0px;
	border: 0;
	.loading-roll {
		width: 21px;
		height: 21px;
	}
`;

interface ModalContainerProps {
	showScroll?: boolean;
}

const ModalContainer = styled.div<ModalContainerProps>`
	background: #ffffff 0% 0% no-repeat padding-box;
	box-shadow: -16px 0px 16px #00000029;
	opacity: 1;
	display: flex;
	flex-direction: column;
	//flex: 1;
	height: calc(100vh - 80px);
	overflow-y: scroll;

	::-webkit-scrollbar {
		background: transparent;

		// display:none;

		width: 0;
	}

	&::-webkit-scrollbar-track {
		/*  margin-top:0;
      border-bottom-right-radius: 16px;
      background-image: url("https://i.stack.imgur.com/Pwbuz.png");
    background-repeat: repeat-y;
    background-size: contain;
      */
		// margin-top: 54px;
	}
	&::-webkit-scrollbar-thumb {
		background: ${(props) =>
			props.showScroll ? 'rgba(103, 103, 103, 0.5)' : 'transparent'};
		border-radius: 20px;
		border: 2.5px solid rgba(0, 0, 0, 0);
		border-right: 5px solid rgba(0, 0, 0, 0);

		background-clip: padding-box;
		max-height: 5px;
		transition: all 1s;
	}
	padding: 30px;
	padding-top: 0;
	.modal_item_container {
		max-width: 100%;
		width: 100%;
		// margin-bottom: 24px;
	}

	.inputContainer {
		background: #ffffff 0% 0% no-repeat padding-box;
		border: 1px solid #9f9f9f;
		border-radius: 16px;
		height: 50px;
		display: flex;
		flex-direction: row;
		padding: 15px;
		input,
		select {
			::placeholder {
				font: normal normal 300 14px/19px Roboto;
				letter-spacing: 0px;
				color: #9f9f9f;
			}
			text-align: left;
			font: normal normal 500 14px/19px Roboto;
			letter-spacing: 0px;
			color: #222222;
			flex: 1;
			border: 0;
			margin-top: -15px;
			margin-bottom: -15px;
		}
	}
	.inputTitle {
		font: normal normal 500 11px/15px Roboto;
		letter-spacing: 0px;
		color: #4b4b4b;
		text-transform: uppercase;
		margin: 0;
		margin-bottom: 8px;
	}
`;

const ModalLabel = styled.p`
	margin: 0;
	text-align: left;
	font: normal normal 500 11px/15px Roboto;
	letter-spacing: 0px;
	color: #4b4b4b;
	text-transform: uppercase;
`;

interface ModalInputContainerProps {
	shadowColor?: string;
	borderColor?: string;
}
const ModalInputContainer = styled.div<ModalInputContainerProps>`
	background: #ffffff 0% 0% no-repeat padding-box;
	border: 1px solid
		${(props) => (props.borderColor ? props.borderColor : '#9f9f9f')};
	border-radius: 16px;
	display: flex;
	flex-direction: row;
	height: 50px;
	box-shadow: 0px 0px 10px 1px
		${(props) => (props.shadowColor ? props.shadowColor : 'transparent')};
	-webkit-box-shadow: 0px 0px 10px 1px
		${(props) => (props.shadowColor ? props.shadowColor : 'transparent')};
	-moz-box-shadow: 0px 0px 10px 1px
		${(props) => (props.shadowColor ? props.shadowColor : 'transparent')};

	width: 100%;
	.modal_input_icon_container {
		height: 100%;
		padding-right: 16px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.modal_input_icon {
		width: 16px;
		height: 16px;
		border-radius: 8px;
		align-self: center;
		cursor: pointer;
	}

	.modal_input {
		border: transparent;
		border-radius: 16px;
		flex: 1;
		overflow: hidden;
		padding-left: 16px;
		text-align: left;
		font: normal normal 500 14px/19px Roboto;
		letter-spacing: 0px;
		color: #222222;

		::-webkit-input-placeholder {
			/* Edge */
			text-align: left;
			font: normal normal 300 14px/19px Roboto;
			letter-spacing: 0px;
			color: #9f9f9f;
		}

		:-ms-input-placeholder {
			/* Internet Explorer 10-11 */
			text-align: left;
			font: normal normal 300 14px/19px Roboto;
			letter-spacing: 0px;
			color: #9f9f9f;
		}

		::placeholder {
			text-align: left;
			font: normal normal 300 14px/19px Roboto;
			letter-spacing: 0px;
			color: #9f9f9f;
		}
	}

	select {
		border: 0;
	}
`;

const ImageButton = styled.div`
	border: 1px dashed var(--disabled);
	background: #f5f5f5 0% 0% no-repeat padding-box;
	border: 1px dashed #9f9f9f;
	border-radius: 10px;

	display: flex;
	flex-direction: column;
	align-items: center;

	justify-content: space-between;

	padding-top: 10px;
	padding-bottom: 10px;
	position: relative;

	//width: 114px;
	max-height: 120px;
	max-width: 50%;
	svg {
		width: 32px;
		height: 27px;
		color: #9f9f9f;
	}
	img {
		width: 100%;
		height: 100%;
	}
`;

const RemoveImg = styled.button`
	width: 20px;
	height: 20px;
	border-radius: 50%;
	background-color: #f21f4a;
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: 5px;
	right: 5px;
	border: solid 1px transparent;

	img {
		width: 14px;
		height: 14px;
	}
`;

interface ModalProps {
	setModalVisible(b: boolean): void;
	produto: Produto;
	UpdateProdutos(p: Produto): void;
}

export default function ModalProduto({
	setModalVisible,
	produto,
	UpdateProdutos,
}: ModalProps) {
	const [isError, setIsError] = useState<any>({});
	const [name, setName] = useState('');

	const [categoria, setCategoria] = useState('');
	const [status, setStatus] = useState('0');
	const [code, setCode] = useState('');
	const [provider, setProvider] = useState('');
	const [description, setDescription] = useState('');
	const [visible, setVisible] = useState(false);
	const history = useHistory();

	const [categorias, setCategorias] = useState<Categoria[]>([]);
	const [categoriasList, setCategoriasList] = useState([
		{ label: '', value: '' },
	]);

	const tiposStatus = [
		{
			label: 'Ativo',
			value: '1',
		},
		{
			label: 'Inativo',
			value: '0',
		},
	];

	async function getCategorias() {
		const token = sessionStorage.getItem('@bill-token');

		const config = {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		};

		try {
			const response = await api.get(
				`/admin/api/merchant-category?type=P`,
				config
			);

			if (response.data && response.data.categories) {
				setCategorias(response.data.categories);
			}
		} catch (error) {
			console.log(error);
		}
	}
	useEffect(() => {
		const lista = [{ label: '', value: '' }];

		categorias.forEach((cat) => {
			lista.push({ label: cat.name, value: cat.id.toString() });
		});

		setCategoriasList([...lista]);
	}, [categorias]);

	useEffect(() => {
		getCategorias();
	}, []);

	useEffect(() => {
		if (produto) {
			setName(produto.name);
			setCategoria(produto.category.id.toString());
			setStatus(produto.status.toString());
			setCode(produto.code);
			setProvider(produto.provider);
			setDescription(produto.description);
			setVisible(produto.visible);
			setPreImg(produto.img);
		}
	}, [produto]);

	const tiposTaxas = [
		{ value: '', label: '' },
		{ value: 'ABSOLUT', label: 'Absoluto' },
		{ value: 'PERCENTAGE', label: 'Percentual' },
	];

	const [loading, setLoading] = useState(false);
	const [images, setImages] = useState<any>();
	const [reader, setReader] = useState<any>();

	const [preImg, setPreImg] = useState<string | undefined>();

	/*async function EditMethod() {
		setLoading(true);
		const token = sessionStorage.getItem('@bill-token');
		setIsError({});

		const config = {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		};

		try {
			const data = {
				merchant: empresa?.id,
				name: name,
				rate: parseFloat(taxa),
				tag,
				code: identificador,
				creditDate: parseInt(dataCredito),
				rateType: tipoTaxa,
			};

			const schema = yup.object().shape({
				merchant: yup.number().required(),
				name: yup.string().required(),
				rate: yup.number().required(),
				tag: yup.string().required(),
				code: yup.string().required(),
				creditDate: yup.number().required(),
				rateType: yup.string().required(),
			});

			await schema.validate(data, { abortEarly: false });

			const response = await api.post(
				`/admin/api/payment-method${metodo ? `/${metodo.id}` : ''}`,
				data,
				config
			);
			if (response.data.payment_method) {
				UpdateMetodo(response.data.payment_method);
				setModalVisible(false);
				setPreModalVisible(false);
			}
			setLoading(false);
		} catch (error:any) {
			console.log(error);
			const validationError = {} as any;
			if (error instanceof yup.ValidationError) {
				error.inner.forEach((err) => {
					//console.log(err.path);
					if (err.path) validationError[err.path] = true;
				});
			}
			console.log(validationError);
			setIsError(validationError);
			setLoading(false);
			if (error.response && error.response.status === 401) {
				history.push('/login');
			}
		}
	}*/

	const onDrop = useCallback((acceptedFiles) => {
		// this callback will be called after files get dropped, we will get the acceptedFiles. If you want, you can even access the rejected files too

		acceptedFiles.map((file: any) => {
			const reader = new FileReader();

			reader.onload = function (e) {
				if (e.target) {
					console.log(e.target.result);
					const x = e.target.result;

					setImages({ id: 5, src: x });
				}
			};
			console.log(file);

			reader.readAsDataURL(file);

			setReader(file);

			return file;
		});

		/*if (reader) {
			reader.onload = function (e) {
				if (e.target !== null && e !== null) {
					setImages((prevState: any) => [
						...prevState,
						{ id: 5, src: e.target.result },
					]);
				}
			};

			reader.readAsDataURL(acceptedFiles);
			return acceptedFiles;
		}*/

		//console.log(acceptedFiles);
	}, []);

	async function clearImg() {
		setImages(undefined);
		setPreImg(undefined);
	}

	async function editProduto() {
		setIsError({});
		setLoading(true);

		const token = sessionStorage.getItem('@bill-token');

		const config = {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		};

		try {
			const { type, merchant, mobile } = produto;

			const f = categoriasList.find((cl) => cl.value === categoria);

			const data = {
				name,
				description,
				category: {
					id: parseInt(categoria),
					name: f ? f.label : '',
				},
				status: parseInt(status),
				code,
				provider,
				type,
				merchant: merchant,
				visible,
				mobile,
			};

			const schema = yup.object().shape({
				name: yup.string().required(),
				description: yup.string(),
				category: yup.object().shape({
					id: yup.number().required(),
				}),
				status: yup.number().required(),
				code: yup.string().required(),
				provider: yup.string().required(),
			});

			await schema.validate(data, {
				abortEarly: false,
			});

			const response = await api.post(
				`
				admin/api/product/${produto.id}
			`,
				data,
				config
			);

			if (response.data && response.data.product) {
				UpdateProdutos(response.data.product);
				if(images || preImg)
				await editImage(response.data.product);
				else{
					deleteImage(response.data.product)
				}
			}
			// setLoading(false);

			///admin/api/product/{id}
		} catch (error: any) {
			console.log(error);
			const validationError = {} as any;
			if (error instanceof yup.ValidationError) {
				error.inner.forEach((err) => {
					if (err.path) validationError[err.path] = true;
				});
			} else {
				if (
					error.response &&
					error.response.status &&
					error.response.status === 401
				) {
					history.push('/login');
				}
			}
			console.log(validationError);
			setIsError(validationError);
			setLoading(false);
		}
	}

	async function deleteImage(product: Produto) {
		const token = sessionStorage.getItem('@bill-token');

		const config = {
			headers: {
				Authorization: `Bearer ${token}`,
				'Content-Type': 'multipart/form-data',
			},
		};
		try {
			const response = await api.delete(
				`admin/api/product/${product.id}/img`,
				config
			);

			if(response.data && response.data.product){
				UpdateProdutos(response.data.product);
			}

			setModalVisible(false);
			setLoading(false);
		} catch (error) {
			setLoading(false);

			console.log(error);
		}
	}

	async function editImage(product: Produto) {
		const token = sessionStorage.getItem('@bill-token');

		const config = {
			headers: {
				Authorization: `Bearer ${token}`,
				'Content-Type': 'multipart/form-data',
			},
		};

		try {
			const formData = new FormData();

			formData.append('img', reader);
			const response = await api.post(
				`
				admin/api/product/${product.id}/img
			`,
				formData,
				config
			);
			if (response.data && response.data.product) {
				UpdateProdutos(response.data.product);
				setModalVisible(false);
			}
			setLoading(false);
		} catch (error: any) {
			console.log(error);
			setLoading(false);
			if (
				error.response &&
				error.response.status &&
				error.response.status === 401
			) {
				history.push('/login');
			}
		}
	}

	return (
		<Modal style={{ maxWidth: 600 }}>
			<ModalHeader>
				<HeaderTitle>
					{produto ? produto.name : 'Novo Metódo'}
					<OverlayTrigger
						placement='bottom'
						overlay={
							<Tooltip id='tool-tip-prod'>
								<span>{'Visibilidade'}</span>
							</Tooltip>
						}>
						<HeaderSwitch
							active={visible}
							onClick={(e) => {
								e.preventDefault();
								if (visible) {
									setVisible(false);
								} else {
									setVisible(true);
								}
							}}>
							<div className='header_switch_button' />
						</HeaderSwitch>
					</OverlayTrigger>
				</HeaderTitle>

				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
					}}>
					<HeaderButton
						onClick={(e) => {
							e.preventDefault();

							setModalVisible(false);
						}}
						style={{
							background: 'transparent',
							color: '#676767',
							marginRight: 8,
						}}>
						Cancelar
					</HeaderButton>
					<HeaderButton
						onClick={(e) => {
							e.preventDefault();
							editProduto();
						}}
						disabled={loading}
						style={{ background: '#28A745', color: '#FFFFFF' }}>
						{!loading ? (
							'Salvar'
						) : (
							<img className='loading-roll' alt='loading' src={Loading} />
						)}
					</HeaderButton>
				</div>
			</ModalHeader>

			<ModalContainer>
				<div style={{ marginTop: 30 }} className='inputGroup'>
					<p className='inputTitle'>ÍCONE DO PRODUTO</p>
					{images ? (
						<WithDropZone onDrop={onDrop} accept={'image/*'}>
							<ImageButton>
								<img className='logoImg' src={images.src} alt='' />
								<RemoveImg
									onClick={(e) => {
										e.stopPropagation();
										clearImg();
									}}>
									<img alt='x' src={CloseWhite} />
								</RemoveImg>
							</ImageButton>
						</WithDropZone>
					) : preImg ? (
						<WithDropZone onDrop={onDrop} accept={'image/*'}>
							<ImageButton>
								<img
									className='logoImg'
									src={`${process.env.REACT_APP_IMG_HOST}/upload/products/${preImg}`}
									alt='img'
								/>
								<RemoveImg
									onClick={(e) => {
										e.stopPropagation();
										clearImg();
									}}>
									<img alt='x' src={CloseWhite} />
								</RemoveImg>
							</ImageButton>
						</WithDropZone>
					) : (
						<ImageButton>
							<Dropzone onDrop={onDrop} accept={'image/*'} />
						</ImageButton>
					)}
				</div>

				<InputContainer
					fieldLabel='Nome'
					inputName={name}
					setInputField={setName}
					isError={isError.name}
					placeholder={'Insira o nome'}
				/>

				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between',
					}}>
					<div style={{ width: '47%' }}>
						<SelectContainer
							fieldLabel='Categoria'
							inputName={categoria}
							setInputField={setCategoria}
							isError={isError.categoria}
							options={categoriasList}
						/>
					</div>
					<div style={{ width: '47%' }}>
						<SelectContainer
							fieldLabel='Status'
							inputName={status}
							setInputField={setStatus}
							isError={isError.status}
							options={tiposStatus}
						/>
					</div>
				</div>

				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between',
					}}>
					<div style={{ width: '47%' }}>
						<InputContainer
							fieldLabel='Código'
							inputName={code}
							setInputField={setCode}
							isError={isError.code}
							placeholder={'Insira o código'}
							type='number'
						/>
					</div>

					<div style={{ width: '47%' }}>
						<InputContainer
							fieldLabel='Provider'
							inputName={provider}
							setInputField={setProvider}
							isError={isError.provider}
							placeholder={'Insira o provedor'}
							type='number'
						/>
					</div>
				</div>

				<InputContainer
					fieldLabel='Descrição'
					inputName={description}
					setInputField={setDescription}
					isError={isError.description}
					placeholder={'Insira um texto de apoio'}
				/>
			</ModalContainer>
		</Modal>
	);
}
