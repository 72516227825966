export function StringToCurrency(value: string): string {
    return parseFloat(value).toLocaleString('pt-BR', {
      minimumFractionDigits: 2,
      style: 'currency',
      currency: 'BRL'
    })
  }
  
  export function StringToCurrencyNoSifra(value: string): string {
    const res = parseFloat(value).toLocaleString('pt-BR', {
      minimumFractionDigits: 2,
      style: 'currency',
      currency: 'BRL'
    })
    return res.replace('R$ ', '')
  }
  

  export function formatFloatToCurrency(value: number) {
    const newValue = new Intl.NumberFormat('pt-BR', {
      currency: 'BRL',
      minimumFractionDigits: 2
    }).format(value)
    return `${newValue}`
  }

  export function formatStringToFloat(value: string) {
    let newValue = value.replace(/\D/g, '')
    newValue = newValue.replace(/^[0]*/, '')
  
    if (newValue.length === 0) {
      return Number.parseFloat(`0.00`)
    }
    if (newValue.length === 1) {
      return Number.parseFloat(`0.0${newValue}`)
    }
    if (newValue.length === 2) {
      return Number.parseFloat(`0.${newValue}`)
    }
  
    newValue = newValue.replace(/(\d{2})$/, '.$1')
  
    return Number.parseFloat(newValue)
  }