import { useEffect } from 'react';

export function useOutsideAlert(ref: any, handleClickOutside: any): void {
	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			// Unbind the event listener on clean up
			document.removeEventListener('mousedown', handleClickOutside);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ref]);
}
