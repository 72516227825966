import styled from 'styled-components';

export const ReactDiv = styled.div`
  display: flex;
  position: fixed;
  z-index: 10;
  border-radius: 8px;
  height: 54px;
  bottom: 20px;
  vertical-align: top;
  width: 256px;
  background: #4a90e2;
  right: -200px;
  transition: 0.3s;
  .img{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
    height: 54px;
    width: 60px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    background-color: #fff;
    img{
      height: 40px;
      width: 40px;
      transition: all 0.3s;
      &[unselectable="off"]{
        animation-name: mymove;
        animation-timing-function: ease-in-out;
        animation-duration: 2s;
        animation-delay: 0.3s;
        animation-iteration-count: infinite;
        animation-fill-mode: forwards;
        -webkit-animation-fill-mode: forwards;
      }
      @keyframes mymove {
        from{transform: rotate(0deg);}
        to{transform: rotate(360deg);}
      }
    }
  }
  .text{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    >span{
      color: #fff;
      font-size: 12px;
      margin-bottom: 5px;
      a{
        color: #fff;
        text-decoration: none;
        &:hover{
          text-decoration: underline;
        }
      }
    }
  }
  &:hover{
    right: 10px;
  }
`;
