/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import styled from 'styled-components';
import { colors } from '../../globalStyles/colors';
import { Contrato, Empresa, Parceiro } from '../../models/model';
import api from '../../services/api';
import ClearIcon from '../../assets/images/clearIcon.svg';
import Loading from '../../assets/images/loading-roll.svg';

import * as yup from 'yup';
import { InputContainer, SelectContainer } from '../../components/Input';

const Modal = styled.div`
	//padding-top: 25px;
	//padding-left: 50px;
	//padding-right: 20000px;
	//padding-bottom: 25px;
	min-height: 100vh;
	background-color: #f5f5f5;
	width: 100vw;
	//margin-left: 50vw;
	height: 100%;
	flex: 1;
	.mb-8 {
		margin-bottom: 8px;
	}

	.mb-32 {
		margin-bottom: 32px;
	}
	.mb-40 {
		margin-bottom: 40px;
	}
`;

const ModalHeader = styled.div`
	background: #f6f6f6 0% 0% no-repeat padding-box;
	box-shadow: 0px 16px 16px #00000029;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	padding-top: 21px;
	padding-bottom: 21px;

	padding-left: 30px;
	padding-right: 30px;
	height: 80px;
`;

const HeaderTitle = styled.h1`
	font: normal normal 500 24px/32px Roboto;
	letter-spacing: 0px;
	color: #222222;
	display: flex;
	align-items: center;
`;

const HeaderButton = styled.button`
	border-radius: 19px;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 38px;
	padding-left: 16px;
	padding-right: 16px;

	text-align: center;
	font: normal normal 500 16px/21px Roboto;
	letter-spacing: 0px;
	border: 0;
	.loading-roll {
		width: 21px;
		height: 21px;
	}
`;

interface ModalContainerProps {
	showScroll?: boolean;
}

const ModalContainer = styled.div<ModalContainerProps>`
	background: #ffffff 0% 0% no-repeat padding-box;
	//box-shadow: -16px 0px 16px #00000029;
	opacity: 1;
	display: flex;
	flex-direction: column;
	//flex: 1;
	height: calc(100vh - 80px);

	overflow-y: scroll;

	::-webkit-scrollbar {
		background: transparent;

		// display:none;

		width: 0;
	}

	&::-webkit-scrollbar-track {
		/*  margin-top:0;
      border-bottom-right-radius: 16px;
      background-image: url("https://i.stack.imgur.com/Pwbuz.png");
    background-repeat: repeat-y;
    background-size: contain;
      */
		// margin-top: 54px;
	}
	&::-webkit-scrollbar-thumb {
		background: ${(props) =>
			props.showScroll ? 'rgba(103, 103, 103, 0.5)' : 'transparent'};
		border-radius: 20px;
		border: 2.5px solid rgba(0, 0, 0, 0);
		border-right: 5px solid rgba(0, 0, 0, 0);

		background-clip: padding-box;
		max-height: 5px;
		transition: all 1s;
	}
	padding: 30px;
	.modal_item_container {
		max-width: 100%;
		width: 100%;
		margin-bottom: 24px;
	}

	.inputContainer {
		background: #ffffff 0% 0% no-repeat padding-box;
		border: 1px solid #9f9f9f;
		border-radius: 16px;
		height: 50px;
		display: flex;
		flex-direction: row;
		padding: 15px;
		input,
		select {
			::placeholder {
				font: normal normal 300 14px/19px Roboto;
				letter-spacing: 0px;
				color: #9f9f9f;
			}
			text-align: left;
			font: normal normal 500 14px/19px Roboto;
			letter-spacing: 0px;
			color: #222222;
			flex: 1;
			border: 0;
			margin-top: -15px;
			margin-bottom: -15px;
		}
	}
`;

const ModalLabel = styled.p`
	margin: 0;
	text-align: left;
	font: normal normal 500 11px/15px Roboto;
	letter-spacing: 0px;
	color: #4b4b4b;
	text-transform: uppercase;
`;

interface ModalInputContainerProps {
	shadowColor?: string;
	borderColor?: string;
}
const ModalInputContainer = styled.div<ModalInputContainerProps>`
	background: #ffffff 0% 0% no-repeat padding-box;
	border: 1px solid
		${(props) => (props.borderColor ? props.borderColor : '#9f9f9f')};
	border-radius: 16px;
	display: flex;
	flex-direction: row;
	height: 50px;
	box-shadow: 0px 0px 10px 1px
		${(props) => (props.shadowColor ? props.shadowColor : 'transparent')};
	-webkit-box-shadow: 0px 0px 10px 1px
		${(props) => (props.shadowColor ? props.shadowColor : 'transparent')};
	-moz-box-shadow: 0px 0px 10px 1px
		${(props) => (props.shadowColor ? props.shadowColor : 'transparent')};

	width: 100%;
	.modal_input_icon_container {
		height: 100%;
		padding-right: 16px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.modal_input_icon {
		width: 16px;
		height: 16px;
		border-radius: 8px;
		align-self: center;
		cursor: pointer;
	}

	.modal_input {
		border: transparent;
		border-radius: 16px;
		flex: 1;
		overflow: hidden;
		padding-left: 16px;
		text-align: left;
		font: normal normal 500 14px/19px Roboto;
		letter-spacing: 0px;
		color: #222222;

		::-webkit-input-placeholder {
			/* Edge */
			text-align: left;
			font: normal normal 300 14px/19px Roboto;
			letter-spacing: 0px;
			color: #9f9f9f;
		}

		:-ms-input-placeholder {
			/* Internet Explorer 10-11 */
			text-align: left;
			font: normal normal 300 14px/19px Roboto;
			letter-spacing: 0px;
			color: #9f9f9f;
		}

		::placeholder {
			text-align: left;
			font: normal normal 300 14px/19px Roboto;
			letter-spacing: 0px;
			color: #9f9f9f;
		}
	}

	select {
		border: 0;
	}
`;

interface ModalProps {
	setModalVisible(b: boolean): void;
	setPreModalVisible(b: boolean): void;
	empresa: Empresa | undefined;
	//contratos?: any;*/
	contratoEscolhido?: Contrato | undefined;
	updateContrato(u: Contrato): void;
	deletarContrato(c: number): void;
	listaParceiros: Parceiro[];

	//setContratoEscolhido(a: Contrato | undefined): void;
}

export default function ModalNovoContrato({
	setModalVisible,
	setPreModalVisible,
	//categorias,
	empresa,
	//	contratos,*/
	contratoEscolhido,
	updateContrato,
	listaParceiros,
}: ModalProps) {
	const [isError, setIsError] = useState<any>({});
	const [provedor, setProvedor] = useState('STONE');
	const [parceiro, setParceiro] = useState('');
	const [bonusParceiro, setBonusParceiro] = useState('');
	const [situacao, setSituacao] = useState('0');
	const [chave, setChave] = useState('');
	const [chaveSecreta, setChaveSecreta] = useState('');
	const [stoneCode, setStoneCode] = useState('');
	const history = useHistory();

	const [listPartner, setListPartner] = useState([{ label: '', value: '' }]);

	useEffect(() => {
		const l = [{ label: '', value: '' }];

		listaParceiros.forEach((lp) => {
			l.push({ label: lp.name, value: lp.id.toString() });
		});

		setListPartner([...l]);
	}, [listaParceiros]);
	useEffect(() => {
		if (contratoEscolhido) {
			setProvedor(contratoEscolhido.provider);
			setParceiro(contratoEscolhido.partner.id.toString());
			setBonusParceiro(contratoEscolhido.partnerBonus);
			setSituacao(contratoEscolhido.status.toString());
			setChave(contratoEscolhido.merchantKey);
			setChaveSecreta(contratoEscolhido.merchantSecretKey);
			setStoneCode(contratoEscolhido.code);
		}
	}, [contratoEscolhido]);

	const provedores = [
		{ label: 'Stone', value: 'STONE' },
		{ value: 'MUNDIPAGG', label: 'Mundipagg' },
	];
	//const parceiros = ['', 'Brasil', 'Argentina', 'Parceiro'];

	const situacoes = [
		{ label: 'Ativo', value: '1' },
		{ label: 'Inativo', value: '0' },
	];

	const [loading, setLoading] = useState(false);

	async function EditContrato() {
		setIsError({});
		setLoading(true);
		const token = sessionStorage.getItem('@bill-token');

		const config = {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		};

		try {
			const data = {
				partner: parseInt(parceiro),
				merchant: empresa?.id,
				provider: provedor,
				status: parseInt(situacao),
				partner_bonus: parseFloat(bonusParceiro),
				merchant_key: chave,
				merchant_secret_key: chaveSecreta,
				code: stoneCode,
			};

			const schema = yup.object().shape({
				partner: yup.number().required(),
				merchant: yup.number().required(),
				provider: yup.string().required(),
				status: yup.number().default(0),
				partner_bonus: yup.number().min(0),
				merchant_key: yup.string().required(),
				merchant_secret_key: yup.string().required(),
				code: yup.string().required(),
			});

			await schema.validate(data, { abortEarly: false });

			const response = await api.post(
				`admin/api/contract${
					contratoEscolhido ? `/${contratoEscolhido.id}` : ''
				}`,
				data,
				config
			);

			if (response.data.contract) {
				updateContrato(response.data.contract);

				setModalVisible(false);
				setPreModalVisible(false);
			}
			setLoading(false);
		} catch (error:any) {
			console.log(error);
			const validationError = {} as any;
			if (error instanceof yup.ValidationError) {
				error.inner.forEach((err) => {
					//console.log(err.path);
					if (err.path) validationError[err.path] = true;
				});
			}
			console.log(validationError);
			setIsError(validationError);
			setLoading(false);
			if (error.response && error.response.status === 401) {
				history.push('/login');
			}
		}
	}

	return (
		<Modal style={{ maxWidth: 500 }}>
			<ModalHeader>
				<HeaderTitle>
					{contratoEscolhido ? 'Editar Contrato' : 'Novo Contrato'}
				</HeaderTitle>

				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
					}}>
					<HeaderButton
						onClick={(e) => {
							e.preventDefault();

							setModalVisible(false);
							setPreModalVisible(false);
						}}
						style={{
							background: 'transparent',
							color: '#676767',
							marginRight: 8,
						}}>
						Cancelar
					</HeaderButton>
					<HeaderButton
						onClick={(e) => {
							e.preventDefault();
							//EditEmpresa();
							EditContrato();
						}}
						disabled={loading}
						style={{ background: '#28A745', color: '#FFFFFF' }}>
						{!loading ? (
							'Salvar'
						) : (
							<img className='loading-roll' alt='loading' src={Loading} />
						)}
					</HeaderButton>
				</div>
			</ModalHeader>

			<ModalContainer>
				<SelectContainer
					fieldLabel='Provedor'
					inputName={provedor}
					setInputField={setProvedor}
					isError={isError.provider}
					options={provedores}
				/>

				<SelectContainer
					fieldLabel='Parceiro'
					inputName={parceiro}
					setInputField={setParceiro}
					isError={isError.partner}
					options={listPartner}
				/>

				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between',
					}}>
					<div style={{ width: '47%' }}>
						<InputContainer
							fieldLabel='Bônus Parceiro'
							inputName={bonusParceiro}
							setInputField={setBonusParceiro}
							isError={isError.partner_bonus}
							type='number'
							placeholder={'Insira o bônus do parceiro'}
						/>
					</div>

					<div style={{ width: '47%' }}>
						<SelectContainer
							fieldLabel='Situação'
							inputName={situacao}
							setInputField={setSituacao}
							isError={isError.status}
							options={situacoes}
						/>
					</div>
				</div>

				<InputContainer
					fieldLabel='Stone Code'
					inputName={stoneCode}
					setInputField={setStoneCode}
					isError={isError.code}
					placeholder={'Insira o Stone Code'}
				/>

				<InputContainer
					fieldLabel='Chave'
					inputName={chave}
					setInputField={setChave}
					isError={isError.merchant_key}
					placeholder={'Insira a chave'}
				/>

				<InputContainer
					fieldLabel='Chave Secreta'
					inputName={chaveSecreta}
					setInputField={setChaveSecreta}
					isError={isError.merchant_secret_key}
					placeholder={'Insira a chave secreta'}
				/>
			</ModalContainer>
		</Modal>
	);
}
