import React, { useState, useEffect, useRef } from 'react';
import { DateRange } from 'react-date-range';
import { pt } from 'date-fns/locale';
import { Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';

import { UilCalendarAlt } from '@iconscout/react-unicons';
import { useOutsideAlert } from './clickOutside';

import * as S from './styles';

interface CalendarProps {
	range: { startDate: Date; endDate: Date; key: string }[];
	setRange(r: { startDate: Date; endDate: Date; key: string }[]): void;
	setShowDropdown(b: boolean): void;
	showDropdown: boolean;
}

export function CalendarContainer({
	range,
	setRange,
	setShowDropdown,
	showDropdown,
}: CalendarProps): any {
	const [tRange, setTRange] = useState([...range]);

	useEffect(() => {
		if (showDropdown) setTRange([...range]);
	}, [showDropdown]);

	return (
		<S.CalendarSettings
			onClick={(e) => {
				e.preventDefault();
				e.stopPropagation();
			}}>
			<DateRange
				// showMonthAndYearPickers={false}
				// showMonthArrow={false}
				showDateDisplay={false}
				// twoStepChange={true}

				// scroll={{enabled: true}}
				// customInput={<InputMask mask='99/99/9999' />}
				startDatePlaceholder='data inicial'
				endDatePlaceholder='data final'
				rangeColors={['#18AA36', '#FFFFFF']}
				editableDateInputs
				onChange={(item: any) => {
					if (item.selection) {
						/// /console.log(item.selection);
						setTRange([item.selection]);
					}
					/// /console.log(item);
					// setRangeHeader([item.selection]);
				}}
				moveRangeOnFirstSelection={false}
				ranges={tRange}
				months={2}
				direction='horizontal'
				locale={pt}
				dateDisplayFormat='P'
				// dateDisplayFormat="'data': P"
				monthDisplayFormat='MMMM yyy'
				weekdayDisplayFormat='EEEEE'
				weekStartsOn={0}
				//
			/>
			<div className='calendario_footer'>
				<button
					onClick={(e) => {
						e.preventDefault();
						setShowDropdown(false);
					}}
					type='button'
					className='calendario_footer_cancelar'>
					Cancelar
				</button>
				<button
					onClick={(e) => {
						e.preventDefault();
						setRange([...tRange]);
						setShowDropdown(false);
					}}
					type='button'
					className='calendario_footer_confirmar'>
					Confirmar
				</button>
			</div>
		</S.CalendarSettings>
	);
}

interface PersonalizadoDropdown {
	item: string;
	setItem(i: string): void;
	range: { startDate: Date; endDate: Date; key: string }[];
	setRange(r: { startDate: Date; endDate: Date; key: string }[]): void;
}

export function CalendarioPersonalizadoV2({
	item,
	setItem,
	range,
	setRange,
}: PersonalizadoDropdown) {
	const [showDropdown, setShowDropdown] = useState(false);
	const calendarRef = useRef<any>(null);

	function handleClickOutside(e: any): void {
		if (
			calendarRef.current &&
			calendarRef.current !== null &&
			!calendarRef.current.contains(e.target)
		) {
			setShowDropdown(false);
		}
	}
	useOutsideAlert(calendarRef, handleClickOutside);

	return (
		<S.DropdownContainer>
			<Dropdown show={showDropdown} ref={calendarRef}>
				<Dropdown.Toggle className='dropdown-none'>
					<S.HeaderButton
						//key={data.dia}
						onClick={(e) => {
							e.preventDefault();
							setItem('personalizado');
							setShowDropdown(!showDropdown);
							//handleRange(data.dia);
						}}
						active={'personalizado' === item}>
						
						<p>{'personalizado'}</p>
					</S.HeaderButton>
				</Dropdown.Toggle>
				<Dropdown.Menu>
					<CalendarContainer
						setShowDropdown={setShowDropdown}
						range={range}
						setRange={setRange}
						showDropdown={showDropdown}
					/>
				</Dropdown.Menu>
			</Dropdown>
		</S.DropdownContainer>
	);
}



interface ModalProps {
	range: { startDate: Date; endDate: Date; key: string }[];
	setRange(r: { startDate: Date; endDate: Date; key: string }[]): void;
	setModalVisible(b: boolean): void;
	modalVisible: boolean;
}


export function CalendarModal({
	range,
	setRange,
	setModalVisible,
	modalVisible,
}: ModalProps) {
	return (
		<S.ModalCalendar
			onClick={(e) => {
				e.preventDefault();
				setModalVisible(false);
			}}>
			<CalendarModalContainer
				range={range}
				setRange={setRange}
				setShowDropdown={setModalVisible}
				showDropdown={modalVisible}
			/>
		</S.ModalCalendar>
	);
}

export function CalendarModalContainer({
	range,
	setRange,
	setShowDropdown,
	showDropdown,
}: CalendarProps): any {
	const [tRange, setTRange] = useState([...range]);

	useEffect(() => {
		if (showDropdown) setTRange([...range]);
	}, [showDropdown]);

	return (
		<S.CalendarModalSettings
			onClick={(e) => {
				e.preventDefault();
				e.stopPropagation();
			}}>
			<DateRange
				// showMonthAndYearPickers={false}
				// showMonthArrow={false}
				showDateDisplay={false}
				// twoStepChange={true}

				// scroll={{enabled: true}}
				// customInput={<InputMask mask='99/99/9999' />}
				startDatePlaceholder='data inicial'
				endDatePlaceholder='data final'
				rangeColors={['#18AA36', '#FFFFFF']}
				editableDateInputs
				onChange={(item: any) => {
					if (item.selection) {
						/// /console.log(item.selection);
						setTRange([item.selection]);
					}
					/// /console.log(item);
					// setRangeHeader([item.selection]);
				}}
				moveRangeOnFirstSelection={false}
				ranges={tRange}
				months={2}
				direction='horizontal'
				locale={pt}
				dateDisplayFormat='P'
				// dateDisplayFormat="'data': P"
				monthDisplayFormat='MMMM yyy'
				weekdayDisplayFormat='EEEEE'
				weekStartsOn={0}
				//
			/>
			<div className='calendario_footer'>
				<button
					onClick={(e) => {
						e.preventDefault();
						setShowDropdown(false);
					}}
					type='button'
					className='calendario_footer_cancelar'>
					Cancelar
				</button>
				<button
					onClick={(e) => {
						e.preventDefault();
						setRange([...tRange]);
						setShowDropdown(false);
					}}
					type='button'
					className='calendario_footer_confirmar'>
					Confirmar
				</button>
			</div>
		</S.CalendarModalSettings>
	);
}
