import * as S from "./styles";
import NotFoundImg from "../../assets/images/not_found.png";

export const NotFoundComponent = () => {
  return (
    <S.NotFoundWrapper>
      <S.NotFoundImage src={NotFoundImg} alt="not found" />
      <S.NotFoundText>
        Não encontramos resultados para sua busca. Tente outro termo.
      </S.NotFoundText>
    </S.NotFoundWrapper>
  );
};
