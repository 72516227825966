import styled from 'styled-components';

export const Body = styled.div`
	width: 100vw;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const Container = styled.div`
	background: #f6f6f8 0% 0% no-repeat padding-box;
	box-shadow: 0px 6px 12px #0000001a;
	border: 1px solid #ebebeb;
	border-radius: 24px;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 23px;
	position: relative;

	max-width: 348px;

	.bold_item {
		font-weight: bold;
	}

	.row_between {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
	}
`;

export const Title = styled.h1`
	text-align: center;
	font: normal normal bold 24px/32px Roboto;
	letter-spacing: 0px;
	color: #222222;
	margin: 0;
	margin-bottom: 16px;
	padding-top: 40px;
`;

export const Label = styled.p`
	text-align: center;
	font: normal normal normal 16px/24px Roboto;
	letter-spacing: 0px;
	margin: 0;
	color: #222222;
	margin-bottom: 32px;
`;

export const CancelButton = styled.button`
	background: #f6f6f8 0% 0% no-repeat padding-box;
	border: 1px solid #28a745;
	border-radius: 19px;

	height: 38px;
	flex: 1;

	text-align: center;
	font: normal normal 500 16px/21px Roboto;
	letter-spacing: 0px;
	color: #28a745;
`;

export const DeleteButton = styled.button`
	background: #dc3645 0% 0% no-repeat padding-box;
	border-radius: 19px;
	border: 1px solid #dc3645;

	height: 38px;
	flex: 1;

	text-align: center;
	font: normal normal 500 16px/21px Roboto;
	letter-spacing: 0px;
	color: #ffffff;
`;

export const DeleteIcon = styled.div`
	width: 88px;
	height: 88px;
	background: #f21f4a 0% 0% no-repeat padding-box;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;

	top: -40px;

	img {
		width: 56px;
		height: 56px;
	}
`;
