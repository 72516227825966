import styled from "styled-components";

interface CalendarSettingsProps {
  height?: number;
}

export const CalendarModalSettings = styled.div<CalendarSettingsProps>`
  position: absolute;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: -3px 3px 8px #394f6314;
  border: 0.5px solid #394f6333;
  border-radius: 24px;
  // padding-top: 24px;
  padding-bottom: 24px;
  // transform: translate(-250px, 0);

  .calendario_title {
    text-align: left;
    font: normal normal bold 18px/22px Poppins;
    letter-spacing: 0px;
    color: #394f63;
    margin: 0;
    margin-bottom: 4px;
    padding-left: 24px;
  }

  .calendario_footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    // width: 100%;
    align-items: center;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 8px;

    .calendario_footer_cancelar {
      background: #ffffff 0% 0% no-repeat padding-box;
      border-radius: 6px;
      font: normal normal bold 12px/14px Roboto;
      letter-spacing: 0px;
      color: #394f63;
      padding: 16px;
      border: 0;
      border-radius: 16px;
      cursor: pointer;
      margin-right: 16px;
    }
    .calendario_footer_confirmar {
      background: #18aa36 0% 0% no-repeat padding-box;
      border-radius: 8px;
      font: normal normal bold 12px/14px Roboto;
      letter-spacing: 0px;
      color: #ffffff;
      padding: 16px;
      border: 0;
      border-radius: 16px;
      cursor: pointer;
    }
  }

  .rdrCalendarWrapper {
    //	height: 250px;
    max-width: 628px;
    background-color: transparent;
  }

  .rdrMonthAndYearWrapper {
    padding-left: 24px;
    padding-right: 24px;
  }

  .rdrMonthAndYearPickers {
    text-transform: capitalize;

    select {
      text-align: start;
    }
  }

  .rdrMonthPicker {
    select {
      text-transform: capitalize;
    }
  }

  .rdrMonths {
    padding: 0;
  }
  .rdrMonth {
    padding: 0;
    padding-left: 24px;
    padding-right: 24px;

    //	height: 250px;
  }

  .rdrMonth + .rdrMonth {
    border-left: 1px solid #394f6380;
  }
  .rdrMonthName {
    border: 1px solid #00000000;

    text-align: center;
    text-align: center;
    font: normal normal bold 15px/18px Roboto;
    letter-spacing: 0px;
    color: #4b4b4b;
    text-transform: capitalize;
    height: 23px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .rdrWeekDays {
    height: 32px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border-bottom: 1px solid #394f6333;
  }
  .rdrWeekDay {
    background: #ffffff 0% 0% no-repeat padding-box;
    // border: 1px solid #00000000;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: capitalize;
    font: normal normal 500 12px/14px Roboto;
    letter-spacing: 0px;
    color: #86919b;
  }

  .rdrDay {
    height: 32px;
    // width:32px;
    text-align: center;
    font: normal normal 500 12px/14px Roboto;
    letter-spacing: 0px;
    color: #394f63;
  }

  .rdrDayToday .rdrDayNumber span:after {
    content: "";
    position: absolute;
    bottom: 1px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 18px;
    height: 2px;
    border-radius: 2px;
    background: #18aa36;
  }

  .rdrInRange {
    color: #ecf7ed !important;
  }
  .rdrInRange + .rdrDayNumber {
    // background: #f00;
    color: #222222 !important;

    span {
      color: #222222 !important;
    }
  }

  .rdrInRange + span + .rdrDayNumber {
    // background: #f00;
    color: #222222 !important;

    span {
      color: #222222 !important;
    }
  }
  .rdrStartEdge,
  .rdrEndEdge {
    // border-radius: 1.042em;
  }

  .rdrDayPassive {
    opacity: 0;
  }
`;

export const CalendarSettings = styled.div<CalendarSettingsProps>`
  position: absolute;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: -3px 3px 8px #394f6314;
  border: 0.5px solid #394f6333;
  border-radius: 24px;
  // padding-top: 24px;
  padding-bottom: 24px;
  transform: translate(-250px, 0);

  .calendario_title {
    text-align: left;
    font: normal normal bold 18px/22px Poppins;
    letter-spacing: 0px;
    color: #394f63;
    margin: 0;
    margin-bottom: 4px;
    padding-left: 24px;
  }

  .calendario_footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    // width: 100%;
    align-items: center;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 8px;

    .calendario_footer_cancelar {
      background: #ffffff 0% 0% no-repeat padding-box;
      border-radius: 6px;
      font: normal normal bold 12px/14px Roboto;
      letter-spacing: 0px;
      color: #394f63;
      padding: 16px;
      border: 0;
      border-radius: 16px;
      cursor: pointer;
      margin-right: 16px;
    }
    .calendario_footer_confirmar {
      background: #18aa36 0% 0% no-repeat padding-box;
      border-radius: 8px;
      font: normal normal bold 12px/14px Roboto;
      letter-spacing: 0px;
      color: #ffffff;
      padding: 16px;
      border: 0;
      border-radius: 16px;
      cursor: pointer;
    }
  }

  .rdrCalendarWrapper {
    //	height: 250px;
    max-width: 628px;
    background-color: transparent;
  }

  .rdrMonthAndYearWrapper {
    padding-left: 24px;
    padding-right: 24px;
  }

  .rdrMonthAndYearPickers {
    text-transform: capitalize;

    select {
      text-align: start;
    }
  }

  .rdrMonthPicker {
    select {
      text-transform: capitalize;
    }
  }

  .rdrMonths {
    padding: 0;
  }
  .rdrMonth {
    padding: 0;
    padding-left: 24px;
    padding-right: 24px;

    //	height: 250px;
  }

  .rdrMonth + .rdrMonth {
    border-left: 1px solid #394f6380;
  }
  .rdrMonthName {
    border: 1px solid #00000000;

    text-align: center;
    text-align: center;
    font: normal normal bold 15px/18px Roboto;
    letter-spacing: 0px;
    color: #4b4b4b;
    text-transform: capitalize;
    height: 23px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .rdrWeekDays {
    height: 32px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border-bottom: 1px solid #394f6333;
  }
  .rdrWeekDay {
    background: #ffffff 0% 0% no-repeat padding-box;
    // border: 1px solid #00000000;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: capitalize;
    font: normal normal 500 12px/14px Roboto;
    letter-spacing: 0px;
    color: #86919b;
  }

  .rdrDay {
    height: 32px;
    // width:32px;
    text-align: center;
    font: normal normal 500 12px/14px Roboto;
    letter-spacing: 0px;
    color: #394f63;
  }

  .rdrDayToday .rdrDayNumber span:after {
    content: "";
    position: absolute;
    bottom: 1px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 18px;
    height: 2px;
    border-radius: 2px;
    background: #18aa36;
  }

  .rdrInRange {
    color: #ecf7ed !important;
  }
  .rdrInRange + .rdrDayNumber {
    // background: #f00;
    color: #222222 !important;

    span {
      color: #222222 !important;
    }
  }

  .rdrInRange + span + .rdrDayNumber {
    // background: #f00;
    color: #222222 !important;

    span {
      color: #222222 !important;
    }
  }
  .rdrStartEdge,
  .rdrEndEdge {
    // border-radius: 1.042em;
  }

  .rdrDayPassive {
    opacity: 0;
  }
`;

interface HeaderButtonProps {
  active: boolean;
}

export const HeaderButton = styled.button<HeaderButtonProps>`
  cursor: pointer;
  background: ${(props) => (props.active ? "#18AA36" : "#F2F2F2")};
  //border: ${(props) => (props.active ? "1px solid #09aa36" : 0)};
  border: 0;
  box-shadow: ${(props) => (props.active ? "0px 0px 8px #00000029" : 0)};
  border-radius: 15px;
  padding-right: 16px;
  padding-left: 16px;
  margin-right: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 32px;

  p {
    text-align: center;
    font: normal normal normal 12px/16px Roboto;
    letter-spacing: 0px;
    color: ${(props) => (props.active ? "#FFFFFF" : "#676767")};
    text-transform: capitalize;
    margin: 0;
  }

  .MenuPersonalizadoSvgContainer {
    background: ${(props) =>
      props.active
        ? "#0D5E1E 0% 0% no-repeat padding-box"
        : "transparent 0% 0% no-repeat padding-box"};
    margin-right: 3px;
    width: 44px;
    height: 29px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    margin-top: -6px;
    margin-bottom: -6px;
    margin-left: -16px;
  }

  svg {
    color: ${(props) => (props.active ? "#FFFFFF" : "#676767")};
    width: 13px;
    height: 13px;
  }
  :hover{
  background: ${(props) => (props.active ? "#18AA36" : "#D9F2E5")};

  }
`;

export const DropdownContainer = styled.div`
  .dropdown-menu {
    border: 0;
    background: transparent;
  }
`;

export const ModalCalendar = styled.div`
  min-height: 100vh;
  min-width: 100vw;
  background-color: transparent;
  //max-width: 60vw;
  // width: fit-content;
  //width:calc(fit-content - 50px);
  height: 100%;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;
