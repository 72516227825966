import styled from 'styled-components';
//import { colors } from '../../globalStyles/colors';

export const Container = styled.div`
	background: #f5f5f5;
	flex: 1;
	height: 100%;
	display: flex;
	flex-direction: row;
	min-height: 100vh;
	padding: 30px;
	padding-left: 0;

	.flex-row-center {
		display: flex;
		align-items: center;
		flex-direction: row;
	}
`;

export const Sidebar = styled.div`
	background: #ffffff 0% 0% no-repeat padding-box;
	box-shadow: 0px 16px 16px #00000014;
	border-radius: 24px;

	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
	/*padding-right: 16px;
	padding-left: 52px;
*/
	margin-left: -20px;
	//padding-top: 24px;
	width: 340px;

	height: calc(100vh - 60px);
	overflow-y: scroll;
	::-webkit-scrollbar {
		width: 0px;
	}
`;

export const SidebarHeader = styled.div`
	position: sticky;
	top: 0;

	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	background: #ffffff;
	padding-top: 24px;

	margin-left: 52px;
	margin-right: 0px;
	padding-bottom: 24px;
	//width:100%;
	padding-right: 16px;

	//z-index: 1000;
`;

export const SidebarTitle = styled.p`
	text-align: left;
	text-align: left;
	font: normal normal 500 24px/32px Roboto;
	letter-spacing: 0px;
	color: #222222;
	opacity: 1;
	margin-right: 20px;
	margin: 0;
`;

export const SidebarButton = styled.button`
	cursor: pointer;
	background: #ffffff 0% 0% no-repeat padding-box;
	border: 1px solid #09aa36;
	border-radius: 16px;
	opacity: 1;
	//width: 132px;
	//height: 29px;
	padding: 6px;
	padding-left: 12px;
	padding-right: 12px;

	vertical-align: middle;
	text-align: center;
	font: normal normal 500 12px/16px Roboto;
	letter-spacing: 0px;
	color: #09aa36;
	text-transform: uppercase;
	opacity: 1;
`;

interface SidebarItemProps {
	selected: boolean;
	active?: boolean;
}

export const SidebarItem = styled.button<SidebarItemProps>`
	//background-color: ;
	margin-left: 20px;

	background: #ffffff 0% 0% no-repeat padding-box;

	cursor: pointer;

	border: 0;
	border-top: 1px solid #f0f0f0;
	border-bottom: 1px solid #f0f0f0;

	//border: ${(props) => (props.selected ? '1px solid #09aa36' : 0)};
	//border-radius: 10px;
	width: calc(100% - 20px);

	/**
		background: #F5F5F5 0% 0% no-repeat padding-box;
border-radius: 10px;*/

	display: flex;
	flex-direction: row;

	justify-content: space-between;
	align-items: center;

	//width: 256px;
	height: 68px;
	//margin-bottom: 16px;
	padding-left: 32px;

	svg {
		width: 20px;
		height: 32px;
		color: #09aa36;
		align-self: center;
	}

	span {
		background: ${(props) => (props.active ? '#93cc2f' : '#CC2F2F')} 0% 0%
			no-repeat padding-box;
		border-radius: 10px;

		padding-top: 3px;
		padding-bottom: 3px;
		padding-right: 8px;
		padding-left: 8px;

		font: normal normal 500 9px/11px Roboto;
		letter-spacing: 0px;
		color: #ffffff;
		text-transform: uppercase;
		text-align: center;
		vertical-align: middle;
	}
	.sideItem_icon_container {
		background: transparent 0% 0% no-repeat padding-box;
		border: 1px solid transparent;
		border-radius: 18px;
		width: 36px;
		height: 36px;
		display: flex;
		align-items: center;
		justify-content: center;
		opacity: 0;

		:hover {
			background: #ffffff 0% 0% no-repeat padding-box;
			border: 1px solid #f0f0f0;

			.sideItem_editIcon {
				transform: rotate(-45deg);
				-moz-transform: rotate(-45deg);
				/* Safari and Chrome */
				-webkit-transform: rotate(-45deg);
				/* Opera */
				-o-transform: rotate(-45deg);
				/* IE9 */
				-ms-transform: rotate(-45deg);
				transition: all 0.3s;
			}
		}
	}

	:hover {
		background: #f6f9f8 0% 0% no-repeat padding-box;
		.sideItem_icon_container {
			opacity: 1;
		}
	}
`;

export const SidebarIconContainer = styled.div<SidebarItemProps>`
	background: ${(props) => (props.selected ? '#F5F5F5' : '#FFFFFF')} 0% 0%
		no-repeat padding-box;
	border-radius: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-left: 10px;
	margin-right: 10px;
	margin-top: 16px;
	margin-bottom: 16px;
	width: 48px;
	height: 48px;

	svg {
		width: 24px;
		height: 28px;
		color: #09aa36;
	}
`;

export const SidebarItemInfo = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: flex-start;
	margin-top: 16px;
	margin-bottom: 16px;
`;

export const SidebarItemTitle = styled.p`
	text-align: left;
	font: normal normal 500 16px/18px Roboto;
	letter-spacing: 0px;
	color: #222222;
	margin: 0;
	//text-transform: capitalize;
`;

export const SidebarItemSubTitle = styled.p`
	text-align: left;
	font: normal normal normal 13px/18px Roboto;
	letter-spacing: 0px;
	color: #676767;
	margin: 0;
`;

export const SidebarElip = styled.div`
	//position: fixed;
	//top: 4px;
	//right: 18px;
	//background:#000;
	padding-top: 10px;
	padding-right: 10px;

	svg {
		width: 16px;
		height: 36px;
		color: #09aa36;
	}
`;

export const Main = styled.div`
	flex: 1;
	padding-left: 16px;
`;

export const Header = styled.div`
	display: flex;
	flex-direction: row;
	//flex: 1;
	align-items: center;
	padding-left: 20px;
	//padding-right: 16px;

	justify-content: space-between;

	background: #ffffff 0% 0% no-repeat padding-box;
	box-shadow: 0px 16px 16px #00000014;
	border-radius: 24px;
	height: 80px;

	//background:#000
	//max-width:100vw;
`;
export const HeaderTitle = styled.p`
	text-align: left;
	font: normal normal 500 24px/32px Roboto;
	letter-spacing: 0px;
	color: #222222;
	opacity: 1;
	margin-right: 32px;
`;

interface HeaderButtonProps {
	active?: boolean;
}

export const HeaderButton = styled.button<HeaderButtonProps>`
	cursor: pointer;
	background: ${(props) => (props.active ? '#18AA36' : '#F2F2F2')};
	//border: ${(props) => (props.active ? '1px solid #09aa36' : 0)};
	border: 0;
	box-shadow: ${(props) => (props.active ? '0px 0px 8px #00000029' : 0)};
	border-radius: 15px;
	padding-top: 6px;
	padding-bottom: 6px;
	padding-right: 16px;
	padding-left: 16px;
	margin-right: 8px;
	display: flex;
	flex-direction: row;
	align-items: center;

	p {
		text-align: center;
		font: normal normal normal 12px/16px Roboto;
		letter-spacing: 0px;
		color: ${(props) => (props.active ? '#FFFFFF' : '#676767')};
		text-transform: uppercase;
		margin: 0;
	}

	.MenuPersonalizadoSvgContainer {
		background: ${(props) =>
			props.active
				? '#0D5E1E 0% 0% no-repeat padding-box'
				: 'transparent 0% 0% no-repeat padding-box'};
		margin-right: 3px;
		width: 44px;
		height: 29px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 15px;
		margin-top: -6px;
		margin-bottom: -6px;
		margin-left: -16px;
	}

	svg {
		color: ${(props) => (props.active ? '#FFFFFF' : '#676767')};
		width: 13px;
		height: 13px;
	}
`;

interface HeaderSearchContainerProps {
	active: boolean;
}

export const HeaderSearchContainer = styled.form<HeaderSearchContainerProps>`
	height: 40px;
	display: flex;
	flex-direction: row;

	background: #f0f0f0 0% 0% no-repeat padding-box;
	border-radius: 20px;
	border: 0;
	opacity: 1;
	align-items: center;
	min-width: 230px;

	margin-right: 24px;

	input {
		//display: ${(props) => !props.active && 'none'};
		transition: all 1s;
		width: ${(props) => (!props.active ? 0 : '230px')};
		// margin-left: ${(props) => (!props.active ? 0 : '16px')};
		padding: ${(props) => (!props.active ? 0 : '11px')};
	}

	button {
		cursor: pointer;
		padding: 11px;
		background: transparent;
		border: 0;
		display: flex;
		align-items: center;
	}

	svg {
		color: #09aa36;
		width: 16px;
		height: 16px;
	}
`;

export const HeaderSearchInput = styled.input`
	text-align: left;
	font: normal normal normal 14px/19px Roboto;
	letter-spacing: 0px;
	color: #9f9f9f;
	opacity: 1;
	flex: 1;
	border: 0;
	background-color: transparent;
	//padding:11px;
`;

export const Body = styled.div`
	//padding: 25px;
	padding-bottom: 0;
	margin-top: 17px;
	flex: 1;
	//background:#000;
	background: #ffffff 0% 0% no-repeat padding-box;
	box-shadow: 0px 16px 16px #00000014;
	border-radius: 24px;
	//max-height: calc(100% - 30px - 80px - 17px - 80px - 60px - 500px);
	//height: calc(100% - 97px);

	height: calc(100vh - 97px - 30px - 30px);
	// overflow-y: scroll;

	.body_header {
		padding: 20px;
		display: flex;
		align-items: center;
		height: 80px;
		//justify-content: space-between;
	}
`;

interface SearchSelectorProps {
	active?: boolean;
	pressed?: boolean;
}
export const SearchSelector = styled.button<SearchSelectorProps>`
	height: 32px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding-left: 16px;
	padding-right: 16px;
	margin-right: 10px;

	background: ${(props) => (props.active ? '#18AA36' : '#F0F0F0')} 0% 0%
		no-repeat padding-box;
	border-radius: 20px;

	text-align: center;
	font: normal normal 500 12px/16px Roboto;
	letter-spacing: 0px;
	color: ${(props) => (props.active ? '#FFFFFF' : '#676767')};
	text-transform: uppercase;
	cursor: pointer;
	border: ${(props) =>
		props.pressed ? '1px solid #EBEBEB' : '1px solid transparent'};

	box-shadow: ${(props) => (props.pressed ? 'inset 0px 3px 6px #00000029' : 0)};
`;

export const TableContainer = styled.div`
	//margin-top: 20px;
	//max-height: 505px;
	//max-height: calc(100% - 30px - 80px - 17px - 80px - 60px - 200000px);
	// max-height: calc(100% - 97px - 30px - 80px - 17px - 80px - 60px - 100px - 100px);

	height: calc(100vh - 97px - 30px - 30px - 80px - 50px);
	// display: none;

	overflow-y: scroll;
	overflow-x: hidden;

	::-webkit-scrollbar {
		width: 0px;
	}
`;

export const Gradient = styled.div`
	position: sticky;
	bottom: 0;
	background: transparent linear-gradient(180deg, #f5f5f500 0%, #f5f5f5 100%) 0%
		0% no-repeat padding-box;
	width: 100%;
	height: 20px;
	margin-top: -20px;

	:hover {
		//	display:none
	}
`;

export const Table = styled.table`
	border: 0;
	width: 100%;
	border-spacing: 0px 0px;
`;

export const THead = styled.thead`
	//background: #09aa36 0% 0% no-repeat padding-box;
	//border-radius: 100px;
	border: 0;
	width: 100%;

	tr {
		//border-radius: 100px;
		height: 40px;
		//background-color:#000
	}

	th {
		//border: solid 1px #000;
		//border-style: none none;
		padding: 10px;
		//background-color: cyan;
		position: -webkit-sticky; /* Safari */
		position: sticky;
		top: 0;
		background: #f4f4f4 0% 0% no-repeat padding-box;
		//z-index: 1000;

		:first-child {
			border-left-style: solid;
			//border-top-left-radius: 10px;
			//border-bottom-left-radius: 10px;
			padding-left: 24px;
		}
		:last-child {
			border-right-style: solid;
			//border-bottom-right-radius: 10px;
			//border-top-right-radius: 10px;
		}
	}
`;

export const Th = styled.th`
	text-align: left;
	font: normal normal 500 12px/16px Roboto;
	letter-spacing: 0px;
	color: #676767;
	text-transform: uppercase;
	border: 0;
	background-color: transparent;
	//border-radius:10px;
`;

export const TBody = styled.tbody``;

export const BodyTR = styled.tr`
	//padding-top: 22px;
	//padding-bottom: 22px;
	padding-right: 20px;
	padding-left: 20px;
	background-color: #ffffff;

	.link_click{
		cursor:pointer;
		text-align: right;
text-decoration: underline;
font: normal normal normal 14px/19px Roboto;
letter-spacing: 0px;
color: #18AA36;
	}

	td {
		//border: solid 1px #000;
		//border-style: none none;
		padding-left: 10px;
		padding-right: 10px;
		//background-color: cyan;
		text-align: left;
		font: normal normal 500 14px/19px Roboto;
		letter-spacing: 0px;
		color: #676767;
		height: 52px;
		border-bottom: 1px #f0f0f0 solid;

		:first-child {
			//border-left-style: solid;
			//border-top-left-radius: 10px;
			//border-bottom-left-radius: 10px;
			padding-left: 24px;
		}
		:last-child {
			//border-right-style: solid;
			//border-bottom-right-radius: 10px;
			//border-top-right-radius: 10px;
		}
	}

	.tableItem_icon_container {
		background: transparent 0% 0% no-repeat padding-box;
		border: 1px solid transparent;
		border-radius: 18px;

		width: 36px;
		height: 36px;
		display: flex;
		align-items: center;
		justify-content: center;

		:hover {
			background: #ffffff 0% 0% no-repeat padding-box;
			border: 1px solid #f0f0f0;

			.tableItem_icon_edit {
				transform: rotate(-45deg);
				-moz-transform: rotate(-45deg);
				/* Safari and Chrome */
				-webkit-transform: rotate(-45deg);
				/* Opera */
				-o-transform: rotate(-45deg);
				/* IE9 */
				-ms-transform: rotate(-45deg);
				transition: all 0.3s;
			}
		}

		.tableItem_icon {
			width: 15px;
			height: 15px;
		}
	}
`;

export const ActionButton = styled.button`
	background: #ffffff 0% 0% no-repeat padding-box;
	border: 1px solid #09aa36;
	border-radius: 10px;
	opacity: 1;
	padding-top: 6px;
	padding-bottom: 6px;
	padding-right: 12px;
	padding-left: 12px;

	text-align: center;
	font: normal normal normal 12px/16px Roboto;
	letter-spacing: 0px;
	color: #09aa36;
	opacity: 1;

	margin-right: 5px;

	svg {
		width: 16px;
		height: 16px;
		color: #09aa36;
	}
`;

export const TableFooter = styled.div`
	position: sticky;
	position: -webkit-sticky;
	background: #f9f9f9 0% 0% no-repeat padding-box;
	border-radius: 18px;
	height: 36px;
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	//z-index: 20000;
	padding-left: 16px;
	padding-right: 11px;
	p {
		margin: 0;
	}

	.table_footer_label {
		text-align: left;
		font: normal normal normal 12px/16px Roboto;
		letter-spacing: 0px;
		color: #222222;
	}
	.table_footer_label_strong {
		font: normal normal bold 12px/16px Roboto;
	}

	.table_footer_input {
		background: #ffffff 0% 0% no-repeat padding-box;
		border: 1px solid #d3d3d3;
		border-radius: 12px;
		height: 24px;
		width: 48px;
		text-align: center;
		font: normal normal bold 14px/19px Roboto;
		letter-spacing: 0px;
		color: #222222;
		margin-right: 9px;
		margin-left: 9px;
	}
	.table_footer_border_right {
		border-right: 1px solid #d3d3d3;
	}

	.table_footer_padding_right-20 {
		padding-right: 20px;
	}
	.table_footer_margin_left-20 {
		margin-left: 20px;
	}

	.table_footer_border_right-12 {
		border-top-right-radius: 12px;
		border-bottom-right-radius: 12px;
	}

	.table_footer_border_left-12 {
		border-top-left-radius: 12px;
		border-bottom-left-radius: 12px;
	}

	.table_footer_button_container {
		background: #18aa36 0% 0% no-repeat padding-box;
		height: 24px;
		width: 70px;
		display: flex;
		flex-direction: row;

		img {
			width: 16px;
			height: 16px;
		}
	}

	.table_footer_button {
		flex: 1;
		:hover {
			background: #12842a 0% 0% no-repeat padding-box;
		}
		color: #ffffff;
		border: 0;
		background-color: transparent;
		display: flex;
		align-items: center;
		justify-content: center;
	}
`;

interface Active {
	active: boolean;
}

export const Square = styled.div<Active>`
	background: ${(props) => (props.active ? '#09AA36' : '#FFFFFF')} 0% 0%
		no-repeat padding-box;
	border: 1px solid #09aa36;
	border-radius: 3px;
	opacity: 1;
	width: 10px;
	height: 10px;

	transition: all 1s;
`;

export const Skeleton = styled.div`
	background-image: linear-gradient(
		-90deg,
		#fafafc 0%,
		#e4e4e4 50%,
		#fafafc 100%
	);
	background-size: 400% 400%;
	animation: shimmer 1.2s ease-in-out infinite;
	@keyframes shimmer {
		0% {
			background-position: 0% 0%;
		}
		100% {
			background-position: -135% 0%;
		}
	}
`;

export const SkeletonGreen = styled.div`
	background-image: linear-gradient(
		-90deg,
		var(--primaryColorDark) 0%,
		#abdb30 50%,
		var(--primaryColorDark) 100%
	);
	background-size: 400% 400%;
	animation: shimmer 1.2s ease-in-out infinite;
	@keyframes shimmer {
		0% {
			background-position: 0% 0%;
		}
		100% {
			background-position: -135% 0%;
		}
	}
`;
