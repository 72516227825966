/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';

import styled from 'styled-components';
import InputMask from 'react-input-mask';

import { UilCamera } from '@iconscout/react-unicons';
import { EmpresaFinancialSetting } from '../../models/model';
import { colors } from '../../globalStyles/colors';

const Modal = styled.div`
	//padding-top: 25px;
	//padding-left: 50px;
	//padding-right: 20000px;
	//padding-bottom: 25px;
	min-height: 100vh;
	background-color: #f5f5f5;
	//max-width: 60vw;
	width: fit-content;
	//width:calc(fit-content - 50px);
	height: 100%;
	flex: 1;
	display: flex;
	//margin-left:30vw;
`;

const ModalBody = styled.div`
	//margin:50px;
	background-color: #f5f5f5;
	padding-top: 20px;
	margin-left: 50px;
	//margin-right: 50px;
	padding-bottom: 20px;
	display: flex;
	min-height: calc(100% - 40px);
`;

const ModalTitle = styled.div`
	text-align: left;
	font: normal normal bold 20px/26px Roboto;
	letter-spacing: 0px;
	color: ${colors.default};
	opacity: 1;
	margin-bottom: 31px;
`;

const Text = styled.p`
	text-align: left;
	font: normal normal normal 12px/16px Roboto;
	letter-spacing: 0px;
	color: #676767;
`;

const ImageButton = styled.div`
	border: 1px dashed var(--disabled);
	background: #f5f5f5 0% 0% no-repeat padding-box;
	border: 1px dashed #9f9f9f;
	border-radius: 10px;

	display: flex;
	flex-direction: column;
	align-items: center;

	justify-content: space-between;

	padding-top: 10px;
	padding-bottom: 10px;

	width: 114px;
	height: 94px;
	svg {
		width: 32px;
		height: 27px;
		color: #9f9f9f;
	}
`;

const Button = styled.button`
	background: #09aa36 0% 0% no-repeat padding-box;
	border-radius: 10px;
	border: 0;

	padding-top: 6px;
	padding-bottom: 6px;
	padding-right: 12px;
	padding-left: 12px;

	text-align: center;
	font: normal normal 500 11px/15px Roboto;
	letter-spacing: 0px;
	color: #ffffff;
`;

const Input = styled.input`
	text-align: left;
	font: normal normal 500 16px/21px Roboto;
	height: 22px;
	letter-spacing: 0px;
	color: #09aa36;

	max-width: 85%;
	width: 200px;

	background: transparent;
	border: 0;
	border-bottom: 1px solid #09aa36;
	padding-left: 5px;
	padding-right: 5px;

	margin: 0;
`;

const InputCredenciais = styled.input`
	text-align: left;
	font: normal normal 500 16px/21px Roboto;
	height: 22px;
	letter-spacing: 0px;
	color: #09aa36;

	max-width: 80%;
	//width: 200px;

	background: transparent;
	border: 0;
	border-bottom: 1px solid #09aa36;
	padding-left: 5px;
	padding-right: 5px;

	margin: 0;
`;

const InputChave = styled.input`
	text-align: left;
	font: normal normal 500 16px/21px Roboto;
	height: 22px;
	letter-spacing: 0px;
	color: #09aa36;

	max-width: calc(80%);
	//width: 200px;

	background: transparent;
	border: 0;
	border-bottom: 1px solid #09aa36;
	padding-left: 5px;
	padding-right: 5px;

	margin: 0;
`;

const Select = styled.select`
	text-align: left;
	font: normal normal 500 16px/21px Roboto;
	letter-spacing: 0px;
	color: #09aa36;

	margin: 0;
	max-width: 85%;
	width: 100%;
	height: 25px;

	background: transparent;
	border: 0;
	border-bottom: 1px solid #09aa36;
	padding-left: 5px;
	padding-right: 5px;
`;

const GridDisplay = styled.div`
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-gap: 10px;
`;

const ButtonContainer = styled.div`
	padding-top: 20px;
	margin-top: 20px;
	border-top: 1px solid #09aa36;

	display: flex;
	flex-direction: row;
`;

const ButtonCancel = styled.button`
	background: #ffffff 0% 0% no-repeat padding-box;
	border-radius: 10px;
	border: 1px solid #09aa36;

	padding-top: 6px;
	padding-bottom: 6px;
	padding-right: 12px;
	padding-left: 12px;

	text-align: center;
	font: normal normal 500 11px/15px Roboto;
	letter-spacing: 0px;
	color: #09aa36;

	margin-right: 16px;
`;

const Subtitle = styled.p`
	text-align: left;
	font: normal normal bold 16px/21px Roboto;
	letter-spacing: 0px;
	color: ${colors.default};
	opacity: 1;
	margin-bottom: 16px;
	margin-top: 30px;
`;

interface gridValues {
	value: number;
}

const GridCredenciais = styled.div<gridValues>`
	display: grid;
	grid-template-columns: ${(props) => `repeat(${props.value},1fr)`};
	grid-column-gap: 5px;
	grid-row-gap: 10px;
`;

interface ModalProps {
	setModalVisible(b: boolean): void;
	informacaoFinanceira: EmpresaFinancialSetting;
	name: string;
}

export default function ModalEmpresa({
	setModalVisible,
	//categorias,
	informacaoFinanceira,
	name,
}: ModalProps) {
	const [companyName, setCompanyName] = useState('');
	const [segment, setSegment] = useState('');
	const [agreementCode, setAgreementCode] = useState('');
	const [bankCode, setBankCode] = useState('');
	const [bankName, setBankName] = useState('');
	const [accountBankNumber, setAccountBankNumber] = useState('');
	const [collectionMethod, setCollectionMethod] = useState('');
	const [serviceIdentification, setServiceIdentification] = useState('');
	const [id, setId] = useState('');
	const [installmentRate, setInstallmentRate] = useState('');
	const [layoutVersion, setLayoutVersion] = useState('');
	const [maxInstallment, setMaxInstallment] = useState('');
	const [maxInstallmentFree, setMaxInstallmentFree] = useState('');

	useEffect(() => {
		if (informacaoFinanceira) {
			setCompanyName(informacaoFinanceira.companyName);
			setSegment(informacaoFinanceira.segment);
			setAgreementCode(informacaoFinanceira.agreementCode);
			setBankCode(informacaoFinanceira.bankCode);
			setBankName(informacaoFinanceira.bankName);
			setAccountBankNumber(informacaoFinanceira.accountBankNumber);
			setCollectionMethod(informacaoFinanceira.collectionMethod);
			setServiceIdentification(informacaoFinanceira.serviceIdentification);
			setId(informacaoFinanceira.id.toString());
			setInstallmentRate(informacaoFinanceira.installmentRate);
			setLayoutVersion(informacaoFinanceira.layoutVersion);
			setMaxInstallment(informacaoFinanceira.maxInstallment.toString());
			setMaxInstallmentFree(informacaoFinanceira.maxInstallmentFree.toString());
		}
	}, [informacaoFinanceira]);

	const segmentos = [
		{ value: '0', name: 'selecione' },
		{ value: '1', name: '1. Prefeitura' },
		{ value: '2', name: '2. Saneamento' },
		{ value: '3', name: '3. Enegia Elétrica e Gás' },
		{ value: '4', name: '4. Telecomunicações' },
		{ value: '5', name: '5. Órgãos Governamentais' },
		{ value: '6', name: '6. Carnes ou demais Empresas' },
		{ value: '7', name: '7. Multas de trânsito' },
		//{ value: '8', name: '8. Saneamento' },
		{ value: '9', name: '9. Uso exclusivo do banco' },
	];

	return (
		<Modal>
			<ModalBody>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'space-between',
						//height: '100%',
						flex: 1,

						minHeight: '100%',
						//background:"#000",
						// height:'100%',
					}}>
					<div>
						<ModalTitle>{`Configuração Financeira · ${name}`}</ModalTitle>

						<div>
							<GridCredenciais value={8}>
								<div style={{ gridColumnStart: 1, gridColumnEnd: 3 }}>
									<Text>Nome da Empresa</Text>
									<InputCredenciais
										value={companyName}
										onChange={(e) => {
											setCompanyName(e.target.value);
										}}
									/>
								</div>
								<div style={{ gridColumnStart: 3, gridColumnEnd: 6 }}>
									<Text>Segmento</Text>
									<Select
										value={segment}
										onChange={(e) => {
											setSegment(e.target.value);
										}}>
										{segmentos.map((e) => {
											return <option value={e.value}>{e.name}</option>;
										})}
									</Select>
								</div>
								<div style={{ gridColumnStart: 6, gridColumnEnd: 8 }}>
									<Text>Código do convênio</Text>
									<InputCredenciais
										value={agreementCode}
										onChange={(e) => {
											setAgreementCode(e.target.value);
										}}
									/>
								</div>
							</GridCredenciais>
						</div>
						<GridCredenciais value={4}>
							<div>
								<Text>Código do Banco</Text>
								<InputCredenciais
									value={bankCode}
									onChange={(e) => {
										setBankCode(e.target.value);
									}}
								/>
							</div>
							<div>
								<Text>Nome do Banco</Text>
								<InputCredenciais
									value={bankName}
									onChange={(e) => {
										setBankName(e.target.value);
									}}
								/>
							</div>
							<div>
								<Text>Agência Bancária</Text>
								<InputCredenciais
									value={accountBankNumber}
									onChange={(e) => {
										setAccountBankNumber(e.target.value);
									}}
								/>
							</div>
							<div>
								<Text>Forma de arrecadação</Text>
								<InputChave
									value={collectionMethod}
									onChange={(e) => {
										setCollectionMethod(e.target.value);
									}}
								/>
							</div>
						</GridCredenciais>

						<GridCredenciais value={4}>
							<div>
								<Text>Identificação do Serviço</Text>
								<InputCredenciais
									value={serviceIdentification}
									onChange={(e) => {
										setServiceIdentification(e.target.value);
									}}
								/>
							</div>
							<div>
								<Text>ID. da informacaoFinanceira</Text>
								<InputCredenciais
									type={'number'}
									value={id}
									onChange={(e) => {
										setId(e.target.value);
									}}
								/>
							</div>
							<div>
								<Text>Juros de Parcelamento</Text>
								<InputCredenciais
									value={installmentRate}
									onChange={(e) => {
										setInstallmentRate(e.target.value);
									}}
								/>
							</div>
							<div>
								<Text>Versão do Layout</Text>
								<InputCredenciais
									value={layoutVersion}
									onChange={(e) => {
										setLayoutVersion(e.target.value);
									}}
								/>
							</div>
							<div>
								<Text>Máx. de parcelas</Text>
								<InputCredenciais
									type={'number'}
									value={maxInstallment}
									onChange={(e) => {
										setMaxInstallment(e.target.value);
									}}
								/>
							</div>
							<div>
								<Text>Máx. de parcelas sem juros</Text>
								<InputCredenciais
									type={'number'}
									value={maxInstallmentFree}
									onChange={(e) => {
										setMaxInstallmentFree(e.target.value);
									}}
								/>
							</div>
						</GridCredenciais>
					</div>
					<ButtonContainer>
						<ButtonCancel
							onClick={(e) => {
								e.preventDefault();
								setModalVisible(false);
							}}>
							Cancelar
						</ButtonCancel>
						<Button>Salvar</Button>
					</ButtonContainer>
				</div>
			</ModalBody>
		</Modal>
	);
}
