/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';

import styled from 'styled-components';
import { colors } from '../../globalStyles/colors';
import { Empresa, PaymentMethod } from '../../models/model';
import ClearIcon from '../../assets/images/clearIcon.svg';
import api from '../../services/api';
import Loading from '../../assets/images/loading-roll.svg';
import * as yup from 'yup';
import { InputContainer, SelectContainer } from '../../components/Input';
import { useHistory } from 'react-router';

const Modal = styled.div`
	//padding-top: 25px;
	//padding-left: 50px;
	//padding-right: 20000px;
	//padding-bottom: 25px;
	min-height: 100vh;
	background-color: #f5f5f5;
	width: 100vw;
	//margin-left: 50vw;
	height: 100%;
	flex: 1;
	.mb-8 {
		margin-bottom: 8px;
	}

	.mb-32 {
		margin-bottom: 32px;
	}
	.mb-40 {
		margin-bottom: 40px;
	}
`;

const ModalHeader = styled.div`
	background: #f6f6f6 0% 0% no-repeat padding-box;
	box-shadow: 0px 16px 16px #00000029;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	padding-top: 21px;
	padding-bottom: 21px;

	padding-left: 30px;
	padding-right: 30px;
	height: 80px;
`;

const HeaderTitle = styled.h1`
	font: normal normal 500 24px/32px Roboto;
	letter-spacing: 0px;
	color: #222222;
	display: flex;
	align-items: center;
`;

const HeaderButton = styled.button`
	border-radius: 19px;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 38px;
	padding-left: 16px;
	padding-right: 16px;

	text-align: center;
	font: normal normal 500 16px/21px Roboto;
	letter-spacing: 0px;
	border: 0;
	.loading-roll {
		width: 21px;
		height: 21px;
	}
`;

interface ModalContainerProps {
	showScroll?: boolean;
}

const ModalContainer = styled.div<ModalContainerProps>`
	background: #ffffff 0% 0% no-repeat padding-box;
	box-shadow: -16px 0px 16px #00000029;
	opacity: 1;
	display: flex;
	flex-direction: column;
	//flex: 1;
	height: calc(100vh - 80px);
	overflow-y: scroll;

	::-webkit-scrollbar {
		background: transparent;

		// display:none;

		width: 0;
	}

	&::-webkit-scrollbar-track {
		/*  margin-top:0;
      border-bottom-right-radius: 16px;
      background-image: url("https://i.stack.imgur.com/Pwbuz.png");
    background-repeat: repeat-y;
    background-size: contain;
      */
		// margin-top: 54px;
	}
	&::-webkit-scrollbar-thumb {
		background: ${(props) =>
			props.showScroll ? 'rgba(103, 103, 103, 0.5)' : 'transparent'};
		border-radius: 20px;
		border: 2.5px solid rgba(0, 0, 0, 0);
		border-right: 5px solid rgba(0, 0, 0, 0);

		background-clip: padding-box;
		max-height: 5px;
		transition: all 1s;
	}
	padding: 30px;
	padding-top: 0;
	.modal_item_container {
		max-width: 100%;
		width: 100%;
		// margin-bottom: 24px;
	}

	.inputContainer {
		background: #ffffff 0% 0% no-repeat padding-box;
		border: 1px solid #9f9f9f;
		border-radius: 16px;
		height: 50px;
		display: flex;
		flex-direction: row;
		padding: 15px;
		input,
		select {
			::placeholder {
				font: normal normal 300 14px/19px Roboto;
				letter-spacing: 0px;
				color: #9f9f9f;
			}
			text-align: left;
			font: normal normal 500 14px/19px Roboto;
			letter-spacing: 0px;
			color: #222222;
			flex: 1;
			border: 0;
			margin-top: -15px;
			margin-bottom: -15px;
		}
	}
`;

const ModalLabel = styled.p`
	margin: 0;
	text-align: left;
	font: normal normal 500 11px/15px Roboto;
	letter-spacing: 0px;
	color: #4b4b4b;
	text-transform: uppercase;
`;

interface ModalInputContainerProps {
	shadowColor?: string;
	borderColor?: string;
}
const ModalInputContainer = styled.div<ModalInputContainerProps>`
	background: #ffffff 0% 0% no-repeat padding-box;
	border: 1px solid
		${(props) => (props.borderColor ? props.borderColor : '#9f9f9f')};
	border-radius: 16px;
	display: flex;
	flex-direction: row;
	height: 50px;
	box-shadow: 0px 0px 10px 1px
		${(props) => (props.shadowColor ? props.shadowColor : 'transparent')};
	-webkit-box-shadow: 0px 0px 10px 1px
		${(props) => (props.shadowColor ? props.shadowColor : 'transparent')};
	-moz-box-shadow: 0px 0px 10px 1px
		${(props) => (props.shadowColor ? props.shadowColor : 'transparent')};

	width: 100%;
	.modal_input_icon_container {
		height: 100%;
		padding-right: 16px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.modal_input_icon {
		width: 16px;
		height: 16px;
		border-radius: 8px;
		align-self: center;
		cursor: pointer;
	}

	.modal_input {
		border: transparent;
		border-radius: 16px;
		flex: 1;
		overflow: hidden;
		padding-left: 16px;
		text-align: left;
		font: normal normal 500 14px/19px Roboto;
		letter-spacing: 0px;
		color: #222222;

		::-webkit-input-placeholder {
			/* Edge */
			text-align: left;
			font: normal normal 300 14px/19px Roboto;
			letter-spacing: 0px;
			color: #9f9f9f;
		}

		:-ms-input-placeholder {
			/* Internet Explorer 10-11 */
			text-align: left;
			font: normal normal 300 14px/19px Roboto;
			letter-spacing: 0px;
			color: #9f9f9f;
		}

		::placeholder {
			text-align: left;
			font: normal normal 300 14px/19px Roboto;
			letter-spacing: 0px;
			color: #9f9f9f;
		}
	}

	select {
		border: 0;
	}
`;

interface ModalProps {
	setModalVisible(b: boolean): void;
	setPreModalVisible(b: boolean): void;
	empresa: Empresa | undefined;
	metodo?: PaymentMethod;
	UpdateMetodo(m: PaymentMethod): void;
}

export default function ModalNovoMetodo({
	setModalVisible,
	setPreModalVisible,
	//categorias,
	empresa,
	metodo,
	UpdateMetodo,
}: ModalProps) {
	const [isError, setIsError] = useState<any>({});
	const [nome, setNome] = useState('');
	const [taxa, setTaxa] = useState('');
	const [tipoTaxa, setTipoTaxa] = useState('');
	const [identificador, setIdentificador] = useState('');
	const [dataCredito, setDataCredito] = useState('');
	const [tag, setTag] = useState('');
	const history = useHistory();

	useEffect(() => {
		if (metodo) {
			setNome(metodo.name);
			setTaxa(metodo.rate);
			setTipoTaxa(metodo.rateType);
			setIdentificador(metodo.code.toString());
			setDataCredito(metodo.creditDate.toString());
			setTag(metodo.tag);
			/*setNome(metodo.nome);
			setTaxa(metodo.taxa);
			setTipoTaxa(metodo.tipoTaxa);
			setIdentificador(metodo.identificador);
			setDataCredito(metodo.dataCredito);
			setTag(metodo.tag);*/
		}
	}, [metodo]);

	const tiposTaxas = [
		{ value: '', label: '' },
		{ value: 'ABSOLUT', label: 'Absoluto' },
		{ value: 'PERCENTAGE', label: 'Percentual' },
	];

	const [loading, setLoading] = useState(false);

	async function EditMethod() {
		setLoading(true);
		const token = sessionStorage.getItem('@bill-token');
		setIsError({});

		const config = {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		};

		try {
			const data = {
				merchant: empresa?.id,
				name: nome,
				rate: parseFloat(taxa),
				tag,
				code: identificador,
				creditDate: parseInt(dataCredito),
				rateType: tipoTaxa,
			};

			const schema = yup.object().shape({
				merchant: yup.number().required(),
				name: yup.string().required(),
				rate: yup.number().required(),
				tag: yup.string().required(),
				code: yup.string().required(),
				creditDate: yup.number().required(),
				rateType: yup.string().required(),
			});

			await schema.validate(data, { abortEarly: false });

			const response = await api.post(
				`/admin/api/payment-method${metodo ? `/${metodo.id}` : ''}`,
				data,
				config
			);
			if (response.data.payment_method) {
				UpdateMetodo(response.data.payment_method);
				setModalVisible(false);
				setPreModalVisible(false);
			}
			setLoading(false);
		} catch (error:any) {
			console.log(error);
			const validationError = {} as any;
			if (error instanceof yup.ValidationError) {
				error.inner.forEach((err) => {
					//console.log(err.path);
					if (err.path) validationError[err.path] = true;
				});
			}
			console.log(validationError);
			setIsError(validationError);
			setLoading(false);
			if (error.response && error.response.status === 401) {
				history.push('/login');
			}
		}
	}

	return (
		<Modal style={{ maxWidth: 500 }}>
			<ModalHeader>
				<HeaderTitle>{metodo ? metodo.name : 'Novo Metódo'}</HeaderTitle>

				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
					}}>
					<HeaderButton
						onClick={(e) => {
							e.preventDefault();

							setModalVisible(false);
							setPreModalVisible(false);
						}}
						style={{
							background: 'transparent',
							color: '#676767',
							marginRight: 8,
						}}>
						Cancelar
					</HeaderButton>
					<HeaderButton
						onClick={(e) => {
							e.preventDefault();
							EditMethod();
						}}
						disabled={loading}
						style={{ background: '#28A745', color: '#FFFFFF' }}>
						{!loading ? (
							'Salvar'
						) : (
							<img className='loading-roll' alt='loading' src={Loading} />
						)}
					</HeaderButton>
				</div>
			</ModalHeader>

			<ModalContainer>
				<InputContainer
					fieldLabel='Nome'
					inputName={nome}
					setInputField={setNome}
					isError={isError.name}
					placeholder={'Insira o nome'}
				/>

				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between',
					}}>
					<div style={{ width: '47%' }}>
						<SelectContainer
							fieldLabel='Tipo de Taxa'
							inputName={tipoTaxa}
							setInputField={setTipoTaxa}
							isError={isError.rateType}
							options={tiposTaxas}
						/>
					</div>
					<div style={{ width: '47%' }}>
						<InputContainer
							fieldLabel='Valor da Taxa'
							inputName={taxa}
							setInputField={setTaxa}
							type='number'
							isError={isError.rate}
							placeholder={'Insira o valor da taxa'}
						/>
					</div>
				</div>

				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between',
					}}>
					<div style={{ width: '47%' }}>
						<InputContainer
							fieldLabel='Identificador'
							inputName={identificador}
							setInputField={setIdentificador}
							type='number'
							isError={isError.code}
							placeholder={'Insira o identificador'}
						/>
					</div>

					<div style={{ width: '47%' }}>
						<InputContainer
							fieldLabel='Data de crédito'
							inputName={dataCredito}
							setInputField={setDataCredito}
							type='number'
							isError={isError.creditDate}
							placeholder={'Insira a data do crédito'}
						/>
					</div>
				</div>

				<InputContainer
					fieldLabel='TAG'
					inputName={tag}
					setInputField={setTag}
					isError={isError.tag}
					placeholder={'Insira a tag'}
				/>
			</ModalContainer>
		</Modal>
	);
}
