import styled from 'styled-components';
import { colors } from '../../globalStyles/colors';

export const Container = styled.div`
	width: 100vw;
	height: 100vh;
	display: flex;
	background: #f5f5f5;
	flex-direction: row;
	//overflow: none;

	@media only screen and (max-width: 768px) {
		width: 100vw;
		height: 100vh;
		max-height: -webkit-fill-available;
		overflow: hidden;
	}
`;

export const LoginContainer = styled.div`
	width: 50vw;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	justify-content: center;
	background: #f5f5f5;

	//min-height:0;
`;

export const LoginForm2 = styled.form`
	background: #ffffff 0% 0% no-repeat padding-box;
	box-shadow: 16px 16px 16px #22222229;
	border-radius: 36px;
	position: relative;
	width: 30vw;
	padding: 50px;
	min-width: 10vw;
	max-width: 450px;
	//left: 14vw;
	margin-right: -15vw;
	z-index: 500;

	@media only screen and (min-width: 1380px) {
	//	left: 20vw;
	}
`;

export const LoginForm = styled.form`
	display: flex;
	flex-direction: column;
	min-width: 380px;
	overflow-y: auto;
	// background: #000;

	//background:#F8F8F8
	svg {
		width: 20px;
		height: 32px;
		color: 'rgb(251,41,43)';
		align-self: center;
	}
	::-webkit-scrollbar {
		width: 0;
	}
`;

export const LoginTitle = styled.p`
	color: #676767;
	text-align: left;
	font: normal normal bold 24px/32px Roboto;
	letter-spacing: 0.48px;
	color: #676767;
	opacity: 1;
	margin-top: 0;
	// margin-top: 48px;
`;
interface LoginTextProps {
	incorreto?: boolean;
}

export const LoginText = styled.p<LoginTextProps>`
	text-align: left;
	font: normal normal 500 11px/15px Roboto;
	letter-spacing: 0px;
	color: #4b4b4b;
	text-transform: uppercase;

	//fontSize: focusUser || usuario.length > 0 ? 12px : 16px,

	transition: all 0.3s;
	margin: 0;
`;

interface PasswordDivProps {
	shadowColor?: string;
	borderColor?: string;
}

export const PasswordDiv = styled.div<PasswordDivProps>`
	padding: 15px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	background: #ffffff 0% 0% no-repeat padding-box;
	border: 1px solid
		${(props) => (props.borderColor ? props.borderColor : '#9f9f9f')};
	border-radius: 16px;
	height: 50px;
	margin-top: 8px;

	box-shadow: 0px 0px 10px 1px
		${(props) => (props.shadowColor ? props.shadowColor : 'transparent')};
	-webkit-box-shadow: 0px 0px 10px 1px
		${(props) => (props.shadowColor ? props.shadowColor : 'transparent')};
	-moz-box-shadow: 0px 0px 10px 1px
		${(props) => (props.shadowColor ? props.shadowColor : 'transparent')};

	/*padding-left: 8px;
	padding-right: 8px;
	background: transparent;
	border-bottom-width: 1px;
	border-color: ${colors.default};
	border-top: 0;
	border-right: 0;
	border-left: 0;
	border-style: solid;
	max-width: 100%;

	text-align: left;
	font: normal normal 500 16px/21px Roboto;
	letter-spacing: 0px;
	color: ${colors.default};
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-evenly;*/
`;

export const PasswordInput = styled.input`
	flex: 1;
	background: transparent;
	border: 0;
	text-align: left;
	font: normal normal 500 16px/21px Roboto;
	letter-spacing: 0px;
	color: ${colors.strongFont};
	::-webkit-input-placeholder {
		/* Edge */
		font: normal normal 300 14px/19px Roboto;
letter-spacing: 0px;
color: #9F9F9F;
	}

	:-ms-input-placeholder {
		/* Internet Explorer 10-11 */
		font: normal normal 300 14px/19px Roboto;
letter-spacing: 0px;
color: #9F9F9F;
	}

	::placeholder {
		font: normal normal 300 14px/19px Roboto;
letter-spacing: 0px;
color: #9F9F9F;
	}
`;

export const CheckBoxButton = styled.button`
	border: 0;
	background: transparent;
	display: flex;
	flex-direction: row;
	align-items: center;
	cursor: pointer;
`;

export const CheckDiv = styled.div`
	border: 0.5px solid #09aa36;
	border-radius: 5px;
	opacity: 1;
	width: 20px;
	height: 20px;
	padding: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 10px;
`;

interface CheckBoxTextProps{
	checked?:boolean;
}

export const CheckBoxText = styled.p<CheckBoxTextProps>`
	text-align: left;
	font: normal normal normal 14px/19px Roboto;
	letter-spacing: 0px;
	color: ${(props)=>(props.checked ?'#676767' : '#9f9f9f')} ;
	opacity: 1;
`;

export const LoginTextGreen = styled.p`
	text-align: center;
	font: normal normal 500 16px/21px Roboto;
	letter-spacing: 0px;
	color: ${colors.default};
	opacity: 1;
`;

export const LoginTextTerms = styled.p`
	text-align: center;
	text-decoration: underline;
	font: normal normal medium 14px/19px Roboto;
	letter-spacing: 0px;
	color: #676767;
	opacity: 1;
	position: absolute;
	bottom: -60px;
`;

interface ButtonProps {
	loading: boolean;
	isError: boolean;
}

export const Button = styled.button<ButtonProps>`
	background: ${(props) =>
		props.isError
			? 'rgb(251,41,43)'
			: 'linear-gradient(to left, #FFFFFF 50% , #18AA36 50%)right'};
	background-position: ${(props) =>
		props.loading ? 'left bottom' : 'right bottom'};
	background-size: 200% 100%;
	//background:${(props) => (props.loading ? colors.default : '#FFFFFF')};

	border: ${(props) => (props.isError ? 'rgb(251,41,43)' : colors.default)} 1px
		solid;

	/*border:  ${(props) =>
		props.loading ? '#FFFFFF' : colors.default} 2px solid;*/

	//background: ${colors.default} 0% 0% no-repeat padding-box;
	border-radius: 10px;
	width: 100%;
	display: flex;

	align-items: center;
	justify-content: center;
	font: normal normal medium 14px/19px Roboto;
	letter-spacing: 0px;
	color: ${(props) =>
		props.loading || props.isError ? '#FFFFFF' : colors.default};
	padding-top: 10px;
	padding-bottom: 10px;
	//border: 0;
	cursor: pointer;

	transition: all 1s;
`;

interface ErrorProps {
	error: boolean;
}

export const AlertaDifferent = styled.div<ErrorProps>`
	background: #e8453e;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	font: normal normal 500 13px/18px Roboto;
	letter-spacing: 0px;
	color: ${colors.white};
	box-shadow: 0px 12px 12px #22222229;
	border-radius: 10px;
	//width: 100%;
	padding-top: 10px;
	padding-bottom: 10px;
	padding-left: 10px;
	padding-right: 10px;
	height: 50px;
	//margin-bottom: 5px;

	opacity: ${(props) => (props.error ? 1 : 0)};

	transition: all 1s;

	//max-width: 100%;

	//min-width: 350px;
	/* width: 350px; */

	//left: calc(14vw + 30px);

	@media only screen and (min-width: 1380px) {
		//left: calc(20vw + 30px);
	}
`;

export const Alerta = styled.div<ErrorProps>`
	background: #e8453e;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	font: normal normal 500 13px/18px Roboto;
	letter-spacing: 0px;
	color: ${colors.white};
	box-shadow: 0px 12px 12px #22222229;
	border-radius: 10px;
	//width: 100%;
	padding-top: 10px;
	padding-bottom: 10px;
	height: 50px;
	//margin-bottom: 5px;
	position: absolute;
	bottom: -60px;

	opacity: ${(props) => (props.error ? 1 : 0)};

	transition: all 1s;

	//max-width: 100%;

	//min-width: 350px;
	width: 350px;

	//left: calc(14vw + 30px);

	@media only screen and (min-width: 1380px) {
		//left: calc(20vw + 30px);
	}
`;

export const ImageContainer = styled.div`
	width: 50vw;
	height: 100%;
	display: flex;
	flex-direction: column;
	//align-items: center;
	justify-content: center;
	padding-left: 100px;

	.login_big_text{
		font: normal normal 300 42px/56px Roboto;
letter-spacing: 0px;
color: #FFFFFF;
max-width: 300px;
text-align: left;
	}


	
	

	.login_footer{
		display: flex;
		align-items: center;
		justify-content: center;
		align-self: flex-start;
		p{
			margin: 0;
			margin-right: 10px;
		}
	}

	background: transparent linear-gradient(314deg, #93CC2F 0%, #09AA36 100%) 0% 0% no-repeat padding-box;
`;

export const CreditText = styled.p`
	text-align: left;
	font: normal normal normal 13px/18px Roboto;
	letter-spacing: 0.26px;
	color: #ffffff;
	opacity: 1;
	margin-bottom: 3px;
`;

export const LoginFormDiv = styled.div``;

export const CenterRow = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
`;
