import api from "../../services/api";
import fileDownload from "js-file-download";
import { PaymentRequestDetail } from "./ConciliadorPaymentModal/Models";

export async function exportReportSearchRequest(
  startDate: string,
  endDate: string,
  merchantId?: number | string
) {
  const token = sessionStorage.getItem("@bill-token");

  try {
    const response = await api.get(
      `/admin/api/report/pdf?start=${startDate}&end=${endDate}&merchant_id=${merchantId}`,
      {
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/pdf",
        },
      }
    );

    const { data } = response;

    if (data) {
      fileDownload(response.data, `relatorios${startDate}_${endDate}.${"pdf"}`);
    }
  } catch (error: any) {
    console.log(error);
    throw error;
  }
}

export async function getPaymentInvoiceRequest(invoiceId: string | number) {
  const token = sessionStorage.getItem("@bill-token");

  try {
    const response = await api.get(
      `/admin/api/payment-request/invoice?invoice=${invoiceId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const { data } = response;

    return data;
  } catch (error: any) {
    console.log(error);
    throw error;
  }
}

export async function postCreateRequestProcess(invoiceId: number|string): Promise<PaymentRequestDetail> {
  const token = sessionStorage.getItem("@bill-token");

  try {
    const response = await api.post(
      `/admin/api/payment-request`,
      { invoice: invoiceId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const { data } = response;

    return data.payment_request;
  } catch (error: any) {
    console.log(error);
    throw error;
  }
}

export async function postPaymentRequestProcess(
  processId: number,
  postObj: any
) {
  const token = sessionStorage.getItem("@bill-token");

  try {
    const response = await api.post(
      `/admin/api/payment-request/${processId}/process`,
      postObj,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const { data } = response;

    return data;
  } catch (error: any) {
    console.log(error);
    throw error;
  }
}
