/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';

import styled from 'styled-components';
import { colors } from '../../globalStyles/colors';
import { Correspondente, Dispositivo, Empresa } from '../../models/model';
import DoneWhite from '../../assets/images/done-white.svg';
import InputMask from 'react-input-mask';
import ClearIcon from '../../assets/images/clearIcon.svg';
import { InputContainer, SelectContainer } from '../../components/Input';
import api from '../../services/api';
import Loading from '../../assets/images/loading-roll.svg';
import CloseWhite from '../../assets/images/close-white.svg';

import * as yup from 'yup';
import { useHistory } from 'react-router';
const Modal = styled.div`
	//padding-top: 25px;
	//padding-left: 50px;
	//padding-right: 20000px;
	//padding-bottom: 25px;
	min-height: 100vh;
	background-color: #f5f5f5;
	width: 100vw;
	//margin-left: 50vw;
	height: 100%;
	flex: 1;
`;

const ModalHeader = styled.div`
	background: #f6f6f6 0% 0% no-repeat padding-box;
	box-shadow: 0px 16px 16px #00000029;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	padding-top: 21px;
	padding-bottom: 21px;

	padding-left: 30px;
	padding-right: 30px;
	height: 80px;
`;

const HeaderTitle = styled.h1`
	font: normal normal 500 24px/32px Roboto;
	letter-spacing: 0px;
	color: #222222;
`;

const HeaderButton = styled.button`
	background: #28a745 0% 0% no-repeat padding-box;
	border-radius: 19px;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 38px;
	padding-left: 16px;
	padding-right: 16px;

	text-align: center;
	font: normal normal 500 16px/21px Roboto;
	letter-spacing: 0px;
	border: 0;
	color: #ffffff;
	.loading-roll {
		width: 21px;
		height: 21px;
	}
`;

const HeaderButtonCancel = styled.button`
	background: transparent 0% 0% no-repeat padding-box;
	border-radius: 19px;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 38px;
	padding-left: 16px;
	padding-right: 16px;

	text-align: center;
	font: normal normal 500 16px/21px Roboto;
	letter-spacing: 0px;
	border: 0;
	color: #676767;
`;

const ModalContainer = styled.div`
	background: #ffffff 0% 0% no-repeat padding-box;
	box-shadow: -16px 0px 16px #00000029;
	opacity: 1;
	display: flex;
	flex-direction: row;
	//flex: 1;
	height: calc(100vh - 80px);
`;

const BodyMenu = styled.div`
	/*padding-left: 30px;
	padding-right: 30px;
	max-height: calc(100vh - 80px);*/
	display: flex;
	justify-content: space-between;
	flex: 1;
	flex-direction: column;

	padding-top: 30px;
	padding-bottom: 30px;

	border-right: 1px solid #f0f0f0;
`;

const MenuContainer = styled.div`
	display: flex;
	//justify-content: space-between;
	flex-direction: column;
	//align-items: center;
	flex: 1;
	border-radius: 1px solid #f0f0f0;
`;

const MenuFooter = styled.div`
	padding-left: 30px;
	padding-right: 30px;

	h1 {
		text-align: left;
		font: normal normal 500 24px/32px Roboto;
		letter-spacing: 0px;
		color: #222222;
	}

	p {
		text-align: right;
		font: normal normal normal 11px/15px Roboto;
		letter-spacing: 0px;
		color: #9f9f9f;
	}
`;

const BodyItem = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	text-align: right;

	letter-spacing: 0px;
	color: #222222;
	padding-left: 30px;
	padding-right: 50px;
	justify-content: flex-end;
	//background-color: #000;
	margin-bottom: 60px;
	p {
		font: normal normal 500 18px Roboto;
		margin: 0;
	}
	button {
		border: 0;
		width: 40px;
		height: 40px;
		border-radius: 20px;
		background: #18aa36 0% 0% no-repeat padding-box;
		display: flex;
		align-items: center;
		justify-content: center;

		text-align: center;
		font: normal normal normal 20px/20px Roboto;
		letter-spacing: 0px;
		color: #9f9f9f;
		margin-left: 15px;
		position: relative;
	}
	.icon_error {
		width: 20px;
		height: 20px;
		border-radius: 10px;
		background-color: #f21f4a;
		display: flex;
		align-items: center;
		justify-content: center;
		color: #ffffff;
		position: absolute;
		top: 0px;
		right: -5px;
	}
`;

interface FormBodyProps {
	showScroll?: boolean;
}

const FormBody = styled.form<FormBodyProps>`
	flex: 1;
	height: calc(100vh - 80px);
	overflow-y: scroll;
	padding-top: 30px;
	padding-bottom: 30px;
	padding-left: 30px;
	padding-right: 30px;

	::-webkit-scrollbar {
		background: transparent;

		// display:none;

		width: 12px;
	}

	&::-webkit-scrollbar-track {
		/*  margin-top:0;
      border-bottom-right-radius: 16px;
      background-image: url("https://i.stack.imgur.com/Pwbuz.png");
    background-repeat: repeat-y;
    background-size: contain;
      */
		// margin-top: 54px;
	}
	&::-webkit-scrollbar-thumb {
		background: ${(props) =>
			props.showScroll ? 'rgba(103, 103, 103, 0.5)' : 'transparent'};
		border-radius: 20px;
		border: 2.5px solid rgba(0, 0, 0, 0);
		border-right: 5px solid rgba(0, 0, 0, 0);

		background-clip: padding-box;
		max-height: 5px;
		transition: all 1s;
	}

	h1 {
		text-align: left;
		font: normal normal 500 16px/22px Roboto;
		letter-spacing: 0px;
		color: #222222;
	}

	.inputGroup {
		margin-top: 24px;
	}

	.inputTitle {
		font: normal normal 500 11px/15px Roboto;
		letter-spacing: 0px;
		color: #4b4b4b;
		text-transform: uppercase;
		margin: 0;
		margin-bottom: 8px;
	}

	.input_bonus_container {
		background: #ffffff 0% 0% no-repeat padding-box;
		border: 1px solid #9f9f9f;
		border-radius: 16px;
		height: 50px;
		display: flex;
		input,
		select {
			::placeholder {
				font: normal normal 300 14px/19px Roboto;
				letter-spacing: 0px;
				color: #9f9f9f;
			}
		}
		padding-left: 15px;

		input {
			flex: 1;
			background: transparent;
			height: 50px;
			border: 0;
			border-top-left-radius: 16px;
			border-bottom-left-radius: 16px;

			text-align: left;
			font: normal normal 500 14px/19px Roboto;
			letter-spacing: 0px;
			color: #222222;
			flex: 1;
			border: 0;
			max-width: calc(100% - 50px);
		}

		.input_percentage {
			background: #f0f0f0 0% 0% no-repeat padding-box;
			border: 1px solid #9f9f9f;
			border-radius: 0px 16px 16px 0px;
			display: flex;
			align-items: center;
			justify-content: center;

			font: normal normal 500 18px/24px Roboto;
			letter-spacing: 0px;
			color: #676767;
			width: 50px;
			height: 100%;
			border: 0;
		}
	}

	.inputContainer {
		background: #ffffff 0% 0% no-repeat padding-box;
		border: 1px solid #9f9f9f;
		border-radius: 16px;
		height: 50px;
		display: flex;
		flex-direction: row;
		padding: 15px;
		input,
		select {
			::placeholder {
				font: normal normal 300 14px/19px Roboto;
				letter-spacing: 0px;
				color: #9f9f9f;
			}
			text-align: left;
			font: normal normal 500 14px/19px Roboto;
			letter-spacing: 0px;
			color: #222222;
			flex: 1;
			border: 0;
			margin-top: -15px;
			margin-bottom: -15px;
		}
	}

	.clearIcon {
		width: 17px;
		height: 17px;
		cursor: pointer;
	}
	.logoImg {
		width: 120px;
		height: 120px;
	}
`;

const ImageButton = styled.div`
	border: 1px dashed var(--disabled);
	background: #f5f5f5 0% 0% no-repeat padding-box;
	border: 1px dashed #9f9f9f;
	border-radius: 10px;

	display: flex;
	flex-direction: column;
	align-items: center;

	justify-content: space-between;

	padding-top: 10px;
	padding-bottom: 10px;

	//width: 114px;
	max-height: 120px;
	svg {
		width: 32px;
		height: 27px;
		color: #9f9f9f;
	}
	img {
		width: 100%;
		height: 100%;
	}
`;

const Select = styled.select`
	/*text-align: left;
	font: normal normal 500 16px/21px Roboto;
	letter-spacing: 0px;
	color: #09aa36;

	margin: 0;
	max-width: 85%;
	width: 200px;
	height: 25px;

	background: transparent;
	border: 0;
	border-bottom: 1px solid #09aa36;
	padding-left: 5px;
	padding-right: 5px;*/
`;

interface ModalProps {
	setModalVisible(b: boolean): void;
	dispositivo?: Dispositivo;
	correspondentes: Correspondente[];
	updateDispositivos(d: Dispositivo): void;
}

export default function ModalNovoDispositivo({
	setModalVisible,
	//relatorio,
	dispositivo,
	//tamanho = 0,
	correspondentes,
	updateDispositivos,
}: ModalProps) {
	const [isError, setIsError] = useState<any>({});

	const [name, setName] = useState('');
	const [provider, setProvider] = useState('');
	const [status, setStatus] = useState('0');
	const [codigo, setCodigo] = useState('');
	const [secretKey, setSecretKey] = useState('');

	const [recipient, setRecipient] = useState('');
	const [loading, setLoading] = useState(false);

	const [listaCorrespondentes, setListaCorrespondentes] = useState([
		{ label: '', value: '' },
	]);

	useEffect(() => {
		const l = [{ label: '', value: '' }];

		correspondentes.forEach((c) => {
			l.push({ label: c.name, value: c.id.toString() });
		});
		setListaCorrespondentes([...l]);
	}, [correspondentes]);

	useEffect(() => {
		if (dispositivo) {
			setName(dispositivo.name);
			setProvider(dispositivo.provider);
			setStatus(dispositivo.status.toString());
			setCodigo(dispositivo.username);
			setSecretKey(dispositivo.secret);
			if (dispositivo.recipient)
				setRecipient(dispositivo.recipient.id.toString());
		}
	}, [dispositivo]);
	const [showScroll, setShowScroll] = useState(false);
	const statusMap = [
		{ label: 'Ativo', value: '1' },
		{ label: 'Inativo', value: '0' },
	];
	const provedores = [
		{ value: '', label: '' },
		{ value: 'STONE', label: 'Stone' },
		{ value: 'MUNDIPAGG', label: 'Mundipagg' },
	];

	const DadosRef = useRef<any>(null);
	function changeError(s: string) {
		const ie = { ...isError };

		if (ie[s] === true) {
			ie[s] = false;
			setIsError({ ...ie });
		}
	}
	function checkPasso1Error() {
		console.log(isError);
		if (
			isError['name'] ||
			isError['provider'] ||
			isError['status'] ||
			isError['recipient'] ||
			isError['username'] ||
			isError['secret']
		)
			return true;
		return false;
	}

	function checkPasso1(completo: boolean) {
		if (completo) {
			return (
				name.length > 0 &&
				provider.length > 0 &&
				codigo.length > 0 &&
				secretKey.length > 0
			);
		}

		return (
			name.length > 0 ||
			provider.length > 0 ||
			codigo.length > 0 ||
			secretKey.length > 0
		);
	}

	async function EditDispositivo() {
		setLoading(true);
		setIsError({});
		const token = sessionStorage.getItem('@bill-token');

		const config = {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		};

		try {
			const data = {
				name,
				provider,
				status: parseInt(status),
				username: codigo,
				secret: secretKey,
				recipient: parseInt(recipient),
			};

			const schema = yup.object().shape({
				name: yup.string().required(),
				provider: yup.string().required(),
				status: yup.number().required().default(0),
				username: yup.string().required(),
				secret: yup.string().required(),
				recipient: yup.number(),
			});

			await schema.validate(data, { abortEarly: false });

			const response = await api.post(
				`admin/api/device${dispositivo ? `/${dispositivo.id}` : ''}`,
				data,
				config
			);

			if (response.data.device) {
				updateDispositivos(response.data.device);
				setModalVisible(false);
			}
			setLoading(false);
		} catch (error:any) {
			console.log(error);
			const validationError = {} as any;
			if (error instanceof yup.ValidationError) {
				error.inner.forEach((err) => {
					//console.log(err.path);
					if (err.path) validationError[err.path] = true;
				});
			}
			setIsError(validationError);
			// console.log(validationError)
			setLoading(false);
			if (error.response && error.response.status === 401) {
				history.push('/login');
			}
		}
	}
	const history = useHistory();

	return (
		<Modal style={{ maxWidth: 786 }}>
			<ModalHeader>
				<HeaderTitle>
					{dispositivo ? dispositivo.name : 'Novo Dispositivo'}
				</HeaderTitle>

				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
					}}>
					<HeaderButtonCancel
						onClick={(e) => {
							e.preventDefault();
							setModalVisible(false);
						}}
						style={{ marginRight: 8 }}>
						Cancelar
					</HeaderButtonCancel>

					<HeaderButton
						onClick={(e) => {
							e.preventDefault();
							//setModalVisible(false);
							EditDispositivo();
						}}
						disabled={loading}
						style={{ background: '#28A745', color: '#FFFFFF' }}>
						{!loading ? (
							'Salvar'
						) : (
							<img className='loading-roll' alt='loading' src={Loading} />
						)}
					</HeaderButton>
				</div>
			</ModalHeader>

			<ModalContainer>
				<BodyMenu>
					<MenuContainer>
						<BodyItem>
							<div>
								<p style={{ fontSize: 11 }}>PASSO 1</p>
								<p style={{ fontSize: 18 }}>Dados do Dispositivo</p>
							</div>
							<button
								onClick={(e) => {
									e.preventDefault();
									if (DadosRef && DadosRef.current) {
										DadosRef.current.scrollIntoView({
											behavior: 'smooth',
											block: 'start',
										});
									}
								}}
								style={{
									background: checkPasso1Error()
										? '#F0F0F0'
										: checkPasso1(false)
										? '#18AA36'
										: '#F0F0F0',
									color: checkPasso1Error()
										? '#9F9F9F'
										: checkPasso1(false)
										? 'white'
										: '#9F9F9F',
								}}>
								{checkPasso1Error() ? (
									'1'
								) : checkPasso1(true) ? (
									<img src={DoneWhite} alt='check' />
								) : (
									'1'
								)}
								{checkPasso1Error() && <div className='icon_error'> !</div>}
							</button>
						</BodyItem>
					</MenuContainer>
				</BodyMenu>
				<FormBody
					onSubmit={(e) => {
						e.preventDefault();
						EditDispositivo();
					}}
					showScroll={showScroll}>
					<div>
						<h1>Comece inserindo os dados básicos do correspondente</h1>

						<InputContainer
							changeError={changeError}
							name='name'
							fieldLabel={'NOME FANTASIA'}
							inputName={name}
							setInputField={setName}
							isError={isError.name}
							placeholder={'Insira o nome do correspondente'}
						/>

						<div style={{ display: 'flex', justifyContent: 'space-between' }}>
							<div style={{ width: '47%' }}>
								<SelectContainer
									changeError={changeError}
									name='provider'
									fieldLabel={'Provedor'}
									inputName={provider}
									setInputField={setProvider}
									options={provedores}
									isError={isError.provider}
								/>
							</div>

							<div style={{ width: '47%' }}>
								<SelectContainer
									changeError={changeError}
									name='status'
									fieldLabel={'Situação'}
									inputName={status}
									setInputField={setStatus}
									options={statusMap}
									isError={isError.status}
								/>
							</div>
						</div>
						<SelectContainer
							changeError={changeError}
							name='recipient'
							fieldLabel={'Correspondente'}
							inputName={recipient}
							setInputField={setRecipient}
							options={listaCorrespondentes}
							isError={isError.recipient}
						/>

						<InputContainer
							changeError={changeError}
							name='username'
							fieldLabel={'Código'}
							inputName={codigo}
							setInputField={setCodigo}
							isError={isError.username}
							placeholder={'Insira o código'}
						/>

						<InputContainer
							changeError={changeError}
							name='secret'
							fieldLabel={'Chave Secreta'}
							inputName={secretKey}
							setInputField={setSecretKey}
							isError={isError.secret}
							placeholder={'Insira a chave secreta'}
						/>
					</div>
				</FormBody>
			</ModalContainer>

			{/*
		<ModalBody>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'space-between',
					height: '100%',
					//background:"#000",
				}}>
				<div>
					<ModalTitle>
						{Dispositivo ? 'Editar Dispositivo' : `Nova Dispositivo`}
					</ModalTitle>

					{Dispositivo && <ModalName>{correspondente.name}</ModalName>}

					<GridDisplay style={{ flex: 1 }}>
						<div>
							<Text>Nome Fantasia</Text>
							<Input
								value={name}
								onChange={(e) => {
									setName(e.target.value);
								}}
							/>
						</div>
						<div style={{}}>
							<Text>CNPJ</Text>
							<ReactInputMask
								value={CNPJ}
								onChange={(e) => {
									setCNPJ(e.target.value);
								}}
								className='modal-categoria-mask-input'
								mask='99.999.999/9999-99'
							/>
						</div>
						<div>
							<Text>Código</Text>
							<Input
								value={codigo}
								onChange={(e) => {
									setCod(e.target.value);
								}}
							/>
						</div>
						<div />
					</GridDisplay>
				</div>
				<ButtonContainer>
					<ButtonCancel
						onClick={(e) => {
							e.preventDefault();
							setModalVisible(false);
						}}>
						Cancelar
					</ButtonCancel>
					<Button>Salvar</Button>
				</ButtonContainer>
			</div>
		</ModalBody>
*/}
		</Modal>
	);
}
