export const isAuthenticated = (): boolean => {
	const tokenLocal = localStorage.getItem('@bill-token')
	const token = sessionStorage.getItem('@bill-token');
	//console.log(token,'is authenticated')
	//console.log(tokenLocal,'is authenticatedLoca')

	if (token || tokenLocal) {
		return true;
	}

	return false;
};
