/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useEffect, useState } from 'react';

import styled from 'styled-components';
import InputMask from 'react-input-mask';
import Loading from '../../assets/images/loading-roll.svg';

//import { UilCamera } from '@iconscout/react-unicons';
import Dropzone, { WithDropZone } from '../../components/Dropzone';
import {
	Categoria,
	Contrato,
	Empresa,
	Parceiro,
	PaymentMethod,
} from '../../models/model';
import api from '../../services/api';
import { useHistory } from 'react-router-dom';
import { colors } from '../../globalStyles/colors';
import ClearIcon from '../../assets/images/clearIcon.svg';
import DoneWhite from '../../assets/images/done-white.svg';
import CloseWhite from '../../assets/images/close-white.svg';
import { useRef } from 'react';
import { Table, TableContainer, TBody, Th, THead, BodyTR } from './style';

import EditGray from '../../assets/images/edit-gray.svg';
import RemoveGray from '../../assets/images/delete_outline-gray.svg';
import SearchGray from '../../assets/images/search-gray.svg';
import {
	Dropdown,
	Modal as NewModal,
	OverlayTrigger,
	Tooltip,
} from 'react-bootstrap';

import ModalNovoContrato from './ModalNovoContrato';
import ModalNovoMetodo from './ModalNovoMetodo';
import DeleteModal from '../../components/deleteModal';
import * as yup from 'yup';
import {
	InputContainer,
	InputContainerPercentage,
	InputMaskContainer,
	SelectContainer,
} from '../../components/Input';

const Modal = styled.div`
	//padding-top: 25px;
	//padding-left: 50px;
	//padding-right: 20000px;
	//padding-bottom: 25px;
	min-height: 100vh;
	background-color: #f5f5f5;
	width: 100vw;
	//margin-left: 50vw;
	height: 100%;
	flex: 1;
`;

const ModalHeader = styled.div`
	background: #f6f6f6 0% 0% no-repeat padding-box;
	box-shadow: 0px 16px 16px #00000029;
	//display: flex;
	//flex-direction: row;
	//align-items: center;
	//justify-content: space-between;
	padding-top: 21px;

	padding-left: 30px;
	padding-right: 30px;
	height: 80px;

	.header_container {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		padding-bottom: 21px;
	}

	.header_options {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}
`;

interface HeaderItemProps {
	active?: boolean;
}

const HeaderItem = styled.div<HeaderItemProps>`
	font: normal normal
		${(props) => (props.active ? '500 16px/20px' : '300 14px/20px')} Roboto;
	letter-spacing: 0px;
	color: ${(props) => (props.active ? '#222222' : '#9F9F9F')};

	padding-bottom: 18px;

	border: 0;

	border-bottom: ${(props) =>
		props.active ? '2px solid #28A745' : '2px solid transparent'};
	cursor: pointer;
`;

const HeaderTitle = styled.h1`
	font: normal normal 500 24px/32px Roboto;
	letter-spacing: 0px;
	color: #222222;
`;

const HeaderButton = styled.button`
	border-radius: 19px;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 38px;
	padding-left: 16px;
	padding-right: 16px;

	text-align: center;
	font: normal normal 500 16px/21px Roboto;
	letter-spacing: 0px;
	border: 0;

	.loading-roll {
		width: 21px;
		height: 21px;
	}
`;

interface ModalContainerProps {
	active?: boolean;
}

const ModalContainer = styled.div<ModalContainerProps>`
	background: #ffffff 0% 0% no-repeat padding-box;
	box-shadow: -16px 0px 16px #00000029;
	opacity: 1;
	display: flex;
	flex-direction: row;
	//flex: 1;
	height: ${(props) =>
		props.active ? 'calc(100vh - 123px)' : 'calc(100vh - 80px)'};

	.empresa_icon_container {
		background: transparent 0% 0% no-repeat padding-box;
		border: 1px solid transparent;
		border-radius: 18px;
		width: 36px;
		height: 36px;
		display: flex;
		align-items: center;
		justify-content: center;
		opacity: 1;

		:hover {
			background: #ffffff 0% 0% no-repeat padding-box;
			border: 1px solid #f0f0f0;

			.empresa_icon_edit {
				transform: rotate(-45deg);
				-moz-transform: rotate(-45deg);
				/* Safari and Chrome */
				-webkit-transform: rotate(-45deg);
				/* Opera */
				-o-transform: rotate(-45deg);
				/* IE9 */
				-ms-transform: rotate(-45deg);
				transition: all 0.3s;
			}
		}
	}

	.label_abrir_novo_metodo {
		text-align: left;
		text-decoration: underline;
		font: normal normal normal 14px/19px Roboto;
		letter-spacing: 0px;
		color: #18aa36;
		cursor: pointer;
		margin-top: 25px;
		margin-left: 30px;
	}
`;

const BodyMenu = styled.div`
	/*padding-left: 30px;
	padding-right: 30px;
	max-height: calc(100vh - 80px);*/
	display: flex;
	justify-content: space-between;
	flex: 1;
	flex-direction: column;

	padding-top: 30px;
	padding-bottom: 30px;

	border-right: 1px solid #f0f0f0;
`;

const MenuContainer = styled.div`
	display: flex;
	//justify-content: space-between;
	flex-direction: column;
	//align-items: center;
	flex: 1;
	border-radius: 1px solid #f0f0f0;
`;

const MenuFooter = styled.div`
	padding-left: 30px;
	padding-right: 30px;

	h1 {
		text-align: left;
		font: normal normal 500 24px/32px Roboto;
		letter-spacing: 0px;
		color: #222222;
	}

	p {
		text-align: right;
		font: normal normal normal 11px/15px Roboto;
		letter-spacing: 0px;
		color: #9f9f9f;
	}
`;

const BodyItem = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	text-align: right;

	letter-spacing: 0px;
	color: #222222;
	padding-left: 30px;
	padding-right: 50px;
	justify-content: flex-end;
	//background-color: #000;
	margin-bottom: 60px;
	p {
		font: normal normal 500 18px Roboto;
		margin: 0;
	}
	button {
		border: 0;
		width: 40px;
		height: 40px;
		border-radius: 20px;
		background: #18aa36 0% 0% no-repeat padding-box;
		display: flex;
		align-items: center;
		justify-content: center;

		text-align: center;
		font: normal normal normal 20px/20px Roboto;
		letter-spacing: 0px;
		color: #9f9f9f;
		margin-left: 15px;

		position: relative;
	}

	.icon_error {
		width: 20px;
		height: 20px;
		border-radius: 10px;
		background-color: #f21f4a;
		display: flex;
		align-items: center;
		justify-content: center;
		color: #ffffff;
		position: absolute;
		top: 0px;
		right: -5px;
	}
`;

interface FormBodyProps {
	showScroll?: boolean;
	active?: boolean;
}

const FormBody = styled.form<FormBodyProps>`
	.mt-24 {
		margin-top: 24px;
	}

	.mb-8 {
		margin-bottom: 8px;
	}

	flex: 1;
	height: ${(props) =>
		props.active ? 'calc(100vh - 123px)' : 'calc(100vh - 80px)'};
	overflow-y: scroll;
	padding-top: 30px;
	padding-bottom: 30px;
	padding-left: 30px;
	padding-right: 30px;

	::-webkit-scrollbar {
		background: transparent;

		// display:none;

		width: 12px;
	}

	&::-webkit-scrollbar-track {
		/*  margin-top:0;
      border-bottom-right-radius: 16px;
      background-image: url("https://i.stack.imgur.com/Pwbuz.png");
    background-repeat: repeat-y;
    background-size: contain;
      */
		// margin-top: 54px;
	}
	&::-webkit-scrollbar-thumb {
		background: ${(props) =>
			props.showScroll ? 'rgba(103, 103, 103, 0.5)' : 'transparent'};
		border-radius: 20px;
		border: 2.5px solid rgba(0, 0, 0, 0);
		border-right: 5px solid rgba(0, 0, 0, 0);

		background-clip: padding-box;
		max-height: 5px;
		transition: all 1s;
	}

	h1 {
		text-align: left;
		font: normal normal 500 16px/22px Roboto;
		letter-spacing: 0px;
		color: #222222;
	}

	.inputGroup {
		margin-top: 24px;
	}

	.inputTitle {
		font: normal normal 500 11px/15px Roboto;
		letter-spacing: 0px;
		color: #4b4b4b;
		text-transform: uppercase;
		margin: 0;
		margin-bottom: 8px;
	}

	.inputContainer {
		background: #ffffff 0% 0% no-repeat padding-box;
		border: 1px solid #9f9f9f;
		border-radius: 16px;
		height: 50px;
		display: flex;
		flex-direction: row;
		padding: 15px;
		input,
		select {
			::placeholder {
				font: normal normal 300 14px/19px Roboto;
				letter-spacing: 0px;
				color: #9f9f9f;
			}
			text-align: left;
			font: normal normal 500 14px/19px Roboto;
			letter-spacing: 0px;
			color: #222222;
			flex: 1;
			border: 0;
			margin-top: -15px;
			margin-bottom: -15px;
		}
	}

	.clearIcon {
		width: 17px;
		height: 17px;
		cursor: pointer;
	}
	.logoImg {
		width: 120px;
		height: 120px;
	}
`;

const ModalLabel = styled.p`
	margin: 0;
	text-align: left;
	font: normal normal 500 11px/15px Roboto;
	letter-spacing: 0px;
	color: #4b4b4b;
	text-transform: uppercase;
`;

interface ModalInputContainerProps {
	shadowColor?: string;
	borderColor?: string;
}
const ModalInputContainer = styled.div<ModalInputContainerProps>`
	background: #ffffff 0% 0% no-repeat padding-box;
	border: 1px solid
		${(props) => (props.borderColor ? props.borderColor : '#9f9f9f')};
	border-radius: 16px;
	display: flex;
	flex-direction: row;
	height: 50px;
	box-shadow: 0px 0px 10px 1px
		${(props) => (props.shadowColor ? props.shadowColor : 'transparent')};
	-webkit-box-shadow: 0px 0px 10px 1px
		${(props) => (props.shadowColor ? props.shadowColor : 'transparent')};
	-moz-box-shadow: 0px 0px 10px 1px
		${(props) => (props.shadowColor ? props.shadowColor : 'transparent')};

	.modal_input_icon_container {
		height: 100%;
		padding-right: 16px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.modal_input_icon {
		width: 16px;
		height: 16px;
		border-radius: 8px;
		align-self: center;
		cursor: pointer;
	}

	.modal_input {
		border: transparent;
		border-radius: 16px;
		flex: 1;
		overflow: hidden;
		padding-left: 16px;
		text-align: left;
		font: normal normal 500 14px/19px Roboto;
		letter-spacing: 0px;
		color: #222222;

		::-webkit-input-placeholder {
			/* Edge */
			text-align: left;
			font: normal normal 300 14px/19px Roboto;
			letter-spacing: 0px;
			color: #9f9f9f;
		}

		:-ms-input-placeholder {
			/* Internet Explorer 10-11 */
			text-align: left;
			font: normal normal 300 14px/19px Roboto;
			letter-spacing: 0px;
			color: #9f9f9f;
		}

		::placeholder {
			text-align: left;
			font: normal normal 300 14px/19px Roboto;
			letter-spacing: 0px;
			color: #9f9f9f;
		}
	}

	.percentage_container {
		background: #09aa36 0% 0% no-repeat padding-box;
		border: 1px solid #09aa36;
		border-radius: 0px 16px 16px 0px;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		text-align: center;
		width: 50px;
		height: 50px;
		font: normal normal 500 18px/24px Roboto;
		letter-spacing: 0px;
		color: #ffffff;
	}
`;

const ImageButton = styled.div`
	border: 1px dashed var(--disabled);
	background: #f5f5f5 0% 0% no-repeat padding-box;
	border: 1px dashed #9f9f9f;
	border-radius: 10px;

	display: flex;
	flex-direction: column;
	align-items: center;

	justify-content: space-between;

	padding-top: 10px;
	padding-bottom: 10px;

	//width: 114px;
	max-height: 120px;
	position: relative;

	svg {
		width: 32px;
		height: 27px;
		color: #9f9f9f;
	}
	img {
		width: 100%;
		height: 100%;
	}
`;

const Select = styled.select`
	/*text-align: left;
	font: normal normal 500 16px/21px Roboto;
	letter-spacing: 0px;
	color: #09aa36;

	margin: 0;
	max-width: 85%;
	width: 200px;
	height: 25px;

	background: transparent;
	border: 0;
	border-bottom: 1px solid #09aa36;
	padding-left: 5px;
	padding-right: 5px;*/
`;

const RemoveImg = styled.button`
	width: 20px;
	height: 20px;
	border-radius: 50%;
	background-color: #f21f4a;
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: 5px;
	right: 5px;
	border: solid 1px transparent;

	img {
		width: 14px;
		height: 14px;
	}
`;

interface ModalProps {
	setModalVisible(b: boolean): void;
	//categorias: Categoria[];
	empresa?: Empresa;
	UpdateEmpresas(E: Empresa): void;
}

export default function ModalEmpresa({
	setModalVisible,
	//categorias,
	empresa,
	UpdateEmpresas,
}: ModalProps) {
	const history = useHistory();
	const [categoriasList, setCategoriasList] = useState([
		{ label: '', value: '' },
	]);

	const [isError, setIsError] = useState<any>({});
	const [isErrorFin, setIsErrorFin] = useState<any>({});

	const [selectedHeader, setSelectedHeader] = useState(1);

	const [category, setCategory] = useState('');
	const [CNPJ, setCNPJ] = useState('');
	const [stoneCode, setStoneCode] = useState('');
	const [nameFantasy, setNameFantasy] = useState('');
	const [name, setName] = useState('');
	const [user, setUser] = useState('');
	const [Url, setUrl] = useState('');
	const [secretKey, setSecretKey] = useState('');
	const [cor, setColor] = useState('');
	const [rotuloLogin, setRotuloLogin] = useState('');
	const [etiqueta, setEtiqueta] = useState('');
	const [id, setId] = useState('');
	const [localityType, setLocalityType] = useState('');
	const [locality, setLocality] = useState('BR');

	const [integrationName, setIntegrationName] = useState('');
	const [integrationURL, setIntegrationURL] = useState('');
	const [integrationUser, setIntegrationUser] = useState('');
	const [integrationPassword, setIntegrationPassword] = useState('');

	const [financialAccountBankNumber, setFinancialAccountBankNumber] =
		useState('');
	const [financialBankName, setFinancialBankName] = useState('');
	const [financialBankCode, setFinancialBankCode] = useState('');
	const [financialAgreementCode, setFinancialAgreementCode] = useState('');
	const [financialCollectionMethod, setFinancialCollectionMethod] =
		useState('');
	const [
		financialCompanyIdentificationCode,
		setFinancialCompanyIdentificationCode,
	] = useState('');
	const [financialCompanyName, setFinancialCompanyName] = useState('');
	const [financialConciliator, setFinancialConciliator] = useState('');
	const [financialFinancialType, setFinancialFinancialType] = useState('');
	const [financialId, setFinancialId] = useState('');
	const [financialLayoutVersion, setFinancialLayoutVersion] = useState('');
	const [financialInstallmentRate, setFinancialInstallmentRate] = useState('');

	const [financialMaxInstallment, setFinancialMaxInstallment] = useState('');
	const [financialmaxInstallmentFree, setFinancialmaxInstallmentFree] =
		useState('');

	const [financialRemittanceCode, setFinancialRemittanceCode] = useState('');
	const [financialSegment, setFinancialSegment] = useState('');
	const [financialServiceIdentification, setFinancialServiceIdentification] =
		useState('');

	const [loading, setLoading] = useState(false);
	const [categorias, setCategorias] = useState<Categoria[]>([]);

	const [preimg, setPreimg] = useState<string | undefined>();

	const headerItems = [
		{
			label: 'Dados da Empresa',
			value: 1,
		},
		{
			label: 'Configurações financeiras',
			value: 2,
		},

		{
			label: 'Métodos de Pagamento',
			value: 3,
		},
		{
			label: 'Contratos',
			value: 4,
		},
	];

	const localTypes = [
		{ label: '', value: '' },
		{ label: '1. Estadual', value: '1' },
		{ label: '2. Nacional', value: '2' },
	];

	function removeMask(item: string) {
		const value = item.replace(/\D/g, '');
		return value;
	}
	function maskDocument(preValue: string): string {
		let value = preValue;
		value = value.replace(/\D/g, '');
		if (value.length <= 11) {
			value = value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4');
		} else {
			value = value.replace(
				/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
				'$1.$2.$3/$4-$5'
			);
		}
		return value;
	}

	async function getCategorias() {
		const token = sessionStorage.getItem('@bill-token');

		const config = {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		};

		try {
			const response = await api.get(
				`/admin/api/merchant-category?type=M`,
				config
			);

			if (response.data && response.data.categories) {
				setCategorias(response.data.categories);
			}
		} catch (error) {
			console.log(error);
		}
	}

	useEffect(() => {
		const lista = [{ label: '', value: '' }];

		categorias.forEach((cat) => {
			lista.push({ label: cat.name, value: cat.id.toString() });
		});

		setCategoriasList([...lista]);
	}, [categorias]);

	useEffect(() => {
		if (empresa) {
			setId(empresa.id.toString());
			setCategory(empresa.category.id.toString());
			if (empresa.doc) {
				setCNPJ(maskDocument(empresa.doc));
			}
			setStoneCode(empresa.cod);
			setNameFantasy(empresa.name);
			setName(empresa.credential.name);
			setUser(empresa.credential.username);
			setUrl(empresa.credential.uri);
			setSecretKey(empresa.credential.secret);
			if (empresa.extras) {
				if (empresa.extras.primary_color)
					setColor(empresa.extras.primary_color);
				if (empresa.extras.input_placeholder)
					setRotuloLogin(empresa.extras.input_placeholder);

				if (empresa.extras.input_validate) {
					const etiqueta = empresa.extras.input_validate.join(',');

					setEtiqueta(etiqueta);
				}
			}
			setLocality(empresa.locality);
			setLocalityType(empresa.localityType.toString());

			if (empresa.integration) {
				setIntegrationName(empresa.integration.name);
				setIntegrationURL(empresa.integration.url);
				setIntegrationPassword(empresa.integration.password);
				setIntegrationUser(empresa.integration.username);
			}
			if (empresa.financialSettings) {
				const f = empresa.financialSettings;
				setFinancialAccountBankNumber(f.accountBankNumber);
				setFinancialBankName(f.bankName);
				setFinancialBankCode(f.bankCode);
				setFinancialAgreementCode(f.agreementCode);
				setFinancialCollectionMethod(f.collectionMethod);
				setFinancialCompanyIdentificationCode(f.companyIdentificationCode);
				setFinancialCompanyName(f.companyName);
				setFinancialConciliator(f.conciliator);
				setFinancialFinancialType(f.financialType?.toString() || '0');
				setFinancialId(f.id.toString());
				setFinancialLayoutVersion(f.layoutVersion);
				setFinancialMaxInstallment(f.maxInstallment.toString());
				setFinancialmaxInstallmentFree(f.maxInstallmentFree.toString());
				setFinancialSegment(f.segment);
				setFinancialServiceIdentification(f.serviceIdentification);
				if (f.installmentRate) setFinancialInstallmentRate(f.installmentRate);
			}
			setPreimg(empresa.img);
		} else {
			setCategory(categoriasList[0].value);
		}
		getCategorias();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [empresa]);

	const [images, setImages] = useState<any>();
	const [reader, setReader] = useState<any>();

	const onDrop = useCallback((acceptedFiles) => {
		// this callback will be called after files get dropped, we will get the acceptedFiles. If you want, you can even access the rejected files too

		acceptedFiles.map((file: any) => {
			const reader = new FileReader();

			reader.onload = function (e) {
				if (e.target) {
					// console.log(e.target.result);
					const x = e.target.result;

					setImages({ id: 5, src: x });
				}
			};
			console.log(file);

			reader.readAsDataURL(file);

			setReader(file);

			return file;
		});

		/*if (reader) {
			reader.onload = function (e) {
				if (e.target !== null && e !== null) {
					setImages((prevState: any) => [
						...prevState,
						{ id: 5, src: e.target.result },
					]);
				}
			};

			reader.readAsDataURL(acceptedFiles);
			return acceptedFiles;
		}*/

		//console.log(acceptedFiles);
	}, []);

	async function DeleteImage(merchant: Empresa) {
		const token = sessionStorage.getItem('@bill-token');

		const config = {
			headers: {
				Authorization: `Bearer ${token}`,
				'Content-Type': 'multipart/form-data',
			},
		};
		try {
			const response = await api.delete(
				`admin/api/merchant/${merchant.id}/img`,
				config
			);

			if(response.data && response.data.merchant){
				UpdateEmpresas(response.data.merchant);
			}

			setModalVisible(false);
			setLoading(false);
		} catch (error) {
			setLoading(false);

			console.log(error);
		}
	}

	async function EditImage(merchant: Empresa) {
		const token = sessionStorage.getItem('@bill-token');

		const config = {
			headers: {
				Authorization: `Bearer ${token}`,
				'Content-Type': 'multipart/form-data',
			},
		};

		//console.log('123');
		try {
			const formData = new FormData();

			formData.append('img', reader);

			const response = await api.post(
				`/admin/api/merchant/${merchant.id}/img`,
				formData,
				config
			);
			//console.log(response.data);
			if (response.data.merchant) {
				UpdateEmpresas(response.data.merchant);
				setModalVisible(false);
			}
			setLoading(false);
		} catch (error: any) {
			console.error(error);
			setLoading(false);
			if (error.response && error.response.status === 401) {
				history.push('/login');
			}
		}
	}

	async function EditEmpresa() {
		setIsError({});
		setLoading(true);
		const token = sessionStorage.getItem('@bill-token');

		const config = {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		};

		try {
			const data = empresa
				? {
						id: id,
						/*img:
				empresa && empresa.img64
					? empresa.img64
					: images
					? images.src
					: undefined,*/
						name: nameFantasy,
						doc: removeMask(CNPJ),
						cod: stoneCode,
						localityType: localityType,
						locality: locality,
						extras: {
							primary_color: cor,
							input_placeholder: rotuloLogin,
							input_validate: etiqueta,
						},
						credential: {
							name: name,
							username: user,
							secret: secretKey,
							uri: Url,
						},
						integration: {
							name: integrationName,
							url: integrationURL,
							username: integrationUser,
							password: integrationPassword,
						},
						category: {
							id: category,
						},
				  }
				: {
						/*img:
				empresa && empresa.img64
					? empresa.img64
					: images
					? images.src
					: undefined,*/
						name: nameFantasy,
						doc: removeMask(CNPJ),
						cod: stoneCode,
						localityType: parseInt(localityType),
						locality: locality,
						credential: {
							name: name,
							username: user,
							secret: secretKey,
							uri: Url,
						},
						extras: {
							primary_color: cor,
							input_placeholder: rotuloLogin,
							input_validate: etiqueta,
						},
						integration: {
							name: integrationName,
							url: integrationURL,
							username: integrationUser,
							password: integrationPassword,
						},
						category: {
							id: parseInt(category),
						},
				  };

			const schema = yup.object().shape({
				name: yup.string().required(),
				doc: yup.string().required().min(11),
				//cod: yup.string().required(),
				localityType: yup.number().positive(),
				locality: yup.string().default('BR'),
				extras: yup.object({
					primary_color:
						data.extras.primary_color.length > 0
							? yup.string().matches(/(#)/).min(7)
							: yup.string(),
					input_placeholder: yup.string(),
					input_validate: yup.string(),
				}),
				credential: yup.object({
					name: yup.string().required(),
					username: yup.string().required(),
					secret: yup.string().required(),
					uri: yup.string().required().url(),
				}),
				category: yup.object({
					id: yup.number().required(),
				}),
			});

			await schema.validate(data, {
				abortEarly: false,
			});

			const response = await api.post(
				`/admin/api/merchant${empresa ? `/${id}` : ''}`,
				data,
				config
			);

			//console.log(response.data);
			if (response.data && response.data.merchant) {
				//console.log(response.data);

				UpdateEmpresas(response.data.merchant);

				if (images || preimg) {
					await EditImage(response.data.merchant);
				} else await DeleteImage(response.data.merchant);
			}
		} catch (error: any) {
			console.error(error);

			const validationError = {} as any;
			if (error instanceof yup.ValidationError) {
				error.inner.forEach((err) => {
					if (err.path) validationError[err.path] = true;
				});
			}
			// console.log(validationError)
			else {
				if (error.response && error.response.status === 401) {
					history.push('/login');
				}
				if (error.response) {
					if (error.response.data) {
						if (error.response.data.doc) {
							validationError['doc'] = true;
						}
					}
				}
			}

			console.log(validationError);
			setIsError(validationError);

			setLoading(false);
		}
	}

	async function editFinance() {
		setLoading(true);
		setIsErrorFin({});

		const token = sessionStorage.getItem('@bill-token');

		const config = {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		};

		try {
			const financialSettings = {
				accountBankNumber: financialAccountBankNumber,
				agreementCode: financialAgreementCode,
				bankCode: financialBankCode,
				bankName: financialBankName,
				collectionMethod: financialCollectionMethod,
				companyIdentificationCode: financialCompanyIdentificationCode,
				companyName: financialCompanyName,
				conciliator: financialConciliator,
				financialType: parseInt(financialFinancialType),
				//id: financialId,
				layoutVersion: financialLayoutVersion,
				maxInstallment: parseInt(financialMaxInstallment),
				maxInstallmentFree: parseInt(financialmaxInstallmentFree),
				remittanceCode: financialRemittanceCode,
				segment: financialSegment,
				serviceIdentification: financialServiceIdentification,
				installmentRate:
					parseFloat(financialInstallmentRate) <= 0
						? 0
						: parseFloat(financialInstallmentRate) / 100,
			};

			const schema = yup.object().shape({
				accountBankNumber: yup.string().required(),
				agreementCode: yup.string().required(),
				bankCode: yup.string().required(),
				bankName: yup.string().required(),
				collectionMethod: yup.string().required(),
				companyIdentificationCode: yup.string().required(),
				companyName: yup.string().required(),
				conciliator: yup.string().required(),
				financialType: yup.number().required(),
				//id: yup.string().required(),
				layoutVersion: yup.string().required(),
				maxInstallment: yup.number().required(),
				maxInstallmentFree: yup.number().required(),
				remittanceCode: yup.string(),
				segment: yup.string().required().required(),
				serviceIdentification: yup.string().required(),
				installmentRate: yup.number().required(),
			});

			await schema.validate(financialSettings, { abortEarly: false });

			const response = await api.post(
				`/admin/api/merchant/${id}/financial-settings`,
				financialSettings,
				config
			);

			if (response.data.merchant) {
				UpdateEmpresas(response.data.merchant);
				setModalVisible(false);
			}
			setLoading(false);
		} catch (error: any) {
			const validationError = {} as any;
			if (error instanceof yup.ValidationError) {
				error.inner.forEach((err) => {
					if (err.path) validationError[err.path] = true;
				});
			}
			setIsErrorFin(validationError);
			//console.log(validationError);
			setLoading(false);
			if (error.response && error.response.status === 401) {
				history.push('/login');
			}
		}
	}

	const x = 1;

	const [showScroll, setShowScroll] = useState(false);

	function handleScroll() {
		if (showScroll === false) {
			setShowScroll(true);

			setTimeout(() => {
				setShowScroll(false);
			}, 2000);
		}
	}

	useEffect(() => {
		window.addEventListener('scroll', handleScroll, true);
	}, []);

	const [stepsDone, setStepsDone] = useState(0);
	const [stepsDoneFin, setStepsDoneFin] = useState(0);

	function changeError(s: string) {
		const ie = { ...isError };

		if (ie[s] === true) {
			ie[s] = false;
			setIsError({ ...ie });
		}
	}

	function changeErrorFin(s: string) {
		const ie = { ...isErrorFin };

		if (ie[s] === true) {
			ie[s] = false;
			setIsErrorFin({ ...ie });
		}
	}

	useEffect(() => {
		let counter = 0;

		if (nameFantasy.length > 0 && CNPJ.length > 0) counter = counter + 1;
		if (
			category &&
			(images || preimg) &&
			cor.length > 0 &&
			rotuloLogin.length > 0 &&
			etiqueta.length > 0
		)
			counter = counter + 1;

		if (
			name.length > 0 &&
			user.length > 0 &&
			Url.length > 0 &&
			secretKey.length > 0
		)
			counter = counter + 1;
		if (
			integrationName.length > 0 &&
			integrationUser.length > 0 &&
			integrationURL.length > 0 &&
			integrationPassword.length > 0
		)
			counter = counter + 1;

		setStepsDone(counter);
	}, [
		CNPJ,
		Url,
		category,
		cor,
		empresa,
		etiqueta,
		images,
		integrationName,
		integrationPassword,
		integrationURL,
		integrationUser,
		name,
		nameFantasy,
		rotuloLogin,
		secretKey,
		user,
	]);

	useEffect(() => {
		let counter = 0;

		if (checkFinPasso1(true)) counter = counter + 1;
		if (checkFinPasso2(true)) counter = counter + 1;

		if (checkFinPasso3(true)) counter = counter + 1;
		if (checkFinPasso4(true)) counter = counter + 1;

		setStepsDoneFin(counter);
	}, [
		financialCompanyName,
		financialAgreementCode,
		financialCompanyIdentificationCode,
		financialSegment,
		financialBankCode,
		financialBankName,
		financialAccountBankNumber,
		financialLayoutVersion,
		financialCollectionMethod,
		financialServiceIdentification,
		financialInstallmentRate,
		financialMaxInstallment,
		financialFinancialType,
		financialConciliator,
	]);

	function checkPasso1Error() {
		if (isError.name || isError.doc) return true;
		return false;
	}

	function checkPasso1(all: boolean) {
		if (all) {
			return nameFantasy.length > 0 && CNPJ.length > 0;
		} else return nameFantasy.length > 0 || CNPJ.length > 0;
	}

	function checkPasso2Error() {
		if (
			isError['category.id'] ||
			isError['extras.primary_color'] ||
			isError['extras.input_placeholder'] ||
			isError['extras.input_validate'] ||
			isError.localityType
		)
			return true;
		return false;
	}
	function checkPasso2(all: boolean) {
		if (all) {
			return (
				category &&
				(images || preimg) &&
				cor.length > 0 &&
				rotuloLogin.length > 0 &&
				etiqueta.length > 0
			);
		} else
			return (
				category ||
				images ||
				preimg ||
				cor.length > 0 ||
				rotuloLogin.length > 0 ||
				etiqueta.length > 0
			);
	}

	function checkPasso3Error() {
		if (
			isError['credential.name'] ||
			isError['credential.username'] ||
			isError['credential.uri'] ||
			isError['credential.secret']
		)
			return true;
		return false;
	}

	function checkPasso3(all: boolean) {
		if (all) {
			return (
				name.length > 0 &&
				user.length > 0 &&
				Url.length > 0 &&
				secretKey.length > 0
			);
		} else
			return (
				name.length > 0 ||
				user.length > 0 ||
				Url.length > 0 ||
				secretKey.length > 0
			);
	}

	function checkPasso4Error() {
		if (
			isError['integrationName'] ||
			isError['integrationURL'] ||
			isError['integrationUser'] ||
			isError['integrationPassword']
		)
			return true;
		return false;
	}

	function checkPasso4(all: boolean) {
		if (all) {
			return (
				integrationName.length > 0 &&
				integrationUser.length > 0 &&
				integrationURL.length > 0 &&
				integrationPassword.length > 0
			);
		} else
			return (
				integrationName.length > 0 ||
				integrationUser.length > 0 ||
				integrationURL.length > 0 ||
				integrationPassword.length > 0
			);
	}

	function checkFinPasso1Error() {
		if (
			isErrorFin['companyName'] ||
			isErrorFin['agreementCode'] ||
			isErrorFin['companyIdentificationCode'] ||
			isErrorFin['segment']
		)
			return true;
		return false;
	}

	function checkFinPasso1(all: boolean) {
		if (all) {
			return (
				financialCompanyName.length > 0 &&
				financialAgreementCode.length > 0 &&
				financialCompanyIdentificationCode.length > 0 &&
				financialSegment.length > 0
			);
		} else {
			return (
				financialCompanyName.length > 0 ||
				financialAgreementCode.length > 0 ||
				financialCompanyIdentificationCode.length > 0 ||
				financialSegment.length > 0
			);
		}
	}

	function checkFinPasso2Error() {
		if (
			isErrorFin['bankCode'] ||
			isErrorFin['bankName'] ||
			isErrorFin['accountBankNumber']
		)
			return true;
		return false;
	}

	function checkFinPasso2(all: boolean) {
		if (all) {
			return (
				financialBankCode.length > 0 &&
				financialBankName.length > 0 &&
				financialAccountBankNumber.length > 0
			);
		} else {
			return (
				financialBankCode.length > 0 ||
				financialBankName.length > 0 ||
				financialAccountBankNumber.length > 0
			);
		}
	}

	function checkFinPasso3Error() {
		if (
			isErrorFin['layoutVersion'] ||
			isErrorFin['collectionMethod'] ||
			isErrorFin['serviceIdentification']
		)
			return true;
		return false;
	}

	function checkFinPasso3(all: boolean) {
		if (all) {
			return (
				financialLayoutVersion.length > 0 &&
				financialCollectionMethod.length > 0 &&
				financialServiceIdentification.length > 0
			);
		} else {
			return (
				financialLayoutVersion.length > 0 ||
				financialCollectionMethod.length > 0 ||
				financialServiceIdentification.length > 0
			);
		}
	}

	function checkFinPasso4Error() {
		if (
			isErrorFin['maxInstallment'] ||
			isErrorFin['maxInstallmentFree'] ||
			isErrorFin['installmentRate'] ||
			isErrorFin['financialType'] ||
			isErrorFin['conciliator']
		)
			return true;
		return false;
	}

	function checkFinPasso4(all: boolean) {
		if (all) {
			return (
				financialInstallmentRate.length > 0 &&
				financialMaxInstallment.length > 0 &&
				financialFinancialType.length > 0 &&
				financialConciliator.length > 0
			);
		} else {
			return (
				financialInstallmentRate.length > 0 ||
				financialMaxInstallment.length > 0 ||
				financialFinancialType.length > 0 ||
				financialConciliator.length > 0
			);
		}
	}

	const DadosRef = useRef<any>(null);
	const PersonalizacaoRef = useRef<any>(null);
	const CredenciaisRef = useRef<any>(null);
	const IntegracaoRef = useRef<any>(null);

	const DadosRefFin = useRef<any>(null);
	const BancRefFin = useRef<any>(null);
	const AjutesRefFin = useRef<any>(null);
	const ConfigRefFin = useRef<any>(null);

	const [hide, setHide] = useState(false);

	const listaSegmentos = [
		{ label: 'Selecione', value: '' },
		{ label: '1. Prefeitura', value: '1' },
		{ label: '2. Saneamento', value: '2' },
		{ label: '3. Energia Elétrica e Gás', value: '3' },
		{ label: '4. Telecomunicações', value: '4' },
		{ label: '5. Órgãos Governamentais', value: '5' },
		{ label: '6. Carnes ou demais Empresas', value: '6' },
		{ label: '7. Multas de trânsito', value: '7' },
		{ label: '9. Uso exclusivo do banco', value: '9' },
	];

	const tiposDeBaixa = [
		{
			label: '',
			value: '0',
		},
		{
			label: 'Trasanção',
			value: '1',
		},
		{
			label: 'Fatura',
			value: '2',
		},
	];

	async function clearImg() {
		setImages(undefined);
		setPreimg(undefined);
	}

	return (
		<Modal style={{ maxWidth: 849, opacity: hide ? 0 : 1 }}>
			<ModalHeader style={{ height: empresa ? 123 : 80 }}>
				<div className='header_container'>
					<HeaderTitle>{empresa ? empresa.name : 'Nova Empresa'}</HeaderTitle>

					{selectedHeader === 1 ? (
						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
								alignItems: 'center',
							}}>
							<HeaderButton
								onClick={(e) => {
									e.preventDefault();

									setModalVisible(false);
								}}
								style={{
									background: 'transparent',
									color: '#676767',
									marginRight: 8,
								}}>
								{empresa ? 'Fechar' : 'Cancelar'}
							</HeaderButton>
							<HeaderButton
								onClick={(e) => {
									e.preventDefault();
									EditEmpresa();
								}}
								disabled={loading}
								style={{ background: '#28A745', color: '#FFFFFF' }}>
								{!loading ? (
									'Salvar'
								) : (
									<img className='loading-roll' alt='loading' src={Loading} />
								)}
							</HeaderButton>
						</div>
					) : selectedHeader === 2 ? (
						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
								alignItems: 'center',
							}}>
							<HeaderButton
								onClick={(e) => {
									e.preventDefault();

									setModalVisible(false);
								}}
								style={{
									background: 'transparent',
									color: '#676767',
									marginRight: 8,
								}}>
								Fechar
							</HeaderButton>
							<HeaderButton
								onClick={(e) => {
									e.preventDefault();
									editFinance();
								}}
								disabled={loading}
								style={{ background: '#28A745', color: '#FFFFFF' }}>
								{!loading ? (
									'Salvar'
								) : (
									<img className='loading-roll' alt='loading' src={Loading} />
								)}
							</HeaderButton>
						</div>
					) : (
						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
								alignItems: 'center',
							}}>
							<HeaderButton
								onClick={(e) => {
									e.preventDefault();
									setModalVisible(false);
								}}
								style={{ background: '#28A745', color: '#FFFFFF' }}>
								Fechar
							</HeaderButton>
						</div>
					)}
				</div>

				<div
					style={{ display: empresa ? 'flex' : 'none' }}
					className='header_options'>
					{headerItems.map((hi) => (
						<HeaderItem
							onClick={(e) => {
								e.preventDefault();
								setSelectedHeader(hi.value);
							}}
							active={selectedHeader === hi.value}>
							{hi.label}
						</HeaderItem>
					))}
				</div>
			</ModalHeader>

			{selectedHeader === 1 ? (
				<ModalContainer active={empresa ? true : false}>
					<BodyMenu>
						<MenuContainer>
							<BodyItem>
								<div>
									<p style={{ fontSize: 11 }}>PASSO 1</p>
									<p style={{ fontSize: 18 }}>Dados da Empresa</p>
								</div>
								<button
									onClick={(e) => {
										e.preventDefault();
										if (DadosRef && DadosRef.current) {
											DadosRef.current.scrollIntoView({
												behavior: 'smooth',
												block: 'start',
											});
										}
									}}
									style={{
										background: checkPasso1Error()
											? '#F0F0F0'
											: checkPasso1(false)
											? '#18AA36'
											: '#F0F0F0',
										color: checkPasso1Error()
											? '#9F9F9F'
											: checkPasso1(false)
											? 'white'
											: '#9F9F9F',
									}}>
									{checkPasso1Error() ? (
										'1'
									) : //<img src={CloseWhite} alt='X' />
									checkPasso1(true) ? (
										<img src={DoneWhite} alt='check' />
									) : (
										'1'
									)}
									{checkPasso1Error() && <div className='icon_error'> !</div>}
								</button>
							</BodyItem>
							<BodyItem>
								<div>
									<p style={{ fontSize: 11 }}>PASSO 2</p>
									<p style={{ fontSize: 18 }}>Personalizações</p>
								</div>
								<button
									onClick={(e) => {
										e.preventDefault();
										if (PersonalizacaoRef && PersonalizacaoRef.current) {
											PersonalizacaoRef.current.scrollIntoView({
												behavior: 'smooth',
												block: 'start',
											});
										}
									}}
									style={{
										background: checkPasso2Error()
											? '#F0F0F0'
											: checkPasso2(false)
											? '#18AA36'
											: '#F0F0F0',
										color: checkPasso2Error()
											? '#9F9F9F'
											: checkPasso2(false)
											? 'white'
											: '#9F9F9F',
									}}>
									{checkPasso2Error() ? (
										'2' //<img src={CloseWhite} alt='X' />
									) : checkPasso2(true) ? (
										<img src={DoneWhite} alt='check' />
									) : (
										'2'
									)}
									{checkPasso2Error() && <div className='icon_error'> !</div>}
								</button>
							</BodyItem>
							<BodyItem>
								<div>
									<p style={{ fontSize: 11 }}>PASSO 3</p>
									<p style={{ fontSize: 18 }}>Credenciais • API</p>
								</div>
								<button
									onClick={(e) => {
										e.preventDefault();
										if (CredenciaisRef && CredenciaisRef.current) {
											CredenciaisRef.current.scrollIntoView({
												behavior: 'smooth',
												block: 'start',
											});
										}
									}}
									style={{
										background: checkPasso3Error()
											? '#F0F0F0'
											: checkPasso3(false)
											? '#18AA36'
											: '#F0F0F0',
										color: checkPasso3Error()
											? '#9F9F9F'
											: checkPasso3(false)
											? 'white'
											: '#9F9F9F',
									}}>
									{checkPasso3Error() ? (
										'3' //<img src={CloseWhite} alt='X' />
									) : checkPasso3(true) ? (
										<img src={DoneWhite} alt='check' />
									) : (
										'3'
									)}
									{checkPasso3Error() && <div className='icon_error'> !</div>}
								</button>
							</BodyItem>
							<BodyItem>
								<div>
									<p style={{ fontSize: 11 }}>PASSO 4 (OPCIONAL)</p>
									<p style={{ fontSize: 18 }}>Integração</p>
								</div>
								<button
									onClick={(e) => {
										e.preventDefault();
										if (IntegracaoRef && IntegracaoRef.current) {
											IntegracaoRef.current.scrollIntoView({
												behavior: 'smooth',
												block: 'start',
											});
										}
									}}
									style={{
										background: checkPasso4Error()
											? '#F0F0F0'
											: checkPasso4(false)
											? '#18AA36'
											: '#F0F0F0',
										color: checkPasso4Error()
											? '#9F9F9F'
											: checkPasso4(false)
											? 'white'
											: '#9F9F9F',
									}}>
									{checkPasso4Error() ? (
										'4' //<img src={CloseWhite} alt='X' />
									) : checkPasso4(true) ? (
										<img src={DoneWhite} alt='check' />
									) : (
										'4'
									)}
									{checkPasso4Error() && <div className='icon_error'> !</div>}
								</button>
							</BodyItem>
						</MenuContainer>
						<MenuFooter>
							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
									justifyContent: 'space-between',
								}}>
								<h1>{25 * stepsDone}%</h1>
								<p>{stepsDone} de 4 passos concluidos</p>
							</div>

							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
									justifyContent: 'space-between',
								}}>
								<div
									style={{
										flex: 1,
										background: stepsDone > 0 ? '#18AA36' : '#F0F0F0',
										height: 4,
										borderRadius: 4,
									}}
								/>
								<div
									style={{
										flex: 1,
										background: stepsDone > 1 ? '#18AA36' : '#F0F0F0',
										height: 4,
										borderRadius: 4,
										marginLeft: 5,
									}}
								/>
								<div
									style={{
										flex: 1,
										background: stepsDone > 2 ? '#18AA36' : '#F0F0F0',
										height: 4,
										borderRadius: 4,
										marginLeft: 5,
									}}
								/>
								<div
									style={{
										flex: 1,
										background: stepsDone > 3 ? '#18AA36' : '#F0F0F0',
										height: 4,
										borderRadius: 4,
										marginLeft: 5,
									}}
								/>
							</div>
						</MenuFooter>
					</BodyMenu>
					<FormBody
						active={empresa ? true : false}
						onSubmit={(e) => {
							e.preventDefault();
							EditEmpresa();
						}}
						showScroll={showScroll}>
						<div>
							<h1 ref={DadosRef}>
								{empresa
									? 'Dados da empresa'
									: 'Comece inserindo os dados básicos da empresa'}
							</h1>

							<InputContainer
								changeError={changeError}
								name='name'
								isError={isError.name}
								fieldLabel='NOME FANTASIA'
								inputName={nameFantasy}
								setInputField={setNameFantasy}
								placeholder={'Insira o nome da empresa'}
							/>

							<InputMaskContainer
								changeError={changeError}
								name='doc'
								isError={isError.doc}
								fieldLabel={'CNPJ'}
								inputName={CNPJ}
								setInputField={setCNPJ}
								placeholder={'00.000.000/0000-00'}
							/>
						</div>
						<div style={{ marginTop: 60 }}>
							<h1 ref={PersonalizacaoRef}>
								{' '}
								{empresa
									? 'Personalizações'
									: 'Agora, vamos personalizar a empresa no POS'}
							</h1>

							<SelectContainer
								changeError={changeError}
								name='category.id'
								isError={isError['category.id']}
								fieldLabel='CATEGORIA'
								inputName={category}
								setInputField={setCategory}
								options={categoriasList}
							/>

							<div className='inputGroup'>
								<p className='inputTitle'>LOGOTIPO</p>
								{images ? (
									<WithDropZone onDrop={onDrop} accept={'image/*'}>
										<ImageButton>
											<img className='logoImg' src={images.src} alt='' />
											<RemoveImg
												onClick={(e) => {
													e.stopPropagation();
													clearImg();
												}}>
												<img alt='x' src={CloseWhite} />
											</RemoveImg>
										</ImageButton>
									</WithDropZone>
								) : preimg ? (
									<WithDropZone onDrop={onDrop} accept={'image/*'}>
										<ImageButton>
											<img
												className='logoImg'
												src={`${process.env.REACT_APP_IMG_HOST}/upload/merchants/${preimg}`}
												alt='img'
											/>
											<RemoveImg
												onClick={(e) => {
													e.stopPropagation();
													clearImg();
												}}>
												<img alt='x' src={CloseWhite} />
											</RemoveImg>
										</ImageButton>
									</WithDropZone>
								) : (
									<ImageButton>
										<Dropzone onDrop={onDrop} accept={'image/*'} />
									</ImageButton>
								)}
							</div>

							<div style={{ marginTop: 50 }}>
								<InputContainer
									changeError={changeError}
									name='extras.primary_color'
									isError={isError['extras.primary_color']}
									fieldLabel='COR HEXADECIMAL'
									inputName={cor}
									setInputField={setColor}
									placeholder={'#FFFFFF'}
								/>
							</div>

							<InputContainer
								fieldLabel='RÓTULO DE LOGIN'
								changeError={changeError}
								name='extras.input_placeholder'
								inputName={rotuloLogin}
								isError={isError['extras.input_placeholder']}
								setInputField={setRotuloLogin}
								placeholder={'Insira o rótulo de login'}
							/>

							<InputContainer
								changeError={changeError}
								name='extras.input_validate'
								fieldLabel='ETIQUETA DE VALIDAÇÃO'
								inputName={etiqueta}
								setInputField={setEtiqueta}
								placeholder={'divido por ,'}
								isError={isError['extras.input_validate']}
							/>

							<SelectContainer
								changeError={changeError}
								name='localityType'
								fieldLabel='Tipo de empresa'
								inputName={localityType}
								setInputField={setLocalityType}
								options={localTypes}
								isError={isError.localityType}
							/>
						</div>
						<div style={{ marginTop: 60 }}>
							<h1 ref={CredenciaisRef}>
								{empresa
									? 'Credenciais da API'
									: 'Vamos configurar as credenciais da API'}
							</h1>

							<InputContainer
								changeError={changeError}
								name='credential.name'
								fieldLabel='NOME LOGIN'
								inputName={name}
								setInputField={setName}
								isError={isError['credential.name']}
								placeholder={'Insira o nome'}
							/>

							<InputContainer
								changeError={changeError}
								name='credential.username'
								fieldLabel='NOME DE USUÁRIO'
								inputName={user}
								setInputField={setUser}
								isError={isError['credential.username']}
								placeholder={'Insira o nome de usuário'}
							/>
							<InputContainer
								changeError={changeError}
								name='credential.uri'
								fieldLabel='URL'
								inputName={Url}
								setInputField={setUrl}
								isError={isError['credential.uri']}
								placeholder={'Insira a url'}
							/>
							<InputContainer
								changeError={changeError}
								name='credential.secret'
								fieldLabel='CHAVE SECRETA'
								inputName={secretKey}
								setInputField={setSecretKey}
								isError={isError['credential.secret']}
								placeholder={'Insira o chave'}
							/>
						</div>

						<div style={{ marginTop: 60 }}>
							<h1 ref={IntegracaoRef}>
								{empresa
									? 'Integração'
									: 'Por fim, vamos configurar a integração'}
							</h1>

							<InputContainer
								changeError={changeError}
								name='integrationName'
								fieldLabel='NOME'
								inputName={integrationName}
								setInputField={setIntegrationName}
								isError={isError.integrationName}
								placeholder={'Insira o nome'}
							/>
							<InputContainer
								changeError={changeError}
								name='integrationURL'
								fieldLabel='URL'
								inputName={integrationURL}
								setInputField={setIntegrationURL}
								isError={isError.integrationURL}
								placeholder={'Insira a url'}
							/>

							<InputContainer
								changeError={changeError}
								name='integrationUser'
								fieldLabel='NOME DE USUÁRIO'
								inputName={integrationUser}
								setInputField={setIntegrationUser}
								isError={isError.integrationUser}
								placeholder={'Insira o nome de usuário'}
							/>
							<InputContainer
								changeError={changeError}
								name='integrationPassword'
								fieldLabel='SENHA'
								inputName={integrationPassword}
								setInputField={setIntegrationPassword}
								isError={isError.integrationPassword}
								placeholder={'Insira a senha'}
							/>
						</div>
					</FormBody>
				</ModalContainer>
			) : selectedHeader === 2 ? (
				<ModalContainer active={empresa ? true : false}>
					<BodyMenu>
						<MenuContainer>
							<BodyItem>
								<div>
									<p style={{ fontSize: 11 }}>PASSO 1</p>
									<p style={{ fontSize: 18 }}>Dados da Empresa</p>
								</div>
								<button
									onClick={(e) => {
										e.preventDefault();
										if (DadosRefFin && DadosRefFin.current) {
											DadosRefFin.current.scrollIntoView({
												behavior: 'smooth',
												block: 'start',
											});
										}
									}}
									style={{
										background: checkFinPasso1Error()
											? '#F0F0F0'
											: checkFinPasso1(false)
											? '#18AA36'
											: '#F0F0F0',
										color: checkFinPasso1Error()
											? '#9F9F9F'
											: checkFinPasso1(false)
											? 'white'
											: '#9F9F9F',
									}}>
									{checkFinPasso1Error() ? (
										'1' //<img src={CloseWhite} alt='X' />
									) : checkFinPasso1(true) ? (
										<img src={DoneWhite} alt='check' />
									) : (
										'1'
									)}
									{checkFinPasso1Error() && (
										<div className='icon_error'> !</div>
									)}
								</button>
							</BodyItem>
							<BodyItem>
								<div>
									<p style={{ fontSize: 11 }}>PASSO 2</p>
									<p style={{ fontSize: 18 }}>Dados Bancarios</p>
								</div>
								<button
									onClick={(e) => {
										e.preventDefault();
										if (BancRefFin && BancRefFin.current) {
											BancRefFin.current.scrollIntoView({
												behavior: 'smooth',
												block: 'start',
											});
										}
									}}
									style={{
										background: checkFinPasso2Error()
											? '#F0F0F0'
											: checkFinPasso2(false)
											? '#18AA36'
											: '#F0F0F0',
										color: checkFinPasso2Error()
											? '#9F9F9F'
											: checkFinPasso2(false)
											? 'white'
											: '#9F9F9F',
									}}>
									{checkFinPasso2Error() ? (
										'2' //<img src={CloseWhite} alt='X' />
									) : checkFinPasso2(true) ? (
										<img src={DoneWhite} alt='check' />
									) : (
										'2'
									)}
									{checkFinPasso2Error() && (
										<div className='icon_error'> !</div>
									)}
								</button>
							</BodyItem>
							<BodyItem>
								<div>
									<p style={{ fontSize: 11 }}>PASSO 3</p>
									<p style={{ fontSize: 18 }}>Ajustes</p>
								</div>
								<button
									onClick={(e) => {
										e.preventDefault();
										if (AjutesRefFin && AjutesRefFin.current) {
											AjutesRefFin.current.scrollIntoView({
												behavior: 'smooth',
												block: 'start',
											});
										}
									}}
									style={{
										background: checkFinPasso3Error()
											? '#F0F0F0'
											: checkFinPasso3(false)
											? '#18AA36'
											: '#F0F0F0',
										color: checkFinPasso3Error()
											? '#9F9F9F'
											: checkFinPasso3(false)
											? 'white'
											: '#9F9F9F',
									}}>
									{checkFinPasso3Error() ? (
										'3' //	<img src={CloseWhite} alt='X' />
									) : checkFinPasso3(true) ? (
										<img src={DoneWhite} alt='check' />
									) : (
										'3'
									)}
									{checkFinPasso3Error() && (
										<div className='icon_error'> !</div>
									)}
								</button>
							</BodyItem>
							<BodyItem>
								<div>
									<p style={{ fontSize: 11 }}>PASSO 4 (OPCIONAL)</p>
									<p style={{ fontSize: 18 }}>Configurações Financeiras</p>
								</div>
								<button
									onClick={(e) => {
										e.preventDefault();
										if (ConfigRefFin && ConfigRefFin.current) {
											ConfigRefFin.current.scrollIntoView({
												behavior: 'smooth',
												block: 'start',
											});
										}
									}}
									style={{
										background: checkFinPasso4Error()
											? '#F0F0F0'
											: checkFinPasso4(false)
											? '#18AA36'
											: '#F0F0F0',
										color: checkFinPasso4Error()
											? '#9F9F9F'
											: checkFinPasso4(false)
											? 'white'
											: '#9F9F9F',
									}}>
									{checkFinPasso4Error() ? (
										'4' //<img src={CloseWhite} alt='X' />
									) : checkFinPasso4(true) ? (
										<img src={DoneWhite} alt='check' />
									) : (
										'4'
									)}
									{checkFinPasso4Error() && (
										<div className='icon_error'> !</div>
									)}
								</button>
							</BodyItem>
						</MenuContainer>
						<MenuFooter>
							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
									justifyContent: 'space-between',
								}}>
								<h1>{25 * stepsDoneFin}%</h1>
								<p>{stepsDoneFin} de 4 passos concluidos</p>
							</div>

							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
									justifyContent: 'space-between',
								}}>
								<div
									style={{
										flex: 1,
										background: stepsDoneFin > 0 ? '#18AA36' : '#F0F0F0',
										height: 4,
										borderRadius: 4,
									}}
								/>
								<div
									style={{
										flex: 1,
										background: stepsDoneFin > 1 ? '#18AA36' : '#F0F0F0',
										height: 4,
										borderRadius: 4,
										marginLeft: 5,
									}}
								/>
								<div
									style={{
										flex: 1,
										background: stepsDoneFin > 2 ? '#18AA36' : '#F0F0F0',
										height: 4,
										borderRadius: 4,
										marginLeft: 5,
									}}
								/>
								<div
									style={{
										flex: 1,
										background: stepsDoneFin > 3 ? '#18AA36' : '#F0F0F0',
										height: 4,
										borderRadius: 4,
										marginLeft: 5,
									}}
								/>
							</div>
						</MenuFooter>
					</BodyMenu>
					<FormBody
						active={empresa ? true : false}
						onSubmit={(e) => {
							e.preventDefault();
							EditEmpresa();
						}}
						showScroll={showScroll}>
						<div>
							<h1 ref={DadosRefFin}>Dados da empresa</h1>

							<InputContainer
								changeError={changeErrorFin}
								name='companyName'
								fieldLabel='NOME FANTASIA'
								inputName={financialCompanyName}
								setInputField={setFinancialCompanyName}
								isError={isErrorFin.companyName}
								placeholder={'Insira o nome da empresa'}
							/>

							<div style={{ display: 'flex', justifyContent: 'space-between' }}>
								<div style={{ maxWidth: '47%' }}>
									<InputContainer
										changeError={changeErrorFin}
										name='agreementCode'
										fieldLabel='Código de Convênio'
										inputName={financialAgreementCode}
										setInputField={setFinancialAgreementCode}
										isError={isErrorFin.agreementCode}
										placeholder={'Insira o código de convênio'}
									/>
								</div>
								<div style={{ maxWidth: '47%' }}>
									<InputContainer
										changeError={changeErrorFin}
										name='companyIdentificationCode'
										fieldLabel='Identificador'
										inputName={financialCompanyIdentificationCode}
										setInputField={setFinancialCompanyIdentificationCode}
										isError={isErrorFin.companyIdentificationCode}
										placeholder={'Insira o identificador'}
									/>
								</div>
							</div>

							<SelectContainer
								changeError={changeErrorFin}
								name='segment'
								fieldLabel='Segmento'
								inputName={financialSegment}
								setInputField={setFinancialSegment}
								options={listaSegmentos}
								isError={isErrorFin.segment}
							/>
						</div>
						<div style={{ marginTop: 60 }}>
							<h1 ref={BancRefFin}>Dados Bancários</h1>

							<div style={{ display: 'flex', justifyContent: 'space-between' }}>
								<div style={{ maxWidth: '47%' }}>
									<InputContainer
										changeError={changeErrorFin}
										name='bankCode'
										fieldLabel='Código do Banco'
										inputName={financialBankCode}
										setInputField={setFinancialBankCode}
										isError={isErrorFin.bankCode}
										placeholder={'Insira o código bancário'}
									/>
								</div>
								<div style={{ maxWidth: '47%' }}>
									<InputContainer
										changeError={changeErrorFin}
										name='bankName'
										fieldLabel='Nome do Banco'
										inputName={financialBankName}
										setInputField={setFinancialBankName}
										isError={isErrorFin.bankName}
										placeholder={'Insira o nome do banco'}
									/>
								</div>
							</div>

							<InputContainer
								changeError={changeErrorFin}
								name='accountBankNumber'
								fieldLabel='Agência Bancária'
								inputName={financialAccountBankNumber}
								setInputField={setFinancialAccountBankNumber}
								isError={isErrorFin.accountBankNumber}
								placeholder={'Insira a agência bancária'}
							/>
						</div>
						<div style={{ marginTop: 60 }}>
							<h1 ref={AjutesRefFin}>Ajustes</h1>

							<div style={{ display: 'flex', justifyContent: 'space-between' }}>
								<div style={{ maxWidth: '47%' }}>
									<InputContainer
										changeError={changeErrorFin}
										name='accountBankNumber'
										fieldLabel='Versão de Layout'
										inputName={financialLayoutVersion}
										setInputField={setFinancialLayoutVersion}
										isError={isErrorFin.layoutVersion}
										placeholder={'Insira a versão do Layout'}
									/>
								</div>
								<div style={{ maxWidth: '47%' }}>
									<InputContainer
										changeError={changeErrorFin}
										name='collectionMethod'
										fieldLabel='Forma de Arrecadação'
										inputName={financialCollectionMethod}
										setInputField={setFinancialCollectionMethod}
										isError={isErrorFin.collectionMethod}
										placeholder={'Insira a forma de arrecadação'}
									/>
								</div>
							</div>

							<InputContainer
								changeError={changeErrorFin}
								name='collectionMethod'
								fieldLabel='Identificação de Serviço'
								inputName={financialServiceIdentification}
								setInputField={setFinancialServiceIdentification}
								isError={isErrorFin.serviceIdentification}
								placeholder={'Insira a identificação de serviço'}
							/>
						</div>

						<div style={{ marginTop: 60 }}>
							<h1 ref={ConfigRefFin}>Configurações Financeiras</h1>

							<div style={{ display: 'flex', justifyContent: 'space-between' }}>
								<div style={{ maxWidth: '47%' }}>
									<InputContainer
										changeError={changeErrorFin}
										name='maxInstallment'
										fieldLabel='Máximo de parcelas'
										inputName={financialMaxInstallment}
										setInputField={setFinancialMaxInstallment}
										isError={isErrorFin.maxInstallment}
										type='number'
										placeholder={'Insira o máximo de parcelas'}
									/>
								</div>
								<div style={{ maxWidth: '47%' }}>
									<InputContainer
										changeError={changeErrorFin}
										name='maxInstallmentFree'
										fieldLabel='Máximo de parcelas sem juros'
										inputName={financialmaxInstallmentFree}
										setInputField={setFinancialmaxInstallmentFree}
										isError={isErrorFin.maxInstallmentFree}
										type='number'
										placeholder={'Insira o máximo de parcelas grátis'}
									/>
								</div>
							</div>

							<InputContainerPercentage
								changeError={changeErrorFin}
								name='installmentRate'
								fieldLabel='Juros de Parcelamento'
								inputName={financialInstallmentRate}
								setInputField={setFinancialInstallmentRate}
								isError={isErrorFin.installmentRate}
								type='number'
								placeholder={'Insira o valor dos juros'}
							/>

							<div style={{ display: 'flex', justifyContent: 'space-between' }}>
								<div style={{ width: '47%' }}>
									<SelectContainer
										changeError={changeErrorFin}
										name='financialType'
										fieldLabel='Tipo de Baixa Financeira'
										inputName={financialFinancialType}
										setInputField={setFinancialFinancialType}
										options={tiposDeBaixa}
										isError={isErrorFin.financialType}
									/>
								</div>
								<div style={{ maxWidth: '47%' }}>
									<InputContainer
										changeError={changeErrorFin}
										name='conciliator'
										fieldLabel='Conciliador'
										inputName={financialConciliator}
										setInputField={setFinancialConciliator}
										isError={isErrorFin.conciliator}
										placeholder={'Insira o conciliador'}
									/>
								</div>
							</div>
						</div>
					</FormBody>
				</ModalContainer>
			) : selectedHeader === 3 ? (
				<MetodosMenu setHide={setHide} empresa={empresa} />
			) : (
				<ContratosMenu setHide={setHide} empresa={empresa} />
			)}

			{/*<ModalBody>
				<BodyMenu>
					<BodyItem>
						<div>
							<p style={{ fontSize: 11 }}>PASSO 1</p>
							<p style={{ fontSize: 18 }}>Dados da Empresa</p>
						</div>
						<button>1</button>
					</BodyItem>
				</BodyMenu>
			</ModalBody>*/}
		</Modal>
	);
}

function InputFocus({
	field,
	setField,
}: {
	field: string;
	setField(s: string): void;
}) {
	const inputRef = useRef<any>(null);
	return (
		<div
			onClick={() => {
				if (inputRef && inputRef.current) {
					inputRef.current.focus();
				}
			}}
			className='inputContainer'>
			{
				<input
					ref={inputRef}
					type='text'
					value={field}
					onChange={(e) => {
						setField(e.target.value);
					}}
				/>
			}
			{field.length > 0 && (
				<img
					onClick={() => {
						setField('');
					}}
					className='clearIcon'
					src={ClearIcon}
					alt='remove'
				/>
			)}
		</div>
	);
}

function MetodosMenu({
	empresa,
	setHide,
}: {
	empresa: Empresa | undefined;
	setHide(b: boolean): void;
}) {
	const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([]);

	const [modalMetodo, setModalMetodo] = useState(false);
	async function getEmpresaPayment() {
		const token = sessionStorage.getItem('@bill-token');

		const config = {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		};

		try {
			const response = await api.get(
				`admin/api/payment-method?merchant=${empresa?.id}`,
				config
			);
			console.log(response.data.payment_methods);
			if (response.data.payment_methods) {
				setPaymentMethods(response.data.payment_methods);
			}
		} catch (error: any) {
			console.error(error);
			if (error.response && error.response.status === 401) {
				history.push('/login');
			}
		}
	}

	function UpdateMetodo(MetodoUpdate: PaymentMethod) {
		//setEmpresas([...Empresas]);
		const lista = [...paymentMethods];
		const findIndex = lista.findIndex(
			(l) => l.id === (MetodoUpdate.id as number)
		);
		//console.log(findIndex)
		console.log(lista);
		if (findIndex !== -1) {
			lista[findIndex] = MetodoUpdate;
		} else {
			lista.push(MetodoUpdate);
		}

		setPaymentMethods([...lista]);
	}

	function DeletarMetodo(MetodoId: number) {
		//setEmpresas([...Empresas]);
		const lista = [...paymentMethods];
		const findIndex = lista.findIndex((l) => l.id === MetodoId);
		//console.log(findIndex)
		console.log(lista);
		if (findIndex !== -1) {
			lista.splice(findIndex, 1);
		}

		setPaymentMethods([...lista]);
	}

	const history = useHistory();
	useEffect(() => {
		getEmpresaPayment();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<ModalContainer style={{ flexDirection: 'column' }} active={true}>
			<NewModal
				show={modalMetodo}
				onHide={() => {
					setModalMetodo(false);
					setHide(false);
				}}>
				<NewModal.Body>
					<ModalNovoMetodo
						empresa={empresa}
						setModalVisible={setModalMetodo}
						setPreModalVisible={setHide}
						UpdateMetodo={UpdateMetodo}
					/>
				</NewModal.Body>
			</NewModal>
			<TableContainer style={{ width: '100%' }}>
				<Table>
					<THead>
						<tr>
							<Th>Nome</Th>
							<Th>Taxa</Th>
							<Th>Tipo de Taxa</Th>
							<Th>Identificador</Th>
							<Th>Data do Crédito</Th>
							<Th></Th>
						</tr>
					</THead>
					<TBody>
						{paymentMethods.map((pm) => (
							<MetodoTR
								UpdateMetodo={UpdateMetodo}
								empresa={empresa}
								setHide={setHide}
								payment={pm}
								DeletarMetodo={DeletarMetodo}
							/>
						))}
					</TBody>
				</Table>
			</TableContainer>
			<div
				onClick={(e) => {
					setModalMetodo(true);
					setHide(true);
				}}
				className='label_abrir_novo_metodo'>
				Adicionar método de pagamento...
			</div>
		</ModalContainer>
	);
}

function MetodoTR({
	payment,
	setHide,
	empresa,
	UpdateMetodo,
	DeletarMetodo,
}: {
	payment: PaymentMethod;
	setHide(b: boolean): void;
	empresa: Empresa | undefined;
	UpdateMetodo(m: PaymentMethod): void;
	DeletarMetodo(id: number): void;
}) {
	function StringToCurrency(value: string) {
		return parseFloat(value).toLocaleString('pt-BR', {
			minimumFractionDigits: 2,
			style: 'currency',
			currency: 'BRL',
		});
	}
	function rateType() {
		if (payment.rateType === 'ABSOLUT') {
			return 'Absoluto';
		} else {
			return 'Percentual';
		}
	}
	const [modalMetodo, setModalMetodo] = useState(false);

	async function RemoverMetodo() {
		const token = sessionStorage.getItem('@bill-token');

		const config = {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		};

		try {
			const response = await api.delete(
				`/admin/api/payment-method/${payment.id}`,
				config
			);
			if (response.data.success) {
				DeletarMetodo(payment.id);
			}
		} catch (error: any) {
			console.log(error);
			if (error.response && error.response.status === 401) {
				history.push('/login');
			}
		}
	}
	const history = useHistory();

	const [deleteModal, setDeleteModal] = useState(false);
	return (
		<BodyTR>
			<NewModal
				show={modalMetodo}
				onHide={() => {
					setModalMetodo(false);
					setHide(false);
				}}>
				<NewModal.Body>
					<ModalNovoMetodo
						setModalVisible={setModalMetodo}
						setPreModalVisible={setHide}
						metodo={payment}
						empresa={empresa}
						UpdateMetodo={UpdateMetodo}
					/>
				</NewModal.Body>
			</NewModal>
			<NewModal show={deleteModal} onHide={() => setDeleteModal(false)}>
				<NewModal.Body>
					<DeleteModal
						activeFunction={RemoverMetodo}
						labelStart='Tem certeza que deseja deletar o método '
						setModalVisible={setDeleteModal}
						focusItem={payment.name}
						labelEnd={'? Essa ação não poderá ser desfeita.'}
						title={'Deletar Método?'}
					/>
				</NewModal.Body>
			</NewModal>
			<td>{payment.name}</td>
			<td>{StringToCurrency(payment.rate)}</td>
			<td>{rateType()}</td>
			<td>{payment.code}</td>
			<td>{payment.creditDate}</td>
			<td>
				<span style={{ float: 'right' }}>
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							marginRight: 16,
						}}>
						<OverlayTrigger
							placement='bottom'
							overlay={
								<Tooltip id='tool-tip'>
									<span style={{ textTransform: 'capitalize' }}>
										{'Editar Método'}
									</span>
								</Tooltip>
							}>
							<button
								type='button'
								onClick={(e) => {
									e.preventDefault();
									e.stopPropagation();
									//setShowDropdown(!showDropdown);
									//setModalVisible(true);
									setModalMetodo(true);
									setHide(true);
								}}
								style={{ marginRight: 8 }}
								className='empresa_icon_container'>
								<img className='empresa_icon_edit' src={EditGray} alt='e' />
							</button>
						</OverlayTrigger>

						<OverlayTrigger
							placement='bottom'
							overlay={
								<Tooltip id='tool-tip'>
									<span style={{ textTransform: 'capitalize' }}>
										{'Remover Método'}
									</span>
								</Tooltip>
							}>
							<button
								onClick={(e) => {
									e.preventDefault();
									e.stopPropagation();
									setDeleteModal(true);
									//();
								}}
								className='empresa_icon_container'>
								<img src={RemoveGray} alt='e' />
							</button>
						</OverlayTrigger>
					</div>
				</span>
			</td>
		</BodyTR>
	);
}

function ContratosMenu({
	empresa,
	setHide,
}: {
	empresa: Empresa | undefined;
	setHide(b: boolean): void;
}) {
	const history = useHistory();

	const [contratos, setContratos] = useState<Contrato[]>([]);

	const [modalContrato, setModalContrato] = useState(false);
	const [listaParceiros, setListaParceiros] = useState<Parceiro[]>([]);

	async function getParceiros() {
		const token = sessionStorage.getItem('@bill-token');

		const config = {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		};
		try {
			const response = await api.get('/admin/partner/search', config);
			if (response.data) {
				setListaParceiros(response.data);
			}
		} catch (error: any) {
			console.error(error);
			if (error.response && error.response.status === 401) {
				history.push('/login');
			}
		}
	}

	useEffect(() => {
		getParceiros();
	}, []);

	async function getContratos() {
		const token = sessionStorage.getItem('@bill-token');

		const config = {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		};
		try {
			const response = await api.get(
				`/admin/api/contract?merchant=${empresa?.id}`,
				config
			);
			if (response.data.contracts) {
				setContratos(response.data.contracts);
			}
		} catch (error: any) {
			console.error(error);
			if (error.response && error.response.status === 401) {
				history.push('/login');
			}
		}
	}
	useEffect(() => {
		getContratos();
	}, []);

	function updateContrato(contrato: Contrato) {
		const lista = [...contratos];
		const findIndex = lista.findIndex((l) => l.id === (contrato.id as number));
		//console.log(findIndex)
		console.log(lista);
		if (findIndex !== -1) {
			lista[findIndex] = contrato;
		} else {
			lista.push(contrato);
		}

		setContratos([...lista]);
	}

	function deletarContrato(contratoId: number) {
		//setEmpresas([...Empresas]);
		const lista = [...contratos];
		const findIndex = lista.findIndex((l) => l.id === contratoId);
		//console.log(findIndex)
		console.log(lista);
		if (findIndex !== -1) {
			lista.splice(findIndex, 1);
		}

		setContratos([...lista]);
	}

	return (
		<ModalContainer
			style={{ flexDirection: 'column' }}
			active={empresa ? true : false}>
			<NewModal
				show={modalContrato}
				onHide={() => {
					setModalContrato(false);
					setHide(false);
				}}>
				<NewModal.Body>
					<ModalNovoContrato
						setModalVisible={setModalContrato}
						setPreModalVisible={setHide}
						empresa={empresa}
						updateContrato={updateContrato}
						deletarContrato={deletarContrato}
						listaParceiros={listaParceiros}
					/>
				</NewModal.Body>
			</NewModal>
			<TableContainer style={{ width: '100%' }}>
				<Table>
					<THead>
						<tr>
							<Th>Provedor</Th>
							<Th>Parceiro</Th>
							<Th>Situação</Th>
							<Th>Bônus</Th>
							<Th></Th>
						</tr>
					</THead>
					<TBody>
						{contratos.map((c) => (
							<ContratoTR
								updateContrato={updateContrato}
								deletarContrato={deletarContrato}
								empresa={empresa}
								setHide={setHide}
								contrato={c}
								listaParceiros={listaParceiros}
							/>
						))}
					</TBody>
				</Table>
			</TableContainer>
			<div
				onClick={(e) => {
					setModalContrato(true);
					setHide(true);
				}}
				className='label_abrir_novo_metodo'>
				Adicionar contrato...
			</div>
		</ModalContainer>
	);
}

function ContratoTR({
	contrato,
	setHide,
	empresa,
	updateContrato,
	deletarContrato,
	listaParceiros,
}: {
	contrato: Contrato;
	setHide(b: boolean): void;
	empresa: Empresa | undefined;
	updateContrato(u: Contrato): void;
	deletarContrato(c: number): void;
	listaParceiros: Parceiro[];
}) {
	const [modalContrato, setModalContrato] = useState(false);
	const history = useHistory();

	async function removerContrato() {
		const token = sessionStorage.getItem('@bill-token');

		const config = {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		};

		try {
			const response = await api.delete(
				`admin/api/contract/${contrato.id}`,
				config
			);
			if (response.data.success) {
				deletarContrato(contrato.id);
			}
		} catch (error: any) {
			console.log(error);
			if (error.response && error.response.status === 401) {
				history.push('/login');
			}
		}
	}

	const [deleteModal, setDeleteModal] = useState(false);
	return (
		<BodyTR>
			<NewModal
				show={modalContrato}
				onHide={() => {
					setModalContrato(false);
					setHide(false);
				}}>
				<NewModal.Body>
					<ModalNovoContrato
						setModalVisible={setModalContrato}
						setPreModalVisible={setHide}
						contratoEscolhido={contrato}
						empresa={empresa}
						updateContrato={updateContrato}
						deletarContrato={deletarContrato}
						listaParceiros={listaParceiros}
					/>
				</NewModal.Body>
			</NewModal>
			<NewModal show={deleteModal} onHide={() => setDeleteModal(false)}>
				<NewModal.Body>
					<DeleteModal
						activeFunction={removerContrato}
						labelStart='Tem certeza que deseja deletar esse contrato? Essa ação não poderá ser desfeita.'
						setModalVisible={setDeleteModal}
						title={'Deletar Contrato?'}
					/>
				</NewModal.Body>
			</NewModal>
			<td>{contrato.provider}</td>
			<td>{contrato.partner.name}</td>
			<td>{contrato.status === 1 ? 'Ativo' : 'Inativo'}</td>
			<td>{contrato.partnerBonus || '-'}</td>
			<td>
				<span style={{ float: 'right' }}>
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							marginRight: 16,
						}}>
						<OverlayTrigger
							placement='bottom'
							overlay={
								<Tooltip id='tool-tip'>
									<span style={{ textTransform: 'capitalize' }}>
										{'Editar contrato'}
									</span>
								</Tooltip>
							}>
							<button
								type='button'
								onClick={(e) => {
									e.preventDefault();
									e.stopPropagation();
									//setShowDropdown(!showDropdown);
									//setModalVisible(true);
									setModalContrato(true);
									setHide(true);
								}}
								style={{ marginRight: 8 }}
								className='empresa_icon_container'>
								<img className='empresa_icon_edit' src={EditGray} alt='e' />
							</button>
						</OverlayTrigger>

						<OverlayTrigger
							placement='bottom'
							overlay={
								<Tooltip id='tool-tip'>
									<span style={{ textTransform: 'capitalize' }}>
										{'Remover contrato'}
									</span>
								</Tooltip>
							}>
							<button
								onClick={(e) => {
									e.preventDefault();
									e.stopPropagation();
									//removerContrato();
									setDeleteModal(true);
								}}
								className='empresa_icon_container'>
								<img src={RemoveGray} alt='e' />
							</button>
						</OverlayTrigger>
					</div>
				</span>
			</td>
		</BodyTR>
	);
}
