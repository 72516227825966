export function formatarDoc(s: string) {
	let doc = '';
	if (s.length === 11) {
		doc = s;
		doc = doc.replace(/\D/g, ''); //Remodoce tudo o que não é dígito
		doc = doc.replace(/(\d{3})(\d)/, '$1.$2'); //Coloca um ponto entre o terceiro e o quarto dígitos
		doc = doc.replace(/(\d{3})(\d)/, '$1.$2'); //Coloca um ponto entre o terceiro e o quarto dígitos
		//de nodoco (para o segundo bloco de números)
		doc = doc.replace(/(\d{3})(\d{1,2})$/, '$1-$2'); //Coloca um hífen entre o terceiro e o quarto dígitos
		return doc;
	} else if (s.length === 14) {
		doc = s;
		doc = doc.replace(/\D/g, ''); //Remodoce tudo o que não é dígito
		doc = doc.replace(/^(\d{2})(\d)/, '$1.$2'); //Coloca ponto entre o segundo e o terceiro dígitos
		doc = doc.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3'); //Coloca ponto entre o quinto e o sexto dígitos
		doc = doc.replace(/\.(\d{3})(\d)/, '.$1/$2'); //Coloca uma barra entre o oitadoco e o nono dígitos
		doc = doc.replace(/(\d{4})(\d)/, '$1-$2'); //Coloca um hífen depois do bloco de quatro dígitos
		return doc;
	}
}
