/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
//import cookie from 'react-cookies';
import { isAuthenticated } from '../utils/auth';

//const getCookie = cookie.load('OrangeLabs');

const PrivateRoute = ({ component, ...rest }: any): any => {
	const routeComponent = (props: any): any =>
		isAuthenticated() ? (
			React.createElement(component, props)
		) : (
			<Redirect to={{ pathname: '/login' }} />
		);
	
	return <Route {...rest} render={routeComponent} />;
};

export default PrivateRoute;
