/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useRef, useState } from 'react';

import ClearIcon from '../../assets/images/clearIcon.svg';
import ArrowDark from '../../assets/images/arrow_dropdown-dark.svg';
import SearchGray from '../../assets/images/search-gray.svg';

import styled from 'styled-components';
import debounce from 'lodash.debounce';
import { Dropdown } from 'react-bootstrap';
import { useEffect } from 'react';
import DropdownMenu from 'react-bootstrap/esm/DropdownMenu';
const InputCover = styled.div`
	margin-top: 24px;
	.mb-8 {
		margin-bottom: 8px;
	}
`;

const ModalLabel = styled.p`
	margin: 0;
	text-align: left;
	font: normal normal 500 11px/15px Roboto;
	letter-spacing: 0px;
	color: #4b4b4b;
	text-transform: uppercase;
`;

const ModalLabelError = styled.p`
	margin: 0;
	text-align: left;
	font: normal normal 500 11px/15px Roboto;
	letter-spacing: 0px;
	color: #e8453e;
	text-transform: uppercase;
`;

interface ModalInputContainerProps {
	shadowColor?: string;
	borderColor?: string;
	disabled?: boolean;
}
const ModalInputContainer = styled.div<ModalInputContainerProps>`
	background: ${(props) => (props.disabled ? '#F0F0F0' : '#ffffff')} 0% 0%
		no-repeat padding-box;
	border: 1px solid
		${(props) => (props.borderColor ? props.borderColor : '#9f9f9f')};
	border-radius: 16px;
	display: flex;
	flex-direction: row;
	height: 50px;
	box-shadow: 0px 0px 10px 1px
		${(props) => (props.shadowColor ? props.shadowColor : 'transparent')};
	-webkit-box-shadow: 0px 0px 10px 1px
		${(props) => (props.shadowColor ? props.shadowColor : 'transparent')};
	-moz-box-shadow: 0px 0px 10px 1px
		${(props) => (props.shadowColor ? props.shadowColor : 'transparent')};

	.dropdown {
		flex: 1;
		.dropdown-none {
			//
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: flex-start;
			padding-right: 8px !important;
		}

		.dropdown-item {
			height: 27px;
		}
	}
	//overflow: hidden;
	.modal_input_icon_container {
		height: 100%;
		padding-right: 16px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.modal_input_icon {
		width: 16px;
		height: 16px;
		border-radius: 8px;
		align-self: center;
		cursor: pointer;
	}

	.modal_select_input {
		border: transparent;
		border-radius: 16px;
		flex: 1;
		overflow: hidden;
		padding-left: 16px;
		text-align: left;
		font: normal normal 500 14px/19px Roboto;
		letter-spacing: 0px;
		color: #222222;

		background-color: transparent;

		::-webkit-input-placeholder {
			/* Edge */
			text-align: left;
			font: normal normal 300 14px/19px Roboto;
			letter-spacing: 0px;
			color: #9f9f9f;
		}

		:-ms-input-placeholder {
			/* Internet Explorer 10-11 */
			text-align: left;
			font: normal normal 300 14px/19px Roboto;
			letter-spacing: 0px;
			color: #9f9f9f;
		}

		::placeholder {
			text-align: left;
			font: normal normal 300 14px/19px Roboto;
			letter-spacing: 0px;
			color: #9f9f9f;
		}
	}

	.modal_input {
		border: transparent;
		border-radius: 16px;
		flex: 1;
		overflow: hidden;
		padding-left: 16px;
		text-align: left;
		font: normal normal 500 14px/19px Roboto;
		letter-spacing: 0px;
		color: #222222;

		background-color: transparent;

		::-webkit-input-placeholder {
			/* Edge */
			text-align: left;
			font: normal normal 300 14px/19px Roboto;
			letter-spacing: 0px;
			color: #9f9f9f;
		}

		:-ms-input-placeholder {
			/* Internet Explorer 10-11 */
			text-align: left;
			font: normal normal 300 14px/19px Roboto;
			letter-spacing: 0px;
			color: #9f9f9f;
		}

		::placeholder {
			text-align: left;
			font: normal normal 300 14px/19px Roboto;
			letter-spacing: 0px;
			color: #9f9f9f;
		}
	}

	.percentage_container {
		background: #09aa36 0% 0% no-repeat padding-box;
		border: 1px solid #09aa36;
		// border-radius: 0px 16px 16px 0px;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		text-align: center;
		width: 50px;
		height: 50px;
		font: normal normal 500 18px/24px Roboto;
		letter-spacing: 0px;
		color: #ffffff;
	}

	input[type='number']::-webkit-inner-spin-button {
		-webkit-appearance: none;
	}
	input[type='number'] {
		-moz-appearance: textfield;
		appearance: textfield;
	}
`;

interface HeaderSearchContainerProps {
	active: boolean;
}

export const HeaderSearchContainer = styled.form<HeaderSearchContainerProps>`
	height: 40px;
	display: flex;
	flex-direction: row;

	background: #f0f0f0 0% 0% no-repeat padding-box;
	border-radius: 20px;
	border: 0;
	opacity: 1;
	align-items: center;
	min-width: 230px;

	margin-right: 24px;

	input {
		//display: ${(props) => !props.active && 'none'};
		transition: all 1s;
		width: ${(props) => (!props.active ? 0 : '230px')};
		// margin-left: ${(props) => (!props.active ? 0 : '16px')};
		padding: ${(props) => (!props.active ? 0 : '11px')};
	}

	button {
		cursor: pointer;
		padding: 11px;
		background: transparent;
		border: 0;
		display: flex;
		align-items: center;
	}

	svg {
		color: #09aa36;
		width: 16px;
		height: 16px;
	}
`;

export const HeaderSearchInput = styled.input`
	text-align: left;
	font: normal normal normal 14px/19px Roboto;
	letter-spacing: 0px;
	color: #9f9f9f;
	opacity: 1;
	flex: 1;
	border: 0;
	background-color: transparent;
	//padding:11px;
`;

interface SearchSelectorProps {
	active?: boolean;
	pressed?: boolean;
}
export const SearchSelector = styled.button<SearchSelectorProps>`
	//height: 100%;
	flex: 1;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding-left: 16px;
	padding-right: 16px;
	margin-right: 10px;

	background: ${(props) => (props.active ? '#18AA36' : '#F0F0F0')} 0% 0%
		no-repeat padding-box;
	border-radius: 20px;

	text-align: center;
	font: normal normal 500 12px/16px Roboto;
	letter-spacing: 0px;
	color: ${(props) => (props.active ? '#FFFFFF' : '#676767')};
	//text-transform: uppercase;
	cursor: pointer;
	border: ${(props) =>
		props.pressed ? '1px solid #EBEBEB' : '1px solid transparent'};

	box-shadow: ${(props) => (props.pressed ? 'inset 0px 3px 6px #00000029' : 0)};
`;

export function InputContainer({
	isError,
	inputName,
	setInputField,
	fieldLabel,
	fieldLabelError,
	placeholder,
	disabled,
	type,
	name,
	changeError,
}: {
	isError?: boolean;
	inputName: string;
	setInputField(s: string): void;
	fieldLabel: string;
	fieldLabelError?: string;
	placeholder?: string;
	disabled?: boolean;
	type?: string;
	changeError?(s: string): void;
	name?: string;
}) {
	const inputRef = useRef<any>(null);
	const containerRef = useRef<any>(null);

	const [focusInput, setFocusInput] = useState(false);
	const [errorActive, setErrorActive] = useState<boolean | undefined>(false);

	useEffect(() => {
		if (name && changeError && isError) {
			changeError(name);
		}
	}, [inputName]);

	useEffect(() => {
		setErrorActive(isError);
		if (isError && containerRef && containerRef.current) {
			containerRef.current.scrollIntoView({
				behavior: 'smooth',
				block: 'end',
			});
		}
	}, [isError]);
	return (
		<InputCover
			ref={containerRef}
			onClick={(e) => {
				e.preventDefault();
				if (inputRef && inputRef.current) {
					inputRef.current.focus();
				}
			}}>
			{!errorActive ? (
				<ModalLabel className='mb-8'>{fieldLabel}</ModalLabel>
			) : (
				<ModalLabelError className='mb-8'>
					{fieldLabelError ? fieldLabelError : fieldLabel}
				</ModalLabelError>
			)}

			<ModalInputContainer
				shadowColor={
					errorActive && focusInput
						? 'rgba(232,69,62,0.75)'
						: focusInput
						? 'rgba(40,167,69,0.75)'
						: 'transparent'
				}
				borderColor={
					errorActive
						? '#E8453E'
						: focusInput || inputName.length > 0
						? '#28A745'
						: '#9f9f9f'
				}>
				<input
					ref={inputRef}
					className='modal_input'
					type={type ? type : 'text'}
					value={inputName}
					disabled={disabled}
					onChange={(e) => {
						setInputField(e.target.value);
						setErrorActive(false);
					}}
					onScroll={(e) => {
						e.preventDefault();
						if (type === 'number')
							if (inputRef && inputRef.current) {
								inputRef.current.blur();
							}
					}}
					onWheel={(e) => {
						e.preventDefault();
						//e.
						if (type === 'number')
							if (inputRef && inputRef.current) {
								inputRef.current.blur();
							}
					}}
					placeholder={placeholder}
					onFocus={() => {
						setFocusInput(true);
					}}
					onBlur={() => {
						setFocusInput(false);
					}}
				/>
				{inputName.length > 0 && !disabled && (
					<div
						style={{ opacity: focusInput ? 1 : 0 }}
						className='modal_input_icon_container'>
						<img
							className='modal_input_icon'
							src={ClearIcon}
							alt='x'
							onClick={(e) => {
								e.preventDefault();
								setInputField('');
							}}
						/>
					</div>
				)}
			</ModalInputContainer>
		</InputCover>
	);
}

export function InputMaskContainer({
	isError,
	inputName,
	setInputField,
	fieldLabel,
	fieldLabelError,
	placeholder,
	disabled,
	changeError,
	name,
}: {
	isError?: boolean;
	inputName: string;
	setInputField(s: string): void;
	fieldLabel: string;
	fieldLabelError?: string;
	placeholder?: string;
	disabled?: boolean;
	changeError?(s: string): void;
	name?: string;
}) {
	const inputRef = useRef<any>(null);

	const [focusInput, setFocusInput] = useState(false);
	const [errorActive, setErrorActive] = useState<boolean | undefined>(false);
	useEffect(() => {
		if (name && changeError && isError) {
			changeError(name);
		}
	}, [inputName]);
	useEffect(() => {
		setErrorActive(isError);
	}, [isError]);

	function maskDocument(preValue: string): string {
		let value = preValue;
		value = value.replace(/\D/g, '');
		if (value.length <= 11) {
			value = value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4');
		} else {
			value = value.replace(
				/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
				'$1.$2.$3/$4-$5'
			);
		}
		return value;
	}

	return (
		<InputCover
			onClick={(e) => {
				e.preventDefault();
				if (inputRef && inputRef.current) {
					inputRef.current.focus();
				}
			}}
			className='mt-24'>
			{!errorActive ? (
				<ModalLabel className='mb-8'>{fieldLabel}</ModalLabel>
			) : (
				<ModalLabelError className='mb-8'>
					{fieldLabelError ? fieldLabelError : fieldLabel}
				</ModalLabelError>
			)}
			<ModalInputContainer
				shadowColor={
					errorActive && focusInput
						? 'rgba(232,69,62,0.75)'
						: focusInput
						? 'rgba(40,167,69,0.75)'
						: 'transparent'
				}
				borderColor={
					errorActive
						? '#E8453E'
						: focusInput || inputName.length > 0
						? '#28A745'
						: '#9f9f9f'
				}>
				<input
					disabled={disabled}
					ref={inputRef}
					className='modal_input'
					type='text'
					value={inputName}
					onChange={(e) => {
						setInputField(maskDocument(e.target.value));
						setErrorActive(false);
					}}
					placeholder={placeholder}
					onFocus={() => {
						setFocusInput(true);
					}}
					onBlur={() => {
						setFocusInput(false);
					}}
				/>
				{inputName.length > 0 && !disabled && (
					<div
						style={{ opacity: focusInput ? 1 : 0 }}
						className='modal_input_icon_container'>
						<img
							className='modal_input_icon'
							src={ClearIcon}
							alt='x'
							onClick={(e) => {
								e.preventDefault();
								setInputField('');
							}}
						/>
					</div>
				)}
			</ModalInputContainer>
		</InputCover>
	);
}

export function SelectContainer({
	isError,
	inputName,
	setInputField,
	fieldLabel,
	fieldLabelError,
	placeholder,
	options,
	disabled,
	name,
	changeError,
}: {
	isError?: boolean;
	inputName: string;
	setInputField(s: string): void;
	fieldLabel: string;
	fieldLabelError?: string;
	placeholder?: string;
	options: { label: string; value: string }[];
	disabled?: boolean;
	changeError?(s: string): void;
	name?: string;
}) {
	const inputRef = useRef<any>(null);

	const [focusInput, setFocusInput] = useState(false);

	const [errorActive, setErrorActive] = useState<boolean | undefined>(false);

	const [shownInput, setShownInput] = useState('');

	useEffect(() => {
		if (name && changeError && isError) {
			changeError(name);
		}
	}, [inputName]);

	useEffect(() => {
		const f = options.find((o) => o.value === inputName);

		if (f) {
			setShownInput(f.label);
		} else {
			setShownInput('');
		}
	}, [inputName, options]);

	useEffect(() => {
		setErrorActive(isError);
	}, [isError]);

	const dropdownRef = useRef<any>(null);

	useOutsideAlert(dropdownRef, handleOutside);

	function handleOutside() {
		setFocusInput(false);
	}

	return (
		<InputCover
			onClick={(e) => {
				e.preventDefault();
				if (inputRef && inputRef.current) {
					inputRef.current.focus();
				}
			}}
			className='mt-24'>
			{!errorActive ? (
				<ModalLabel className='mb-8'>{fieldLabel}</ModalLabel>
			) : (
				<ModalLabelError className='mb-8'>
					{fieldLabelError ? fieldLabelError : fieldLabel}
				</ModalLabelError>
			)}
			<ModalInputContainer
				//style={{ paddingRight: 12 }}
				shadowColor={
					errorActive && focusInput
						? 'rgba(232,69,62,0.75)'
						: focusInput
						? 'rgba(40,167,69,0.75)'
						: 'transparent'
				}
				borderColor={
					errorActive
						? '#E8453E'
						: focusInput || inputName.length > 0
						? '#28A745'
						: '#9f9f9f'
				}>
				{
					<Dropdown ref={dropdownRef}>
						<Dropdown.Toggle className='dropdown-none'>
							<SearchSelector
								onClick={(e) => {
									setFocusInput(true);
								}}
								className='modal_input'>
								{shownInput}
							</SearchSelector>
							<img alt='v' src={ArrowDark} />
						</Dropdown.Toggle>
						<DropdownMenu>
							{options.map((o) => {
								if (o.value === '') return null;
								return (
									<Dropdown.Item
										onClick={(e) => {
											e.preventDefault();
											e.stopPropagation();
											setInputField(o.value);
											setFocusInput(false);
										}}>
										{o.label}
									</Dropdown.Item>
								);
							})}
						</DropdownMenu>
					</Dropdown>
				}

				{/*<select
					//style={{paddingRight:16}}
					disabled={disabled}
					ref={inputRef}
					className='modal_input'
					value={inputName}
					onChange={(e) => {
						setInputField(e.target.value);
						setErrorActive(false);
					}}
					placeholder={placeholder}
					onFocus={(e) => {
						setFocusInput(true);
						//e.target.size=options.length;
					}}
					onBlur={(e) => {
						setFocusInput(false);
						//e.target.size=0;
					}}>
					{options.map((o) => (
						<option value={o.value}>{o.label}</option>
					))}
				</select>*/}
			</ModalInputContainer>
		</InputCover>
	);
}

export function InputContainerPercentage({
	isError,
	inputName,
	setInputField,
	fieldLabel,
	fieldLabelError,
	placeholder,
	disabled,
	type,
	name,
	changeError,
}: {
	isError?: boolean;
	inputName: string;
	setInputField(s: string): void;
	fieldLabel: string;
	fieldLabelError?: string;
	placeholder?: string;
	disabled?: boolean;
	type?: string;
	changeError?(s: string): void;
	name?: string;
}) {
	const inputRef = useRef<any>(null);

	const [focusInput, setFocusInput] = useState(false);
	const [errorActive, setErrorActive] = useState<boolean | undefined>(false);

	useEffect(() => {
		if (name && changeError && isError) {
			changeError(name);
		}
	}, [inputName]);
	useEffect(() => {
		setErrorActive(isError);
	}, [isError]);
	return (
		<InputCover
			onClick={(e) => {
				e.preventDefault();
				if (inputRef && inputRef.current) {
					inputRef.current.focus();
				}
			}}
			className='mt-24'>
			{!errorActive ? (
				<ModalLabel className='mb-8'>{fieldLabel}</ModalLabel>
			) : (
				<ModalLabelError className='mb-8'>
					{fieldLabelError ? fieldLabelError : fieldLabel}
				</ModalLabelError>
			)}
			<ModalInputContainer
				style={{ overflow: 'hidden' }}
				shadowColor={
					errorActive && focusInput
						? 'rgba(232,69,62,0.75)'
						: focusInput
						? 'rgba(40,167,69,0.75)'
						: 'transparent'
				}
				borderColor={
					errorActive
						? '#E8453E'
						: focusInput || inputName.length > 0
						? '#28A745'
						: '#9f9f9f'
				}>
				<input
					disabled={disabled}
					ref={inputRef}
					className='modal_input'
					type={type ? type : 'text'}
					value={inputName}
					onScroll={(e) => {
						e.preventDefault();
						if (type === 'number')
							if (inputRef && inputRef.current) {
								inputRef.current.blur();
							}
					}}
					onWheel={(e) => {
						e.preventDefault();
						//e.
						if (type === 'number')
							if (inputRef && inputRef.current) {
								inputRef.current.blur();
							}
					}}
					onChange={(e) => {
						setInputField(e.target.value);
						setErrorActive(false);
					}}
					placeholder={placeholder}
					onFocus={() => {
						setFocusInput(true);
					}}
					onBlur={() => {
						setFocusInput(false);
					}}
				/>
				<div className='percentage_container'>%</div>
			</ModalInputContainer>
		</InputCover>
	);
}

export function DebouncerInput({
	setSearchItem,
	placeholder,
}: {
	setSearchItem(s: string): void;
	placeholder?: string;
}) {
	const [searchInput, setSearchInput] = useState('');

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const debouncedSave = useCallback(
		debounce((nextValue) => setSearchItem(nextValue), 1000),
		[]
	);

	function handleChange(s: string) {
		setSearchInput(s);
		debouncedSave(s);
	}

	return (
		<HeaderSearchContainer
			onSubmit={(e) => {
				e.preventDefault();
				//console.log(searchActive);
				setSearchItem(searchInput);
			}}
			active={true}>
			<button type='submit'>
				<img src={SearchGray} alt='src' style={{ width: 24, height: 24 }} />
			</button>

			<HeaderSearchInput
				value={searchInput}
				onChange={(e) => {
					//console.log(e.target.value)
					handleChange(e.target.value);
				}}
				placeholder={placeholder ? placeholder : ''}
			/>
		</HeaderSearchContainer>
	);
}

function useOutsideAlert(ref: any, handleClickOutside: any) {
	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			// Unbind the event listener on clean up
			document.removeEventListener('mousedown', handleClickOutside);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ref]);
}
