import axios from 'axios';

//const { apiUrl } = Enviroment();

//const localAPI = 'http://192.168.100.6'

const api = axios.create({
	//base URL

	baseURL: process.env.REACT_APP_API_HOST,
	//timeout: 5000,
});

export default api;
