import api from "../../services/api";
import fileDownload from "js-file-download";

export async function exportReportSearchRequest(
  startDate: string,
  endDate: string,
  merchantId?: number | string
) {
  const token = sessionStorage.getItem("@bill-token");

  try {
    const response = await api.get(
      `/admin/api/report/pdf?start=${startDate}&end=${endDate}&merchant_id=${merchantId}`,
      {
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/pdf",
        },
      }
    );

    const { data } = response;

    if (data) {
      fileDownload(response.data, `relatorios${startDate}_${endDate}.${"pdf"}`);
    }
  } catch (error: any) {
    console.log(error);
    throw error;
  }
}
