import React, { useState } from "react";

import {
  Footer,
  Menu,
  MenuHeader,
  MenuImg,
  MenuItem,
  MenuItems,
  MenuText,
} from "./style";

import OrangeLogo from "../../assets/images/orange-logo.svg";
import LogoBillGreen from "../../assets/images/logo-bill.svg";
import LogoCut from "../../assets/images/logo-cut.svg";
import SpaceDash from "../../assets/images/space-dash.svg";
import SpaceDashWhite from "../../assets/images/space-dash-white.svg";

import Poll from "../../assets/images/poll.svg";
import PollWhite from "../../assets/images/poll-white.svg";

import List from "../../assets/images/list_alt.svg";
import ListWhite from "../../assets/images/list_alt-white.svg";

import Work from "../../assets/images/work.svg";
import WorkWhite from "../../assets/images/work-white.svg";
import ThumbUp from "../../assets/images/thumb_up-black.svg";
import ThumbUpWhite from "../../assets/images/thumb_up-white.svg";

import ConciliadorGray from "../../assets/images/conciliador-gray.svg";
import ConciliadorWhite from "../../assets/images/conciliador-white.svg";

import ReopenGray from "../../assets/images/reopen-gray.svg";
import ReopenWhite from "../../assets/images/reopen-white.svg";

import People from "../../assets/images/people_alt.svg";
import PeopleWhite from "../../assets/images/people_alt-white.svg";

//import Settings from '../../assets/images/settings.svg';
//import SettingsWhite from '../../assets/images/settings-white.svg';

import { useHistory } from "react-router";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

//import { MaterialCommunityIcons, MaterialIcons } from 'react-web-vector-icons';
import { useEffect } from "react";

interface LoginProps {
  email: string | null;
  login: string;
  merchant: string | null;
  type: number;
}
interface MenuProps {
  login: LoginProps | undefined;
}

export default function MenuContainer({ login }: MenuProps) {
  const history = useHistory();
  const [path, setPath] = useState(window.location.pathname);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [open, setOpen] = useState(true);

  useEffect(() => {
    setPath(window.location.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname]);

  const menuInfo =
    login && login.type === 999
      ? [
          {
            nome: "dashboard",
            Icon: (
              <img
                className="invoice_menu_icon_on"
                src={SpaceDash}
                alt="dash"
              />
            ),
            IconOff: (
              <img
                className="invoice_menu_icon_off"
                src={SpaceDashWhite}
                alt="dash"
              />
            ),
            path: "",
          },
          {
            nome: "Pagamentos",
            Icon: (
              <img src={Poll} className="invoice_menu_icon_on" alt="poll" />
            ),
            IconOff: (
              <img
                src={PollWhite}
                className="invoice_menu_icon_off"
                alt="poll"
              />
            ),

            path: "relatorios",
          },
          {
            nome: "Empresas",
            Icon: (
              <img src={List} className="invoice_menu_icon_on" alt="List" />
            ),
            IconOff: (
              <img
                src={ListWhite}
                className="invoice_menu_icon_off"
                alt="List"
              />
            ),
            path: "categorias",
          },
          {
            nome: "correspondentes",
            Icon: (
              <img src={Work} className="invoice_menu_icon_on" alt="Work" />
            ),
            IconOff: (
              <img
                src={WorkWhite}
                className="invoice_menu_icon_off"
                alt="Work"
              />
            ),
            path: "correspondentes",
          },
          {
            nome: "Conciliações",
            Icon: (
              <img src={ThumbUp} className="invoice_menu_icon_on" alt="up" />
            ),
            IconOff: (
              <img
                src={ThumbUpWhite}
                className="invoice_menu_icon_off"
                alt="up"
              />
            ),
            path: "conciliacoes",
          },

          {
            nome: "usuários",
            Icon: (
              <img src={People} className="invoice_menu_icon_on" alt="People" />
            ),
            IconOff: (
              <img
                src={PeopleWhite}
                className="invoice_menu_icon_off"
                alt="People"
              />
            ),
            path: "usuarios",
          },
          //{ nome: 'configurações', Icon: <img src={Settings} className='invoice_menu_icon_on' alt='Settings'/>,
          //IconOff:<img src={SettingsWhite} className='invoice_menu_icon_off' alt='Settings'/>, path: 'configuracoes' },
        ]
      : [
          {
            nome: "dashboard",
            Icon: (
              <img
                className="invoice_menu_icon_on"
                src={SpaceDash}
                alt="dash"
              />
            ),
            IconOff: (
              <img
                className="invoice_menu_icon_off"
                src={SpaceDashWhite}
                alt="dash"
              />
            ),
            path: "",
          },
          {
            nome: "Pagamentos",
            Icon: (
              <img src={Poll} className="invoice_menu_icon_on" alt="poll" />
            ),
            IconOff: (
              <img
                src={PollWhite}
                className="invoice_menu_icon_off"
                alt="poll"
              />
            ),

            path: "relatorios",
          },

          {
            nome: "Conciliações",
            Icon: (
              <img src={ThumbUp} className="invoice_menu_icon_on" alt="up" />
            ),
            IconOff: (
              <img
                src={ThumbUpWhite}
                className="invoice_menu_icon_off"
                alt="up"
              />
            ),
            path: "conciliacoes",
          },
          {
            nome: "Conciliador",
            Icon: (
              <img
                src={ConciliadorGray}
                className="invoice_menu_icon_on"
                alt="up"
              />
            ),
            IconOff: (
              <img
                src={ConciliadorWhite}
                className="invoice_menu_icon_off"
                alt="up"
              />
            ),
            path: "conciliador",
          },
          {
            nome: "Reabrir guia",
            Icon: (
              <img src={ReopenGray} className="invoice_menu_icon_on" alt="up" />
            ),
            IconOff: (
              <img
                src={ReopenWhite}
                className="invoice_menu_icon_off"
                alt="up"
              />
            ),
            path: "reabrirguia",
          },

          //{ nome: 'configurações', Icon: <img src={Settings} className='invoice_menu_icon_on' alt='Settings'/>,
          //IconOff:<img src={SettingsWhite} className='invoice_menu_icon_off' alt='Settings'/>, path: 'configuracoes' },
        ];
  return (
    <Menu open={open}>
      <MenuItems open={open}>
        <MenuHeader
          open={open}
          onClick={(e) => {
            e.preventDefault();
            //setOpen(!open);
          }}
        >
          <MenuImg
            open={open}
            src={open ? LogoBillGreen : LogoCut}
            alt="Bill"
          />
        </MenuHeader>

        {menuInfo.map((menu) => {
          return (
            <OverlayTrigger
              key={menu.nome}
              placement="right"
              overlay={
                <Tooltip
                  style={{ display: open ? "none" : "block" }}
                  id="tool-tip"
                >
                  <span style={{ textTransform: "capitalize" }}>
                    {menu.nome}
                  </span>
                </Tooltip>
              }
            >
              <MenuItem
                open={open}
                onClick={(e) => {
                  e.preventDefault();
                  history.push(`/${menu.path}`);
                  setPath(`/${menu.path}`);
                }}
                active={path === `/${menu.path}`}
              >
                {menu.Icon}
                {menu.IconOff}

                {open && <MenuText>{menu.nome}</MenuText>}
                {/* {open && <div className='menuGreenDot' style={{}} />} */}
              </MenuItem>
            </OverlayTrigger>
          );
        })}

        {
          <Footer open={open}>
            <img alt="Orange" src={OrangeLogo} />
          </Footer>
        }
      </MenuItems>
    </Menu>
  );
}
