import React from 'react';

import * as S from './style';
import DeleteOutline from '../../assets/images/delete_forever-white.svg';

interface DeleteModalProps {
	setModalVisible(b: boolean): void;
	title?: string;
	labelStart: string;
	labelEnd?: string;
	focusItem?: string;
	activeFunction(): void;
}

export default function DeleteModal({
	activeFunction,
	labelStart,
	setModalVisible,
	focusItem,
	labelEnd,
	title,
}: DeleteModalProps) {
	return (
		<S.Body
			onClick={(e) => {
				e.preventDefault();
				e.stopPropagation();
				setModalVisible(false);
			}}>
			<S.Container
				onClick={(e) => {
					e.stopPropagation();
				}}>
				<S.DeleteIcon>
					<img alt='delete' src={DeleteOutline} />
				</S.DeleteIcon>
				<S.Title>{title ? title : 'Deletar Item?'}</S.Title>
				<S.Label>
					{labelStart}{' '}
					{focusItem && <span className='bold_item'>{focusItem}</span>}
					{labelEnd ? labelEnd : ''}
				</S.Label>

				<div style={{ width: '100%' }} className='row_between'>
					<S.CancelButton
						onClick={(e) => {
							e.preventDefault();
							setModalVisible(false);
						}}>
						Cancelar
					</S.CancelButton>
					<S.DeleteButton
						onClick={(e) => {
							e.preventDefault();
							setModalVisible(false);
							activeFunction();
						}}
						style={{ marginLeft: 15 }}>
						Deletar
					</S.DeleteButton>
				</div>
			</S.Container>
		</S.Body>
	);
}
