import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import styled from 'styled-components';
import { colors } from '../../globalStyles/colors';
import { Empresa, PaymentMethod } from '../../models/model';
import api from '../../services/api';

import {
	ActionButton,
	BodyTR,
	Table,
	TableContainer,
	TBody,
	Th,
	THead,
} from './style';

const Modal = styled.div`
	//padding-top: 25px;
	//padding-left: 50px;
	//padding-right: 20000px;
	//padding-bottom: 25px;
	min-height: 100vh;
	background-color: #f5f5f5;
	//max-width: 60vw;
	width: 60vw;
	//width:calc(fit-content - 50px);
	height: 100%;
	flex: 1;
	display: flex;
	//margin-left:30vw;
`;

const ModalBody = styled.div`
	//margin:50px;
	background-color: #f5f5f5;
	padding-top: 20px;
	margin-left: 70px;
	//margin-right: 50px;
	padding-bottom: 20px;
	display: flex;
	min-height: calc(100% - 40px);
	flex: 1;
	//width: calc(100% - 100px);
	padding-right: 70px;
`;

const ModalTitle = styled.div`
	text-align: left;
	font: normal normal bold 20px/26px Roboto;
	letter-spacing: 0px;
	color: ${colors.default};
	opacity: 1;
	margin-bottom: 31px;

	display: flex;
	flex-direction: row;
`;

export const HeaderButton = styled.button`
	cursor: pointer;
	background: #ffffff;
	border: 1px solid #09aa36;
	box-shadow: 0px 0px 8px #00000029;
	border-radius: 5px;
	padding-top: 6px;
	padding-bottom: 6px;
	padding-right: 16px;
	padding-left: 16px;
	margin-right: 8px;
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-left: 20px;

	p {
		margin: 0;
		text-align: center;
		font: normal normal 500 12px/16px Roboto;
		letter-spacing: 0px;
		color: #09aa36;
		text-transform: uppercase;
		opacity: 1;
	}

	svg {
		color: #09aa36;
		width: 13px;
		height: 13px;
		margin-right: 10px;
	}
`;

const Button = styled.button`
	background: #09aa36 0% 0% no-repeat padding-box;
	border-radius: 10px;
	border: 0;

	padding-top: 6px;
	padding-bottom: 6px;
	padding-right: 12px;
	padding-left: 12px;

	text-align: center;
	font: normal normal 500 11px/15px Roboto;
	letter-spacing: 0px;
	color: #ffffff;
`;
const ButtonContainer = styled.div`
	padding-top: 20px;
	margin-top: 20px;
	border-top: 1px solid #09aa36;

	display: flex;
	flex-direction: row;
`;

const ButtonCancel = styled.button`
	background: #ffffff 0% 0% no-repeat padding-box;
	border-radius: 10px;
	border: 1px solid #09aa36;

	padding-top: 6px;
	padding-bottom: 6px;
	padding-right: 12px;
	padding-left: 12px;

	text-align: center;
	font: normal normal 500 11px/15px Roboto;
	letter-spacing: 0px;
	color: #09aa36;

	margin-right: 16px;
`;

interface ModalProps {
	setModalVisible(b: boolean): void;
	setModalVisibleNovo(b: boolean): void;
	setModalVisibleEdit(b: boolean): void;
	empresa: Empresa;
	setMetodo(m: PaymentMethod): void;
}

export default function ModalMetodo({
	setModalVisible,
	setModalVisibleNovo,
	//categorias,
	empresa,
	setMetodo,
	setModalVisibleEdit,
}: ModalProps) {
	/*const [categoria, setCategoria] = useState('');
	const [CNPJ, setCNPJ] = useState('');
	const [stoneCode, setStoneCode] = useState('');
	const [nomeFan, setNomeFan] = useState('');
	const [nome, setNome] = useState('');
	const [usuario, setUsuario] = useState('');
	const [Url, setUrl] = useState('');
	const [chaveSecreta, setChaveSecreta] = useState('');
	const [cor, setCor] = useState('');
	const [rotuloLogin, setRotuloLogin] = useState('');
	const [etiqueta, setEtiqueta] = useState('');

	const [segmento, setSegmento] = useState('');
	const [codigoConvenio, setCodigoConvenio] = useState('');
	const [codigoBanco, setCodigoBanco] = useState('');
	const [nomeBanco, setNomeBanco] = useState('');
	const [agenciaBancaria, setAgenciaBancaria] = useState('');
	const [formaDeArrecadacao, setFormaDeArredacao] = useState('');
	const [identificacaoDeServico, setIdentificacaoDeServico] = useState('');
	const [idEmpresa, setIdEmpresa] = useState('');
	const [jurosParcelamento, setJurosParcelamento] = useState('');
	const [versaoLayout, setVersaoLayout] = useState('');
	const [maxParcelas, setMaxParcelas] = useState('');
	const [maxParcelasSemJuros, setMaxParcelasSemJuros] = useState('');*/

	useEffect(() => {
		if (empresa) {
			/*setCategoria(empresa.categoria);
			setCNPJ(empresa.CNPJ);
			setStoneCode(empresa.codigo);
			setNomeFan(empresa.nome);
			setNome(empresa.nomeMini);
			setUsuario(empresa.usuario);
			setUrl(empresa.siteUrl);
			setChaveSecreta(empresa.chaveSecreta);
			setCor(empresa.cor);
			setRotuloLogin(empresa.rotulo);
			setEtiqueta(empresa.etiqueta);
			setSegmento(empresa.segmento);
			setCodigoConvenio(empresa.codigoConvenio);
			setCodigoBanco(empresa.codigoBanco);
			setNomeBanco(empresa.nomeBanco);
			setAgenciaBancaria(empresa.agenciaBancaria);
			setFormaDeArredacao(empresa.formaDeArrecadacao);
			setIdentificacaoDeServico(empresa.identificacaoServico);
			setIdEmpresa(empresa.idEmpresa);
			setJurosParcelamento(empresa.jurosParcelamento);
			setVersaoLayout(empresa.versaoLayout);
			setMaxParcelas(empresa.maxParcelas);
			setMaxParcelasSemJuros(empresa.maxParcelasSemJuros);*/
		}
	}, [empresa]);

	useEffect(() => {
		getEmpresaPayment();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([]);

	async function getEmpresaPayment() {
		const token = sessionStorage.getItem('@bill-token');

		const config = {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		};

		try {
			const response = await api.get(
				`admin/payment-method/search?merchant=${empresa.id}`,
				config
			);
			console.log(response.data.payment_methods);
			if (response.data.payment_methods) {
				setPaymentMethods(response.data.payment_methods);
			}
		} catch (error:any) {
			console.error(error);
			if (error.response && error.response.status === 401) {
				history.push('/login');
			}
		}
	}
	const history = useHistory();

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	async function EditEmpresaPayment(){
		
	}



	return (
		<Modal>
			<ModalBody>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'space-between',
						//height: '100%',
						flex: 1,

						minHeight: '100%',
						//background:"#000",
						// height:'100%',
					}}>
					<div>
						<ModalTitle>
							{`Metodos de Pagamento · ${empresa?.name}`}
							<HeaderButton
								onClick={(e) => {
									e.preventDefault();
									setModalVisible(false);
									setModalVisibleNovo(true);
									//setModalNovaEmpresa(true);
								}}>
								<p>Novo Contrato</p>
							</HeaderButton>
						</ModalTitle>

						<TableContainer>
							<Table>
								<THead>
									<tr>
										<Th>nome</Th>
										<Th>taxa</Th>
										<Th>tipo de taxa</Th>
										<Th>identificador</Th>
										<Th>data de credito</Th>
										<Th></Th>
									</tr>
								</THead>
								<TBody>
									{paymentMethods.map((paymentMethod) => (
										<TRs
											setModalVisible={setModalVisible}
											setModalVisibleNovo={setModalVisibleEdit}
											//empresa={empresa}
											paymentMethod={paymentMethod}
											setMetodo={setMetodo}
											//setProvedor={setProvedor}
										/>
									))}
								</TBody>
							</Table>
						</TableContainer>
					</div>
					<ButtonContainer>
						<ButtonCancel
							onClick={(e) => {
								e.preventDefault();
								setModalVisible(false);
							}}>
							Cancelar
						</ButtonCancel>
						<Button>Salvar</Button>
					</ButtonContainer>
				</div>
			</ModalBody>
		</Modal>
	);
}

interface TRProps {
	setModalVisible(b: boolean): void;
	setModalVisibleNovo(b: boolean): void;
	paymentMethod: PaymentMethod;
	setMetodo(a: PaymentMethod): void;
}

function TRs({
	setModalVisible,
	setModalVisibleNovo,
	//categorias,
	//empresa,
	paymentMethod,
	setMetodo,
}: TRProps) {
	return (
		<BodyTR>
			<td>{paymentMethod.name}</td>
			<td>{paymentMethod.rate}</td>
			<td>{paymentMethod.rateType}</td>
			<td>{paymentMethod.code}</td>
			<td>{paymentMethod.creditDate}</td>
			<td>
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						float: 'right',
					}}>
					<ActionButton
						onClick={(e) => {
							e.preventDefault();
							//console.log(provedor);
							//setProvedor(provedor);
							setMetodo(paymentMethod);
							setModalVisible(false);
							setModalVisibleNovo(true);
							//setModalNovaEmpresa(true);
						}}>
						Editar
					</ActionButton>
					<ActionButton>Remover</ActionButton>
				</div>
			</td>
		</BodyTR>
	);
}
