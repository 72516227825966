/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from "react";
//import MenuContainer from '../../components/Menu';

import * as S from "./style";
import { connect } from "react-redux";
import { InitialStateProps } from "../../store";
import { Modal } from "react-bootstrap";
//import { Modal } from 'react-bootstrap';

import moment from "moment";
import { Empresa, Payment } from "../../models/model";
import { useHistory } from "react-router-dom";

import { DebouncerInput } from "../../components/InputV2";
import { paymentStatusesData } from "../../utils/statusesList";
import { HeaderUserButtonV2 } from "../../components/logOutV2";
import { StringToCurrencyNoSifra } from "../../utils/StringToCurrency";
import { GlobalRow } from "../../utils/GlobalRow";
import { getPaymentInvoiceRequest } from "./viewModel";
import { NotFoundComponent } from "../../components/NotFound";
import { EnqueueSnackbar, useSnackbar } from "notistack";
import { snackbarErrorHandler } from "../../utils/snackbarErrorHandler";
import { InvoicePaymentInfo } from "./Models";
import { ReopenPaymentModal } from "./ReopenPaymentModal";

interface Data {
  modules: any;
  dispatch: any;
}

const LIMIT = 25;

function ReabrirGuiaPage({ modules, dispatch }: Data) {
  const { enqueueSnackbar } = useSnackbar();

  const [guia, setGuia] = useState("");
  const [foundInvoice, setFoundInvoice] = useState<
    InvoicePaymentInfo | undefined
  >();

  const [user, setUser] = useState<any>();

  useEffect(() => {
    const login = localStorage.getItem("@bill-login");
    if (login) {
      const parseLogin = JSON.parse(login);

      setUser(parseLogin);
    }
  }, []);

  //console.log(range)

  const [listaParceiros, setListaParceiros] = useState<Empresa[]>([]);

  const [payments, setPayments] = useState<Payment[]>([]);

  const [maxPage, setMaxPage] = useState(0);
  const [thisPage, setThisPage] = useState(1);

  const [visiblePage, setVisiblePage] = useState(1);

  function refreshPage() {
    setGuia("");
  }

  function handleThisPage(newPage: number) {
    setThisPage(newPage);
    //setVisiblePage(newPage)
  }

  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const [adm, setAdm] = useState(false);

  async function getPaymentInvoice(textSearch: string) {
    if (textSearch) {
      setLoading(true);
      try {
        const data = await getPaymentInvoiceRequest(guia);
        if (data.invoice) {
          setFoundInvoice(data.invoice);
          return;
        }
        setFoundInvoice(undefined);
      } catch (error) {
        snackbarErrorHandler(
          error,
          enqueueSnackbar,
          "Falha ao encontrar invoice"
        );
        setFoundInvoice(undefined);
      } finally {
        setLoading(false);
      }
    } else {
      setFoundInvoice(undefined);
    }
  }

  useEffect(() => {
    getPaymentInvoice(guia);
  }, [guia]);

  useEffect(() => {
    const l = localStorage.getItem("@bill-login");
    if (l) {
      const login = JSON.parse(l);
      if (login && login.type === 999) {
        setAdm(true);
      } else {
        setAdm(false);
      }
    }
  }, []);

  return (
    <S.Container>
      <S.Header>
        <S.HeaderTitle>Reabrir Guia</S.HeaderTitle>
        <DebouncerInput
          placeholder="Buscar por Guia..."
          setSearchItem={setGuia}
        />
        <HeaderUserButtonV2 />
      </S.Header>

      <S.Body>
        <div>
          <S.TableContainer>
            {!loading && !foundInvoice ? (
              <NotFoundComponent />
            ) : (
              <S.Table>
                {loading ? (
                  <S.BodyTR>
                    <td>
                      <S.Skeleton style={{ width: "100%", height: 19 }} />
                    </td>
                    <td>
                      <S.Skeleton style={{ width: "100%", height: 19 }} />
                    </td>
                    <td>
                      <S.Skeleton style={{ width: "100%", height: 19 }} />
                    </td>
                    <td>
                      <S.Skeleton style={{ width: "100%", height: 19 }} />
                    </td>
                    <td>
                      <S.Skeleton style={{ width: "100%", height: 19 }} />
                    </td>
                    <td>
                      <S.Skeleton style={{ width: "100%", height: 19 }} />
                    </td>
                    <td>
                      <S.Skeleton style={{ width: "100%", height: 19 }} />
                    </td>
                    <td>
                      <S.Skeleton style={{ width: "100%", height: 19 }} />
                    </td>
                    <td>
                      <S.Skeleton style={{ width: "100%", height: 19 }} />
                    </td>
                  </S.BodyTR>
                ) : (
                  <>
                    <S.THead>
                      {foundInvoice ? (
                        <tr>
                          <S.Th active={true}>Guia</S.Th>
                          <S.Th>Interessado</S.Th>
                          <S.Th>Criado em</S.Th>
                          <S.Th>Vencimento</S.Th>
                          <S.Th>Comarca </S.Th>
                          <S.Th>Situação</S.Th>
                          <S.Th>Valor</S.Th>

                          <S.Th></S.Th>
                        </tr>
                      ) : null}
                    </S.THead>
                    <S.TBody>
                      {foundInvoice ? (
                        <PaymentTR
                          refreshPage={refreshPage}
                          payment={foundInvoice}
                          index={0}
                          enqueueSnackbar={enqueueSnackbar}
                        />
                      ) : null}
                    </S.TBody>
                  </>
                )}
              </S.Table>
            )}
          </S.TableContainer>
        </div>
      </S.Body>
    </S.Container>
  );
}

function PaymentTR({
  payment,
  index,
  refreshPage,
  enqueueSnackbar,
}: {
  payment: InvoicePaymentInfo;
  index: number;
  refreshPage(): void;
  enqueueSnackbar: EnqueueSnackbar;
}) {
  //console.log(relatorio);
  const [modalVisible, setModalVisible] = useState(false);

  function StringToLocalDate(stringDate: string) {
    return moment(stringDate).format("DD/MM/YYYY");
  }

  function getStatus() {
    const find = paymentStatusesData.find((f) => f.value === payment.status);
    if (find) {
      return (
        <GlobalRow justifyContent="flex-start" gap="8px">
          {find.iconColor ? (
            <S.StatusIcon statusColor={find.iconColor} />
          ) : null}{" "}
          {find.label}
        </GlobalRow>
      );
    }
    return payment.status || "-";
  }

  return (
    <>
      <S.BodyTR
        isEven={index % 2 === 0}
        onClick={(e) => {
          e.preventDefault();

          //   handleSelectedInvoices(payment.id);
        }}
      >
        <td>{payment.code || "-"}</td>
        <td>{payment?.content?.customer || "-"}</td>
        <td>{StringToLocalDate(payment.content.billingPeriod)}</td>
        <td>{StringToLocalDate(payment.content.paymentDueDate)}</td>
        <td>{payment.content.extras.description || "-"}</td>
        <td>{getStatus()}</td>
        <td style={{ textAlign: "right" }}>
          {StringToCurrencyNoSifra(`${payment.total}`)}
        </td>

        <td>
          <S.Detalhes
            onClick={(e) => {
              if (payment.status !== 1) {
                enqueueSnackbar("Essa ainda não foi paga", { variant: "info" });
                return;
              }
              setModalVisible(true);
              //dispatch(ToggleModal(modules, true));
            }}
          >
            Reabrir
          </S.Detalhes>
        </td>
      </S.BodyTR>
      {modalVisible ? (
        <Modal
          show={modalVisible}
          onHide={() => {
            setModalVisible(false);
          }}
        >
          <Modal.Body>
            <ReopenPaymentModal
              setModalVisible={setModalVisible}
              payment={payment}
              refreshPage={refreshPage}
            />
          </Modal.Body>
        </Modal>
      ) : null}
    </>
  );
}

export default connect((state: InitialStateProps) => ({
  modules: state.showModal,
}))(ReabrirGuiaPage);
