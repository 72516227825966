import moment from "moment";
import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import { Payment, Transaction } from "../../../models/model";
import { statusesData, transactionStatuses } from "../../../utils/statusesList";
import { paymentMethodList } from "../../../utils/paymentMethodList";
import * as S from "./styles";
import { GlobalRow } from "../../../utils/GlobalRow";
import CloseBlack from "../../../assets/images/close-black.svg";
import { GlobalColumn } from "../../../utils/GlobalColumn";

interface ModalProps {
  setModalVisible(b: boolean): void;
  payment: Payment;
}

export function ModalRelatorioScreen({ setModalVisible, payment }: ModalProps) {
  const clientName = payment.transactions[0]
    ? payment.transactions[0].name
    : "-";
  const clientDoc =
    payment.doc && payment.doc.length > 0
      ? payment.doc
      : payment.transactions[0] && payment.transactions[0].doc
      ? payment.transactions[0].doc
      : "";

  const empresa = payment.merchant ? payment.merchant.name : "-";

  const provider = payment.transactions[0]
    ? payment.transactions[0].provider
    : "-";
  const paymentLastTransaction = payment.lastTransaction;

  const ref = payment.transactions[0] ? payment.transactions[0].ref : "-";

  function StringToLocalDate(stringDate: string) {
    return moment(stringDate).format("DD/MM/YYYY HH:mm");
  }
  function getStatus() {
    const find = statusesData.find((f) => f.value === payment.status);
    if (find) {
      return (
        <GlobalRow justifyContent="flex-start" gap="8px">
          {find.iconColor ? (
            <S.StatusIcon statusColor={find.iconColor} />
          ) : null}{" "}
          {find.label}
        </GlobalRow>
      );
    }
    return payment.status;
  }
  function getTransactionStatus(transactionStatus?: number) {
    if (transactionStatus !== undefined) {
      const find = transactionStatuses.find(
        (f) => f.value === transactionStatus
      );
      if (find) {
        return (
          <GlobalRow justifyContent="flex-start" gap="8px">
            {find.iconColor ? (
              <S.StatusIcon statusColor={find.iconColor} />
            ) : null}{" "}
            <p className="menu_item_status_info">{find.label}</p>
          </GlobalRow>
        );
      }
    }
    return (
      <p className="menu_item_status_info">{`${transactionStatus || "-"}`}</p>
    );
  }

  function getStatusColor() {
    const data = [
      { label: "", value: -1 },
      { label: "#7CB342", value: 0 },
      { label: "#1588D1", value: 1 },
      { label: "#757575", value: 2 },
      { label: "#E53935", value: 3 },
      { label: "#7CB342", value: 4 },
      { label: "#7CB342", value: 5 },
      { label: "#FFBB33", value: 7 },
      { label: "#5E35B1", value: 8 },
      { label: "#757575", value: 9 },
      { label: "#757575", value: 11 },
    ];
    const find = data.find((f) => f.value === payment.status);
    if (find) {
      return find.label;
    }
    return "#757575";
  }

  function StringToCurrency(value: string) {
    return parseFloat(value).toLocaleString("pt-BR", {
      minimumFractionDigits: 2,
      style: "currency",
      currency: "BRL",
    });
  }

  function getType(transaction: Transaction) {
    const find = paymentMethodList.find(
      (f) => `${f.value}` === `${transaction.type}`
    );

    return find?.label || "-";
  }

  function maskDocument(preValue: string): string {
    let value = preValue;
    value = value.replace(/\D/g, "");
    if (value.length <= 11) {
      value = value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "$1.$2.$3-$4");
    } else {
      value = value.replace(
        /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
        "$1.$2.$3/$4-$5"
      );
    }
    return value;
  }

  function paymentDeviceName() {
    let pdn = payment.device ? payment.device.name : "-";
    if (pdn.length > 10) {
      pdn = pdn.slice(0, 8) + "...";
    }

    return pdn;
  }

  return (
    <S.Modal style={{ maxWidth: 768 }}>
      <S.ModalHeader>
        <S.HeaderTitle>{"Detalhes da Transação"}</S.HeaderTitle>

        <GlobalRow
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <S.HeaderButton
            onClick={(e) => {
              e.preventDefault();
              setModalVisible(false);
            }}
          >
            <img alt="x" src={CloseBlack} />
            Fechar
          </S.HeaderButton>
        </GlobalRow>
      </S.ModalHeader>

      <S.ModalContainer>
        <S.MenuItem>
          <GlobalRow
            alignItems="center"
            justifyContent="space-between"
            className="menu_margin_b-8 menu_item_header"
          >
            <p className="menu_item_title">Detalhes do pagamento</p>

            <div className="menu_detalhes_status">{getStatus()}</div>
          </GlobalRow>
          <GlobalColumn
            gap="16px"
            alignItems="flex-start"
            className="menu_padding_b-16 item_width-100"
          >
            <S.GridContainer className="item_width-100">
              <GlobalColumn
                alignItems="flex-start"
                justifyContent="flex-start"
                gap="4px"
              >
                <p className="menu_item_label">Total pago</p>
                <p className="menu_item_info">
                  {StringToCurrency(payment.total) || "-"}
                </p>
              </GlobalColumn>
              {paymentLastTransaction?.paymentDate ? (
                <GlobalColumn
                  alignItems="flex-start"
                  justifyContent="flex-start"
                  gap="4px"
                >
                  <p className="menu_item_label">PAGO EM</p>
                  <p className="menu_item_info">
                    {StringToLocalDate(paymentLastTransaction?.paymentDate)}
                  </p>
                </GlobalColumn>
              ) : null}
            </S.GridContainer>
            <S.GridContainer className="item_width-100">
              <GlobalColumn
                alignItems="flex-start"
                justifyContent="flex-start"
                gap="4px"
              >
                <p className="menu_item_label">Origem</p>
                <div className="menu_item_grid">
                  <OverlayTrigger
                    overlay={
                      <Tooltip id="tool-tip">
                        <span style={{ textTransform: "capitalize" }}>
                          {payment.device?.name || "-"}
                        </span>
                      </Tooltip>
                    }
                  >
                    <p className="menu_item_info menu_item_ellipsis">
                      {payment?.device?.name}
                    </p>
                  </OverlayTrigger>
                </div>
              </GlobalColumn>
              <GlobalColumn
                alignItems="flex-start"
                justifyContent="flex-start"
                gap="4px"
              >
                <p className="menu_item_label">Provedor</p>
                <p className="menu_item_info">{provider || "-"}</p>
              </GlobalColumn>
            </S.GridContainer>

            <S.GridContainer
              gridTemplateColumns="1fr"
              className="item_width-100"
            >
              <GlobalColumn
                alignItems="flex-start"
                justifyContent="flex-start"
                gap="4px"
              >
                <p className="menu_item_label">Identificador do pagamento</p>
                <p className="menu_item_info menu_item_ellipsis">
                  {payment.id}
                </p>
              </GlobalColumn>
            </S.GridContainer>
          </GlobalColumn>
        </S.MenuItem>
        <S.MenuItem>
          <GlobalRow
            alignItems="center"
            justifyContent="space-between"
            className="menu_margin_b-8 menu_item_header"
          >
            <p className="menu_item_title">Detalhes da cobrança</p>
          </GlobalRow>
          <GlobalColumn
            gap="16px"
            alignItems="flex-start"
            className="menu_padding_b-16  item_width-100"
          >
            <S.GridContainer
              gridTemplateColumns="1fr 1fr 1fr"
              className="item_width-100"
            >
              <GlobalColumn
                alignItems="flex-start"
                justifyContent="flex-start"
                gap="4px"
              >
                <p className="menu_item_label">Tipo</p>
                <p className="menu_item_info">Fatura</p>
              </GlobalColumn>
              {payment.total ? (
                <GlobalColumn
                  alignItems="flex-start"
                  justifyContent="flex-start"
                  gap="4px"
                >
                  <p className="menu_item_label">Valor do documento</p>
                  <p className="menu_item_info">
                    {StringToCurrency(payment.total)}
                  </p>
                </GlobalColumn>
              ) : null}
              {payment.created ? (
                <GlobalColumn
                  alignItems="flex-start"
                  justifyContent="flex-start"
                  gap="4px"
                >
                  <p className="menu_item_label">Criado em</p>
                  <p className="menu_item_info">
                    {StringToLocalDate(payment.created)}
                  </p>
                </GlobalColumn>
              ) : null}
            </S.GridContainer>
            <S.GridContainer
              gridTemplateColumns="1fr"
              className="item_width-100"
            >
              <GlobalColumn
                alignItems="flex-start"
                justifyContent="flex-start"
                gap="4px"
              >
                <p className="menu_item_label">Empresa</p>
                <div className="menu_item_grid">
                  <OverlayTrigger
                    overlay={
                      <Tooltip id="tool-tip">
                        <span style={{ textTransform: "capitalize" }}>
                          {empresa || "-"}
                        </span>
                      </Tooltip>
                    }
                  >
                    <p className="menu_item_info menu_item_ellipsis">
                      {empresa || "-"}
                    </p>
                  </OverlayTrigger>
                </div>
              </GlobalColumn>
            </S.GridContainer>

            <S.GridContainer
              gridTemplateColumns="1fr"
              className="item_width-100"
            >
              <GlobalColumn
                alignItems="flex-start"
                justifyContent="flex-start"
                gap="4px"
              >
                <p className="menu_item_label">
                  {payment.merchant.id === 6 ? "Guias" : "Faturas"}
                </p>
                {payment.invoices.map((invoice) => (
                  <p className="menu_item_info menu_item_ellipsis">
                    {payment.merchant.id === 6
                      ? invoice.code || "-"
                      : invoice.contentRefDate || "-"}
                  </p>
                ))}
              </GlobalColumn>
            </S.GridContainer>
          </GlobalColumn>
        </S.MenuItem>
        <S.MenuItem>
          <p className="menu_item_title menu_margin_b-8 menu_item_header">
            Detalhes do Cliente
          </p>
          <S.GridContainer className="item_width-100">
            <GlobalColumn
              alignItems="flex-start"
              justifyContent="flex-start"
              gap="4px"
            >
              <p className="menu_item_label">Nome</p>
              <p className="menu_item_info menu_item_ellipsis">{clientName}</p>
            </GlobalColumn>
            <GlobalColumn
              alignItems="flex-start"
              justifyContent="flex-start"
              gap="4px"
            >
              <p className="menu_item_label">documento</p>
              <p className="menu_item_info menu_item_ellipsis">
                {clientDoc.length > 0 ? maskDocument(clientDoc || "-") : "-"}
              </p>
            </GlobalColumn>
          </S.GridContainer>
        </S.MenuItem>
        {payment.transactions.length > 0 && (
          <S.MenuItem>
            <p className="menu_item_title menu_item_header menu_margin_b-8">
              Detalhes das transações
            </p>

            {payment.transactions.map((transaction, index) => {
              return (
                <S.GridContainer
                  gridTemplateColumns="1fr 1fr 1fr"
                  className={`item_width-100 ${
                    index !== payment.transactions.length - 1
                      ? "menu_padding_b-24 menu_border_b-gray"
                      : ""
                  } ${index !== 0 ? "menu_padding_t-24" : ""}`}
                >
                  <GlobalColumn
                    alignItems="flex-start"
                    justifyContent="flex-start"
                    gap="4px"
                  >
                    <p className="menu_item_label ">Tipo</p>
                    <p className="menu_item_info">{`${getType(
                      transaction
                    )} ${`${
                      transaction.installments
                        ? `(${transaction.installments}x)`
                        : ""
                    }`}`}</p>
                  </GlobalColumn>

                  <GlobalColumn
                    alignItems="flex-start"
                    justifyContent="flex-start"
                    gap="4px"
                  >
                    <p
                      style={{ textAlign: "right" }}
                      className="menu_item_label "
                    >
                      Valor
                    </p>
                    <p
                      style={{ textAlign: "right" }}
                      className="menu_item_info"
                    >{`${StringToCurrency(transaction.invoice)}`}</p>
                  </GlobalColumn>

                  <GlobalColumn
                    alignItems="flex-start"
                    justifyContent="flex-start"
                    gap="4px"
                  >
                    <p className="menu_item_label ">Situação</p>
                    {getTransactionStatus(transaction.status)}
                  </GlobalColumn>

                  <GlobalColumn
                    alignItems="flex-start"
                    justifyContent="flex-start"
                    gap="4px"
                  >
                    <p className="menu_item_label ">Código Autorizador</p>
                    <p className="menu_item_info">{`${
                      //relatorio.authorizerCode ||
                      transaction.authorizerCode || "-"
                    }`}</p>
                  </GlobalColumn>

                  <GlobalColumn
                    alignItems="flex-start"
                    justifyContent="flex-start"
                    gap="4px"
                  >
                    <p className="menu_item_label ">PAN</p>
                    <p className="menu_item_info">
                      {
                        //relatorio.pan ||
                        transaction.pan || "-"
                      }
                    </p>
                  </GlobalColumn>

                  <GlobalColumn
                    alignItems="flex-start"
                    justifyContent="flex-start"
                    gap="4px"
                  >
                    <p className="menu_item_label ">NSU</p>
                    <p className="menu_item_info">
                      {
                        //relatorio.atk ||
                        transaction.atk || "-"
                      }
                    </p>
                  </GlobalColumn>

                  <GlobalColumn
                    alignItems="flex-start"
                    justifyContent="flex-start"
                    gap="4px"
                  >
                    <p className="menu_item_label ">itk</p>
                    <p className="menu_item_info">
                      {
                        //relatorio.itk ||
                        transaction.itk || "-"
                      }
                    </p>
                  </GlobalColumn>
                </S.GridContainer>
              );
            })}
          </S.MenuItem>
        )}
      </S.ModalContainer>
    </S.Modal>
  );
}
