import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { colors } from '../../globalStyles/colors';
import { InitialStateProps } from '../../store';

export interface Data {
	modules: any;
	dispatch: any;
}

function ModalScreen({ modules, dispatch }: Data) {
	const [started, setStarted] = useState(false);
	useEffect(() => {
		setTimeout(() => {
			setStarted(true);
			//console.log(started);
		}, 1000);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	//console.log(modules);

	const ModalContainer = styled.div`
		transition: all 10s;
		position: fixed;
		width: ${started ? '100vw' : '0vw'};
		height: 100%;
		background: rgba(0, 0, 0, 0.5);
		z-index: 2;
		overflow: hidden;
		//transform: translate(${modules ? '0vh' : '100vh'}, 0);

		//opacity: ${modules ? 1 : 0};

		display: ${modules ? 'flex' : 'none'};
		justify-content: flex-end;
	`;
	const Modal = styled.div`
		height: 100%;
		width: 50%;
		background: #f5f5f5;
		padding-top: 25px;
		padding-left: 50px;
		padding-right: 50px;
		padding-bottom: 25px;
	`;

	const ModalTitle = styled.div`
		text-align: left;
		font: normal normal bold 20px/26px Roboto;
		letter-spacing: 0px;
		color: ${colors.default};
		opacity: 1;
		margin-bottom: 31px;
	`;

	const ModalGrid = styled.div`
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		border-bottom: 1px solid ${colors.default};
		text-align: left;
		span {
			text-align: left;
			font: normal normal normal 12px/16px Roboto;
			letter-spacing: 0px;
			color: #676767;
			opacity: 1;
		}
	`;

	const ModalGridBottom = styled.div`
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		border-bottom: 1px solid ${colors.default};
		text-align: left;
		span {
			text-align: left;
			font: normal normal normal 12px/16px Roboto;
			letter-spacing: 0px;
			color: #676767;
			opacity: 1;
		}
	`;

	const ModalText = styled.p`
		text-align: left;
		font: normal normal 500 16px/21px Roboto;
		letter-spacing: 0px;
		color: #09aa36;
		opacity: 1;
	`;

	const CloseButton = styled.button`
		cursor: pointer;

		width: 90px;
		height: 32px;
		border: 0;
		background: #09aa36 0% 0% no-repeat padding-box;
		border-radius: 10px;
		opacity: 1;
		text-align: center;

		font: normal normal 500 14px/19px Roboto;
		letter-spacing: 0px;
		color: #ffffff;
		opacity: 1;
	`;

	function ToggleModal(module: any, setShowModal: boolean) {
		return {
			type: 'setModalVisible',
			module,
			setShowModal,
		};
	}

	return (
		<ModalContainer>
			<Modal>
				<ModalTitle>Detalhes da transação</ModalTitle>
				<ModalGrid>
					<div>
						<span>Cliente:</span>
						<ModalText>Leonercio</ModalText>
					</div>
					<div>
						<span>documento:</span>
						<ModalText>123-1213-1212</ModalText>
					</div>
					<div>
						<span>Identificador:</span>
						<ModalText>951412412412</ModalText>
					</div>
					<div style={{ display: 'flex', flexDirection: 'row' }}>
						<div>
							<span>Ref. fatura:</span>
							<ModalText>02/2020</ModalText>
						</div>
						<div>
							<span>TD</span>
							<ModalText>1</ModalText>
						</div>
					</div>
					<div>
						<span>Valor da fatura:</span>
						<ModalText>R$123,12</ModalText>
					</div>
					<div>
						<span>Vencimento da fatura:</span>
						<ModalText>15/02/2020</ModalText>
					</div>
				</ModalGrid>

				<ModalGridBottom>
					<div>
						<span>Situação:</span>
						<ModalText>Paga</ModalText>
					</div>
					<div>
						<span>Dispositivo:</span>
						<ModalText>BILL_EASY</ModalText>
					</div>
					<div>
						<span>Provedor:</span>
						<ModalText>Stone</ModalText>
					</div>

					<div>
						<span>Tipo:</span>
						<ModalText>Débito</ModalText>
					</div>
					<div>
						<span>Portador/PAN:</span>
						<ModalText>Lucas ramalhos da silva/131231****2312</ModalText>
					</div>
					<div>
						<span>Codigo autorizador:</span>
						<ModalText>12345</ModalText>
					</div>
					<div>
						<span>ATK</span>
						<ModalText>1313123122</ModalText>
					</div>
					<div>
						<span>Data do cadastro:</span>
						<ModalText>01/02/03</ModalText>
					</div>
					<div>
						<span>ITK</span>
						<ModalText>2312412412512451125112512512</ModalText>
					</div>
				</ModalGridBottom>
				<CloseButton
					onClick={(e) => {
						e.preventDefault();
						dispatch(ToggleModal(modules, false));
					}}>
					Sair
				</CloseButton>
			</Modal>
		</ModalContainer>
	);
}

export default connect((state: InitialStateProps) => ({
	modules: state.showModal,
}))(ModalScreen);
