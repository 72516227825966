import styled from 'styled-components';
import { colors } from '../../globalStyles/colors';

export const Container = styled.div`
	background: #f5f5f5;
	flex: 1;
	height: 100%;
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	
	//flex-direction: row;
	//max-width:100vw;
`;

export const Header = styled.div`
	display: flex;
	flex-direction: row;
	height: 64px;
	//flex: 1;
	align-items: center;
	padding-left: 28px;

	border: solid;
	border-top: 0;
	border-left: 0;
	border-right: 0;

	border-bottom-width: 1px;
	border-color: #e5e5e5;
	//background:#000
	//max-width:100vw;
`;
export const HeaderTitle = styled.p`
	text-align: left;
	font: normal normal 500 20px/26px Roboto;
	letter-spacing: 0px;
	color: ${colors.default};
	opacity: 1;
	margin-right: 32px;
`;

export const HeaderExport = styled.button`
	background: #ffffff 0% 0% no-repeat padding-box;
	border-radius: 5px;
	opacity: 1;
	border: 0;
	width: 102px;
	height: 32px;
	margin-right: 24px;
	cursor: pointer;
`;

export const HeaderExportText = styled.p`
	text-align: center;
	font: normal normal 500 14px/19px Roboto;
	letter-spacing: 0px;
	color: #09aa36;
	opacity: 1;
	vertical-align: middle;
	margin: 0;
`;

interface HeaderButtonProps {
	active: boolean;
}

export const HeaderButton = styled.button<HeaderButtonProps>`
	cursor: pointer;
	background: ${(props) => (props.active ? '#FFFFFF' : 'transparent')};
	border: ${(props) => (props.active ? '1px solid #09aa36' : 0)};
	box-shadow: ${(props) => (props.active ? '0px 0px 8px #00000029' : 0)};
	border-radius: 5px;
	padding-top: 6px;
	padding-bottom: 6px;
	padding-right: 16px;
	padding-left: 16px;
	margin-right: 8px;
	display: flex;
	flex-direction: row;
	align-items: center;

	p {
		margin: 0;
		text-align: center;
		font: ${(props) =>
			props.active
				? 'normal normal 500 12px/16px Roboto'
				: 'normal normal normal 12px/16px Roboto'};
		letter-spacing: 0px;
		color: ${(props) => (props.active ? '#09AA36' : '#676767')};
		text-transform: uppercase;
		opacity: 1;
	}

	svg {
		color: ${(props) => (props.active ? '#09AA36' : '#676767')};
		width: 13px;
		height: 13px;
		margin-right: 10px;
	}
`;

interface HeaderSearchContainerProps {
	active: boolean;
}

export const HeaderSearchContainer = styled.div<HeaderSearchContainerProps>`
	position: absolute;
	right: 32px;
	top: 16px;
	display: flex;
	flex-direction: row;

	background: #ffffff 0% 0% no-repeat padding-box;
	border: 1px solid #09aa36;
	border-radius: 10px;
	opacity: 1;
	align-items: center;

	input {
		//display: ${(props) => !props.active && 'none'};
		transition: all 1s;
		width: ${(props) => (!props.active ? 0 : '150px')};
		margin-left: ${(props) => (!props.active ? 0 : '16px')};
		padding: ${(props) => (!props.active ? 0 : '11px')};
	}

	button {
		cursor: pointer;
		padding: 11px;
		background: transparent;
		border: 0;
		display: flex;
		align-items: center;
	}

	svg {
		color: #09aa36;
		width: 16px;
		height: 16px;
	}
`;

export const HeaderSearchInput = styled.input`
	text-align: left;
	font: normal normal normal 14px/19px Roboto;
	letter-spacing: 0px;
	color: #9f9f9f;
	opacity: 1;
	flex: 1;
	border: 0;
	background-color: transparent;
	//padding:11px;
`;

export const Body = styled.div`
	padding: 25px;
	padding-bottom: 0;
	flex: 1;
	//background:#000;
`;