import { createStore } from 'redux';

export interface InitialStateProps {
	showModal: boolean;
}

const INITIAL_STATE: InitialStateProps = {
	showModal: false,
};

function reducer(state = INITIAL_STATE, action: any) {
	switch (action.type) {
		case 'setModalVisible':
			return { ...state, showModal: action.setShowModal };
		default:
			return state;
	}
}

const store = createStore(reducer);

export default store;
