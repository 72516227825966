/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from "react";
import * as S from "./style";
import {
  UilDraggabledots,
  UilBoltAlt,
  UilEllipsisV,
  UilTear,
  UilBusAlt,
  UilPhone,
  UilSearch,
  UilPen,
  UilTrashAlt,
  UilUsdCircle,
  UilFileContract,
  UilMoneyWithdraw,
  UilCalendarAlt,
} from "@iconscout/react-unicons";
import { Dropdown, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";

import api from "../../services/api";
import { Conciliation, Correspondente, Dispositivo } from "../../models/model";
import { useEffect } from "react";
import { formatarDoc } from "../../utils/formatacaoDoc";
import { useHistory } from "react-router-dom";
import RemoveGray from "../../assets/images/delete_outline-gray.svg";
import SearchGray from "../../assets/images/search-gray.svg";
import EditGray from "../../assets/images/edit-gray.svg";
import { DateRange } from "react-date-range";

import ArrowRightWhite from "../../assets/images/arrow_right-white.svg";
import ArrowLeftWhite from "../../assets/images/arrow_left-white.svg";
import moment from "moment";
import { pt } from "date-fns/locale";
import fileDownload from "js-file-download";
import HeaderUserButton from "../../components/logOut";
import { DebouncerInput } from "../../components/InputV2";
import { CalendarioPersonalizado } from "../../components/CalendarioContainer";
import { HeaderUserButtonV2 } from "../../components/logOutV2";
import { downloadXLSRequest, getConciliacoesRequest } from "./viewModel";

const LIMIT = 50;

export default function Conciliacoes() {
  const [filtroDia, setFiltroDia] = useState("");

  const [estado, setEstado] = useState("");

  const [adm, setAdm] = useState(false);

  useEffect(() => {
    const l = localStorage.getItem("@bill-login");
    if (l) {
      const login = JSON.parse(l);
      if (login && login.type === 999) {
        setAdm(true);
      } else {
        setAdm(false);
      }
    }
  }, []);

  const [rangeHeader, setRangeHeader] = useState([
    {
      startDate: moment(
        moment().subtract(30, "days").format("DD/MM/YYYY"),
        "DD/MM/YYYY"
      ).toDate(),
      endDate: moment(moment().format("DD/MM/YYYY"), "DD/MM/YYYY").toDate(),
      key: "selection",
    },
  ]);
  const estadoList = [
    { label: "Todos", value: "" },
    { label: "Ativo", value: "1" },
    { label: "Inativo", value: "0" },
  ];

  const [conciliacoes, setConcliacoes] = useState<Conciliation[]>([]);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(1);
  const [thisPage, setThisPage] = useState(1);
  const [maxPage, setMaxPage] = useState(0);

  const [visiblePage, setVisiblePage] = useState(1);

  function handleThisPage(newPage: number) {
    setThisPage(newPage);
    //setVisiblePage(newPage)
  }

  async function getConciliacoes() {
    setLoading(true);

    try {
      const startDate = moment(rangeHeader[0].startDate).format("YYYY-MM-DD");
      const endDate = moment(rangeHeader[0].endDate).format("YYYY-MM-DD");

      const qs = `&start=${startDate}&end=${endDate}`;
      const data = await getConciliacoesRequest(qs, apelido);

      if (data) {
        if (data.total !== undefined) {
          setTotal(data.total);
          const t = data.total;
          const floorMax = Math.floor(t / LIMIT);
          if (t % LIMIT !== 0) {
            setMaxPage(floorMax + 1);
          } else {
            setMaxPage(floorMax);
          }
        }

        if (data.conciliations) {
          setConcliacoes(data.conciliations);
        }
        //setCorrespondentes(response.data);
      }

      setLoading(false);
    } catch (error: any) {
      console.error(error);
      setLoading(false);
      if (error.response && error.response.status === 401) {
        history.push("/login");
      }
    }
  }

  const [correspondenteSelecionado, setCorrespondenteSelecionado] = useState<
    Correspondente | null | undefined
  >();

  function selecionarCorrespondente(c: Correspondente) {
    if (
      correspondenteSelecionado === undefined ||
      correspondenteSelecionado === null
    ) {
      setCorrespondenteSelecionado(c);
    } else if (correspondenteSelecionado.id === c.id) {
      setCorrespondenteSelecionado(undefined);
    } else setCorrespondenteSelecionado(c);
  }

  const [apelido, setApelido] = useState("");
  const [searchInput, setSearchInput] = useState("");

  const [dispositivos, setDispositivos] = useState<Dispositivo[]>([]);

  const [loadingDispositivos, setLoadingDispositivos] = useState(false);

  const history = useHistory();

  useEffect(() => {
    getConciliacoes();
    //getDispositivos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rangeHeader, filtroDia, apelido]);

  return (
    <S.Container>
      <S.Main>
        <S.Header>
          <S.HeaderTitle>Conciliações</S.HeaderTitle>
          {adm && (
              <DebouncerInput
                placeholder="Buscar por Empresa..."
                setSearchItem={setApelido}
              />
            )}
          <HeaderUserButtonV2 />
        </S.Header>
        <S.Body>
          <div className="body_header">
            
            <S.SearchSelector
              active={filtroDia === ""}
              style={{ marginRight: 12 }}
              onClick={(e) => {
                e.preventDefault();
                //setModalNovaEmpresa(true);
                setFiltroDia("");
              }}
            >
              <p>{"Todos"}</p>
            </S.SearchSelector>
            <CalendarioPersonalizado
              item={filtroDia}
              setItem={setFiltroDia}
              range={rangeHeader}
              setRange={setRangeHeader}
            />
          </div>

          <div>
            <S.TableContainer style={{ minHeight: 250 }}>
              <S.Table>
                <S.THead>
                  <tr>
                    <S.Th>Data</S.Th>
                    <S.Th>Empresa</S.Th>

                    <S.Th>
                      <span style={{ float: "right", paddingRight: 10 }}>
                        Download
                      </span>
                    </S.Th>
                  </tr>
                </S.THead>
                <S.TBody>
                  {loading ? (
                    <S.BodyTR>
                      <td>
                        <S.Skeleton style={{ width: "50%", height: 19 }} />
                      </td>
                      <td>
                        <S.Skeleton style={{ width: "50%", height: 19 }} />
                      </td>
                      <td>
                        <S.Skeleton
                          style={{ width: "50%", height: 19, float: "right" }}
                        />
                      </td>
                    </S.BodyTR>
                  ) : (
                    conciliacoes.map((conciliacao) => {
                      //return null;
                      return <ConciliacaoTR conciliacao={conciliacao} />;
                    })
                  )}

                  {/*empresasData.map((empresa) => (
									<EmpresaTR
										empresa={empresa}
										categoriasData={categoriasData}
									/>
                                ))*/}
                </S.TBody>
              </S.Table>
            </S.TableContainer>
            {!loading && total > 0 && (
              <div style={{ padding: 10 }}>
                <S.TableFooter>
                  <p className="table_footer_label">
                    Mostrando{" "}
                    <span className="table_footer_label_strong">
                      {(thisPage - 1) * LIMIT + 1} -{" "}
                      {LIMIT * thisPage >= total ? total : LIMIT * thisPage}
                    </span>{" "}
                    de {total} transações
                  </p>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                        handleThisPage(visiblePage);
                      }}
                      className="table_footer_border_right table_footer_padding_right-20"
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <p className="table_footer_label">Você está na página </p>
                      <input
                        className="table_footer_input"
                        type="tel"
                        value={visiblePage}
                        onChange={(e) => {
                          let value = e.target.value;
                          value = value.replace(/\D/g, "");

                          if (value.length > 0) {
                            let intValue = parseInt(value);
                            if (intValue > maxPage) {
                              setVisiblePage(maxPage);
                            } else setVisiblePage(intValue);
                          } else {
                            setVisiblePage(0);
                          }
                        }}
                      />
                      <p className="table_footer_label">de {maxPage} </p>
                    </form>
                    <div className="table_footer_button_container table_footer_margin_left-20 table_footer_border_right-12 table_footer_border_left-12">
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          if (visiblePage > 1) {
                            handleThisPage(visiblePage - 1);
                          }
                        }}
                        className="table_footer_button table_footer_border_left-12"
                      >
                        <img alt="<" src={ArrowLeftWhite} />
                      </button>
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          if (visiblePage !== maxPage) {
                            handleThisPage(visiblePage + 1);
                          }
                        }}
                        className="table_footer_button table_footer_border_right-12"
                      >
                        <img alt=">" src={ArrowRightWhite} />
                      </button>
                    </div>
                  </div>
                </S.TableFooter>
              </div>
            )}
          </div>
        </S.Body>
      </S.Main>
    </S.Container>
  );
}

function SideItem({
  correspondente,
  selected,
  selecionarCorrespondente,
}: {
  correspondente: Correspondente;
  selected: boolean;
  selecionarCorrespondente(c: Correspondente): void;
}) {
  const [modalNovoCorrespondente, setModalNovoCorrespondente] = useState(false);

  return (
    <S.SidebarItem
      onClick={(e) => {
        e.preventDefault();
        selecionarCorrespondente(correspondente);
      }}
      selected={selected}
    >
      <div style={{ display: "flex" }}>
        <S.SidebarItemInfo>
          <S.SidebarItemTitle>{correspondente.name}</S.SidebarItemTitle>
          <S.SidebarItemSubTitle>
            {formatarDoc(correspondente.doc)}
          </S.SidebarItemSubTitle>
        </S.SidebarItemInfo>
      </div>

      <div
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        style={{ display: "flex", alignItems: "center", marginRight: 16 }}
      >
        <OverlayTrigger
          placement="bottom"
          overlay={
            <Tooltip id="tool-tip">
              <span style={{ textTransform: "capitalize" }}>
                {"Editar correspondente"}
              </span>
            </Tooltip>
          }
        >
          <button
            type="button"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setModalNovoCorrespondente(true);

              //setShowDropdown(!showDropdown);
              //setModalVisible(true);
            }}
            style={{ marginRight: 8 }}
            className="sideItem_icon_container"
          >
            <img className="sideItem_editIcon" src={EditGray} alt="e" />
          </button>
        </OverlayTrigger>

        <OverlayTrigger
          placement="bottom"
          overlay={
            <Tooltip id="tool-tip">
              <span style={{ textTransform: "capitalize" }}>
                {"Remover correspondente"}
              </span>
            </Tooltip>
          }
        >
          <button className="sideItem_icon_container">
            <img src={RemoveGray} alt="e" />
          </button>
        </OverlayTrigger>
      </div>

      {
        <div
          style={{ display: "none" }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <Dropdown>
            <Dropdown.Toggle className="dropdown-toggle-categoria">
              <UilEllipsisV />
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu-container">
              <Dropdown.Item
                onClick={(e) => {
                  e.preventDefault();
                  setModalNovoCorrespondente(true);
                }}
                className="dropdown-item-categoria"
              >
                <UilPen /> Editar
              </Dropdown.Item>
              <Dropdown.Item className="dropdown-item-categoria">
                <UilTrashAlt /> Remover
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      }
    </S.SidebarItem>
  );
}

function ConciliacaoTR({ conciliacao }: { conciliacao: Conciliation }) {
  const history = useHistory();

  function formatDate() {
    let format = "";

    format = `${conciliacao.ref.substr(6, 2)}/${conciliacao.ref.substr(
      4,
      2
    )}/${conciliacao.ref.substr(0, 4)}`;

    return format;
  }

  async function downloadXLS() {
    try {
      const data = await downloadXLSRequest(conciliacao.id);

      if (data) {
        fileDownload(data, `conciliacao${conciliacao.merchant.name}.xml`);
      }
      console.log(data);
    } catch (error: any) {
      console.log(error);
      if (error.response && error.response.status === 401) {
        history.push("/login");
      }
    }
  }

  return (
    <S.BodyTR>
      <td>{formatDate()}</td>
      <td>{conciliacao.merchant.name}</td>
      <td>
        <span
          onClick={(e) => {
            e.preventDefault();
            downloadXLS();
          }}
          className="link_click"
          style={{ float: "right", paddingRight: 10 }}
        >
          XML
        </span>
      </td>{" "}
    </S.BodyTR>
  );
}
