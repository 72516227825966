import styled from 'styled-components';
//import { colors } from '../../globalStyles/colors';

export const Container = styled.div`
	background: #f5f5f5;
	flex: 1;
	height: 100%;
	display: flex;
	flex-direction: row;
	min-height: 100vh;
	padding: 30px;
	padding-left: 0;

	.flex-row-center {
		display: flex;
		align-items: center;
		flex-direction: row;
	}
`;

export const Sidebar = styled.div`
	background: #ffffff 0% 0% no-repeat padding-box;
	box-shadow: 0px 16px 16px #00000014;
	border-radius: 24px;

	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
	/*padding-right: 16px;
	padding-left: 52px;
*/
	margin-left: -20px;
	//padding-top: 24px;
	width: 340px;

	height: calc(100vh - 60px);
	overflow-y: scroll;
	::-webkit-scrollbar {
		width: 0px;
	}
`;

export const SidebarHeader = styled.div`
	position: sticky;
	top: 0;

	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	background: #ffffff;
	padding-top: 24px;

	margin-left: 20px;
	margin-right: 0px;
	padding-bottom: 24px;
	//width:100%;
	padding-right: 16px;

	padding-left: 32px;
	z-index: 1000;
`;

export const SidebarTitle = styled.p`
	text-align: left;
	text-align: left;
	font: normal normal 500 24px/32px Roboto;
	letter-spacing: 0px;
	color: #222222;
	opacity: 1;
	margin-right: 20px;
	margin: 0;
`;

export const SidebarButton = styled.button`
	cursor: pointer;
	background: #ffffff 0% 0% no-repeat padding-box;
	border: 1px solid #09aa36;
	border-radius: 16px;
	opacity: 1;
	//width: 132px;
	//height: 29px;
	padding: 6px;
	padding-left: 12px;
	padding-right: 12px;

	vertical-align: middle;
	text-align: center;
	font: normal normal 500 12px/16px Roboto;
	letter-spacing: 0px;
	color: #09aa36;
	text-transform: uppercase;
	opacity: 1;
`;

interface SidebarItemProps {
	selected: boolean;
	active?: boolean;
	newHeight?: number;
}

interface SidebarItemContainerProps {
	selected: boolean;
	active?: boolean;
	newHeight?: number;
	overflow?:boolean;
}

export const SidebarSubItem = styled.div<SidebarItemContainerProps>`
	height: 42px;
	border-radius: 21px;
	padding-left: 24px;
	//p/adding-right: 20px;
	display: flex;
	flex-direction: row;

	justify-content: space-between;
	align-items: center;
	margin-left: 16px;
	margin-right: 16px;
	margin-bottom: 8px;

	background: ${(props) => (props.selected ? '#FFFFFF' : 'transparent')} 0% 0%
		no-repeat padding-box;

	box-shadow: ${(props) => (props.selected ? '0px 6px 6px #22222214' : '0')};

	svg {
		width: 20px;
		height: 32px;
		color: #676767;
		//align-self: center;
	}

	:hover {
		//border: 2px solid #09aa36;

		//box-shadow: 0px 16px 16px #0000001f;

		background: #ffffff 0% 0% no-repeat padding-box;
		.sideItem_draggableDot {
			opacity: 1;
		}

		.sideItem_icon_container {
			opacity: 1;
		}
	}

	.sideItem_draggableDot {
		opacity: 0;
		margin-right: 19px;
	}

	.sideItem_name {
		text-align: left;
		font: normal normal ${(props) => (props.selected ? 500 : 400)} 16px/21px
			Roboto;
		letter-spacing: 0px;
		color: #222222;
		//	margin-right: 8px;
	}

	.sideItem_status {
		height: 8px;
		width: 8px;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;

		// background: ${(props) => (props.active ? '#18AA36' : '#F21F4A')} 0% 0%;
		font: normal normal bold 9px/11px Roboto;
		letter-spacing: 0px;
		color: #ffffff;
		text-transform: uppercase;
		vertical-align: center;
		//align-self: center;
		margin-right: 12px;
	}

	.sideItem_icon_container {
		background: transparent 0% 0% no-repeat padding-box;
		border: 1px solid transparent;
		border-radius: 18px;
		width: 36px;
		height: 36px;
		display: flex;
		align-items: center;
		justify-content: center;
		opacity: 0;

		:hover {
			background: #ffffff 0% 0% no-repeat padding-box;
			border: 1px solid #f0f0f0;

			.sideItem_editIcon {
				transform: rotate(-45deg);
				-moz-transform: rotate(-45deg);
				/* Safari and Chrome */
				-webkit-transform: rotate(-45deg);
				/* Opera */
				-o-transform: rotate(-45deg);
				/* IE9 */
				-ms-transform: rotate(-45deg);
				transition: all 0.3s;
			}
		}
	}
	.dropdown-menu-container {
		background: #ffffff 0% 0% no-repeat padding-box;
		box-shadow: 0px 16px 16px #00000014;
		border-top: 0;

		border-radius: 16px;
		border: 1px solid transparent;
	}
	.dropdown-item-categoria {
		font: normal normal normal 14px/21px Roboto;
		letter-spacing: 0px;
		color: #676767 !important;
		align-items: center;
	}
	.dropdown-item-icon {
		width: 19px;
		height: 19px;
		margin-right: 4px;
	}

	.flex_row_center {
		display: flex;
		flex-direction: row;
		align-items: center;
	}
`;

export const SidebarItemContainer = styled.div<SidebarItemContainerProps>`
	background: ${(props) => (props.selected ? '#F8F8F8' : '#ffffff')} 0% 0%
		no-repeat padding-box;
	margin-left: calc(20px + 12px);
	border-radius: 34px;
	margin-bottom: 10px;
	
	overflow: ${({overflow})=>( overflow ? 'hidden': 'visible')};

	width: calc(100% - 20px - 12px - 12px);
	height: ${(props) => (props.newHeight ? props.newHeight : 60)}px;
	transition: height 0.4s;
	margin-right: 12px;

	.subCategoriasContainer {
		background: transparent;
	}
`;

export const SidebarItem = styled.div<SidebarItemProps>`
	//background-color: ;
	.sideItem_draggableDot {
		opacity: 0;
		margin-right: 19px;
	}

	.sideItem_name {
		text-align: left;
		font: normal normal ${(props) => (props.selected ? '500' : '400')} 18px/24px
			Roboto;
		letter-spacing: 0px;
		color: #222222;
		//	margin-right: 8px;
	}

	.sideItem_status {
		height: 8px;
		width: 8px;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;

		background: ${(props) => (props.active ? '#18AA36' : '#F21F4A')} 0% 0%;
		font: normal normal bold 9px/11px Roboto;
		letter-spacing: 0px;
		color: #ffffff;
		text-transform: uppercase;
		vertical-align: center;
		//align-self: center;
		margin-right: 12px;
	}

	.sideItem_icon_container {
		background: transparent 0% 0% no-repeat padding-box;
		border: 1px solid transparent;
		border-radius: 18px;
		width: 36px;
		height: 36px;
		display: flex;
		align-items: center;
		justify-content: center;
		opacity: 0;

		:hover {
			background: #ffffff 0% 0% no-repeat padding-box;
			border: 1px solid #f0f0f0;

			.sideItem_editIcon {
				transform: rotate(-45deg);
				-moz-transform: rotate(-45deg);
				/* Safari and Chrome */
				-webkit-transform: rotate(-45deg);
				/* Opera */
				-o-transform: rotate(-45deg);
				/* IE9 */
				-ms-transform: rotate(-45deg);
				transition: all 0.3s;
			}
		}
	}

	.sideItem_icon_container_open {
		background: transparent 0% 0% no-repeat padding-box;
		border: 1px solid transparent;
		border-radius: 18px;
		width: 36px;
		height: 36px;
		display: flex;
		align-items: center;
		justify-content: center;
		opacity: 1;

		:hover {
			background: #ffffff 0% 0% no-repeat padding-box;
			border: 1px solid #f0f0f0;

			.sideItem_editIcon {
				transform: rotate(-45deg);
				-moz-transform: rotate(-45deg);
				/* Safari and Chrome */
				-webkit-transform: rotate(-45deg);
				/* Opera */
				-o-transform: rotate(-45deg);
				/* IE9 */
				-ms-transform: rotate(-45deg);
				transition: all 0.3s;
			}
		}
	}

	.dropdown-menu-container {
		background: #ffffff 0% 0% no-repeat padding-box;
		box-shadow: 0px 16px 16px #00000014;
		border-top: 0;

		border-radius: 16px;
		border: 1px solid transparent;
	}
	.dropdown-item-categoria {
		font: normal normal normal 14px/21px Roboto;
		letter-spacing: 0px;
		color: #676767 !important;
		align-items: center;
	}
	.dropdown-item-icon {
		width: 19px;
		height: 19px;
		margin-right: 4px;
	}

	.flex_row_center {
		display: flex;
		flex-direction: row;
		align-items: center;
	}

	background: ${(props) => (props.selected ? '#F8F8F8' : '#ffffff')} 0% 0%
		no-repeat padding-box;
	//border-radius: 16px;
	cursor: pointer;

	/**
		background: #F5F5F5 0% 0% no-repeat padding-box;
border-radius: 10px;*/

	border-radius: 34px;
	display: flex;
	flex-direction: row;

	justify-content: space-between;
	//align-items: center;

	height: 60px;
	// overflow-y: hidden;
	margin-bottom: 8px;
	//margin-bottom: 16px;
	padding-left: 20px;
	/*border: ${(props) => (props.selected ? '2px solid #09aa36' : 0)};

	border-top: ${(props) =>
		props.selected ? '2px solid #09aa36' : '1px solid #F0F0F0'};
	border-bottom: ${(props) =>
		props.selected ? '2px solid #09aa36' : '1px solid #F0F0F0'};
	border-left: ${(props) =>
		props.selected ? '2px solid #09aa36' : '2px solid transparent'};
		border-right: ${(props) =>
		props.selected ? '2px solid #09aa36' : '2px solid transparent'};
*/
	svg {
		width: 20px;
		height: 32px;
		color: #676767;
		//align-self: center;
	}

	:hover {
		//border: 2px solid #09aa36;

		//box-shadow: 0px 16px 16px #0000001f;

		background: #f8f8f8 0% 0% no-repeat padding-box;
		.sideItem_draggableDot {
			opacity: 1;
		}

		.sideItem_icon_container {
			opacity: 1;
		}
	}
	transition: all 0.4s;
`;

export const SidebarIconContainer = styled.div<SidebarItemProps>`
	/*background: ${(props) => (props.selected ? '#F5F5F5' : '#FFFFFF')} 0% 0%
		no-repeat padding-box;*/
	border-radius: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-left: 10px;
	margin-right: 10px;
	margin-top: 16px;
	margin-bottom: 16px;
	width: 48px;
	height: 48px;

	svg {
		width: 24px;
		height: 28px;
		color: #09aa36;
	}
`;

export const SidebarItemInfo = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: flex-start;
	margin-top: 16px;
	margin-bottom: 16px;
`;

export const SidebarItemTitle = styled.p`
	font: normal normal 500 16px/21px Roboto;
	letter-spacing: 0px;
	color: #676767;
	margin: 0;
	text-transform: capitalize;
`;

export const SidebarElip = styled.div`
	//position: fixed;
	//top: 4px;
	//right: 18px;
	//background:#000;
	padding-top: 10px;
	padding-right: 10px;

	svg {
		width: 16px;
		height: 36px;
		color: #09aa36;
	}
`;

export const Main = styled.div`
	flex: 1;
	padding-left: 16px;
`;

export const Header = styled.div`
	display: flex;
	flex-direction: row;
	//flex: 1;
	align-items: center;
	padding-left: 20px;
	//padding-right: 16px;

	justify-content: space-between;

	background: #ffffff 0% 0% no-repeat padding-box;
	box-shadow: 0px 16px 16px #00000014;
	border-radius: 24px;
	height: 80px;

	.headerItems {
		display: flex;
		flex-direction: row;
		align-items: center;
	}

	//background:#000
	//max-width:100vw;
`;

interface HeaderTitleProps {
	borderShown?: boolean;
	active?: boolean;
	selectable?: boolean;
}

export const HeaderTitle = styled.p<HeaderTitleProps>`
	text-align: left;
	font: normal normal ${(props) => (props.active ? '500' : '400')} 24px/32px
		Roboto;
	letter-spacing: 0px;
	color: ${(props) => (props.active ? '#222222' : '#9F9F9F')};
	opacity: 1;
	margin-right: 32px;
	border-bottom: ${(props) =>
		props.borderShown ? '2px solid #4BAF50' : '2px solid transparent'};

	cursor: ${(props) => (props.selectable ? 'pointer' : 'default')};
	display: flex;
	flex-direction: row;
	align-items: center;
`;

export const HeaderContainer = styled.div`
	display: flex;
	flex-direction: row;
	height: 80px;
	align-items: center;
`;

export const HeaderButton = styled.button`
	cursor: pointer;
	background: #ffffff;
	border: 1px solid #09aa36;
	box-shadow: 0px 0px 8px #00000029;
	border-radius: 16px;

	padding-right: 16px;
	padding-left: 16px;
	display: flex;
	flex-direction: row;
	align-items: center;
	height: 32px;

	p {
		margin: 0;
		text-align: center;
		font: normal normal 500 12px/16px Roboto;
		letter-spacing: 0px;
		color: #09aa36;
		text-transform: uppercase;
		opacity: 1;
	}

	svg {
		color: #09aa36;
		width: 13px;
		height: 13px;
		margin-right: 10px;
	}
`;

interface HeaderSearchContainerProps {
	active: boolean;
}

export const HeaderSearchContainer = styled.form<HeaderSearchContainerProps>`
	height: 40px;
	display: flex;
	flex-direction: row;

	background: #f0f0f0 0% 0% no-repeat padding-box;
	border-radius: 20px;
	border: 0;
	opacity: 1;
	align-items: center;
	min-width: 230px;

	margin-right: 24px;

	input {
		//display: ${(props) => !props.active && 'none'};
		transition: all 1s;
		width: ${(props) => (!props.active ? 0 : '230px')};
		// margin-left: ${(props) => (!props.active ? 0 : '16px')};
		padding: ${(props) => (!props.active ? 0 : '11px')};
	}

	button {
		cursor: pointer;
		padding: 11px;
		background: transparent;
		border: 0;
		display: flex;
		align-items: center;
	}

	svg {
		color: #09aa36;
		width: 16px;
		height: 16px;
	}
`;

export const HeaderSearchInput = styled.input`
	text-align: left;
	font: normal normal normal 14px/19px Roboto;
	letter-spacing: 0px;
	color: #9f9f9f;
	opacity: 1;
	flex: 1;
	border: 0;
	background-color: transparent;
	//padding:11px;
`;

export const Body = styled.div`
	//padding: 25px;
	padding-bottom: 0;
	margin-top: 17px;
	flex: 1;
	//background:#000;
	background: #ffffff 0% 0% no-repeat padding-box;
	box-shadow: 0px 16px 16px #00000014;
	border-radius: 24px;
	//max-height: calc(100% - 30px - 80px - 17px - 80px - 60px - 500px);
	//height: calc(100% - 97px);

	height: calc(100vh - 97px - 30px - 30px);
	// overflow-y: scroll;

	.body_header {
		padding: 20px;
		display: flex;
		align-items: center;
		height: 80px;
		justify-content: space-between;
	}
`;

export const TableContainer = styled.div`
	//margin-top: 20px;
	//max-height: 505px;
	//max-height: calc(100% - 30px - 80px - 17px - 80px - 60px - 200000px);
	// max-height: calc(100% - 97px - 30px - 80px - 17px - 80px - 60px - 100px - 100px);

	max-height: calc(100vh - 97px - 30px - 30px - 80px);
	// display: none;

	overflow-y: scroll;
	overflow-x: hidden;

	::-webkit-scrollbar {
		width: 0px;
	}
`;

export const Gradient = styled.div`
	position: sticky;
	bottom: 0;
	background: transparent linear-gradient(180deg, #f5f5f500 0%, #f5f5f5 100%) 0%
		0% no-repeat padding-box;
	width: 100%;
	height: 20px;
	margin-top: -20px;

	:hover {
		//	display:none
	}
`;

export const Table = styled.table`
	border: 0;
	width: 100%;
	border-spacing: 0px 0px;

	//border-radius: 10px;
`;

export const THead = styled.thead`
	//background: #09aa36 0% 0% no-repeat padding-box;
	//border-radius: 100px;
	border: 0;
	width: 100%;

	tr {
		//border-radius: 100px;
		height: 40px;
		//background-color:#000
	}

	th {
		//border: solid 1px #000;
		//border-style: none none;
		padding: 10px;
		//background-color: cyan;
		position: -webkit-sticky; /* Safari */
		position: sticky;
		top: 0;
		background: #f4f4f4 0% 0% no-repeat padding-box;
		z-index: 900;

		:first-child {
			border-left-style: solid;
			//border-top-left-radius: 10px;
			//border-bottom-left-radius: 10px;
			padding-left: 24px;
		}
		:last-child {
			border-right-style: solid;
			//border-bottom-right-radius: 10px;
			//border-top-right-radius: 10px;
		}
	}
`;

export const Th = styled.th`
	text-align: left;
	font: normal normal 500 12px/16px Roboto;
	letter-spacing: 0px;
	color: #676767;
	text-transform: uppercase;
	border: 0;
	background-color: transparent;
	//border-radius:10px;
`;

export const TBody = styled.tbody``;

export const BodyTR = styled.tr`
	//padding-top: 22px;
	//padding-bottom: 22px;
	padding-right: 20px;
	padding-left: 20px;
	background-color: #ffffff;

	td {
		//border: solid 1px #000;
		//border-style: none none;
		padding-left: 10px;
		padding-right: 10px;
		//background-color: cyan;
		text-align: left;
		font: normal normal 500 14px/19px Roboto;
		letter-spacing: 0px;
		color: #676767;
		height: 52px;
		border-bottom: 1px #f0f0f0 solid;

		:first-child {
			//border-left-style: solid;
			//border-top-left-radius: 10px;
			//border-bottom-left-radius: 10px;
			padding-left: 24px;
		}
		:last-child {
			//border-right-style: solid;
			//border-bottom-right-radius: 10px;
			//border-top-right-radius: 10px;
		}
	}

	.tableItem_icon_container {
		background: transparent 0% 0% no-repeat padding-box;
		border: 1px solid transparent;
		border-radius: 18px;

		width: 36px;
		height: 36px;
		display: flex;
		align-items: center;
		justify-content: center;

		:hover {
			background: #ffffff 0% 0% no-repeat padding-box;
			border: 1px solid #f0f0f0;

			.tableItem_icon_edit {
				transform: rotate(-45deg);
				-moz-transform: rotate(-45deg);
				/* Safari and Chrome */
				-webkit-transform: rotate(-45deg);
				/* Opera */
				-o-transform: rotate(-45deg);
				/* IE9 */
				-ms-transform: rotate(-45deg);
				transition: all 0.3s;
			}
		}

		.tableItem_icon {
			width: 15px;
			height: 15px;
		}
	}
`;

export const ActionButton = styled.button`
	background: transparent 0% 0% no-repeat padding-box;
	border: 1px solid transparent;

	:hover {
		background: #ffffff 0% 0% no-repeat padding-box;
		border: 1px solid #09aa36;
	}

	border-radius: 10px;
	height: 36px;
	padding-right: 12px;
	padding-left: 12px;

	text-align: center;
	font: normal normal 500 12px/16px Roboto;
	letter-spacing: 0px;
	color: #09aa36;
	opacity: 1;

	margin-right: 5px;

	svg {
		width: 16px;
		height: 16px;
		color: #09aa36;
	}
`;

export const Skeleton = styled.div`
	background-image: linear-gradient(
		-90deg,
		#fafafc 0%,
		#e4e4e4 50%,
		#fafafc 100%
	);
	background-size: 400% 400%;
	animation: shimmer 1.2s ease-in-out infinite;
	@keyframes shimmer {
		0% {
			background-position: 0% 0%;
		}
		100% {
			background-position: -135% 0%;
		}
	}
`;

export const SkeletonGreen = styled.div`
	background-image: linear-gradient(
		-90deg,
		var(--primaryColorDark) 0%,
		#abdb30 50%,
		var(--primaryColorDark) 100%
	);
	background-size: 400% 400%;
	animation: shimmer 1.2s ease-in-out infinite;
	@keyframes shimmer {
		0% {
			background-position: 0% 0%;
		}
		100% {
			background-position: -135% 0%;
		}
	}
`;
