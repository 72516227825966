import React, {
  FormEvent,
  //InputHTMLAttributes,
  useEffect,
  useState,
} from "react";

import {
  Container,
  LoginContainer,
  ImageContainer,
  //LoginForm,
  LoginTitle,
  LoginText,
  PasswordDiv,
  PasswordInput,
  Button,
  CheckBoxButton,
  CheckDiv,
  CheckBoxText,
  LoginTextGreen,
  LoginTextTerms,
  CreditText,
  Alerta,
  LoginFormDiv,
  CenterRow,
  LoginForm2,
  AlertaDifferent,
  //Alerta,
} from "./style";

//import LogoBill from '../../assets/images/logo-bill.svg';

import BillImg from "../../assets/images/bill.svg";

import orangeLogo from "../../assets/images/orange-logo.svg";

//import loadingIcon from '../../assets/images/loading.svg';
import errorWhite from "../../assets/images/errorWhite.svg";
//import errorIcon from '../../assets/images/errorRed.svg';
//import highLightOff from '../../assets/images/highlight-off.svg';
import ClearIcon from "../../assets/images/clearIcon.svg";

//import InputMask from 'react-input-mask';

import { UilEye, UilEyeSlash, UilCheck } from "@iconscout/react-unicons";

import "./styles.css";
import { useHistory, useLocation } from "react-router";
//import {  } from 'react-bootstrap';

import { ReCaptcha } from "react-recaptcha-v3";
import Recaptcha from "./components/Recaptcha";
import api from "../../services/api";
//import { colors } from '../../globalStyles/colors';
import { useRef } from "react";
import { useSnackbar } from "notistack";
import { snackbarErrorHandler } from "../../utils/snackbarErrorHandler";

const TWO_MINUTES = 1000 * 60 * 2;
const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

function RecoverPassword() {
  const history = useHistory();
  const query = useQuery();
  const tokenParam = query.get("token"); // Replace 'paramName' with your actual query parameter name
  const { enqueueSnackbar } = useSnackbar();

  const [showPassword, setShowPassword] = useState(false);

  const [manterConectado, setManterConectado] = useState(false);

  const [usuario, setUsuario] = useState("");
  const [senha, setSenha] = useState("");
  const [senhaConfirm, setSenhaConfirm] = useState("");

  const [loadingCaptcha, setLoadingCaptcha] = useState(true);
  const [googleRecaptcha, setGoogleRecaptcha] = useState<string | undefined>(
    undefined
  );
  const [captcha, setCaptcha] = useState<ReCaptcha>();

  const [loading, setLoading] = useState(false);

  const [focusUser, setFocusUser] = useState(false);
  const [focusPassword, setFocusPassword] = useState(false);
  const [focusPasswordConfirm, setFocusPasswordConfirm] = useState(false);

  const userInput = useRef<any>(null);
  const passwordInput = useRef<any>(null);
  const passwordInputConfirm = useRef<any>(null);

  const [isError, setIsError] = useState(false);

  // renew captcha
  useEffect(() => {
    if (googleRecaptcha)
      setTimeout(() => {
        console.log("useEffect recaptcha");
        if (googleRecaptcha) {
          setGoogleRecaptcha(undefined);
          //if(!googleRecaptcha)
          captcha?.execute();
        }
      }, TWO_MINUTES);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [googleRecaptcha]);

  useEffect(() => {
    sessionStorage.clear();
    localStorage.clear();
  }, []);

  const verifyCallback = (recaptchaToken: string): void => {
    setLoadingCaptcha(false);
    setGoogleRecaptcha(recaptchaToken);
  };

  useEffect(() => {
    if (!googleRecaptcha) {
      setLoadingCaptcha(true);
    } else {
      setLoadingCaptcha(false);
    }
  }, [googleRecaptcha]);

  async function handleSubmit(e: FormEvent) {
    setLoading(true);
    e.preventDefault();
    if (senhaConfirm === "" || senha === "") {
      setIsError(true);

      setLoading(false);
      return;
    }
    const data = {
      password: senha,
      recaptcha: googleRecaptcha,
      token: tokenParam,
    };
    const config = {
      "X-Forwarded-Host":
        window.location.hostname || process.env.REACT_APP_HOST,
    };

    try {
      const response = await api.post("/recovery", data, {
        headers: config,
      });
      //console.log(response.data, 'data');

      /*if(response.data){
				if(response.data.includes('DOCTYPE')){
					console.log('erro')
					throw new Error('Usuario/senha incorreto')
					
				}
			}*/

      setLoading(false);
      enqueueSnackbar("Senha alterada com sucesso", { variant: "success" });

      history.push("/login", { update: true });
    } catch (error: any) {
      console.error(error);
      // alert('Erro no login, verifique usuario e senha');
      snackbarErrorHandler(error, enqueueSnackbar, "Falha a redefinir senha");
      setIsError(true);

      /*setTimeout(() => {
				setIsError(false);
			}, 3000);*/

      setLoading(false);
    }
    /*console.log(usuario);
		console.log(senha);*/
    //const token = sessionStorage.getItem('@bill-token');
  }

  return (
    <Container>
      {
        <ReCaptcha
          ref={(e) => {
            if (e) setCaptcha(e);
          }}
          sitekey={process.env.REACT_APP_RECAPTCHA_KEY as string}
          action="recovery"
          verifyCallback={verifyCallback}
        />
      }
      <Recaptcha googleRecapt={!loadingCaptcha} />

      <LoginContainer>
        <LoginForm2
          onSubmit={(e) => {
            e.preventDefault();
            if (isError) {
              setIsError(false);
            } else handleSubmit(e);
          }}
        >
          <LoginTitle>Alterar senha!</LoginTitle>

          {senhaConfirm !== senha ? (
            <AlertaDifferent error>
              <img
                alt="error"
                src={errorWhite}
                style={{ width: 16, height: 16 }}
              />
              A confirmação de senha deve ser igual a senha
            </AlertaDifferent>
          ) : null}

          <LoginFormDiv style={{ marginTop: 18 }}>
            <LoginText
              //	focus={focusPassword || senha.length > 0}
              onClick={() => {
                if (passwordInput && passwordInput.current)
                  passwordInput.current.focus();
              }}
            >
              Senha
            </LoginText>
            <PasswordDiv
              shadowColor={
                isError && focusPassword
                  ? "rgba(232,69,62,0.75)"
                  : focusPassword
                  ? "rgba(40,167,69,0.75)"
                  : "transparent"
              }
              borderColor={
                isError
                  ? "#E8453E"
                  : focusPassword || senha.length > 0
                  ? "#28A745"
                  : "#9f9f9f"
              }
              onClick={() => {
                if (passwordInput && passwordInput.current)
                  passwordInput.current.focus();
              }}
              style={{
                //marginTop: 20,
                transition: "all 0.3s",
                //borderBottomColor: !isError ? colors.default : 'rgb(251,41,43)',
                //height: 30,
              }}
            >
              <PasswordInput
                placeholder={"Insira a senha"}
                ref={passwordInput}
                value={senha}
                onChange={(e) => {
                  setSenha(e.target.value);
                }}
                type={showPassword ? "text" : "password"}
                //placeholder='Senha'
                onFocus={() => {
                  setFocusPassword(true);
                }}
                onBlur={() => {
                  setFocusPassword(false);
                }}
              />
              <button
                type="button"
                style={{
                  padding: 0,
                  margin: 0,
                  border: 0,
                  background: "transparent",
                  transition: "all 0.5s",
                  cursor: "pointer",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  if (!isError) setShowPassword(!showPassword);
                  else {
                    setIsError(false);
                  }
                }}
              >
                {showPassword ? (
                  <UilEye size="16" color={"#9F9F9F"} />
                ) : (
                  <UilEyeSlash size="16" color={"#9F9F9F"} />
                )}
              </button>
            </PasswordDiv>
          </LoginFormDiv>

          <LoginFormDiv style={{ marginTop: 18 }}>
            <LoginText
              //	focus={focusPassword || senha.length > 0}
              onClick={() => {
                if (passwordInputConfirm && passwordInputConfirm.current)
                  passwordInputConfirm.current.focus();
              }}
            >
              Confirmar senha
            </LoginText>
            <PasswordDiv
              shadowColor={
                isError && focusPasswordConfirm
                  ? "rgba(232,69,62,0.75)"
                  : focusPasswordConfirm
                  ? "rgba(40,167,69,0.75)"
                  : "transparent"
              }
              borderColor={
                isError
                  ? "#E8453E"
                  : focusPasswordConfirm || senha.length > 0
                  ? "#28A745"
                  : "#9f9f9f"
              }
              onClick={() => {
                if (passwordInputConfirm && passwordInputConfirm.current)
                  passwordInputConfirm.current.focus();
              }}
              style={{
                //marginTop: 20,
                transition: "all 0.3s",
                //borderBottomColor: !isError ? colors.default : 'rgb(251,41,43)',
                //height: 30,
              }}
            >
              <PasswordInput
                placeholder={"Insira a senha"}
                ref={passwordInputConfirm}
                value={senhaConfirm}
                onChange={(e) => {
                  setSenhaConfirm(e.target.value);
                }}
                type={showPassword ? "text" : "password"}
                //placeholder='Senha'
                onFocus={() => {
                  setFocusPasswordConfirm(true);
                }}
                onBlur={() => {
                  setFocusPasswordConfirm(false);
                }}
              />
              <button
                type="button"
                style={{
                  padding: 0,
                  margin: 0,
                  border: 0,
                  background: "transparent",
                  transition: "all 0.5s",
                  cursor: "pointer",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  if (!isError) setShowPassword(!showPassword);
                  else {
                    setIsError(false);
                  }
                }}
              >
                {showPassword ? (
                  <UilEye size="16" color={"#9F9F9F"} />
                ) : (
                  <UilEyeSlash size="16" color={"#9F9F9F"} />
                )}
              </button>
            </PasswordDiv>
          </LoginFormDiv>

          <Button
            loading={loading}
            isError={isError}
            disabled={loadingCaptcha}
            style={{
              marginTop: 20,
              filter: !loadingCaptcha ? "grayscale(0)" : "grayscale(1)",
            }}
          >
            {isError ? "Tentar novamente" : "Alterar senha"}
          </Button>
          {/* <CenterRow>
						<CheckBoxText style={{ marginBottom: 0 }}>
							Não tem conta ainda?
						</CheckBoxText>
						<LoginTextGreen style={{ marginBottom: 0, marginLeft: 5 }}>
							Cadastre-se
						</LoginTextGreen>
					</CenterRow> */}
          {/* <LoginTextGreen style={{}}>Esqueceu sua senha? </LoginTextGreen> */}

         
        </LoginForm2>
        {}

       
      </LoginContainer>

      <ImageContainer>
        {/* <>
				<img style={{ height: 100,width:189 }} src={BillImg} alt='Bill' />
				<p className='login_big_text'>
				Seu gestor descomplicado de pagamentos
				</p>
				<div className='login_footer'>
					<CreditText>Desenvolvido por</CreditText>
					<img style={{width:104,height:30}} alt='orange' src={orangeLogo} />
				</div>
				</> */}
      </ImageContainer>
    </Container>
  );
}

export default RecoverPassword;
