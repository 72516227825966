import styled from "styled-components";

export const NotFoundWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
  width: 100%;
  height: 100%;
`;

export const NotFoundImage = styled.img`
  width: 171.26px;
  height: 128px;
`;

export const NotFoundText = styled.div`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  text-align: center;

  color: #212121;
`;
