import React, {
  FormEvent,
  //InputHTMLAttributes,
  useEffect,
  useState,
} from "react";

import {
  Container,
  LoginContainer,
  ImageContainer,
  //LoginForm,
  LoginTitle,
  LoginText,
  PasswordDiv,
  PasswordInput,
  Button,
  CheckBoxButton,
  CheckDiv,
  CheckBoxText,
  LoginTextGreen,
  LoginTextTerms,
  CreditText,
  Alerta,
  LoginFormDiv,
  CenterRow,
  LoginForm2,
  AlertaDifferent,
  //Alerta,
} from "./style";

//import LogoBill from '../../assets/images/logo-bill.svg';

import BillImg from "../../assets/images/bill.svg";

import orangeLogo from "../../assets/images/orange-logo.svg";

//import loadingIcon from '../../assets/images/loading.svg';
import errorWhite from "../../assets/images/errorWhite.svg";
//import errorIcon from '../../assets/images/errorRed.svg';
//import highLightOff from '../../assets/images/highlight-off.svg';
import ClearIcon from "../../assets/images/clearIcon.svg";

//import InputMask from 'react-input-mask';

import { UilEye, UilEyeSlash, UilCheck } from "@iconscout/react-unicons";

import "./styles.css";
import { useHistory, useLocation } from "react-router";
//import {  } from 'react-bootstrap';

import { ReCaptcha } from "react-recaptcha-v3";
import Recaptcha from "./components/Recaptcha";
import api from "../../services/api";
//import { colors } from '../../globalStyles/colors';
import { useRef } from "react";
import { useSnackbar } from "notistack";
import { snackbarErrorHandler } from "../../utils/snackbarErrorHandler";

const TWO_MINUTES = 1000 * 60 * 2;
const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

function ForgotPasswordPage() {
  const history = useHistory();
  const query = useQuery();
  const tokenParam = query.get("token"); // Replace 'paramName' with your actual query parameter name
  const { enqueueSnackbar } = useSnackbar();

  const [showPassword, setShowPassword] = useState(false);

  const [manterConectado, setManterConectado] = useState(false);

  const [usuario, setUsuario] = useState("");
  const [senha, setSenha] = useState("");
  const [senhaConfirm, setSenhaConfirm] = useState("");

  const [loadingCaptcha, setLoadingCaptcha] = useState(true);
  const [googleRecaptcha, setGoogleRecaptcha] = useState<string | undefined>(
    undefined
  );
  const [captcha, setCaptcha] = useState<ReCaptcha>();

  const [loading, setLoading] = useState(false);

  const [focusUser, setFocusUser] = useState(false);
  const [focusPassword, setFocusPassword] = useState(false);
  const [focusPasswordConfirm, setFocusPasswordConfirm] = useState(false);

  const userInput = useRef<any>(null);
  const passwordInput = useRef<any>(null);
  const passwordInputConfirm = useRef<any>(null);

  const [isError, setIsError] = useState(false);

  // renew captcha
  useEffect(() => {
    if (googleRecaptcha)
      setTimeout(() => {
        console.log("useEffect recaptcha");
        if (googleRecaptcha) {
          setGoogleRecaptcha(undefined);
          //if(!googleRecaptcha)
          captcha?.execute();
        }
      }, TWO_MINUTES);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [googleRecaptcha]);

  useEffect(() => {
    sessionStorage.clear();
    localStorage.clear();
  }, []);

  const verifyCallback = (recaptchaToken: string): void => {
    setLoadingCaptcha(false);
    setGoogleRecaptcha(recaptchaToken);
  };

  useEffect(() => {
    if (!googleRecaptcha) {
      setLoadingCaptcha(true);
    } else {
      setLoadingCaptcha(false);
    }
  }, [googleRecaptcha]);

  async function handleSubmit(e: FormEvent) {
    setLoading(true);
    e.preventDefault();
    if (usuario === "" ) {
      setIsError(true);

      setLoading(false);
      return;
    }

    const data = {
      username: usuario,
      recaptcha: googleRecaptcha,
    };
    const config = {
      "X-Forwarded-Host":
        window.location.hostname || process.env.REACT_APP_HOST,
    };

    try {
      const response = await api.post("/recovery/request", data, {
        headers: config,
      });
      //console.log(response.data, 'data');

      /*if(response.data){
				if(response.data.includes('DOCTYPE')){
					console.log('erro')
					throw new Error('Usuario/senha incorreto')
					
				}
			}*/

      setLoading(false);
      enqueueSnackbar("E-mail de alteração se senha enviado com sucesso!", {
        variant: "success",
      });

      history.push("/login", { update: true });
    } catch (error: any) {
      console.error(error);
      // alert('Erro no login, verifique usuario e senha');
      snackbarErrorHandler(
        error,
        enqueueSnackbar,
        "Falha a soliticar redefinição de senha, verifique se o usuário está correto"
      );
      setIsError(true);

      /*setTimeout(() => {
				setIsError(false);
			}, 3000);*/

      setLoading(false);
    }
    /*console.log(usuario);
		console.log(senha);*/
    //const token = sessionStorage.getItem('@bill-token');
  }

  return (
    <Container>
      {
        <ReCaptcha
          ref={(e) => {
            if (e) setCaptcha(e);
          }}
          sitekey={process.env.REACT_APP_RECAPTCHA_KEY as string}
          action="recovery"
          verifyCallback={verifyCallback}
        />
      }
      <Recaptcha googleRecapt={!loadingCaptcha} />

      <LoginContainer>
        <LoginForm2
          onSubmit={(e) => {
            e.preventDefault();
            if (isError) {
              setIsError(false);
            } else handleSubmit(e);
          }}
        >
          <LoginTitle>Esqueceu a senha?</LoginTitle>

          <LoginFormDiv>
            <LoginText
              onClick={() => {
                if (userInput && userInput.current) userInput.current.focus();
              }}
            >
              {"Informe seu usuário ou e-mail"}
            </LoginText>

            <PasswordDiv
              shadowColor={
                isError && focusUser
                  ? "rgba(232,69,62,0.75)"
                  : focusUser
                  ? "rgba(40,167,69,0.75)"
                  : "transparent"
              }
              borderColor={
                isError
                  ? "#E8453E"
                  : focusUser || usuario.length > 0
                  ? "#28A745"
                  : "#9f9f9f"
              }
              onClick={() => {
                if (userInput && userInput.current) userInput.current.focus();
              }}
              style={{
                //marginTop: 20,
                transition: "all 0.3s",
                //borderBottomColor: !isError ? colors.default : 'rgb(251,41,43)',
                //height: 30,
              }}
            >
              <PasswordInput
                //className='login-user-mask'
                ref={userInput}
                value={usuario}
                onChange={(e) => {
                  setUsuario(e.target.value);
                }}
                onFocus={() => {
                  setFocusUser(true);
                }}
                onBlur={() => {
                  setFocusUser(false);
                }}
                placeholder="usuario@email.com"

                //mask='99.999.999/9999-99'
              />
              <div
                //type='div'
                style={{
                  padding: 0,
                  margin: 0,
                  border: 0,
                  background: "transparent",
                  transition: "all 0.5s",
                  cursor: "pointer",
                }}
              >
                {usuario.length > 0 ? (
                  <img
                    src={ClearIcon}
                    style={{ width: 16, height: 16, borderRadius: 10 }}
                    alt="off"
                    onClick={(e) => {
                      e.preventDefault();
                      setUsuario("");
                    }}
                  />
                ) : null}
              </div>
            </PasswordDiv>
          </LoginFormDiv>

          <Button
            loading={loading}
            isError={isError}
            disabled={loadingCaptcha}
            style={{
              marginTop: 20,
              filter: !loadingCaptcha ? "grayscale(0)" : "grayscale(1)",
            }}
          >
            {isError ? "Tentar novamente" : "Solicitar redefinição de senha"}
          </Button>
          {/* <CenterRow>
						<CheckBoxText style={{ marginBottom: 0 }}>
							Não tem conta ainda?
						</CheckBoxText>
						<LoginTextGreen style={{ marginBottom: 0, marginLeft: 5 }}>
							Cadastre-se
						</LoginTextGreen>
					</CenterRow> */}
          {/* <LoginTextGreen style={{}}>Esqueceu sua senha? </LoginTextGreen> */}
        </LoginForm2>
        {}
      </LoginContainer>

      <ImageContainer>
        {/* <>
				<img style={{ height: 100,width:189 }} src={BillImg} alt='Bill' />
				<p className='login_big_text'>
				Seu gestor descomplicado de pagamentos
				</p>
				<div className='login_footer'>
					<CreditText>Desenvolvido por</CreditText>
					<img style={{width:104,height:30}} alt='orange' src={orangeLogo} />
				</div>
				</> */}
      </ImageContainer>
    </Container>
  );
}

export default ForgotPasswordPage;
