import api from "../../services/api";
import { PaymentRequestDetail } from "./ReopenPaymentModal/Models";



export async function getPaymentInvoiceRequest(invoiceId: string | number) {
  const token = sessionStorage.getItem("@bill-token");

  try {
    const response = await api.get(
      `/admin/api/cancellation-request/invoice?invoice=${invoiceId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const { data } = response;

    return data;
  } catch (error: any) {
    console.log(error);
    throw error;
  }
}

export async function postCreateCancellationRequest(invoiceId: number|string): Promise<PaymentRequestDetail> {
  const token = sessionStorage.getItem("@bill-token");

  try {
    const response = await api.post(
      `/admin/api/cancellation-request`,
      { invoice: invoiceId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const { data } = response;

    return data.cancellation_request
    ;
  } catch (error: any) {
    console.log(error);
    throw error;
  }
}

export async function postPaymentCancellationRequest(
  processId: number,
  postObj: any
) {
  const token = sessionStorage.getItem("@bill-token");

  try {
    const response = await api.post(
      `/admin/api/cancellation-request/${processId}/process`,
      postObj,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const { data } = response;

    return data;
  } catch (error: any) {
    console.log(error);
    throw error;
  }
}
