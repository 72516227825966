/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';

import {
	UilCalendarAlt,
	UilSearch,
	UilFilterSlash,
} from '@iconscout/react-unicons';

import {
	Body,
	Container,
	Header,
	HeaderButton,
	HeaderExport,
	HeaderExportText,
	HeaderSearchContainer,
	HeaderSearchInput,
	HeaderTitle,
} from './style';

import {
	ActionButton,
	BodyTR,
	Table,
	TableContainer,
	TBody,
	Th,
	THead,
} from '../categorias/style';
import { useHistory } from 'react-router';

export default function Configuracoes() {
	const [searchActive, setSearchActive] = useState(false);
	const history = useHistory()

	const [configuracoes, setConfiguracoes] = useState([
		{
			nome: 'Orange-labs',
			CNPJ: '34.895.008/0001-85',
			chaveAfiliacao: '13141DAWFWG4T2',
		},
	]);
	useEffect(() => {
		const login = localStorage.getItem('@bill-login');
		if (login) {
			const l = JSON.parse(login);
			if (!(l && l.type === 999)) {
				history.replace('/');
			}
		} else {
			history.replace('/');
		}
	}, []);
	return (
		<Container>
			<Header>
				<HeaderTitle>Configurações</HeaderTitle>
				<HeaderSearchContainer active={searchActive}>
					<HeaderSearchInput placeholder='Cliente, ATK, UC...' />
					<button
						type='button'
						onClick={(e) => {
							e.preventDefault();
							console.log(searchActive);
							setSearchActive(!searchActive);
						}}>
						<UilSearch />
					</button>
				</HeaderSearchContainer>
			</Header>
			<Body>
				<TableContainer>
					<Table>
						<THead>
							<tr>
								<Th>Nome</Th>
								<Th>CNPJ</Th>
								<Th>Chave de Afiliação</Th>
								<Th></Th>
							</tr>
						</THead>
						<TBody>
							{configuracoes.map((configuracao) => {
								return <TRConfig item={configuracao} />;
							})}
						</TBody>
					</Table>
				</TableContainer>
			</Body>
		</Container>
	);
}

function TRConfig({ item }: any) {
	return (
		<BodyTR>
			<td>{item.nome}</td>
			<td>{item.CNPJ}</td>
			<td>{item.chaveAfiliacao}</td>
			<td>
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						float: 'right',
					}}>
					<ActionButton
						onClick={(e) => {
							e.preventDefault();
							//setModalNovaEmpresa(true);
						}}>
						Editar
					</ActionButton>
				</div>
			</td>
		</BodyTR>
	);
}
