import React from 'react';
import './App.css';
import Routes from './pages/route';
import { loadReCaptcha } from 'react-recaptcha-v3';
import GlobalStyle from './styles/global';
import Dev from './components/LocalMode';

function App() {
	const siteKey = process.env.REACT_APP_RECAPTCHA_KEY as string;
	loadReCaptcha(siteKey);
	// console.log(process.env.REACT_APP_RECAPTCHA_KEY, 'recaptcha')

	const token = localStorage.getItem('@bill-token');

	if (token) sessionStorage.setItem('@bill-token', token);

	return (
		<div className='App'>
			{<Dev />}
			<GlobalStyle />
			<Routes />
		</div>
	);
}

export default App;
