/* 
  filename: Dropzone.js 
*/

import React from 'react';
// Import the useDropzone hooks from react-dropzone
import { useDropzone } from 'react-dropzone';
//import styled from 'styled-components';

//import { UilCamera } from '@iconscout/react-unicons';


const Dropzone = ({ onDrop, accept }) => {
	// Initializing useDropzone hooks with options
	const { getRootProps, getInputProps } = useDropzone({
		onDrop,
		accept,
		multiple: false,
	});

	/* 
    useDropzone hooks exposes two functions called getRootProps and getInputProps
    and also exposes isDragActive boolean
  */

	return (
		<div
			style={{
				width: '100%',
				height: '100%',
				
				paddingTop: 10,
				paddingBottom: 10,
				outline: 'none',
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				justifyContent: 'center',
			}}
			{...getRootProps()}>
			<input className='dropzone-input' {...getInputProps()} />
			<div />
			<p style={{ textAlign: 'center', fontSize: 12, fontFamily: 'Roboto' }}>
				Arraste um arquivo ou{' '}
				<span style={{ textDecoration: 'underline', color: '#18AA36' }}>
					procure no computador
				</span>
			</p>
			<p style={{ textAlign: 'center', fontSize: 12, fontFamily: 'Roboto' }}>
				Formato aceito .PNG
				
			</p>
			{/*<UilCamera />

			<Button>Escolher</Button>*/}
		</div>
	);
};

export default Dropzone;

export function WithDropZone({ onDrop, accept, children }) {
	const { getRootProps, getInputProps } = useDropzone({
		onDrop,
		accept,
		multiple: false,
	});

	return (
		<div
			style={{
				width: 'fit-content',
				height: '100%',
				marginTop: -10,
				marginBottom: -10,
				paddingTop: 10,
				paddingBottom: 10,
				outline: 'none',
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'flex-start',
				justifyContent: 'space-between',
				cursor: 'pointer',
				//position: 'relative',
			}}
			{...getRootProps()}>
			<input className='dropzone-input' {...getInputProps()} />

			{children}
		</div>
	);
}
