import styled from 'styled-components'
import { ContainerRowProps } from './Models'

export const GlobalRow = styled.div<ContainerRowProps>`
  display: flex;
  flex-direction: row;

  align-items: ${({ alignItems }) => alignItems || 'center'};
  justify-content: ${({ justifyContent }) => justifyContent || 'center'};
  gap: ${({ gap }) => gap || 0};
`
