import styled from "styled-components";
import { colors } from "../../globalStyles/colors";

interface Opened {
  open: boolean;
}

export const Menu = styled.div<Opened>`
  min-height: calc(100vh - 60px);
  max-width: ${(props) => (props.open ? "217px" : "100px")};
  min-width: ${(props) => (props.open ? "214px" : "100px")};
  width: 100%;

  background-color: ${(props) => (props.open ? "#FFFFFF" : "#F5F5F5")};
  /* background-color: ${(props) => (props.open ? "#18AA36" : "#F5F5F5")}; */
  //margin-right: 12px;

  transition: all 1s;
  border-radius: 24px;
  margin-top: 30px;
  margin-bottom: 30px;
  z-index: 20;

  //position:fixed;
`;

export const MenuItems = styled.div<Opened>`
  position: fixed;
  background-color: "#F5F5F5";
  //background-color: ${(props) => (props.open ? "#e5e5e5" : "#F5F5F5")};
  border-radius: 50px;
  margin-left: 10px;

  // background-color:#000;
  min-height: calc(100vh - 60px);
  max-height: calc(100vh - 60px);
  max-width: ${(props) => (props.open ? "217px" : "100px")};
  min-width: ${(props) => (props.open ? "210px" : "100px")};
  display: flex;
  flex-direction: column;
  //align-items: center;
  transition: all 1s;

  //z-index:2;
`;

export const MenuHeader = styled.div<Opened>`
  height: 64px;
  width: 100%;
  display: flex;
  //justify-content:center;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  //transition: all 1s;
  transition: all 1s;
  margin-bottom: 20px;
  margin-top: 10px;
`;

export const MenuImg = styled.img<Opened>`
  width: ${(props) => (props.open ? "90px" : "32px")};
  height: 32px;
  //margin-left: 40px;
  //margin-left: ${(props) => (!props.open ? "" : "40px")};
  //transition: all 1s;
`;

export const MenuItem = styled.button<Props>`
  border: 0;
  cursor: pointer;

  border-radius: 21px;

  height: 42px;
  position: relative;

  background: ${(props) => (props.active ? colors.default : "transparent")};
  //background: ${(props) => (props.active ? "#FFFFFF" : "transparent")};

  margin-left: ${(props) => (!props.open ? "24px" : 0)};
  //margin-right: 24px;

  display: ${(props) => (!props.open ? "flex" : "flex")};
  flex-direction: row;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;

  padding-top: 16px;
  padding-bottom: 16px;
  width: ${(props) => (props.open ? "184px" : "48px")};
  //width: calc(100% - 16px);
  transition: all 0.4s;
  //transition: all 1s;

  justify-content: flex-start;

  overflow: hidden;

  text-align: left;

  margin-bottom: 10px;
  border: 1px solid transparent;

  .menuGreenDot {
    position: absolute;
    right: 10px;
    background: #93cc2f;
    width: 8px;
    height: 8px;
    border-radius: 4px;
    display: ${(props) => (props.open && props.active ? "block" : "none")};
    //opacity: ${(props) => (props.open && props.active ? 1 : 0)};
    bottom: 17px;
    //transition: all 3s;
  }

  .invoice_menu_icon_on {
    margin-right: ${(props) => (props.open ? "16px" : "")};
    min-width: 16px;
    min-height: 16px;
    width: 16px;
    height: 16px;

    display: ${(props) => (!props.active || !props.open ? "block" : "none")};
  }

  .invoice_menu_icon_off {
    display: ${(props) => ((props.active || !props.open) ? "block" : "none")};

    margin-right: ${(props) => (props.open ? "16px" : "")};
    min-width: 16px;
    min-height: 16px;
    width: 16px;
    height: 16px;
    //color: ${(props) => (props.active ? "#09aa36" : "#9f9f9f")};
  }

  svg {
    margin-right: ${(props) => (props.open ? "16px" : "")};
    min-width: 16px;
    min-height: 16px;
    width: 16px;
    height: 16px;
    //color: ${(props) => (props.active ? "#09aa36" : "#9f9f9f")};
    color: ${(props) => (!props.active ? colors.strongFont : colors.white)};
    color: ${(props) =>
      props.open ? "" : !props.active ? colors.strongFont : colors.default};
    fill: ${(props) => (!props.active ? colors.strongFont : colors.white)};
    fill: ${(props) =>
      props.open ? "" : !props.active ? colors.strongFont : colors.strongFont};

    //transition: all 1s;
  }
  p {
    font: ${(props) =>
      props.active
        ? "normal normal 500 14px/19px Roboto"
        : "normal normal normal 14px/19px Roboto"};
    color: ${(props) => (!props.active ? colors.strongFont : colors.white)};
    color: ${(props) => (props.open ? "" : "transparent")};
    text-transform: capitalize;
  }

  :hover {
    //background-color: #ffffff;
    background-color: ${(props) =>
      !props.active
        ? colors.white
        : props.open
        ? colors.default
        : colors.white};

    border: ${(props) =>
      props.active ? `1px solid ${colors.default}` : "1px solid #212121"};

    transition: border 0s;

    .menuGreenDot {
      display: ${(props) => (props.active ? "block" : "none")};
    }

    svg {
      margin-right: ${(props) => (props.open ? "16px" : "")};
      //transition: all 1s;

      width: 16px;
      height: 16px;
      //color: #FFFFFF;
    }
    p {
      font: normal normal 500 14px/19px Roboto;
      font-size: ${(props) => (props.open ? "14px" : 0)};
      //	transition: all 1s;

      //color: #09aa36;
      //color: #FFFFFF;
    }
  }
`;

interface Props {
  active: boolean;
  open: boolean;
}

export const MenuText = styled.p`
  text-align: left;

  letter-spacing: 0px;

  opacity: 1;
  margin: 0;
`;

export const UserContainer = styled.div<Opened>`
  position: absolute;
  bottom: 40px;
  left: ${(props) => (props.open ? "40px" : "24px")};

  display: flex;
  flex-direction: column;

  p {
    margin: 0;
  }

  align-items: ${(props) => (props.open ? "" : "center")};

  svg {
    width: 16px;
    height: 19px;
    color: #9f9f9f;
    margin-top: 8px;
  }
`;

export const UserName = styled.p`
  text-align: left;
  font: normal normal 500 14px/19px Roboto;
  letter-spacing: 0px;
  color: #676767;
  text-transform: capitalize;
`;
export const UserEmail = styled.p`
  text-align: left;
  font: normal normal normal 12px/16px Roboto;
  letter-spacing: 0px;
  color: #676767;
  opacity: 1;
`;

export const LogOutButton = styled.button`
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #09aa36;
  border-radius: 10px;
  opacity: 1;

  max-width: 72px;
  max-height: 32px;

  text-align: center;
  font: normal normal 500 14px/19px Roboto;
  letter-spacing: 0px;
  color: #09aa36;
  opacity: 1;
  padding-top: 6px;
  padding-bottom: 6px;
  margin-top: 12px;
`;

export const UserIcon = styled.div`
  background: #09aa36 0% 0% no-repeat padding-box;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  p {
    text-align: center;
    font: normal normal 500 20px/26px Roboto;
    letter-spacing: 0px;
    color: #ffffff;
    vertical-align: middle;
    margin: 0;
    text-transform: uppercase;
  }
`;

export const Footer = styled.div<Opened>`
  position: absolute;
  bottom: 10px;
  width: ${(props) => (props.open ? "194px" : 0)};

  overflow: hidden;

  background: #0d5e1e 0% 0% no-repeat padding-box;
  border-radius: 18px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 1s;

  img {
    color: #000;
    height: 15px;
  }
`;
