import styled from 'styled-components';
//import { colors } from '../../globalStyles/colors';

export const Container = styled.div`
	background: #f5f5f5;
	flex: 1;
	height: 100%;
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	//flex-direction: row;
	//max-width:100vw;

	padding: 30px;
	padding-left: 0;
	padding-right: 0;
	padding-bottom: 0;

	.mr-30 {
		margin-right: 30px;
	}

	.ml-15 {
		margin-left: 15px;
	}

	max-width: calc(100vw - 60px - 127px - 60px);
`;

export const Header = styled.div`
	display: flex;
	flex-direction: row;
	height: 80px;
	//flex: 1;
	align-items: center;
	padding-left: 20px;
	//padding-right: 20px;
	padding-top: 24px;
	padding-bottom: 24px;

	border: solid;
	border-top: 0;
	border-left: 0;
	border-right: 0;

	border-bottom-width: 1px;
	border-color: #e5e5e5;

	background: #ffffff 0% 0% no-repeat padding-box;
	box-shadow: 0px 16px 16px #00000014;
	//box-shadow: 0px 16px 16px #F00;
	border-radius: 24px;

	position: relative;

	margin-bottom: 16px;
	//background:#000
	//max-width:100vw;
	margin-right: 30px;
	justify-content: space-between;
`;

export const HeaderContainer = styled.div`
	display: flex;
	flex-direction: row;
	height: 80px;
	align-items: center;
`;

export const HeaderTitle = styled.p`
	text-align: left;
	font: normal normal 500 24px/32px Roboto;
	letter-spacing: 0px;
	color: #222222;
	opacity: 1;
	text-transform: capitalize;
	margin-right: 23px;
`;

interface HeaderButtonProps {
	active: boolean;
}

export const HeaderButton = styled.button<HeaderButtonProps>`
	cursor: pointer;
	background: ${(props) => (props.active ? '#18AA36' : '#F2F2F2')};
	//border: ${(props) => (props.active ? '1px solid #09aa36' : 0)};
	border: 0;
	box-shadow: ${(props) => (props.active ? '0px 0px 8px #00000029' : 0)};
	border-radius: 15px;
	padding-top: 6px;
	padding-bottom: 6px;
	padding-right: 16px;
	padding-left: 16px;
	margin-right: 8px;
	display: flex;
	flex-direction: row;
	align-items: center;

	p {
		text-align: center;
		font: normal normal normal 12px/16px Roboto;
		letter-spacing: 0px;
		color: ${(props) => (props.active ? '#FFFFFF' : '#676767')};
		text-transform: uppercase;
		margin: 0;
	}

	.MenuPersonalizadoSvgContainer {
		background: ${(props) =>
			props.active
				? '#0D5E1E 0% 0% no-repeat padding-box'
				: 'transparent 0% 0% no-repeat padding-box'};
		margin-right: 3px;
		width: 44px;
		height: 29px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 15px;
		margin-top: -6px;
		margin-bottom: -6px;
		margin-left: -16px;
	}

	svg {
		color: ${(props) => (props.active ? '#FFFFFF' : '#676767')};
		width: 13px;
		height: 13px;
	}
`;

export const HeaderUserContainer = styled.div`
	position: absolute;
	right: 20px;

	height: 40px;

	.dropdown-toggle-categoria {
		padding: 0;
	}

	.header_user_flex {
		display: flex;
		align-items: center;
		cursor: pointer;
	}

	.user_circle {
		background: #ffffff 0% 0% no-repeat padding-box;
		border: 1px solid #18aa36;
		opacity: 1;
		height: 40px;
		width: 40px;
		border-radius: 20px;
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		font: normal normal 500 14px/19px Roboto;
		letter-spacing: 0px;
		color: #18aa36;
		text-transform: uppercase;
		vertical-align: center;
	}
`;

interface HeaderSearchContainerProps {
	active: boolean;
}

export const HeaderSearchContainer = styled.div<HeaderSearchContainerProps>`
	position: absolute;
	right: 32px;
	top: 16px;
	display: flex;
	flex-direction: row;

	background: #ffffff 0% 0% no-repeat padding-box;
	border: 1px solid #09aa36;
	border-radius: 10px;
	opacity: 1;
	align-items: center;

	input {
		//display: ${(props) => !props.active && 'none'};
		transition: all 1s;
		width: ${(props) => (!props.active ? 0 : '150px')};
		margin-left: ${(props) => (!props.active ? 0 : '16px')};
		padding: ${(props) => (!props.active ? 0 : '11px')};
	}

	button {
		cursor: pointer;
		padding: 11px;
		background: transparent;
		border: 0;
		display: flex;
		align-items: center;
	}

	svg {
		color: #09aa36;
		width: 16px;
		height: 16px;
	}
`;

export const HeaderSearchInput = styled.input`
	text-align: left;
	font: normal normal normal 14px/19px Roboto;
	letter-spacing: 0px;
	color: #9f9f9f;
	opacity: 1;
	flex: 1;
	border: 0;
	background-color: transparent;
	//padding:11px;
`;

interface BodyProps {
	showScroll?: boolean;
}

export const Body = styled.div<BodyProps>`
	//padding: 25px;
	padding-bottom: 0;
	flex: 1;
	padding-left: 15px;

	overflow-y: scroll;
	overflow-x: visible;
	//background:#000;

	max-height: calc(100vh - 80px - 16px - 60px + 30px);

	::-webkit-scrollbar {
		background: transparent;

		// display:none;

		width: 0;
	}

	&::-webkit-scrollbar-track {
		/*  margin-top:0;
      border-bottom-right-radius: 16px;
      background-image: url("https://i.stack.imgur.com/Pwbuz.png");
    background-repeat: repeat-y;
    background-size: contain;
      */
		// margin-top: 54px;
	}
	&::-webkit-scrollbar-thumb {
		background: ${(props) =>
			props.showScroll ? 'rgba(103, 103, 103, 0.5)' : 'transparent'};
		border-radius: 20px;
		border: 2.5px solid rgba(0, 0, 0, 0);
		border-right: 5px solid rgba(0, 0, 0, 0);

		background-clip: padding-box;
		max-height: 5px;
		transition: all 1s;
	}
`;

export const Card = styled.div`
	background: #ffffff 0% 0% no-repeat padding-box;
	box-shadow: 0px 16px 16px #00000014;
	border-radius: 24px;
	opacity: 1;
	display: flex;
	flex-direction: row;
	flex: 1;
	//margin-bottom: 15px;
`;

export const CardRow = styled.div`
	background: #ffffff 0% 0% no-repeat padding-box;
	box-shadow: 0px 16px 16px #00000014;
	border-radius: 24px;
	opacity: 1;
	display: flex;
	flex-direction: row;
	margin-bottom: 16px;
	//margin-right: 13px;
	flex: 1;
`;

export const CardItem = styled.div`
	margin-left: 15px;
	margin-right: 15px;
	flex: 1;
	padding: 20px;
`;

export const CardMeasureTitle = styled.p`
	margin: 0;
	font: normal normal 500 15px/20px Roboto;
	letter-spacing: 0px;
	color: #222222;
	opacity: 1;
	text-align: left;
`;

export const CardTitle = styled.p`
	text-align: left;
	font: normal normal 500 20px/26px Roboto;
	letter-spacing: 0px;
	color: #222222;
	opacity: 1;
	margin-bottom: 15px;
	margin: 0;
	//vertical-align: middle;
`;

export const GreenLine = styled.div`
	border: 1px solid #09aa36;
	opacity: 1;
	max-width: 35px;
	margin-top: 5px;
`;

export const CardValue = styled.div`
	text-align: left;
	font: normal normal 500 24px/32px Roboto;
	letter-spacing: 0px;
	color: #222222;
	opacity: 1;
	margin-top: 16px;
	margin-bottom: 10px;

	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;

	span {
		font: normal normal 500 15px/20px Roboto;
		vertical-align: middle;
		margin-right: 4px;
	}
`;

interface CardPercentageProps {
	negative?: boolean;
}

export const CardPercentageContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;

	span {
		text-align: left;
		font: normal normal normal 11px/15px Roboto;
		letter-spacing: 0px;
		color: #676767;
		opacity: 1;
		margin-left: 3px;
	}
`;

export const CardPercentage = styled.div<CardPercentageProps>`
	background: ${(props) => (props.negative ? '#FAE5E7' : '#ecf7ed')} 0% 0%
		no-repeat padding-box;
	border-radius: 12px;
	height: 24px;
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 7.5px;
	width: fit-content;
	text-align: left;
	font: normal normal 500 13px/18px Roboto;
	letter-spacing: 0px;
	color: ${(props) => (props.negative ? '#C90716' : '#4baf50')};

	.TrendingUp {
		display: ${(props) => (!props.negative ? 'block' : 'none')};
		width: 12px;
		height: 12px;
	}
	.TrendingDown {
		display: ${(props) => (props.negative ? 'block' : 'none')};
		width: 12px;
		height: 12px;
	}
`;

export const CardIconContainer = styled.div`
	background: #f5fbf7 0% 0% no-repeat padding-box;
	border-radius: 10px;
	opacity: 1;

	width: 36px;
	height: 36px;
	vertical-align: middle;
	margin-right: 8px;
	display: flex;
	justify-content: center;
	align-items: center;
	svg {
		width: 21px;
		height: 24px;
		color: #09aa36;
	}
	img{
		width: 36px;
		height: 36px;
	}
`;

export const Row = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
`;

interface GridProps {
	length: number;
}

export const Grid = styled.div<GridProps>`
	display: grid;
	grid-template-columns: ${(props) => `repeat(${props.length},1fr)`};
	grid-gap: 13px;

	overflow-x: scroll;

	//	overflow-y: show;
	//margin-right: -30px;

	//z-index: 1000;

	//max-width: 100vw;
	::-webkit-scrollbar {
		display: none;
		margin-top: 10px;

		width: 8px;
	}

	::-webkit-scrollbar-thumb {
		display: none;

		border-radius: 5px;
		background: #09aa36;

		width: 8px;
	}

	max-width: calc(100% + 15px);
`;

export const FooterDash = styled.div`
	display: flex;
	flex-direction: row;
	max-width: 100%;
	justify-content: space-between;

	.dash_footer_item {
		max-width: calc(50% - 7px);
	}
`;

export const CardLegend = styled.p`
	text-align: left;
	font: normal normal normal 11px/15px Roboto;
	letter-spacing: 0px;
	color: #222222;
	opacity: 1;
`;

export const RowBetween = styled.div`
	display: flex;
	flex-direction: row;
	//align-items: center;
	justify-content: space-between;

	.dropdown-menu {
		border: 0;
	}

	.secondGridRightItems {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		.secondGridRightValue {
			margin: 0;
			text-align: right;
			font: normal normal bold 18px/24px Roboto;
			letter-spacing: 0px;
			color: #222222;
			vertical-align: center;
			display: flex;
			flex-direction: row;
			align-items: center;
			padding-right: 12px;
		}
		.secondGridRightSymbol {
			text-align: left;
			font: normal normal 500 13px/24px Roboto;
			letter-spacing: 0px;
			color: #222222;
			vertical-align: center;
			margin-right: 4px;
		}

		.secondGridDropdownToggle {
			text-align: right;
			font: normal normal normal 13px/18px Roboto;
			letter-spacing: 0px;
			color: #222222;
			background-color: transparent;
			display: flex;
			flex-direction: row;
			align-items: center;
		}
	}
`;

export const ToggleText = styled.p`
	text-align: left;
	font: normal normal 500 13px/18px Roboto;
	letter-spacing: 0px;
	color: #222222;
	opacity: 1;

	svg {
		height: 24px;
		color: #09aa36;
	}
`;

export const ListRank = styled.div`
	//max-height: calc(100vh - 64px - 45px - 200px - 100px - 100px - 30px);
	//overflow-y: scroll;
	margin-top: 10px;

	-webkit-overflow-scrolling: touch; /* deixa a rolagem suave */

	::-webkit-scrollbar {
		margin-top: 10px;

		width: 8px;
	}

	::-webkit-scrollbar-thumb {
		border-radius: 5px;
		background: #09aa36;

		width: 8px;
	}
`;

export const MerchantRank = styled.div`
	display: flex;
	flex-direction: row;
	//justify-content: space-between;
	height: calc(100% - 60px);
	padding-top: 50px;
	padding-left: 5px;
	width: 100%;
	overflow-x: scroll;
	.merchant_rank_container {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-right: 15px;
		min-width: 70px;
		justify-content: flex-end;
		//margin-right: 30px;
	}

	.merchant_rank_label {
		text-align: center;
		font: normal normal normal 11px/15px Roboto;
		letter-spacing: 0px;
		color: #676767;
		white-space: nowrap;
		
	}
	::-webkit-scrollbar {
		display: none;
		margin-top: 10px;

		width: 8px;
	}

	::-webkit-scrollbar-thumb {
		display: none;

		border-radius: 5px;
		background: #09aa36;

		width: 8px;
	}
`;

interface LineProps {
	heightPercentage: number;
}

export const MerchantLine = styled.div<LineProps>`
	background: #f8f8f8 0% 0% no-repeat padding-box;
	border-radius: 4px;
	width: 8px;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;

	.merchant_rank_intern {
		background: transparent linear-gradient(180deg, #09aa36 0%, #93cc2f 100%) 0%
			0% no-repeat padding-box;
		border-radius: 6px;
		width: 100%;

		height: ${(props) => `${props.heightPercentage}%`};
	}
`;

export const ListRankTextLight = styled.div`
	text-align: left;
	font: normal normal 300 11px/15px Roboto;
	letter-spacing: 0px;
	color: #222222;
	opacity: 1;
`;

export const Skeleton = styled.div`
	background-image: linear-gradient(
		-90deg,
		#fafafc 0%,
		#e4e4e4 50%,
		#fafafc 100%
	);
	background-size: 400% 400%;
	animation: shimmer 1.2s ease-in-out infinite;
	@keyframes shimmer {
		0% {
			background-position: 0% 0%;
		}
		100% {
			background-position: -135% 0%;
		}
	}
`;

export const SkeletonGreen = styled.div`
	background-image: linear-gradient(
		-90deg,
		var(--primaryColorDark) 0%,
		#abdb30 50%,
		var(--primaryColorDark) 100%
	);
	background-size: 400% 400%;
	animation: shimmer 1.2s ease-in-out infinite;
	@keyframes shimmer {
		0% {
			background-position: 0% 0%;
		}
		100% {
			background-position: -135% 0%;
		}
	}
`;

interface SearchSelectorProps {
	active?: boolean;
	pressed?: boolean;
}
export const SearchSelector = styled.button<SearchSelectorProps>`
	height: 28px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 6px;
	padding-left: 12px;
	padding-right: 12px;
	margin-right: 10px;

	background: ${(props) => (props.active ? '#18AA36' : '#F0F0F0')} 0% 0%
		no-repeat padding-box;
	border-radius: 20px;

	text-align: left;
	font: normal normal 500 13px/18px Roboto;
	letter-spacing: 0px;
	color: #222222;
	cursor: pointer;
	border: ${(props) =>
		props.pressed ? '1px solid #EBEBEB' : '1px solid transparent'};

	box-shadow: ${(props) => (props.pressed ? 'inset 0px 3px 6px #00000029' : 0)};
`;

export const SelectMenu = styled.div`
	background: #f6f6f8 0% 0% no-repeat padding-box;
	box-shadow: 0px 6px 12px #0000001a;
	border: 1px solid #ebebeb;
	border-radius: 18px;
	padding: 10px;
	padding-bottom: 2px;
	margin-top: 6px;

	.dropdown-item {
		:hover {
			background: transparent;
		}
	}
`;

export const SelectItem = styled.div<SearchSelectorProps>`
	background: ${(props) => (props.active ? '#18aa36' : 'transparent')} 0% 0%
		no-repeat padding-box;
	// background: #FFFFFF 0% 0% no-repeat padding-box;

	border-radius: 18px;
	margin-bottom: 8px;
	padding-left: 16px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	height: 35px;
	width: 150px;

	text-align: left;
	font: normal normal ${(props) => (props.active ? 500 : 'normal')} 14px/19px
		Roboto;
	letter-spacing: 0px;
	color: ${(props) => (props.active ? '#FFFFFF' : '#676767')};

	:hover {
		box-shadow: 0px 6px 12px #22222226;
		background: ${(props) => (props.active ? '#18aa36' : '#FFFFFF')} 0% 0%
			no-repeat padding-box;
		font: normal normal 500 14px/19px Roboto;
	}
`;

export const CalendarSettings = styled.div`
	margin-top: 6px;
	background-color: #f6f6f8;

	.rdrCalendarWrapper {
		//	height: 250px;
		max-width: 330px;
		background-color: #f6f6f8;
	}

	.rdrMonths {
		//	height: 250px;
	}
	.rdrMonth {
		//	height: 250px;
	}
	.rdrMonthName {
		background: #e6e6e6 0% 0% no-repeat padding-box;
		border: 1px solid #00000000;

		text-align: center;
		font: normal normal normal 10px/11px Arial;
		letter-spacing: 0px;
		color: #000000;
		text-transform: capitalize;
		height: 23px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.rdrWeekDays {
		height: 23px;
		background: #ebebeb 0% 0% no-repeat padding-box;
		border: 1px solid #00000000;
	}
	.rdrWeekDay {
		background: #ebebeb 0% 0% no-repeat padding-box;
		border: 1px solid #00000000;
		height: 23px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.rdrDay {
		height: 23px;
		//width:23px;
		text-align: center;
		font: normal normal normal 9px/10px Arial;
		letter-spacing: 0px;
		color: #aaaaaa;
	}

	.rdrDayToday .rdrDayNumber span:after {
		content: '';
		position: absolute;
		bottom: 1px;
		left: 50%;
		transform: translate(-50%, 0);
		width: 18px;
		height: 2px;
		border-radius: 2px;
		background: #3d91ff;
	}
`;
