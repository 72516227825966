import { OptionsObject, SnackbarKey, SnackbarMessage } from "notistack";

export function snackbarErrorHandler(
  errorObject: any,
  enqueueSnackbar: (
    message: SnackbarMessage,
    options?: OptionsObject
  ) => SnackbarKey,
  defaultErrorMsg?: string
) {
    console.log(errorObject.response)
    console.log(errorObject.message)
  let err = defaultErrorMsg || "Falha na operação";
  if (
    errorObject &&
    errorObject.response &&
    errorObject.response.data &&
    errorObject.response.data.error
  ) {
    err = errorObject.response.data.error;
  }
  if (
    errorObject &&
    errorObject.response &&
    errorObject.response.data &&
    errorObject.response.data.message
  ) {
    err = errorObject.response.data.message;
  }
  const errArray = [] as string[];
  if (
    errorObject &&
    errorObject.response &&
    errorObject.response.data &&
    errorObject.response.data.errors
  ) {
    const { errors } = errorObject.response.data;
    if (Array.isArray(errors)) {
      errors.forEach((errorCause: any) => {
        if (errorCause.cause) {
          errArray.push(errorCause.cause);
        }
      });
    }
  }

  enqueueSnackbar(err, { variant: "error" });
  errArray.forEach((errCause) => {
    enqueueSnackbar(errCause, { variant: "error" });
  });
}
