import styled from 'styled-components'
import { ContainerColumnProps } from './Models'

export const GlobalColumn = styled.div<ContainerColumnProps>`
  display: flex;
  flex-direction: column;

  align-items: ${({ alignItems }) => alignItems || 'center'};
  justify-content: ${({ justifyContent }) => justifyContent || 'center'};
  gap: ${({ gap }) => gap || 0};
`
