/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import {
	Container,
	Sidebar,
	SidebarButton,
	SidebarElip,
	SidebarHeader,
	SidebarIconContainer,
	SidebarItem,
	SidebarItemInfo,
	SidebarItemTitle,
	SidebarTitle,
	SidebarItemSubTitle,
	Main,
	Header,
	HeaderTitle,
	HeaderButton,
	HeaderSearchContainer,
	HeaderSearchInput,
	Body,
	Table,
	TableContainer,
	TBody,
	Th,
	THead,
	ActionButton,
	Gradient,
	BodyTR,
	Square,
	Skeleton,
	SearchSelector,
	TableFooter,
	HeaderContainer,
} from './style';
import {
	UilDraggabledots,
	UilBoltAlt,
	UilEllipsisV,
	UilTear,
	UilBusAlt,
	UilPhone,
	UilSearch,
	UilPen,
	UilTrashAlt,
	UilUsdCircle,
	UilFileContract,
	UilMoneyWithdraw,
} from '@iconscout/react-unicons';
import { Dropdown, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';

import ModalNovoCorrespondente from './ModalNovoCorrespondente';

import './styles.css';
import ModalNovoDispositivo from './ModalNovoDispositivo';
import api from '../../services/api';
import { Correspondente, Dispositivo } from '../../models/model';
import { useEffect } from 'react';
import { formatarDoc } from '../../utils/formatacaoDoc';
import { useHistory } from 'react-router-dom';
import RemoveGray from '../../assets/images/delete_outline-gray.svg';
import SearchGray from '../../assets/images/search-gray.svg';
import EditGray from '../../assets/images/edit-gray.svg';

import ArrowRightWhite from '../../assets/images/arrow_right-white.svg';
import ArrowLeftWhite from '../../assets/images/arrow_left-white.svg';
import HeaderUserButton from '../../components/logOut';
import DeleteModal from '../../components/deleteModal';
import { DebouncerInput } from '../../components/Input';
const LIMIT = 50;

export default function Correspondentes() {
	const [searchActive, setSearchActive] = useState(false);

	const [modalNovoCorrespondente, setModalNovoCorrespondente] = useState(false);
	const [modalNovoDispositivo, setModalNovoDispositivo] = useState(false);

	const estadoList = [
		{ label: 'Todos', value: '' },
		{ label: 'Ativo', value: '1' },
		{ label: 'Inativo', value: '0' },
	];
	const [estado, setEstado] = useState('');

	const [correspondentes, setCorrespondentes] = useState<Correspondente[]>([]);
	const [loadingCorrespondentes, setLoadingCorrespondentes] = useState(false);
	const [total, setTotal] = useState(1);
	const [thisPage, setThisPage] = useState(1);
	const [maxPage, setMaxPage] = useState(0);

	const [visiblePage, setVisiblePage] = useState(1);

	function handleThisPage(newPage: number) {
		setThisPage(newPage);
		//setVisiblePage(newPage)
	}

	function updateDispositivos(DispositivoUpdate: Dispositivo) {
		const lista = [...dispositivos];
		const findIndex = lista.findIndex(
			(l) => l.id === (DispositivoUpdate.id as number)
		);
		//console.log(findIndex)
		console.log(lista);
		if (findIndex !== -1) {
			lista[findIndex] = DispositivoUpdate;
		} else {
			if (
				correspondenteSelecionado === undefined ||
				(correspondenteSelecionado &&
					correspondenteSelecionado.id === DispositivoUpdate.recipient?.id)
			)
				lista.push(DispositivoUpdate);
		}

		setDispositivos([...lista]);
	}

	function DeletarDispositivos(dispositivoId: number) {
		//setEmpresas([...Empresas]);
		const lista = [...dispositivos];
		const findIndex = lista.findIndex((l) => l.id === dispositivoId);
		//console.log(findIndex)
		console.log(lista);
		if (findIndex !== -1) {
			lista.splice(findIndex, 1);
		}

		setDispositivos([...lista]);
	}

	function updateCorrespondentes(CorrespondenteUpdate: Correspondente) {
		const lista = [...correspondentes];
		const findIndex = lista.findIndex(
			(l) => l.id === (CorrespondenteUpdate.id as number)
		);
		//console.log(findIndex)
		console.log(lista);
		if (findIndex !== -1) {
			lista[findIndex] = CorrespondenteUpdate;
		} else {
			lista.push(CorrespondenteUpdate);
		}

		setCorrespondentes([...lista]);
	}

	function DeletarCorrespondente(correspondenteId: number) {
		//setEmpresas([...Empresas]);
		const lista = [...correspondentes];
		const findIndex = lista.findIndex((l) => l.id === correspondenteId);
		//console.log(findIndex)
		console.log(lista);
		if (findIndex !== -1) {
			lista.splice(findIndex, 1);
		}

		setCorrespondentes([...lista]);
	}

	async function getCorrespondentes() {
		setLoadingCorrespondentes(true);
		const token = sessionStorage.getItem('@bill-token');

		const config = {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		};
		try {
			const response = await api.get('/admin/api/recipient', config);

			if (response.data.recipients) {
				setCorrespondentes(response.data.recipients);
			}
			setLoadingCorrespondentes(false);
		} catch (error: any) {
			console.error(error);
			setLoadingCorrespondentes(false);
			if (error.response && error.response.status === 401) {
				history.push('/login');
			}
		}
	}

	const [correspondenteSelecionado, setCorrespondenteSelecionado] = useState<
		Correspondente | null | undefined
	>();

	function selecionarCorrespondente(c: Correspondente) {
		if (
			correspondenteSelecionado === undefined ||
			correspondenteSelecionado === null
		) {
			setCorrespondenteSelecionado(c);
		} else if (correspondenteSelecionado.id === c.id) {
			setCorrespondenteSelecionado(undefined);
		} else setCorrespondenteSelecionado(c);
	}

	const [apelido, setApelido] = useState('');
	const [searchInput, setSearchInput] = useState('');

	const [dispositivos, setDispositivos] = useState<Dispositivo[]>([]);

	const [loadingDispositivos, setLoadingDispositivos] = useState(false);

	async function getDispositivos() {
		setLoadingDispositivos(true);
		const token = sessionStorage.getItem('@bill-token');

		const config = {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		};

		//const s = estado === 'Todos' ? '' : estado === 'Ativo' ? '1' : '0';

		const qs = correspondenteSelecionado
			? `&recipient=${correspondenteSelecionado.id}`
			: correspondenteSelecionado === null
			? '&recipient=0'
			: '';

		//console.log(qs);

		try {
			const response = await api.get(
				`/admin/api/device?s=${apelido}&status=${estado}${qs}&limit=${LIMIT}&offset=${0}
				`,
				config
			);
			const { data } = response;
			console.log(data);
			if (data.devices) {
				setDispositivos(data.devices);
				//setDispositivos(response.data);
			}
			if (data.total !== undefined) {
				setTotal(data.total);
				setThisPage(1);
				const t = data.total;
				const floorMax = Math.floor(t / LIMIT);
				if (t % LIMIT !== 0) {
					setMaxPage(floorMax + 1);
				} else {
					setMaxPage(floorMax);
				}
			}
			//console.log(response.data)
			setLoadingDispositivos(false);
		} catch (error: any) {
			console.error(error);
			setLoadingDispositivos(false);
			if (error.response && error.response.status === 401) {
				history.push('/login');
			}
		}
	}

	async function changePageDispositivos() {
		setLoadingDispositivos(true);
		const token = sessionStorage.getItem('@bill-token');

		const config = {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		};

		//const s = estado === 'Todos' ? '' : estado === 'Ativo' ? '1' : '0';

		const qs = correspondenteSelecionado
			? `&recipient=${correspondenteSelecionado.id}`
			: correspondenteSelecionado === null
			? '&recipient=0'
			: '';

		//console.log(qs);

		try {
			const response = await api.get(
				`/admin/api/device?s=${apelido}&status=${estado}${qs}&limit=${LIMIT}&offset=${
					(thisPage - 1) * LIMIT
				}
				`,
				config
			);
			const { data } = response;
			console.log(data);
			if (data.devices) {
				setDispositivos(data.devices);
				//setDispositivos(response.data);
			}
			if (data.total !== undefined) {
				setTotal(data.total);
				const t = data.total;
				const floorMax = Math.floor(t / LIMIT);
				if (t % LIMIT !== 0) {
					setMaxPage(floorMax + 1);
				} else {
					setMaxPage(floorMax);
				}
			}
			//console.log(response.data)
			setLoadingDispositivos(false);
		} catch (error: any) {
			console.error(error);
			setLoadingDispositivos(false);
			if (error.response && error.response.status === 401) {
				history.push('/login');
			}
		}
	}

	const history = useHistory();

	useEffect(() => {
		getCorrespondentes();
		//getDispositivos();
	}, []);

	useEffect(() => {
		getDispositivos();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [estado, apelido, correspondenteSelecionado]);

	useEffect(() => {
		changePageDispositivos();
		setVisiblePage(thisPage);
	}, [thisPage]);

	useEffect(() => {
		const login = localStorage.getItem('@bill-login');
		if (login) {
			const l = JSON.parse(login);
			if (!(l && l.type === 999)) {
				history.replace('/');
			}
		} else {
			history.replace('/');
		}
	}, []);

	return (
		<Container>
			<Sidebar>
				<SidebarHeader>
					<SidebarTitle>Correspondentes</SidebarTitle>
					<SidebarButton
						onClick={(e) => {
							e.preventDefault();
							setModalNovoCorrespondente(true);
						}}>
						NOVO
					</SidebarButton>
					<Modal
						show={modalNovoCorrespondente}
						onHide={() => {
							setModalNovoCorrespondente(false);
						}}>
						<Modal.Body>
							<ModalNovoCorrespondente
								setModalVisible={setModalNovoCorrespondente}
								updateCorrespondentes={updateCorrespondentes}
							/>
						</Modal.Body>
					</Modal>
				</SidebarHeader>
				<SidebarItem
					onClick={(e) => {
						e.preventDefault();
						if (correspondenteSelecionado === null)
							setCorrespondenteSelecionado(undefined);
						else setCorrespondenteSelecionado(null);
					}}
					selected={correspondenteSelecionado === null}>
					<div style={{ display: 'flex' }}>
						<SidebarItemInfo>
							<SidebarItemTitle>{'Sem correspondentes'}</SidebarItemTitle>
							<SidebarItemSubTitle>{''}</SidebarItemSubTitle>
						</SidebarItemInfo>
					</div>
				</SidebarItem>

				{loadingCorrespondentes ? (
					<SidebarItem selected={false}>
						<div style={{ display: 'flex' }}>
							<SidebarItemInfo>
								<SidebarItemTitle>
									<Skeleton
										style={{ width: 100, height: 19, marginBottom: 10 }}
									/>
								</SidebarItemTitle>
								<SidebarItemSubTitle>
									<Skeleton style={{ width: 120, height: 14 }} />
								</SidebarItemSubTitle>
							</SidebarItemInfo>
						</div>
					</SidebarItem>
				) : (
					correspondentes.map((correspondente) => {
						return (
							<SideItem
								selecionarCorrespondente={selecionarCorrespondente}
								correspondente={correspondente}
								updateCorrespondentes={updateCorrespondentes}
								selected={
									correspondenteSelecionado &&
									correspondenteSelecionado.id === correspondente.id
										? true
										: false
								}
								DeletarCorrespondente={DeletarCorrespondente}
							/>
						);
					})
				)}

				{/*categoriasData.map((categoria) => {
					return <SideItem item={categoria} />;
				})*/}
			</Sidebar>
			<Main>
				<Header>
					<HeaderTitle>
						{correspondenteSelecionado === undefined ? (
							'Todos os dispositivos'
						) : correspondenteSelecionado === null ? (
							'Dispositivos sem correspondentes'
						) : (
							<span style={{ display: 'flex', alignItems: 'center' }}>
								{correspondenteSelecionado.name}{' '}
								<span className='dispositivo_block'>Dispositivos</span>{' '}
							</span>
						)}
					</HeaderTitle>
					<HeaderContainer>
						<HeaderButton
							style={{ marginRight: 10 }}
							onClick={(e) => {
								e.preventDefault();
								setModalNovoDispositivo(true);

								//setModalNovaEmpresa(true);
							}}>
							<p>Novo Dispositivo</p>
						</HeaderButton>
						<HeaderUserButton />
					</HeaderContainer>

					<Modal
						show={modalNovoDispositivo}
						onHide={() => {
							setModalNovoDispositivo(false);
						}}>
						<Modal.Body>
							<ModalNovoDispositivo
								setModalVisible={setModalNovoDispositivo}
								//dispositivo={item}
								correspondentes={correspondentes}
								updateDispositivos={updateDispositivos}
							/>
						</Modal.Body>
					</Modal>
				</Header>
				<Body>
					<div className='body_header'>
						<DebouncerInput
							placeholder='Buscar por Nome...'
							setSearchItem={setApelido}
						/>

						<div className='flex-row-center'>
							{estadoList.map((est) => {
								return (
									<SearchSelector
										active={estado === est.value}
										style={{ marginRight: 12 }}
										onClick={(e) => {
											e.preventDefault();
											//setModalNovaEmpresa(true);
											setEstado(est.value);
										}}>
										<p>{est.label}</p>
									</SearchSelector>
								);
							})}
						</div>
					</div>

					<div>
						<TableContainer style={{ minHeight: 250 }}>
							<Table>
								<THead>
									<tr>
										<Th>NOME</Th>
										<Th>PROVEDOR</Th>

										<Th>SITUAÇÃO</Th>
										<Th>CORRESPONDENTE</Th>
										<Th></Th>
									</tr>
								</THead>
								<TBody>
									{loadingDispositivos ? (
										<BodyTR>
											<td>
												<Skeleton style={{ width: '50%', height: 19 }} />
											</td>
											<td>
												<Skeleton style={{ width: '50%', height: 19 }} />
											</td>
											<td>
												<Skeleton style={{ width: '50%', height: 19 }} />
											</td>
											<td>
												<Skeleton style={{ width: '50%', height: 19 }} />
											</td>
											<td>
												<Skeleton style={{ width: '50%', height: 19 }} />
											</td>
										</BodyTR>
									) : (
										dispositivos.map((dispositivo) => {
											return (
												<DispositivosTR
													dispositivo={dispositivo}
													correspondentes={correspondentes}
													updateDispositivos={updateDispositivos}
													DeletarDispositivos={DeletarDispositivos}
												/>
											);
										})
									)}

									{/*empresasData.map((empresa) => (
									<EmpresaTR
										empresa={empresa}
										categoriasData={categoriasData}
									/>
                                ))*/}
								</TBody>
							</Table>
						</TableContainer>
						{!loadingCorrespondentes && total > 0 && (
							<div style={{ padding: 10 }}>
								<TableFooter>
									<p className='table_footer_label'>
										Mostrando{' '}
										<span className='table_footer_label_strong'>
											{(thisPage - 1) * LIMIT + 1} -{' '}
											{LIMIT * thisPage >= total ? total : LIMIT * thisPage}
										</span>{' '}
										de {total} transações
									</p>
									<div
										style={{
											display: 'flex',
											flexDirection: 'row',
											alignItems: 'center',
										}}>
										<form
											onSubmit={(e) => {
												e.preventDefault();
												handleThisPage(visiblePage);
											}}
											className='table_footer_border_right table_footer_padding_right-20'
											style={{
												display: 'flex',
												flexDirection: 'row',
												alignItems: 'center',
											}}>
											<p className='table_footer_label'>Você está na página </p>
											<input
												className='table_footer_input'
												type='tel'
												value={visiblePage}
												onChange={(e) => {
													let value = e.target.value;
													value = value.replace(/\D/g, '');

													if (value.length > 0) {
														let intValue = parseInt(value);
														if (intValue > maxPage) {
															setVisiblePage(maxPage);
														} else setVisiblePage(intValue);
													} else {
														setVisiblePage(0);
													}
												}}
											/>
											<p className='table_footer_label'>de {maxPage} </p>
										</form>
										<div className='table_footer_button_container table_footer_margin_left-20 table_footer_border_right-12 table_footer_border_left-12'>
											<button
												onClick={(e) => {
													e.preventDefault();
													if (visiblePage > 1) {
														handleThisPage(visiblePage - 1);
													}
												}}
												className='table_footer_button table_footer_border_left-12'>
												<img alt='<' src={ArrowLeftWhite} />
											</button>
											<button
												onClick={(e) => {
													e.preventDefault();
													if (visiblePage !== maxPage) {
														handleThisPage(visiblePage + 1);
													}
												}}
												className='table_footer_button table_footer_border_right-12'>
												<img alt='>' src={ArrowRightWhite} />
											</button>
										</div>
									</div>
								</TableFooter>
							</div>
						)}
					</div>
				</Body>
			</Main>
		</Container>
	);
}

function SideItem({
	correspondente,
	selected,
	selecionarCorrespondente,
	updateCorrespondentes,
	DeletarCorrespondente,
}: {
	correspondente: Correspondente;
	selected: boolean;
	selecionarCorrespondente(c: Correspondente): void;
	updateCorrespondentes(c: Correspondente): void;
	DeletarCorrespondente(i: number): void;
}) {
	const [modalNovoCorrespondente, setModalNovoCorrespondente] = useState(false);

	async function RemoverCorrespondente() {
		const token = sessionStorage.getItem('@bill-token');

		const config = {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		};

		try {
			const response = await api.delete(
				`admin/api/recipient/${correspondente.id}`,
				config
			);

			if (response.data.success) {
				DeletarCorrespondente(correspondente.id);
			}
		} catch (error: any) {
			console.log(error);
		}
	}

	const [deleteModal, setDeleteModal] = useState(false);

	return (
		<SidebarItem
			onClick={(e) => {
				e.preventDefault();
				selecionarCorrespondente(correspondente);
			}}
			selected={selected}>
			<div style={{ display: 'flex' }}>
				<SidebarItemInfo>
					<SidebarItemTitle>{correspondente.name}</SidebarItemTitle>
					<SidebarItemSubTitle>
						{formatarDoc(correspondente.doc)}
					</SidebarItemSubTitle>
				</SidebarItemInfo>
			</div>

			<div
				onClick={(e) => {
					e.preventDefault();
					e.stopPropagation();
				}}
				style={{ display: 'flex', alignItems: 'center', marginRight: 16 }}>
				<Modal
					show={modalNovoCorrespondente}
					onHide={() => {
						setModalNovoCorrespondente(false);
					}}>
					<Modal.Body>
						<ModalNovoCorrespondente
							setModalVisible={setModalNovoCorrespondente}
							correspondente={correspondente}
							updateCorrespondentes={updateCorrespondentes}
						/>
					</Modal.Body>
				</Modal>
				<Modal show={deleteModal} onHide={() => setDeleteModal(false)}>
					<Modal.Body>
						<DeleteModal
							activeFunction={RemoverCorrespondente}
							labelStart='Tem certeza que deseja deletar o correspondente '
							setModalVisible={setDeleteModal}
							focusItem={correspondente.name}
							labelEnd={'? Essa ação não poderá ser desfeita.'}
							title={'Deletar Correspondente?'}
						/>
					</Modal.Body>
				</Modal>
				<OverlayTrigger
					placement='bottom'
					overlay={
						<Tooltip id='tool-tip'>
							<span style={{ textTransform: 'capitalize' }}>
								{'Editar correspondente'}
							</span>
						</Tooltip>
					}>
					<button
						type='button'
						onClick={(e) => {
							e.preventDefault();
							e.stopPropagation();
							setModalNovoCorrespondente(true);

							//setShowDropdown(!showDropdown);
							//setModalVisible(true);
						}}
						style={{ marginRight: 8 }}
						className='sideItem_icon_container'>
						<img className='sideItem_editIcon' src={EditGray} alt='e' />
					</button>
				</OverlayTrigger>

				<OverlayTrigger
					placement='bottom'
					overlay={
						<Tooltip id='tool-tip'>
							<span style={{ textTransform: 'capitalize' }}>
								{'Remover correspondente'}
							</span>
						</Tooltip>
					}>
					<button
						onClick={(e) => {
							e.preventDefault();
							e.stopPropagation();
							setDeleteModal(true);
						}}
						className='sideItem_icon_container'>
						<img src={RemoveGray} alt='e' />
					</button>
				</OverlayTrigger>
			</div>

			{
				<div
					style={{ display: 'none' }}
					onClick={(e) => {
						e.stopPropagation();
					}}>
					<Dropdown>
						<Dropdown.Toggle className='dropdown-toggle-categoria'>
							<UilEllipsisV />
						</Dropdown.Toggle>
						<Dropdown.Menu className='dropdown-menu-container'>
							<Dropdown.Item
								onClick={(e) => {
									e.preventDefault();
									setModalNovoCorrespondente(true);
								}}
								className='dropdown-item-categoria'>
								<UilPen /> Editar
							</Dropdown.Item>
							<Dropdown.Item className='dropdown-item-categoria'>
								<UilTrashAlt /> Remover
							</Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown>
				</div>
			}
		</SidebarItem>
	);
}

function DispositivosTR({
	dispositivo,
	correspondentes,
	updateDispositivos,
	DeletarDispositivos,
}: {
	dispositivo: Dispositivo;
	correspondentes: Correspondente[];
	updateDispositivos(d: Dispositivo): void;
	DeletarDispositivos(d: number): void;
}) {
	const [modalVisible, setModalVisible] = useState(false);
	const history = useHistory();

	async function RemoverDispositivo() {
		const token = sessionStorage.getItem('@bill-token');

		const config = {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		};

		try {
			const response = await api.delete(
				`admin/api/device/${dispositivo.id}`,
				config
			);

			if (response.data.success) {
				DeletarDispositivos(dispositivo.id);
			}
		} catch (error: any) {
			console.log(error);
			if (error.response && error.response.status === 401) {
				history.push('/login');
			}
		}
	}

	const [deleteModal, setDeleteModal] = useState(false);
	return (
		<BodyTR>
			<td>{dispositivo.name}</td>
			<td>{dispositivo.provider}</td>
			<td>{dispositivo.status === 1 ? 'Ativa' : 'Inativa'}</td>{' '}
			<td>{dispositivo.recipient ? dispositivo.recipient.name : '-'}</td>
			<td>
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						marginRight: 16,
						justifyContent: 'flex-end',
					}}>
					<OverlayTrigger
						placement='bottom'
						overlay={
							<Tooltip id='tool-tip'>
								<span style={{ textTransform: 'capitalize' }}>
									{'Editar Dispositivo'}
								</span>
							</Tooltip>
						}>
						<button
							type='button'
							onClick={(e) => {
								e.preventDefault();
								e.stopPropagation();
								//setShowDropdown(!showDropdown);
								// setModalVisible(true);
								setModalVisible(true);
							}}
							style={{ marginRight: 8 }}
							className='tableItem_icon_container'>
							<img
								className='tableItem_icon tableItem_icon_edit'
								src={EditGray}
								alt='e'
							/>
						</button>
					</OverlayTrigger>

					<OverlayTrigger
						placement='bottom'
						overlay={
							<Tooltip id='tool-tip'>
								<span style={{ textTransform: 'capitalize' }}>
									{'Remover Dispositivo'}
								</span>
							</Tooltip>
						}>
						<button
							onClick={(e) => {
								e.preventDefault();
								e.stopPropagation();
								setDeleteModal(true);
							}}
							className='tableItem_icon_container'>
							<img className='tableItem_icon' src={RemoveGray} alt='e' />
						</button>
					</OverlayTrigger>
				</div>
			</td>
			<Modal
				show={modalVisible}
				onHide={() => {
					setModalVisible(false);
				}}>
				<Modal.Body>
					<ModalNovoDispositivo
						setModalVisible={setModalVisible}
						dispositivo={dispositivo}
						correspondentes={correspondentes}
						updateDispositivos={updateDispositivos}
					/>
				</Modal.Body>
			</Modal>
			<Modal show={deleteModal} onHide={() => setDeleteModal(false)}>
				<Modal.Body>
					<DeleteModal
						activeFunction={RemoverDispositivo}
						labelStart='Tem certeza que deseja deletar o dispositivo '
						setModalVisible={setDeleteModal}
						focusItem={dispositivo.name}
						labelEnd={'? Essa ação não poderá ser desfeita.'}
						title={'Deletar Dispositivo?'}
					/>
				</Modal.Body>
			</Modal>
		</BodyTR>
	);
}
