/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useEffect, useState } from 'react';

import styled from 'styled-components';

import { UilCamera } from '@iconscout/react-unicons';
import Dropzone, { WithDropZone } from '../../components/Dropzone';
import { Categoria } from '../../models/model';
import { colors } from '../../globalStyles/colors';
import ClearIcon from '../../assets/images/clearIcon.svg';
import { useRef } from 'react';
import Loading from '../../assets/images/loading-roll.svg';
import * as yup from 'yup';
import api from '../../services/api';
import { useHistory } from 'react-router';
import DoneWhite from '../../assets/images/done-white.svg';
import NavigateNext from '../../assets/images/navigate_next-green.svg';
import CloseWhite from '../../assets/images/close-white.svg';

const Modal = styled.div`
	//padding-top: 25px;
	//padding-left: 50px;
	//padding-right: 20000px;
	//padding-bottom: 25px;
	min-height: 100vh;
	background-color: #f5f5f5;
	width: 100vw;
	//margin-left: 50vw;
	height: 100%;
	flex: 1;
	.mb-8 {
		margin-bottom: 8px;
	}

	.mb-32 {
		margin-bottom: 32px;
	}
	.mb-40 {
		margin-bottom: 40px;
	}

	.mt-40 {
		margin-top: 40px;
	}
`;

const ModalHeader = styled.div`
	background: #f6f6f6 0% 0% no-repeat padding-box;
	box-shadow: 0px 16px 16px #00000029;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	padding-top: 21px;
	padding-bottom: 21px;

	padding-left: 30px;
	padding-right: 30px;
	height: 80px;
`;

const HeaderTitle = styled.h1`
	font: normal normal 500 18px/24px Roboto;
	letter-spacing: 0px;
	color: #676767;
	display: flex;
	align-items: center;
`;

const HeaderButton = styled.button`
	border-radius: 19px;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 38px;
	padding-left: 16px;
	padding-right: 16px;

	text-align: center;
	font: normal normal 500 16px/21px Roboto;
	letter-spacing: 0px;
	border: 0;

	.loading-roll {
		width: 21px;
		height: 21px;
	}
`;

const ModalContainer = styled.div`
	background: #ffffff 0% 0% no-repeat padding-box;
	box-shadow: -16px 0px 16px #00000029;
	opacity: 1;
	display: flex;
	flex-direction: column;
	//flex: 1;
	height: calc(100vh - 80px);
	padding: 30px;
	.modal_item_container {
		max-width: 50%;
		width: 100%;
	}
`;

const ModalLabel = styled.p`
	margin: 0;
	text-align: left;
	font: normal normal 500 11px/15px Roboto;
	letter-spacing: 0px;
	color: #4b4b4b;
	text-transform: uppercase;
`;

const ModalLabelError = styled.p`
	margin: 0;
	text-align: left;
	font: normal normal 500 11px/15px Roboto;
	letter-spacing: 0px;
	color: #e8453e;

	text-transform: uppercase;
`;
interface ModalInputContainerProps {
	shadowColor?: string;
	borderColor?: string;
}
const ModalInputContainer = styled.div<ModalInputContainerProps>`
	background: #ffffff 0% 0% no-repeat padding-box;
	border: 1px solid
		${(props) => (props.borderColor ? props.borderColor : '#9f9f9f')};
	border-radius: 16px;
	display: flex;
	flex-direction: row;
	height: 50px;
	box-shadow: 0px 0px 10px 1px
		${(props) => (props.shadowColor ? props.shadowColor : 'transparent')};
	-webkit-box-shadow: 0px 0px 10px 1px
		${(props) => (props.shadowColor ? props.shadowColor : 'transparent')};
	-moz-box-shadow: 0px 0px 10px 1px
		${(props) => (props.shadowColor ? props.shadowColor : 'transparent')};

	.modal_input_icon_container {
		height: 100%;
		padding-right: 16px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.modal_input_icon {
		width: 16px;
		height: 16px;
		border-radius: 8px;
		align-self: center;
		cursor: pointer;
	}

	.modal_input {
		border: transparent;
		border-radius: 16px;
		flex: 1;
		overflow: hidden;
		padding-left: 16px;
		text-align: left;
		font: normal normal 500 14px/19px Roboto;
		letter-spacing: 0px;
		color: #222222;

		::-webkit-input-placeholder {
			/* Edge */
			text-align: left;
			font: normal normal 300 14px/19px Roboto;
			letter-spacing: 0px;
			color: #9f9f9f;
		}

		:-ms-input-placeholder {
			/* Internet Explorer 10-11 */
			text-align: left;
			font: normal normal 300 14px/19px Roboto;
			letter-spacing: 0px;
			color: #9f9f9f;
		}

		::placeholder {
			text-align: left;
			font: normal normal 300 14px/19px Roboto;
			letter-spacing: 0px;
			color: #9f9f9f;
		}
	}
`;
interface HeaderSwitchProps {
	active?: boolean;
}
const HeaderSwitch = styled.div<HeaderSwitchProps>`
	background: ${(props) => (props.active ? '#18AA36' : '#f21f4a')} 0% 0%
		no-repeat padding-box;
	border-radius: 19px;
	width: 58px;
	height: 32px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: ${(props) => (props.active ? 'flex-end' : 'flex-start')};
	padding-left: 4px;
	padding-right: 4px;

	cursor: pointer;

	transition: all 0.3s;

	margin-left: 16px;

	position: relative;
	.header_switch_button {
		background: #ffffff 0% 0% no-repeat padding-box;
		width: 24px;
		height: 24px;
		border-radius: 12px;
		//position: absolute;
		transition: all 0.3s;
		//${(props) => (props.active ? 'right: 4px' : 'left: 4px')}
	}
`;

const AlterarImg = styled.button`
	border-color: transparent;
	background: #09aa36 0% 0% no-repeat padding-box;
	border-radius: 16px;
	text-align: center;
	font: normal normal 500 12px/16px Roboto;
	letter-spacing: 0px;
	color: #ffffff;
	text-transform: uppercase;
	height: 32px;
	padding-left: 16px;
	padding-right: 16px;
	align-self: center;
	position: absolute;
	bottom: -40px;
`;

const ImageButton = styled.div`
	border: 1px dashed var(--disabled);
	background: #f5f5f5 0% 0% no-repeat padding-box;
	border: 1px dashed #9f9f9f;
	border-radius: 10px;

	display: flex;
	flex-direction: column;
	align-items: center;

	justify-content: space-between;

	padding: 10px;

	//width: 114px;
	max-height: 120px;
	position: relative;
	svg {
		width: 32px;
		height: 27px;
		color: #9f9f9f;
	}
	img {
		width: 100%;
		height: 100%;
		//border-radius: 10px;
	}

	cursor: pointer;
`;

const TypeContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
`;

interface TypeItemProps {
	selected?: boolean;
}

const TypeItem = styled.button<TypeItemProps>`
	background: ${(props) => (props.selected ? '#F6F9F8' : '#f8f8f8')} 0% 0%
		no-repeat padding-box;
	box-shadow: 0px 12px 12px #22222200;
	border: 1px solid ${(props) => (props.selected ? '#18aa36' : 'transparent')};
	border-radius: 16px;
	padding: 16px;
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 166px;
	height: 144px;
	margin-right: 16px;

	.type_item_title {
		background: ${(props) => (props.selected ? '#28A745' : '#9f9f9f')} 0% 0%
			no-repeat padding-box;
		border-radius: 19px;
		text-align: center;
		font: normal normal 500 14px/19px Roboto;
		letter-spacing: 0px;
		color: #ffffff;
		padding-top: 6px;
		padding-bottom: 6px;
		padding-left: 16px;
		padding-right: 16px;
		max-width: 72px;
	}
	.type_item_group {
		display: flex;
		flex-direction: column;
	}
	.type_item_text {
		text-align: left;
		font: normal normal normal 12px/16px Roboto;
		letter-spacing: 0px;
		color: ${(props) => (props.selected ? '#222222' : '#676767')};
	}

	.type_item_check {
		position: absolute;
		top: 16px;
		right: 16px;
		width: 20px;
		height: 20px;
		border-radius: 50%;
		background: #4baf50 0% 0% no-repeat padding-box;
		opacity: ${(props) => (props.selected ? 1 : 0)};
		display: flex;
		align-items: center;
		justify-content: center;
		img {
			width: 17px;
			height: 17px;
		}
	}
`;

const RemoveImg = styled.button`
	width: 20px;
	height: 20px;
	border-radius: 50%;
	background-color: #f21f4a;
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: 5px;
	right: 5px;
	border: solid 1px transparent;

	img {
		width: 14px;
		height: 14px;
	}
`;

interface modalScreenProps {
	categoria?: Categoria;
	tamanho?: number;
	setModalVisible(m: boolean): void;
	UpdateCategoria(c: Categoria): void;
	parent: { id: number; label: string };
}

export default function ModalSub({
	setModalVisible,
	//relatorio,
	categoria,
	tamanho = 0,
	UpdateCategoria,
	parent,
}: modalScreenProps) {
	const [nome, setNome] = useState('');
	const [situacao, setSituacao] = useState(1);
	const [type, setType] = useState('');

	const [ordem, setOrdem] = useState(tamanho);

	const [preimg, setPreimg] = useState<string | undefined>();

	useEffect(() => {
		if (categoria) {
			setNome(categoria.name);
			setOrdem(categoria.sort);
			setSituacao(categoria.status);
			setType(categoria.type);
			// setUrl(categoria.img64);
			setPreimg(categoria.img);
		}
	}, [categoria]);

	const [images, setImages] = useState<any>();
	const [reader, setReader] = useState<any>();
	const onDrop = useCallback((acceptedFiles) => {
		// this callback will be called after files get dropped, we will get the acceptedFiles. If you want, you can even access the rejected files too

		acceptedFiles.map((file: any) => {
			const reader = new FileReader();

			reader.onload = function (e) {
				if (e.target) {
					// console.log(e.target.result);
					const x = e.target.result;

					setImages({ id: 5, src: x });
				}
			};

			reader.readAsDataURL(file);
			setReader(file);

			return file;
		});

		/*if (reader) {
			reader.onload = function (e) {
				if (e.target !== null && e !== null) {
					setImages((prevState: any) => [
						...prevState,
						{ id: 5, src: e.target.result },
					]);
				}
			};

			reader.readAsDataURL(acceptedFiles);
			return acceptedFiles;
		}*/

		//console.log(acceptedFiles);
	}, []);

	const nomeRef = useRef<any>(null);
	const [focusNome, setFocusNome] = useState(false);

	const [isError, setIsError] = useState({
		name: false,
		status: false,
	});

	const [loading, setLoading] = useState(false);
	const history = useHistory();

	async function EditCategoria() {
		setLoading(true);
		const token = sessionStorage.getItem('@bill-token');

		const config = {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		};

		try {
			const data = {
				name: nome,
				status: situacao,
				type,
				parent: parent.id,
				/*type: 'P',
				parent: null*/
			};

			const schema = yup.object().shape({
				name: yup.string().required().min(1),
				status: yup.number().required(),
				type: yup.string().required(),
			});

			await schema.validate(data, {
				abortEarly: false,
			});
			const response = await api.post(
				`admin/api/merchant-category${categoria ? `/${categoria.id}` : ''}`,
				data,
				config
			);

			if (response.data.merchant_category) {
				if (images !== undefined || preimg !== undefined)
					await editImage(response.data.merchant_category);
				else {
					deleteImage(response.data.merchant_category);
				}
			}
		} catch (error: any) {
			console.log(error);
			setLoading(false);

			const validationError = {
				name: false,
				status: false,
			};

			if (error instanceof yup.ValidationError) {
				error.inner.forEach((err) => {
					if (err.path === 'name') validationError[err.path] = true;
					else if (err.path === 'status') validationError[err.path] = true;
				});
			}

			setIsError(validationError);

			if (error.response && error.response.status === 401) {
				history.push('/login');
			}
		}
	}

	async function deleteImage(category: Categoria) {
		const token = sessionStorage.getItem('@bill-token');

		const config = {
			headers: {
				Authorization: `Bearer ${token}`,
				'Content-Type': 'multipart/form-data',
			},
		};

		const response = await api.delete(
			`admin/api/merchant-category/${category.id}/img`,
			config
		);

		if (response.data && response.data.merchant_category) {
			UpdateCategoria(response.data.merchant_category);
		}
		setModalVisible(false);
		setLoading(false);

		try {
		} catch (error) {
			setLoading(false);

			console.log(error);
		}
	}

	async function clearImg() {
		setImages(undefined);
		setPreimg(undefined);
	}

	async function editImage(category: Categoria) {
		const token = sessionStorage.getItem('@bill-token');

		const config = {
			headers: {
				Authorization: `Bearer ${token}`,
				'Content-Type': 'multipart/form-data',
			},
		};
		try {
			const formData = new FormData();

			formData.append('img', reader);

			const response = await api.post(
				`admin/api/merchant-category/${category.id}/img
			`,
				formData,
				config
			);

			if (response.data.merchant_category) {
				UpdateCategoria(response.data.merchant_category);
				setModalVisible(false);
			}
			setLoading(false);
		} catch (error: any) {
			console.log(error);
			setLoading(false);
			if (error.response && error.response.status === 401) {
				history.push('/login');
			}
		}
	}

	return (
		<Modal style={{ maxWidth: 786 }}>
			<ModalHeader>
				<HeaderTitle>
					{parent.label}
					<img alt='>' src={NavigateNext} />
					<span style={{ color: '#222222' }}>
						{categoria ? categoria.name : 'Nova Categoria'}
					</span>
					{categoria && (
						<HeaderSwitch
							active={situacao === 1}
							onClick={(e) => {
								e.preventDefault();
								if (situacao === 1) {
									setSituacao(0);
								} else {
									setSituacao(1);
								}
							}}>
							<div className='header_switch_button' />
						</HeaderSwitch>
					)}
				</HeaderTitle>

				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
					}}>
					<HeaderButton
						onClick={(e) => {
							e.preventDefault();

							setModalVisible(false);
						}}
						style={{
							background: 'transparent',
							color: '#676767',
							marginRight: 8,
						}}>
						Cancelar
					</HeaderButton>
					<HeaderButton
						onClick={(e) => {
							e.preventDefault();
							//EditEmpresa();
							EditCategoria();
						}}
						style={{ background: '#28A745', color: '#FFFFFF' }}>
						{!loading ? (
							'Salvar'
						) : (
							<img className='loading-roll' alt='loading' src={Loading} />
						)}
					</HeaderButton>
				</div>
			</ModalHeader>

			<ModalContainer>
				<InputContainer
					isError={isError.name}
					fieldLabel='Nome da Categoria'
					fieldLabelError='Insira nome da categoria'
					inputName={nome}
					setInputField={setNome}
				/>
				<div className='modal_item_container mb-32'>
					<ModalLabel className='mb-8'>Ícone da Categoria</ModalLabel>
					{images ? (
						<WithDropZone onDrop={onDrop} accept={'image/*'}>
							<ImageButton>
								<img src={images.src} alt='' />
								<AlterarImg>Alterar</AlterarImg>
								<RemoveImg
									onClick={(e) => {
										e.stopPropagation();
										clearImg();
									}}>
									<img alt='x' src={CloseWhite} />
								</RemoveImg>
							</ImageButton>
						</WithDropZone>
					) : preimg ? (
						<WithDropZone onDrop={onDrop} accept={'image/*'}>
							<ImageButton>
								<img
									src={`${process.env.REACT_APP_IMG_HOST}/upload/categories/${preimg}`}
									alt={categoria?.name || 'img'}
								/>
								<AlterarImg>Alterar</AlterarImg>
								<RemoveImg
									onClick={(e) => {
										e.stopPropagation();
										clearImg();
									}}>
									<img alt='x' src={CloseWhite} />
								</RemoveImg>
							</ImageButton>
						</WithDropZone>
					) : (
						<ImageButton>
							<Dropzone onDrop={onDrop} accept={'image/*'} />
						</ImageButton>
						/*<ImageButton>
										<div />
										<UilCamera />

										<Button>Escolher</Button>
									</ImageButton>*/
					)}
				</div>

				<div className='mt-40'>
					<ModalLabel className='mb-8'>
						SELECIONE O TIPO DA CATEGORIA
					</ModalLabel>

					<TypeContainer>
						{parent === undefined && (
							<TypeItem
								selected={type === 'C'}
								onClick={(e) => {
									e.preventDefault();
									setType('C');
								}}>
								<span className='type_item_title'>Tipo 1</span>
								<div className='type_item_group'>
									<span className='type_item_text'>• Subcategorias</span>
									<span className='type_item_text'>• Empresas</span>
									<span className='type_item_text'>• Produtos</span>
								</div>
								<span className='type_item_check'>
									<img alt='v' src={DoneWhite} />
								</span>
							</TypeItem>
						)}
						<TypeItem
							selected={type === 'M'}
							onClick={(e) => {
								e.preventDefault();
								setType('M');
							}}>
							<span className='type_item_title'>Tipo 2</span>
							<div className='type_item_group'>
								<span className='type_item_text'>• Empresas</span>
								<span style={{ opacity: 0 }} className='type_item_text'>
									• Subcategorias
								</span>
								<span style={{ opacity: 0 }} className='type_item_text'>
									• Produtos
								</span>
							</div>
							<span className='type_item_check'>
								<img alt='v' src={DoneWhite} />
							</span>{' '}
						</TypeItem>
						<TypeItem
							selected={type === 'P'}
							onClick={(e) => {
								e.preventDefault();
								setType('P');
							}}>
							<span className='type_item_title'>Tipo 3</span>
							<div className='type_item_group'>
								<span className='type_item_text'>• Produtos</span>
								<span style={{ opacity: 0 }} className='type_item_text'>
									• Empresas
								</span>
								<span style={{ opacity: 0 }} className='type_item_text'>
									• Subcategorias
								</span>
							</div>
							<span className='type_item_check'>
								<img alt='v' src={DoneWhite} />
							</span>
						</TypeItem>
					</TypeContainer>
				</div>
			</ModalContainer>
		</Modal>
	);
}

function InputContainer({
	isError,
	inputName,
	setInputField,
	fieldLabel,
	fieldLabelError,
}: {
	isError: Boolean;
	inputName: string;
	setInputField(s: string): void;
	fieldLabel: string;
	fieldLabelError: string;
}) {
	const inputRef = useRef<any>(null);

	const [focusInput, setFocusInput] = useState(false);

	return (
		<div
			onClick={(e) => {
				e.preventDefault();
				if (inputRef && inputRef.current) {
					inputRef.current.focus();
				}
			}}
			className='modal_item_container mb-40'>
			{!isError ? (
				<ModalLabel className='mb-8'>{fieldLabel}</ModalLabel>
			) : (
				<ModalLabelError className='mb-8'>
					{fieldLabelError ? fieldLabelError : fieldLabel}
				</ModalLabelError>
			)}
			<ModalInputContainer
				shadowColor={
					isError && focusInput
						? 'rgba(232,69,62,0.75)'
						: focusInput
						? 'rgba(40,167,69,0.75)'
						: 'transparent'
				}
				borderColor={
					isError
						? '#E8453E'
						: focusInput || inputName.length > 0
						? '#28A745'
						: '#9f9f9f'
				}>
				<input
					ref={inputRef}
					className='modal_input'
					type='text'
					value={inputName}
					onChange={(e) => {
						setInputField(e.target.value);
					}}
					placeholder='Insira o nome da categoria'
					onFocus={() => {
						setFocusInput(true);
					}}
					onBlur={() => {
						setFocusInput(false);
					}}
				/>
				{inputName.length > 0 && (
					<div
						style={{ opacity: focusInput ? 1 : 0 }}
						className='modal_input_icon_container'>
						<img
							className='modal_input_icon'
							src={ClearIcon}
							alt='x'
							onClick={(e) => {
								e.preventDefault();
								setInputField('');
							}}
						/>
					</div>
				)}
			</ModalInputContainer>
		</div>
	);
}
