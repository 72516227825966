import api from "../../services/api";

export async function downloadXLSRequest(conciliacaoId: number) {
  const token = sessionStorage.getItem("@bill-token");


  try {
    const response = await api.get(
      `admin/api/conciliation/${conciliacaoId}/xml`,
      {
        responseType: "blob",
        headers: {
          Accept: "text/xml",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error: any) {
    console.log(error);
    throw error;
  }
}

export async function getConciliacoesRequest(
    qs:string,
  apelido: string
) {
  const token = sessionStorage.getItem("@bill-token");

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const response = await api.get(
      `/admin/api/conciliation?s=${apelido}${qs}`,
      config
    );

    const { data } = response;
    return data;
  } catch (error: any) {
    console.error(error);
    throw error;
  }
}
