import React from 'react';

const Dev: React.FC = () => {
  if(process.env.REACT_APP_MODE === "local"){
    return (
      <div
        style={{
          width: '100vw',
          color: 'white',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: 47,
          opacity: 0.8,
          background: 'red',
          position: 'absolute',
          zIndex: 100,
          bottom: 0,
        }}
      >
        AMBIENTE DE DESENVOLVIMENTO
      </div>
    );
  }
  return null
}

export default Dev;
